import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHolder,
    Record,
    
    TableLegendElement,
    TableLegendSection
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlBibNeurFigs} from "../../../../database/models/tblLdlBibNeurFigs";


const BibloFigure = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlBibNeur>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getFigureByBiblioPk", _props, (error: boolean, outData: tblLdlBibNeur | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                // console.log(outData)
                setResultData(outData);

            }
        })
    }, []);


    return <>
        {resultData &&
            <Record title={<>Figure Citations <><IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                href={`mailto:j-oswald@tamu.edu?subject=Bibliography of the Neuropterida: comment/correction (r#${resultData.BibObjID})`}
                IconID={LDLIcons.General_Email}/></></>}>
                <ListHolder>
                    <ListElement title={"Bibliographic Record Links"} element={<>
                        {resultData.LinkedTaxon?.length != 0 &&
                            <IconLink href={"../Taxa"} IconID={LDLIcons.BotN_TaxonCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"BibObjID": resultData.BibObjID}}/>}</>}/>
                    <ListElement title={`Reference Citation (r#${resultData.BibObjID})`} element={resultData.FullCite}/>
                </ListHolder>
                    <i>
                        <>Figure Citations ({resultData.LinkedFigs?.length}).</>
                    </i>
                    {resultData.LinkedFigs && <EasyTable downloadable={true} autoBottomMaxHeight={true} downloadName={`Figure_Citations_BotN_r#${resultData.BibObjID}.csv`}  currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData.LinkedFigs}
                                                         extraSymbolsChildren={<><TableLegendElement title={"♂"} element={"male"}/>
                                                             <TableLegendElement title={"♀"} element={"female"}/>
                        <TableLegendElement title={"?"} element={"unknown"}/>
            <TableLegendElement title={"b&w"}
        element={"black and white abbreviation"}/></>}>
                        <EasyTableElement width={"0%"} title={"Links"}
                                          elementFromValue={(val: tblLdlBibNeurFigs) => <>{(resultData.LinkedEDocs && resultData.LinkedEDocs[0] && (resultData.LinkedEDocs[0].PublicEDoc == -1 || (resultData.LinkedEDocs[0].PrivateEDoc == -1 && PDFAccess))) && <>
                                              <IconLink newWindow={true} href={resultData.LinkedEDocs[0].EDocLink}
                                                        IconID={LDLIcons.General_OpenFile_Work} />
                                              <IconLink newWindow={true} href={"/PageViewer/Pages"} IconID={LDLIcons.Viewer_OpenFile}
                                                        newStates={{"BibObjID": resultData.BibObjID}}/></>}</>}
                                          desc={"if available, link(s) to the publication containing the figure"}/>
                        <EasyTableElement width={"5%"} title={"Page"} elementFromValue={(val: tblLdlBibNeurFigs) => val.FigPage}
                                          desc={"the designation for the page or plate on which the figure appears (or starts)"}/>
                        <EasyTableElement width={"5%"} title={"Figure"}
                                          elementFromValue={(val: tblLdlBibNeurFigs) => val['Fig#Citation']}
                                          desc={"the designation used for the figure, clarification and disambiguation information are given in square brackets []"}/>
                        <EasyTableElement width={"3%"} title={"Stage"}
                                          elementFromValue={(val: tblLdlBibNeurFigs) => val.FigLifeStage}
                                          desc={"the life stage(s) illustrated in or associated with the figure, if applicable"}/>
                        <EasyTableElement width={"0%"} title={"Sex"} elementFromValue={(val: tblLdlBibNeurFigs) => val.FigSexSymbol}
                                          desc={"the sex(es) illustrated in or associated with the figure, if applicable"}/>
                        <EasyTableElement width={"35%"} title={"Taxon"}
                                          elementFromValue={(val: tblLdlBibNeurFigs) => val.FigPrTaxonName}
                                          desc={"the cited scientific name (corrected only for misspellings), followed by its valid scientific name in the Lacewing Digital Library (LDL) standard taxonomy (if different)"}/>
                        <EasyTableElement width={"15%"} title={"Subject"}
                                          elementFromValue={(val: tblLdlBibNeurFigs) => val.FigSubject}
                                          desc={"the subject matter or scope of the figure, as it pertains to the associated taxon"}/>
                        <EasyTableElement width={"10%"} title={"View"} elementFromValue={(val: tblLdlBibNeurFigs) => val.FigView}
                                          desc={"the aspect from which the primary subject matter of the figure is depicted"}/>
                        <EasyTableElement width={"10%"} title={"Style"}
                                          elementFromValue={(val: tblLdlBibNeurFigs) => val.FigArtAbbrev}
                                          desc={"the kind of artwork that the figure represents in the cited publication"}/>
                        <EasyTableElement width={"15%"} title={"Notes"} elementFromValue={(val: tblLdlBibNeurFigs) => val.FigNotes}
                                          desc={"miscellaneous notes, if any"}/>
                    </EasyTable>}
            </Record>}</>
};


export default BibloFigure;