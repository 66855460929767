import React from "react";
import {useLocation} from "react-router-dom";
import {AcknowledgmentsTEXT} from "./Shared/AcknowledgmentsTEXT";

const Faunas_Acknowledgments = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);


    return <div className="content">
        <div className="text-body">
            <h1>Acknowledgments</h1>
            {// @ts-ignore
                AcknowledgmentsTEXT[paths[0]]?AcknowledgmentsTEXT[paths[0]]:<>
                    <p>[We thank ...]</p>
                    <p style={{marginTop: "5px"}}>[...]</p>

                </>}
        </div>
    </div>
};
export default Faunas_Acknowledgments;