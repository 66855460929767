import React from "react";

const Testing_Main = () => {

    return <>

        <div className="content">
            <div className="text-body">

                <h1>Lacewing Digital Library Testing</h1>
                <p className="subheader text-center">
                    A testing module for LDL to experiment with new features
                </p>

                <br/>

                {/* >>>>>>> master */}
                <p className="text-center">
                    John D. Oswald<br/>
                    Department of Entomology, Texas A&amp;M University<br/>
                    College Station, Texas 77843-2475 USA
                </p>

                <p className="text-center">
                    <a style={{width: "100px",}} href="Graphics" className="btn btn-info">
                        Graphics Tests
                    </a>
                </p>
                <span/>

                <div className="text-body">
                    <p>

                        <i><u>Abstract</u></i>:

                        <br/>
                        <br/>
                        <i><u>Cite this Publication as</u></i>:
                        <br/>
                        <br/>
                        <small>(Notes: The different modules of the Lacewing Digital Library constitute distinct
                            publications and should be cited separately. For information on the current version and
                            release of this publication see the <a href="/Biblio/Versions">Versions</a> page.)</small>
                        {/* >>>>>>> master */}
                        <br/>
                        <br/>
                        <i><u>Author Contact Information</u></i>: (JDO, corresponding author) <a
                        href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.
                    </p>
                </div>

                <div className="text-body">
                    <p className="author-reserve-markup">© John D. Oswald, {new Date().getFullYear()}<br/>All rights
                        reserved. </p>
                </div>
            </div>
        </div>
    </>
};

export default Testing_Main;