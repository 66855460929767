import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import {callGETAPI} from "../../utils/APIhelpers";
import {Link, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    Icon,
    IconLink,
    ParagraphDesc,
    Record,
    SafeHTML,
    TableEndLine,
    TableLegendElement,
    TableLegendSection
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCatVersions} from "../../../../database/models/tblLdlSpCatVersions";


const Species_Versions = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlSpCatVersions[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callGETAPI("/api/general/getSpeciesVersions", (error: boolean, outData: tblLdlSpCatVersions[] | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, []);

    return <>{resultData &&
        <Record title={"Versions"}>
            <ParagraphDesc><>The Neuropterida Species of the World (NSW) is an integrating resource that contains web
                site and database elements. Both classes of elements are updated episodically. Significant one-time or
                cumulative updates are sequentially numbered as new ‘versions.’ Smaller updates – to data,
                functionality, or both – made between version breaks are treated as ‘releases.’ In the tables below, the
                states of the NSW on the dates of initial release of several of the versions, and sometimes the active
                release, are provided for a variety of documentary data. Table 4 provides information on release dates
                and selected benchmark metrics and highlights; table 5 gives counts for several classes of data of
                nomenclatural and taxonomic interest; table 6 summarizes design and functionality changes; table 7 displays links to an
                archive of “snapshots” of selected NSW pages; and table 8 provides data on the size and scope (record
                and column counts) of the primary data tables used to deliver the NSW.
            </>
            </ParagraphDesc>

            <EasyTable caption={<>Table 4. Neuropterida Species of the World: version dates, benchmark metrics, and highlights.</>} tableRowData={resultData} extraLegendChildren={
                <TableLegendSection title={"Abbreviations"}>
                    <TableLegendElement title={"\"–\""} element={"not available; n/a – not applicable (no such page)"}/>
                </TableLegendSection>}>
                <EasyTableElement textIndent={"0px"} whiteSpace={"nowrap"} width={"10%"} align={"center"} title={"Version"}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.Version}/>}
                                  desc={"the number assigned to the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} title={"Release Date"}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.DateDocumented}/>}
                                  desc={"the initial release date of the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} title={"Searchable Combinations"}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.CombinationCount}/>}
                                  desc={"the number of distinct scientific name combinations (i.e., unique searchable strings of available and unavailable genus- and species-group names) contained in the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} legendTitle={"Epithet Record Pages"} title={<>Epithet Record<br/>Pages</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.CatalogueRecordCount}/>}
                                  desc={"the number of distinct Epithet Record pages programmatically generated in the version or active release; the number of Epithet Record pages is approximately equal to the sum of the number of unique available and unavailable names treated at each rank in the extended species group of ranks (i.e., species, subspecies, infrasubspecies, subinfrasubspecies)"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} title={"Monograph Record Pages"}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.MonographRecordCount}/>}
                                  desc={"the number of distinct Monograph Record pages programmatically generated in the version or active release; the number of Monograph Record pages is approximately equal to the number of valid Neuropterida species and subspecies plus the number of unavailable Neuropterida species-group names that are not assigned to a valid species or subspecies"}/>
                <EasyTableElement textIndent={"0px"} width={"50%"} align={"center"} title={"Highlights"}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.Benchmarks}/>}
                                  desc={"notable features or milestones associated with the version or active release"}/>
            </EasyTable>

            <EasyTable caption={<>Table 5. Neuropterida Species of the World: species-group names and valid taxa.</>} tableRowData={resultData.filter((val)=>val.SortNum&&val.SortNum>40)} extraLegendChildren={<TableLegendSection title={"Notes"}>
                <>For additional information on the selected non-Neuropterida names included in the NSW see <Link
                    to="../Introduction" itemID={"scope"}>Introduction: Scope</Link></>
            </TableLegendSection>}>
                <EasyTableElement textIndent={"0px"} whiteSpace={"nowrap"} align={"center"} title={"Version"}
                                  elementFromValue={(val) => <SafeHTML html={val.Version}/>}/>
                <EasyTableSection align={"center"} title={"Species-Group Names"}>
                    <EasyTableSection align={"center"} title={"Neuropterida"}>
                        <EasyTableSection align={"center"} title={"Available"}>
                            <EasyTableElement textIndent={"0px"} align={"center"} title={"Valid"}
                                              elementFromValue={(val) => <SafeHTML html={val.ValidNameCount}/>}/>
                            <EasyTableElement textIndent={"0px"} align={"center"} title={"Invalid"}
                                              elementFromValue={(val) => <SafeHTML html={val.InvalidNameCount}/>}/>
                        </EasyTableSection>
                        <EasyTableElement textIndent={"0px"} align={"center"} title={"Unavailable"}
                                          elementFromValue={(val) => <SafeHTML html={val.UnavailableNameCount}/>}/>
                    </EasyTableSection>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Non-Neuropterida"}
                                      elementFromValue={(val) => <SafeHTML html={val.NonNidaNameCount}/>}/>
                </EasyTableSection>
                <EasyTableSection rowSpan={2} align={"center"} title={"Valid Neuropterida Taxa"}>
                    <EasyTableSection align={"center"} title={"Extant"}>
                        <EasyTableElement textIndent={"0px"} align={"center"} title={"Species"}
                                          elementFromValue={(val) => <SafeHTML html={val.NidaExtantSpecCount}/>}/>
                        <EasyTableElement textIndent={"0px"} align={"center"} title={"Subspecies"}
                                          elementFromValue={(val) => <SafeHTML html={val.NidaExtantSubsCount}/>}/>
                    </EasyTableSection>
                    <EasyTableSection align={"center"} title={"Extinct"}>
                        <EasyTableElement textIndent={"0px"} align={"center"} title={"Species"}
                                          elementFromValue={(val) => <SafeHTML html={val.NidaExtinctSpecCount}/>}/>
                        <EasyTableElement textIndent={"0px"} align={"center"} title={"Subspecies"}
                                          elementFromValue={(val) => <SafeHTML html={val.NidaExtinctSubsCount}/>}/>
                    </EasyTableSection>
                </EasyTableSection>
            </EasyTable>

            <EasyTable caption={<>Table 6. Neuropterida Species of the World: design and functionality changes.</>} tableRowData={resultData.filter((val)=>val.ChangesHeader!="&ndash;")}>
                <EasyTableElement whiteSpace={"nowrap"} width={"0%"} title={"Interval"} elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                    html={val.ChangesHeader}/>}/>
                <EasyTableElement width={"85%"} textIndent={"0px"} title={"Changes"}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.ChangesText}/>}/>
            </EasyTable>

            <EasyTable caption={<>Table 7. Neuropterida Species of the World: page archive (selected pages).</>} tableRowData={resultData.filter((val)=>val.SortNum&&val.SortNum>40)} extraLegendChildren={<TableLegendSection title={"Symbols"}>
                <TableLegendElement title={<Icon IconID={LDLIcons.General_OpenFile_Work}/>}
                                    element={"not available; n/a – not applicable (no such page)"}/>
            </TableLegendSection>}>
                <EasyTableElement textIndent={"0px"} whiteSpace={"nowrap"} align={"center"} title={"Version"}
                                  elementFromValue={(val) => <SafeHTML html={val.Version}/>}/>
                <EasyTableSection align={"center"} title={"Neuropterida Species of the World Page"}>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Main"}
                                      elementFromValue={(val) => <>{val.PageMainURL && val.PageMainURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageMainURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageMainURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Introduction"}
                                      elementFromValue={(val) => <>{val.PageIntroductionURL && val.PageIntroductionURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageIntroductionURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageIntroductionURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Simple Search"}
                                      elementFromValue={(val) => <>{val.PageSimpleSearchURL && val.PageSimpleSearchURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageSimpleSearchURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageSimpleSearchURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Advanced Search"}
                                      elementFromValue={(val) => <>{val.PageAdvancedSearchURL && val.PageAdvancedSearchURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageAdvancedSearchURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageAdvancedSearchURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Epithet Record"}
                                      elementFromValue={(val) => <>{val.PageCatalogueRecordURL && val.PageCatalogueRecordURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageCatalogueRecordURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageCatalogueRecordURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Monograph Record"}
                                      elementFromValue={(val) => <>{val.PageMonographRecordURL && val.PageMonographRecordURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageMonographRecordURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageMonographRecordURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={<>Acknowle&#8203;dgements</>}
                                      elementFromValue={(val) => <>{val.PageAcknowledgmentsURL && val.PageAcknowledgmentsURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageAcknowledgmentsURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageAcknowledgmentsURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"References"}
                                      elementFromValue={(val) => <>{val.PageReferencesURL && val.PageReferencesURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageReferencesURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageReferencesURL}/>}</>}/>
                    <EasyTableElement textIndent={"0px"} align={"center"} title={"Versions"}
                                      elementFromValue={(val) => <>{val.PageVersionsURL && val.PageVersionsURL != "&ndash;" ?
                                          <IconLink newWindow={true} href={val.PageVersionsURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageVersionsURL}/>}</>}/>
                </EasyTableSection>
            </EasyTable>

            <EasyTable caption={<>Table 8. Neuropterida Species of the World: principal data tables (upper number = table record count;
                lower number = table column count).</>} align={"center"} tableRowData={resultData} extraLegendChildren={<>
                <TableLegendSection title={"Abbreviations"}>
                    <TableLegendElement title={"\"–\""} element={"not available; n/a – not applicable (no such page)"}/>
                </TableLegendSection>
                <TableLegendSection title={"Notes"}>
                    <> Cell values represent table record count (upper number) and table column count (lower number)</>
                </TableLegendSection></>}>
                <EasyTableElement textIndent={"0px"} whiteSpace={"nowrap"} title={<>Version</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.Version}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Module<br/>Version<br/>Data<br/>(SpCat<br/>Versions)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatVersions}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Basic<br/>Taxon<br/>Data<br/>(SpCat)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.SpCat}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Synonym<br/>Data<br/>(SpCat<br/>Comb)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.SpCatComb}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Taxon<br/>Citation<br/>Data<br/>(SpCat<br/>AnnotCite)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatCombAnnot}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Figure<br/>Citation<br/>Data<br/> (SpCat<br/>Figs)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML html={val.SpCatFigs}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Geographic<br/>Distribution<br/>Data<br/>(SpCat<br/>DistGeog)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatDistGeog}/>}/>

                <EasyTableElement textIndent={"0px"} title={<>Type<br/>Specimen<br/>Data<br/>(SpCatType<br/>Spmn)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatTypeSpmn}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Type<br/>Specimen<br/>TypeSpmn<br/>(SpCatType<br/>SpmnPart)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatTypeSpmnPart}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Nomenclatural<br/>Act<br/>Data<br/>(SpCatType<br/>NomAct)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatTypeNomAct}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Type<br/>Extract<br/>Data<br/>(SpCatType<br/>Extract)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={val.SpCatTypeExtract}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Phylogenetic<br/>Tree<br/>Data<br/>(SpCat<br/>PhyloTree)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={"&ndash;"}/>}/>
                <EasyTableElement textIndent={"0px"} title={<>Congener<br/>Control<br/>Data<br/>(SpCat<br/>Congen)</>}
                                  elementFromValue={(val: tblLdlSpCatVersions) => <SafeHTML
                                      html={"&ndash;"}/>}/>
            </EasyTable>

        </Record>}</>
};


export default Species_Versions;