// @ts-ignore

import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import callAPI from "../../utils/APIhelpers";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {
    EasyTable,
    EasyTableElement,
    getPageCount,
    Icon,
    IconButton,
    IconLink,
    LDLPDFPageViewer,
    LDLPDFViewer,
    Record,
    SelectFromArray,
    SplitPageHolder,
    TableEndLine
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlEDocPages} from "../../../../database/models/tblLdlEDocPages";
import {tblLdlEDocs} from "../../../../database/models/tblLdlEDocs";
import ViewerIndexes from "../../utils/OnServerStartLoadedJsons/ViewerIndexes.json"
import {tblLdlViewerIndexesAttributes} from "../../../../database/models/tblLdlViewerIndexes";
import {tblLdlViewerIndexDetails} from "../../../../database/models/tblLdlViewerIndexDetails";
import "../../Content/pageviewer.css"
import "../../Content/OCRComponent.css"

const ViewerIndexObject: tblLdlViewerIndexesAttributes[] = ViewerIndexes as unknown as tblLdlViewerIndexesAttributes[];


//test regex for page numbers
//.*(?:(?:\[=|: ?)([^", ]+?)(?:-([^", ]+?))?(?:"|]|\n))
/*
* TODO
* 1076
* 1357
* 1636
*1653
*
*
* last tested was 1746
* */

/*const romanFromTranslate: { [KEY: string]: number } = {I: 1, IV: 4, V: 5, IX: 9, X: 10, XL: 40, L: 50, XC: 90, C: 100, CD: 400, D: 500, CM: 900, M: 1000}
const romanToTranslate = [['M'], ['C', 'D'], ['X', 'L'], ['I', 'V']];
*/
// 14 3 -5 -2
/*const fromRoman = (input: string) => {
    if (!Number.isNaN(Number(input))) {
        return Number(input);
    }
    let out: number = 0;
    for (let i = input.length - 1; i >= 0; i -= 1) {
        let a: number | undefined = romanFromTranslate[input[i - 1] + input[i]];
        if (a) {
            i--;
        } else {
            a = romanFromTranslate[input[i]];
        }
        out += a;

    }
    return out;
}
const toRoman = (input: number) => {
    const inputString = String(input).padStart(4);
    let outputString = "";
    for (let i = 0; i < 4; i++) {
        const myNum = Number(inputString[i]);
        const myRoman = romanToTranslate[i];

        if (myNum < 4) {
            outputString += ''.padEnd(myNum, myRoman[0]);
        } else if (myNum == 4) {
            outputString += myRoman[0] + myRoman[1];
        } else if (myNum == 9) {
            outputString += myRoman[0] + romanToTranslate[i - 1][0];
        } else {
            outputString += myRoman[1].padEnd(myNum - 4, myRoman[0]);
        }

    }

    return outputString;

}*/

/*enum pageType{
    bib,
    img,
    none,
    text
}*/


const Pages = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);
    const [resultData, setResultData] = useState<tblLdlBibNeur>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;
    const [bibSearchValue, setBibSearchValue] = useState(_props["BibObjID"])
    const [currentPage, setCurrentPage] = useState(1);
    const [justPage, setJustPage] = useState(false)
    const [selectedEDoc, setSelectedEDoc] = useState<tblLdlEDocs>()
    const [isViewer, setIsViewer] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [error, setError] = useState(0)
    //const [downloadLink, setDownloadLink] = useState<string>();
    //const [pageTypes, setPageTypes] = useState<pageType[]>()
    // const [pageData, setPageData] = useState<{type:pageType,possibleHeaderPages?:number,possibleFooterPages?:number}[]>()
    // const [pageTypeData ,setPageTypeData]=useState({imgCount:0,noneCount:0,bibCount:0,textCount:0,totalCount:0});
    // const [pageRanks,setPageRanks]=useState<{val:number,index:number}[]>()
    // const [sortedPageRanks,setSortedPageRanks]=useState<{val:number,index:number}[]>()
    //const [calculatedIndexes,setCalculatedIndexes]=useState<string[]>()
    useEffect(() => {
        callAPI("/api/general/getBiblioPagesByPk", {BibObjID: _props["BibObjID"]}, (error: boolean, outData: tblLdlBibNeur | Response) => {
            if (error || outData == null) {
                console.log(error);
                setResultData(undefined);
                setError(1)
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
                setIsViewer(!!(outData.ViewerEDocs?.length));
                let inOrderOptions = outData.ViewerEDocs && outData.LinkedEDocs ? ([...outData.ViewerEDocs, ...outData.LinkedEDocs]) : (outData.ViewerEDocs?.length!=0 ? outData.ViewerEDocs : outData.LinkedEDocs)
                inOrderOptions = inOrderOptions?.filter((val) => val.PublicEDoc == -1 || (val.PrivateEDoc == -1 && PDFAccess));
                const selected = inOrderOptions ? inOrderOptions[0] : undefined

                if (!selected) {
                    setError(2);
                }
                //const selected=(outData.LinkedEDocs?.at(0))
                setSelectedEDoc(selected)
                if (outData.EDocPages) {
                    const newIndex = outData.EDocPages.findIndex((val) => val.EDocPageID == _props["EDocPageID"]);
                    if (newIndex != -1) {
                        setCurrentPage(newIndex + 1)
                        setJustPage(true)
                    }
                }
                if (selected && selected.EDocLink) {
                    //  console.log(selected)
                    getPageCount(selected.EDocLink, (n: number) => {
                        //     console.log(n)
                        setPageCount(n)
                    })
                    //const selected2=(outData.LinkedEDocs?.at(0))
                }
            }
        })
    }, [_props["BibObjID"]]);


    /* const handlePageGroups = (regExpMatchArrays: IterableIterator<RegExpMatchArray>) => {
         return Array.from(regExpMatchArrays).map((val) => {
             const start = Number(val[3] ? val[3] : (val[1] ? val[1] : (val[6] ? val[6] : val[5])));
             return {
                 start: start,
                 end: Number(val[4] ? val[4] : val[2] ? val[2] : start),
                 startDisplay: val[1] ? val[1] : val[5],
                 endDisplay: String(val[2] ? val[2] : start),
             }
         })
     }
     const handlePageRomanGroups = (regExpMatchArrays: IterableIterator<RegExpMatchArray>) => {
         return Array.from(regExpMatchArrays).map((val) => {
             const start = val[4] ? val[4] : (val[2] ? val[2] : (val[7] ? val[7] : (val[6]?val[6]:'I')));
             return {
                 start: fromRoman(start),
                 end: fromRoman(val[5] ? val[5] : val[3] ? val[3] : (val[1]?val[1]:start)),
                 startDisplay: toRoman(fromRoman(val[2] ? val[2] : (val[6]?val[6]:'I'))),
                 endDisplay: toRoman(fromRoman(val[3] ? val[3] : (val[1]?val[1]:start))),
             }
         })
     }*/


    /* let pageRanges = resultData && resultData.VolPg ? (handlePageGroups(resultData.VolPg.matchAll(/(?:^.+?:|(?:, ))(?:(?:(?:(\d+|\w+)-\[?(\d+|\w+)\]?)(?: \[=(?:(\d+)-\[?(\d+)\]?))?)|(\d+|\w+)(?: \[=(\d+)\])?)/g))) : undefined;

     const pageSepRanges = resultData && resultData.VolPgSep ? (handlePageGroups(resultData.VolPgSep.matchAll(/(?:(?:^)|(?:^.+?:)|(?:, ))(?:(?:(?:(\d+|\w+)-\[?(\d+|\w+)\]?)(?: \[=(?:(\d+)-\[?(\d+)\]?))?)|(\d+|\w+)(?: \[=(\d+)\])?)/g))) : undefined;
     if(!pageRanges){
         console.log(pageSepRanges)
         pageRanges=pageSepRanges
     }
     const possibleExtraPages = resultData && resultData.GenNotes ? (handlePageGroups(resultData.GenNotes.matchAll(/p\. (?:(?:(?:(\d+)-\[?(\d+)\]?)(?: \[=(?:(\d+)-\[?(\d+)\]?))?)|(\d+)(?: \[=(\d+)\])?)/g))) : undefined;

     const possibleExtraFigPages = resultData && resultData.Figs ? (handlePageRomanGroups(resultData.Figs.matchAll(/(?:\[(\d+?)\] )?u?n?paginated(?: color)? plates? ?(?:(?:\[#(?:(?:(?:([IVXLCDM]+|\d+)-\[?([IVXLCDM]+|\d+)\]?)(?: \[=(?:([IVXLCDM]+|\d+)-\[?([IVXLCDM]+|\d+)\]?))?)|([IVXLCDM]+|\d+)(?: \[=([IVXLCDM]+|\d+)\])?)\])|(?:with))/g))) : undefined;


     const mapPageRange = (val: { start: number, end: number, startDisplay: string, endDisplay: string }) => {
         const isRoman = String(val.start) != val.startDisplay;
         if(Number.isNaN(Number(val.end - val.start + 1))){
             return []
         }
         return Array.from(Array(val.end - val.start + 1).keys()).map((index) => {
             if (isRoman) {
                 return {display: "pl. " + toRoman(val.start + index), val: val.start + index}
             }
             return {display: "p. " + (val.start + index), val: (val.start + index)}
         })

     }

     const citedPageIndexes = pageRanges?.map(mapPageRange).flat();
     const extraPageIndexes = possibleExtraPages?.map(mapPageRange).flat()
     const pageIndexes = extraPageIndexes ? citedPageIndexes?.concat(extraPageIndexes) : citedPageIndexes;
     const pageFigIndexes = possibleExtraFigPages?.map(mapPageRange).flat()
     const pageDefIndexes = Array.from(new Set(pageIndexes?.sort((val, val2) => {
         return val.val - val2.val
     })?.map(val => val.display)));*/


    /*useEffect(() => {
        getPagesText(selectedEDoc?.EDocLink, (s: any) => {
            let nullCount = 0;
            const unSortedPageRanks:{val:number,index:number}[]=s.map((v: any,index:number) => {
                const num = Number(v.items.map((o: any) => o.str).join("").length);
                if(num==0){nullCount++;}
                return {val:num,index:index}});
            const SortedPageRanks=unSortedPageRanks?[...unSortedPageRanks].sort((a:{val:number,index:number},b:{val:number,index:number})=>a.val-b.val):undefined
            setPageRanks(unSortedPageRanks);
            setSortedPageRanks(SortedPageRanks);
            let pageFigIndexes = possibleExtraFigPages?.map(mapPageRange).flat()
            let offset = -((pageDefIndexes.length+(pageFigIndexes?.length as number) + (/Bibliography of the NeuropteridaBibliography of the Neuropterida Reference number \(r#\):\d+?Reference Citation:.*Copyrights:Any\/all applicable copyrights reside with, and are reserved by, the publisher\(s\), theauthor\(s\) and\/or other entities as allowed by law\. No copyrights belong to theBibliography of the Neuropterida\.(?: Work made available through the Bibliography of theNeuropterida with permission\(s\) obtained, or with copyrights believed to be expired\.)?Notes:.*File:File produced for the Bibliography of the Neuropterida \(BotN\) component of the(?: Global)?Lacewing Digital Library \(G?LDL\) Project, \d+?\./g.test(s[s.length - 1].items.map((o: any) => o.str).join(""))?1:0)) - pageCount);
            let offsetZero = offset==0;
            let titleOffset=0;
           if(offset==0){
               offset=(pageFigIndexes?.length as number) - 1 +nullCount;
               if(SortedPageRanks&&SortedPageRanks[nullCount] && SortedPageRanks[nullCount].val>500){
                    offset+=2;
               }
           }else if(offset<0) {

               offset=(pageFigIndexes?.length as number) - 1 ;
               if(nullCount != pageFigIndexes?.length){
                    offset+=nullCount;
               }
           }else if(pageFigIndexes?.length==0){
               pageFigIndexes=[{start:1,end:offset+1,startDisplay:"I",endDisplay:toRoman(offset+1)}].map(mapPageRange).flat()
               offset=0;
               offsetZero=true;
           }else if((pageFigIndexes?.length as number)-1 != offset){
               titleOffset = offset;
               offset=(pageFigIndexes?.length as number) - 1 ;
           }


            console.log(offset,sortedPageRanks)
            const pageNumCutoff=(SortedPageRanks&&SortedPageRanks[offset])?(SortedPageRanks[offset].val):160;
            let pageTypeData = {imgCount:0,noneCount:0,bibCount:0,textCount:0,totalCount:0}
            const pageTypes=s.map((v: any, i: number) => {
                pageTypeData.totalCount++;
                const str=v.items.map((o: any) => o.str).join("");
                const num = str.length;
                if (i == s.length - 1 && /Bibliography of the NeuropteridaBibliography of the Neuropterida Reference number \(r#\):\d+?Reference Citation:.*Copyrights:Any\/all applicable copyrights reside with, and are reserved by, the publisher\(s\), theauthor\(s\) and\/or other entities as allowed by law\. No copyrights belong to theBibliography of the Neuropterida\.(?: Work made available through the Bibliography of theNeuropterida with permission\(s\) obtained, or with copyrights believed to be expired\.)?Notes:.*File:File produced for the Bibliography of the Neuropterida \(BotN\) component of the(?: Global)?Lacewing Digital Library \(G?LDL\) Project, \d+?\./g.test(str)) {
                    pageTypeData.bibCount++;
                    return pageType.bib;
                }

                const isSpceificThing=((/^.*?PLATE \d+?.*?$/g.test(str)&&Object.values(v.styles).findIndex((val:any)=>val.fontFamily=='serif')!=-1)||(/^EXPLANATION OF PLATES.?$/g.test(str)));
                if(num>pageNumCutoff||(!offsetZero&&isSpceificThing)||(offsetZero&&num!=0&&nullCount==(pageFigIndexes?.length as number))){
                    pageTypeData.textCount++;
                }else if(num==0){
                    pageTypeData.noneCount++;
                }else{
                    pageTypeData.imgCount++;
                }
                return (num>pageNumCutoff||(!offsetZero&&isSpceificThing)||(offsetZero&&num!=0&&nullCount==(pageFigIndexes?.length as number)))?pageType.text:(num==0?(pageType.none):(pageType.img));
            })
            setPageTypeData(pageTypeData);
            setPageTypes(pageTypes);
            handleCalcIndexes(pageFigIndexes,pageTypeData,pageTypes,SortedPageRanks,titleOffset);
        })
    }, [selectedEDoc,pageCount]);

    */
    /*
    * possible states
    * pc = cite + bib
    * pc = cite
    * pc = cite + bib + img
    * pc = cite + img
    * pc = cite + bib
    * pc = cite
    * pc = cite + bib + img
    * pc = cite + img
    * */
    /*const stateMap = ()=>{
        let cited={img:pageFigIndexes?.length,page:pageDefIndexes?.length}
        let detected = {img:pageTypeData.imgCount,page:pageTypeData.textCount,other:pageTypeData.noneCount,total:pageCount-pageTypeData.bibCount}
        return<>
        imgLenDIFF: {String((cited.img as number)-detected.img)}<br/>
        imgLenDIFFOther: {String((cited.img as number)-detected.other)}<br/>
        imgLenDIFFOther&IMG: {String((cited.img as number)-(detected.other+detected.img))}<br/>
        pageLenDIFF: {String(cited.page-detected.page)}<br/>
        totalAddLenDIFF:{String((cited.img as number+cited.page)-detected.total)}<br/>
        totalLenEqPageDIFF: {String(cited.page-detected.total)}<br/>
        </>
    }*/


    //console.log(giveUp)

    /*const handleCalcIndexes= (pageFigIndexes: FlatArray<{ val: number; display: string }[][], 1>[] | undefined, pageTypeData: { noneCount: number; bibCount: number; imgCount: number; textCount: number; totalCount: number }, pageTypes: any, sortedPageRanks: { val: number; index: number }[] | undefined, titleOffset: number)=>{
        let figIndex = pageFigIndexes?.length?(pageFigIndexes.length-1):0;
        let numFigs = 0;
        let numFakeFigs = 0;
        let textIndex = 0;
        let titleEnded = false;
        let totalIMG_left = pageFigIndexes?.length as number;
        let totalTEXT_left = pageDefIndexes?.length as number;
        let totalUnused = 0;
        const imagesInLine = pageTypeData.totalCount <= (pageTypeData.bibCount+pageDefIndexes?.length)
        let distfail=false;
        let dist = -1;
        let currLoc = -1;
        if(pageTypes) {
            [...pageTypes]?.reverse()?.map((val, i) => {
                if (val == pageType.img && currLoc == -1) {
                    currLoc = i;
                } else if (val == pageType.img) {
                    if (dist == -1) {
                        dist = currLoc - i;
                    } else if (dist != currLoc - i) {
                        distfail = true;
                    }
                    currLoc = i;
                }
            })
        }
        const tooRandom=distfail;
        let giveUp=false;
        if(!resultData?.LangText?.includes("(Latin)")){giveUp=true}
        console.log(sortedPageRanks)
        let textEnded=false;
        let stillTitle=pageFigIndexes?.length==0&&pageDefIndexes.length<pageCount
        const tooManyNullOnTop = pageTypes!=undefined&&pageTypes[0]==pageType.img&&pageTypes[1]==pageType.none;
        /*setCalculatedIndexes(Array.from(Array(pageCount).keys()).map(
            (currentVal, index) => {
                if (pageTypes&&titleOffset<=index && pageTypes[index]!=undefined&&((!giveUp&&!stillTitle)||pageTypes[index]==pageType.bib)) {
                    const myPageType = pageTypes[index];
                    if (myPageType==pageType.bib) {
                        return "Bibliography Citation Page"
                    } else if (myPageType==pageType.none) {
                        if (titleEnded&&!textEnded&&!numFigs && textIndex < pageDefIndexes.length) {
                            totalTEXT_left--;
                            textIndex++;
                            return pageDefIndexes[textIndex - 1]
                        } else if (titleEnded && pageFigIndexes?.length) {
                            numFakeFigs++;
                            return "%POSSIBLE IMAGE%"
                        }
                    } else if (titleEnded&&myPageType==pageType.img && pageFigIndexes?.length) {
                        if(imagesInLine){
                            textIndex++;
                            totalTEXT_left--;
                        }
                        if(numFigs+1==pageFigIndexes?.length) {
                             textEnded=true;
                        }
                        numFigs++;
                        return "%POSSIBLE IMAGE%"
                    } else if(!(tooManyNullOnTop&&!titleEnded&&myPageType!=pageType.text)&&!textEnded&&textIndex < pageDefIndexes.length){
                        if(myPageType==pageType.text) {
                            titleEnded = true;
                        }
                        totalTEXT_left--;
                        textIndex++;
                        return pageDefIndexes[textIndex - 1];
                    }
                }
                return '%OTHER%';
            }).reverse().map((val,index)=>{
            if(!giveUp&&val=='%POSSIBLE IMAGE%'&&pageFigIndexes&&figIndex>=0){
                totalIMG_left--;
                figIndex--;
                return imagesInLine?pageDefIndexes[(pageCount-(index)-1)]+(distfail?"":" [="+pageFigIndexes[figIndex+1]?.display+"]"):pageFigIndexes[figIndex+1]?.display
            }else if(val=='%POSSIBLE IMAGE%'){
                if (!giveUp&&(titleEnded||stillTitle) && totalTEXT_left>=1) {
                    totalTEXT_left--;
                    return pageDefIndexes[textIndex+totalTEXT_left]
                }
                totalUnused++;
                return "PDF. " + (pageCount-(index));
            }else if(!giveUp&&(!(numFigs&&!distfail)||pageTypeData.imgCount<(pageFigIndexes?pageFigIndexes.length:0))&&titleEnded&&(val=='%OTHER%'||(pageTypes&&pageTypes[(pageCount-(index+1))]==pageType.none))&&pageFigIndexes&&figIndex>=0){
                totalIMG_left--;
                figIndex--;
                return imagesInLine?pageDefIndexes[(pageCount-(index)-1)]+(distfail?"":" [="+pageFigIndexes[figIndex+1]?.display+"]"):pageFigIndexes[figIndex+1]?.display
            }else if(val=='%OTHER%'){
                if (!giveUp&&(titleEnded||stillTitle) && totalTEXT_left>=1) {
                    totalTEXT_left--;
                    return pageDefIndexes[textIndex+totalTEXT_left]
                }
                totalUnused++;
                return "PDF. " + (pageCount-(index));
            }
            return val;
        }).reverse().map((v,i)=>(v+" (index: "+sortedPageRanks?.findIndex((v)=>v.index==i) + ")")));*/
    //}

    /*if(resultData?.VolPgSep!='(not available)') {
        if (pageFigIndexes && calculatedIndexes && calculatedIndexes.length < pageCount - 1) {
            if (pageFigIndexes.length && calculatedIndexes && (calculatedIndexes.length+pageFigIndexes.length*2) < pageCount - 1) {
                calculatedIndexes.push("pl (holder)");
                calculatedIndexes = calculatedIndexes.concat(pageFigIndexes.map((val => [val.display + " (desc)",val.display + " (desc 2)",val.display])).flat());
            }else if (pageFigIndexes && calculatedIndexes && (calculatedIndexes.length+pageFigIndexes.length) < pageCount - 1) {
                calculatedIndexes = calculatedIndexes.concat(pageFigIndexes.map((val => [val.display + " (desc)",val.display])).flat());
            } else{
                calculatedIndexes = calculatedIndexes.concat(pageFigIndexes.map((val => val.display)));
            }

        } else {
            const mult = resultData?.GenNotes?.includes('Separate: No wrapper') || resultData?.GenNotes=='(none)'
            pageFigIndexes?.reverse()?.forEach((val, index) => {
                if(!mult) {
                    calculatedIndexes[calculatedIndexes.length - 1 - index * (mult ? 1 : 2)-1] = calculatedIndexes[calculatedIndexes.length - 1 - index * (mult ? 1 : 2)-1] + "(" + val.display + " (desc) )"
                }
                calculatedIndexes[calculatedIndexes.length - 1 - index*(mult?1:2)] = calculatedIndexes[calculatedIndexes.length - 1 - index*(mult?1:2)] + "(" + val.display + ")"
            })
        }
    }*/
    /* if((calculatedIndexes?.length?(calculatedIndexes.length):(0))<pageCount-(pageFigIndexes?.length?1:2)){
         console.log(pageCount)
         console.log(calculatedIndexes?.length)
         console.log('probably picture in middle');
         calculatedIndexes=[];
     }*/

    //const pullIndex =calculatedIndexes?((pageCount-1<calculatedIndexes.length)?(0):(pageCount-1-calculatedIndexes.length)):0;
    const pageSelector = (index: number) => <>{index == currentPage - 1 && <Icon IconID={LDLIcons.Viewer_CurrentPage}/>}</>

    return <>
        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}>
        <main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">
            <Record paddingTop={"5px"} title={""}>
                <TableEndLine/>
                <SplitPageHolder leftWidth={"33%"} resizer={!!(resultData && selectedEDoc)}>
                    <>
                        <div style={{padding:'5px 0px'}}>
                        <> r#</>
                        <input type={"number"} onKeyUp={(val)=>{if(val.key=="Enter"){navigate("/PageViewer/Pages",{state:{"BibObjID": bibSearchValue}})}}} className={"BibInput"} style={{width:"calc(42px + 0.5em)"}} value={bibSearchValue}
                               onChange={(e) => setBibSearchValue(e.target.value)}/>&nbsp;
                        <IconLink href={"/PageViewer/Pages"} IconID={LDLIcons.LDL_Search}
                                  newStates={{"BibObjID": bibSearchValue}}/>&nbsp;
                        <IconLink href={"/Biblio/Record"} newStates={{"BibObjID": _props["BibObjID"]}}
                                  IconID={LDLIcons.BotN_BibliographicRecord}/>
                            {(resultData&&resultData.LinkedEDocs && resultData.LinkedEDocs[0] && resultData.LinkedEDocs[0].EDocLink && (resultData.LinkedEDocs[0].PublicEDoc || (resultData.LinkedEDocs[0].PrivateEDoc && PDFAccess))) && <>
                                <IconLink newWindow={true} href={resultData.LinkedEDocs[0].EDocLink}
                                          IconID={LDLIcons.General_OpenFile_Work}
                                />
                                <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                          IconID={LDLIcons.Viewer_OpenFile}
                                          newStates={{"BibObjID": resultData.BibObjID}}/></>}
                            {(resultData && selectedEDoc) &&<>&nbsp;&nbsp;{resultData.FullCite}</>}
                        </div>
                        {(resultData && selectedEDoc) ? <>
                            {isViewer ? <SelectFromArray selectRenderAbove={(val)=><>Displayed index: </>} width={"75%"} defaultSelectedIndex={0}
                                                         arrayInput={ViewerIndexObject.map((val) => val.IndexName)}
                                                         selectRender={(val: string, index) => {

                                                             const selection = ViewerIndexObject[index]

                                                             return <><TableEndLine marginTop={"5px"} marginBottom={"5px"} small={true}/>
                                                                 {((isViewer && resultData.EDocPages) || (!isViewer)) &&
                                                                     <EasyTable noBorder={true} noHeaders={true}  paddingBottom={"35px"} width={"100%"} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData.EDocPages ? (resultData.EDocPages.map((val, index) => val.Indexes?.map((val) => {
                                                                                 return {index: index, val: val}
                                                                             })).flat().filter((val) => val?.val?.IndexID == selection.ID)) : []}>
                                                                             <EasyTableElement minWidth={"16px"} textIndent={"0px"}  noPadding={true} noBorderTop={true} width={"0%"} elementFromValue={(val,index)=>pageSelector(val.index)}/>
                                                                         <EasyTableElement paddingRight={"0px"} paddingLeft={"0.5em"}  paddingTop={"0px"} paddingBottom={"0px"}  noBorderTop={true} elementFromValue={(val: { index: number, val: tblLdlViewerIndexDetails }, index) =>
                                                                                 <>
                                                                                     {(resultData?.EDocPages&&resultData.EDocPages[val.index])&&<IconLink newWindow={true} href={resultData.EDocPages[val.index].EDocPageLink} IconID={LDLIcons.General_Openfile_Page}/>}
                                                                                     <IconButton
                                                                                             IconID={LDLIcons.Viewer_OpenPage}
                                                                                             onClick={(e) => {
                                                                                                 setCurrentPage(val.index + 1);
                                                                                                 setJustPage(true)
                                                                                             }}/> <IconButton
                                                                                         IconID={LDLIcons.General_GoToPage}
                                                                                         onClick={(e) => {
                                                                                             setCurrentPage(val.index + 1);
                                                                                             setJustPage(false)
                                                                                         }}/> {isViewer ? val.val.IndexText : <>PDF page position {Number(val) + 1}</>}
                                                                                     </>
                                                                             }/>
                                                                    </EasyTable>}</>


                                                         }} optionRender={(e) => e}/> : <><TableEndLine small={true}/>

                                <EasyTable noBorder={true} noHeaders={true}  paddingBottom={"35px"} width={"100%"} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow}  tableRowData={isViewer && resultData.EDocPages ? resultData.EDocPages : Array.from(Array(pageCount).keys())}>
                                    <EasyTableElement textIndent={"0px"} minWidth={"16px"}  noPadding={true} noBorderTop={true} width={"0%"} elementFromValue={(val,index)=>pageSelector(index)}/>
                                    <EasyTableElement  paddingRight={"0px"} paddingLeft={"0.5em"}  paddingTop={"0px"} paddingBottom={"0px"} noBorderTop={true}
                                        elementFromValue={(val: tblLdlEDocPages, index) => <>
                                                                                                 <IconButton
                                                                                                     IconID={LDLIcons.Viewer_OpenPage}
                                                                                                     onClick={(e) => {
                                                                                                         setCurrentPage(index + 1);
                                                                                                         setJustPage(true)
                                                                                                     }}/> <IconButton
                                                                                                 IconID={LDLIcons.General_GoToPage}
                                                                                                 onClick={(e) => {
                                                                                                     setCurrentPage(index + 1);
                                                                                                     setJustPage(false)
                                                                                                 }}/> {isViewer ? val.PageDesignation : <>PDF page position {Number(val) + 1}</>}
                                                                                             </>}/>

                                </EasyTable></>}</> : <>{error != 0 && <>{error == 1 ?
                            <div style={{height: `${height-50}px`, width: "770px", textAlign: "center"}}><b
                                style={{color: "red"}}>You have entered an invalid reference#. Please enter another reference#.</b>
                            </div> : <>{error == 2 &&
                                <div style={{height: `${height-50}px`, width: "770px", textAlign: "center"}}><b
                                    style={{color: "red"}}>No file is available to display for this reference#. Please enter another reference#.</b></div>}</>}</>}</>}

                    </>
                    <>{(resultData && selectedEDoc) && resultData.EDocPages && <>

                        {justPage ? <>
                                <LDLPDFPageViewer height={height-60}
                                                                     href={selectedEDoc.EDocLink}
                                                                     PageNumber={currentPage}/></> :
                            <LDLPDFViewer height={`${height-20}`} width={"100%"} href={selectedEDoc.EDocLink}
                                          startPage={currentPage}/>}</>}</>
                </SplitPageHolder>

                <TableEndLine/>
            </Record>
        </main></td></tr></tbody>
        <tfoot style={{height:"0px"}} ref={footerRef} id={"mainFooter"}>

        </tfoot>

    </>
};
export default Pages;