import React from "react";

const LoggedIn = (_props: { isPublic: boolean }) => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Login{!_props.isPublic && <> (LDL-Demo)</>}</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    <i>Lacewing Digital Library{!_props.isPublic && <>, Development
                        Version</>}</i>{!_props.isPublic && <> (LDL-Demo)</>}
                </p>
                <hr className="rule1" style={{marginBottom: "4px", marginTop: "4px",}}/>

                <p style={{color: "#FF0000"}}>You are logged in to the <i>Lacewing Digital
                    Library{!_props.isPublic && <>, Development Version</>}</i>{!_props.isPublic && <> (LDL-Demo)</>}.
                    Click <a href="/Authenticate/Logout" target="_self">here</a> to logout now.</p>

            </div>
        </div>
    </>
};
export default LoggedIn;