import React, {useEffect, useState} from "react";
import {proccessSearch, SearchOutputProps, SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";

import MetaKeySearchTerms from "../../utils/OnServerStartLoadedJsons/MetaKeySearchTerms.json"
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    IconLink,
    Record,
    
    SearchCriteria
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlMetakey} from "../../../../database/models/tblLdlMetakey";
import {tblLdlMetakeyTerminals} from "../../../../database/models/tblLdlMetakeyTerminals";


const searchTerms: any = {...MetaKeySearchTerms};

searchTerms.lan.unshift({val: "", dis: "any language"});
searchTerms.taxScope.unshift({val: "", dis: "any Neuropterida taxa"});
searchTerms.geoScope.unshift({val: "", dis: "any geography"});
searchTerms.form.unshift({val: "", dis: "any format"});

enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface MetaKeySearchProps {
    TaxScope0?: { Val: string },
    GeographicScope?: { Val: string },
    sex?: { Val: string },
    stage?: { Val: string },
    Language?: { Val: string },
    Format?: { Val: string },
    PubYear?: { filter: filterType, Val: string },
    Comb0?: { filter: filterType, Val: string },
    KeyTermStatus?: { Val: string },

}

const conversionTable = {
    "Taxonomic Scope": "TaxScope0",
    "Geographic Scope": "GeographicScope",
    "Sex": "sex",
    "Life Stage": "stage",
    "Language": "Language",
    "Format": "Format",
    "Publication Year": "PubYear",
    "Terminal Taxon Name": "Comb0",
    "Search Name Set": "KeyTermStatus"
}
const MetakeyResult = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<{ dat: tblLdlMetakey, term?: tblLdlMetakeyTerminals }[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any | undefined;

    useEffect(() => {
        console.log(_props)
        let out: MetaKeySearchProps = Object()

        out = proccessSearch(_props?.searchProps, conversionTable, out as SearchOutputProps);


        if (!_props.advanced) {
            callAPI("/api/general/doMetaKeySearch", out, (error: boolean, outData: tblLdlMetakey[] | Response) => {
                if (error) {
                    console.log("error")
                } else if (!(outData instanceof Response)) {
                    setResultData(outData.map((val) => {
                        return {dat: val}
                    }))
                    console.log(outData)


                }

            });
        } else {
            callAPI("/api/general/doMetaKeyTerminalSearch", out, (error: boolean, outData: tblLdlMetakeyTerminals[] | Response) => {
                if (error) {
                    console.log("error")
                } else if (!(outData instanceof Response)) {
                    setResultData(outData.map((val) => {
                        return {dat: val.MetaKey, term: val}
                    }));
                    console.log(outData);

                }

            });
        }
    }, []);


    return <>
        {(resultData) && <>
            <Record title={_props.advanced ? "Terminal Taxon / Key List" : "Key List"}>
                <SearchCriteria searchCriteria={_props?.searchProps}>
                <i>
                    <>Results Records ({resultData.length}).</>
                </i>
                </SearchCriteria>
                <EasyTable autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData}>
                    <EasyTableElement width={"0%"} title={"Key"} elementFromValue={(val: {
                        dat: tblLdlMetakey,
                        term?: tblLdlMetakeyTerminals
                    }) => val.dat.KeyID} desc={"the key's World Neuropterida Metakey keyID number"}/>
                    <EasyTableElement width={"0%"} minWidth={"48px"} textIndent={"0px"} title={"Links"}
                                      elementFromValue={(val: {
                                          dat: tblLdlMetakey,
                                          term?: tblLdlMetakeyTerminals
                                      }) => <><IconLink IconID={LDLIcons.WNM_KeyRecord}
                                                        href={"./Record"}
                                                        HistoryLocStates={location.state}
                                                        newStates={{"KeyID": val.dat.KeyID}}/>{(val.dat.EDoc && val.dat.EDoc.EDocLink && (val.dat.EDoc.PublicEDoc || (val.dat.EDoc.PrivateEDoc && PDFAccess))) ?
                                          <><IconLink newWindow={true} href={val.dat.EDoc.EDocLink}
                                                        IconID={LDLIcons.General_OpenFile_Work}
                                                        /><IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                        IconID={LDLIcons.Viewer_OpenKey}
                                                        newStates={{
                                                            "BibObjID": val.dat.BibObjID,
                                                            "EDocPageID": val.dat.KeyEDocPageID
                                                        }}/></> : <> – –</>}</>}/>
                    <EasyTableElement width={"4%"} title={"Page"} elementFromValue={(val: {
                        dat: tblLdlMetakey,
                        term?: tblLdlMetakeyTerminals
                    }) => val.dat.PagePlate}/>
                    {_props.advanced && <EasyTableElement  width={"7%"}  title={"Terminal Taxon"} elementFromValue={(val: {
                        dat: tblLdlMetakey,
                        term?: tblLdlMetakeyTerminals
                    }) => val.term?.Comb0}/>}
                    <EasyTableSection align={"center"} title={"Key Parameter"}>
                        <EasyTableElement width={"22%"} title={"Taxonomic Scope"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => <>{val.dat.TaxScope1 != "$" && <>{val.dat.TaxScope1}: </>}{val.dat.TaxScope2}</>}/>
                        <EasyTableElement width={"18%"} title={"Geographic Scope"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => val.dat.GeographicScope}/>
                        <EasyTableElement width={"0%"} whiteSpace={"nowrap"} paddingRight={"10px"} title={"Year"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => val.dat.PubYear}/>
                        <EasyTableElement width={"5%"} title={"Terminals"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => val.dat.TerminalsShort}/>
                        <EasyTableElement width={"3%"} title={"Stage"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => val.dat.LifeStageShort}/>
                        <EasyTableElement width={"0%"} whiteSpace={"nowrap"} paddingRight={"10px"} title={"Sex"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => val.dat.Sex}/>
                        <EasyTableElement width={"5%"} title={"Language"} elementFromValue={(val: {
                            dat: tblLdlMetakey,
                            term?: tblLdlMetakeyTerminals
                        }) => val.dat.Language}/>

                    </EasyTableSection>
                </EasyTable>
            </Record>
        </>
        }
    </>
};


export default MetakeyResult;