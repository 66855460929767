import React from "react";

const Dir_Contacts = () => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Contacts</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    Inquiries and comments about the <i>Neuropterists Directory</i> may be directed to the editor.
                </p>
                <hr className="rule1" style={{marginBottom: "4px", marginTop: "4px",}}/>
                <p className="header-paragraph-2">
                    <b>For questions regarding all functionality and content (text and image additions, updates,
                        alterations, etc.):</b>
                </p>
                <h4>Editor</h4>
                <div id="hcard-John-D.-Oswald" className="vcard">
                    <p className="indented-paragraph">
                <span className="fn n">
                    <a href="http://entomology.tamu.edu/people/oswald-john/" target="_top">
                        <span className="given-name">John </span>
                        <span className="additional-name">D. </span>
                        <span className="family-name">Oswald</span>
                    </a>
                </span>
                        <br/>
                        <span className="org">
                    Department of Entomology
                </span>
                        <br/>
                        <span className="adr">
                    <span className="street-address">
                        Texas A&amp;M University
                    </span>
                    <br/>
                    <span className="locality"> College Station</span>,
                    <span className="region"> TX </span>,
                    <span className="postal-code"> 77843-2475</span>
                </span>
                        <br/>

                        E-mail: <a
                        href="mailto:j-oswald@tamu.edu?subject=Neuropterists Directory: comment/question">j-oswald@tamu.edu</a>
                        <br/>
                        Phone:
                        <span className="tel:+019798623507"> 979-862-3507</span>
                        <br/>
                    </p>
                </div>
                <p className="filler3"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>

            </div>
        </div>

         </>
};
export default Dir_Contacts;