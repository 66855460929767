import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import {callGETAPI} from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    ParagraphDesc,
    Record,
    RemoveIfLastSame,
    TableLegendElement,
    TableLegendSection
} from "../../utils/CustomRecord";
import {tblLdlSpCat} from "../../../../database/models/tblLdlSpCat";


const Species_Classification = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlSpCat[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callGETAPI("/api/general/getSpCatClassification", (error: boolean, outData: tblLdlSpCat[] | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, []);

    return <>{resultData &&
        <Record title={"Classification"}>
            <ParagraphDesc><>The higher classification used in the <i>Neuropterida Species of the World</i> (NSW; Table
                3) generally follows the taxonomies contained in relevant recent monographic works, and is further
                guided by recent phylogenetic studies, where available. Where multiple phylogenies/taxonomies exist in
                the recent literature the author has used his judgement in creating a classification calculated to best
                reflect current phylogenetic knowledge within the constraints of using nested ranks to construct a
                heirarchical classification. While the monophyly of most extant families appears likely, based on recent
                phylogenetic work, the monophyly of many lower-ranked extant taxa in the family group is less certain,
                and most of these have yet to be investigated in detail with modern phylogenetic techniques and dense
                subtaxon sampling. The monophyly, and inter- and intrafamilial phylogenetic relationships of most
                families that contain only extinct species are also still poorly know.
            </>
            </ParagraphDesc>
            <EasyTable caption={<>Table 3. The higher classification (order to subtribe) used in the <i>Neuropterida Species of the
                World</i>. Orders are listed alphabetically, and all subtaxa are listed alphabetically within each
                higher taxon. No phylogenetic sequencing should be assumed.</>} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow}  tableRowData={resultData} extraLegendChildren={<>
                <TableLegendSection title={"Symbols"}>
                    <TableLegendElement title={<sup><i>H</i></sup>}
                                        element={<>taxon contains only living species, or both living species and
                                            species that became extinct (i.e., are last documented by specimens that
                                            died) during the Holocene (i.e., ‘Recent’ species)</>}/>
                    <TableLegendElement title={<sup><i>†</i></sup>}
                                        element={<>taxon contains only species that became extinct (i.e., are last
                                            documented by specimens that died) prior to the Holocene (i.e., ‘fossil’
                                            species)</>}/>
                    <TableLegendElement title={<sup><i>H†</i></sup>}
                                        element={<>taxon contains one or more ‘Recent’ species and one or more ‘fossil’
                                            species</>}/>
                </TableLegendSection>
                <TableLegendSection title={"Notes"}>
                    <>For the purposes of this work, a ‘fossil’ is defined as the remains (cast, impression, mineral
                        replacement, etc.) of an organism (or any of its parts) that is believed to have died prior to
                        the beginning of the Holocene Epoch (i.e., before 0.0118 Ma; Geologic Time Scale 2012, Gradstein
                        et al. 2012)
                    </>
                </TableLegendSection>
            </>}>
                <EasyTableElement title={"Order"} removeBottomLineIfEmpty={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCOrde != "$" && <>{val.HCOrde}<sup><i>{val.HCOrdeSymb}</i></sup></>}</>)}/>
                <EasyTableElement title={"Suborder"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCSubo != "$" && <>{val.HCSubo}<sup><i>{val.HCSuboSymb}</i></sup></>}</>)}/>
                <EasyTableElement title={"Superfamily"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCSupf != "$" && <>{val.HCSupf}<sup><i>{val.HCSupfSymb}</i></sup></>}</>)}/>
                <EasyTableElement title={"Epifamily"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCEpif != "$" && <>{val.HCEpif}<sup><i>{val.HCEpifSymb}</i></sup></>}</>)}/>
                <EasyTableElement  title={"Family"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCFami != "$" && <>{val.HCFami}<sup><i>{val.HCFamiSymb}</i></sup></>}</>)}/>
                <EasyTableElement  title={"Subfamily"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCSubf != "$" && <>{val.HCSubf}<sup><i>{val.HCSubfSymb}</i></sup></>}</>)}/>
                <EasyTableElement title={"Tribe"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCTrib != "$" && <>{val.HCTrib}<sup><i>{val.HCTribSymb}</i></sup></>}</>)}/>
                <EasyTableElement title={"Subtribe"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{val.HCSubt != "$" && <>{val.HCSubt}<sup><i>{val.HCSubtSymb}</i></sup></>}</>)}/>
            </EasyTable>
        </Record>}</>
};


export default Species_Classification;