import React from "react";

const Acknowledgments = () => {
    return <>(

        <div className="content">
            <div className="text-body">
                <h1>Acknowledgments</h1>

                <p style={{marginTop: "5px", textAlign: "justify",}}>Many individuals and organizations have contributed
                    in various ways to the development of this work. In the paragraphs below some of those contributions
                    are individually noted and acknowledged. As this work has now consumed parts of more than 30 years
                    of my professional life, and as its connections reach into nearly every corner of the
                    neuropterological community, and more thinly across the global community of entomologists, it is not
                    possible to note all contributions individually. My thanks to all of you who are not specifically
                    mentioned below, but who have contributed to this project in a myriad of different ways over the
                    years. </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>To all of my neuropterist friends and colleagues
                    around the world, I offer my thanks for your past and continuing efforts to help build and improve
                    the Bibliography by sending me your personal publication lists, specialized reference lists,
                    "additions" lists, corrections notes, reprints, photocopies, digital files, etc., so that we can
                    build an increasingly comprehensive and useful resource for our research community. All of your
                    contributions, large and small, have helped to shape the Bibliography. Your works constitute a
                    substantial portion of the materials that are cited in and linked to the Bibliography. To date, more
                    than 530 of you have explicitly granted personal permissions to the BotN project to post of copies
                    of your works. The success of this project as a comprehensive community resource depends critically
                    upon your continued support and good will. </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Several individuals have provided particularly
                    noteworthy help in specialized areas. Levente Abrahám and András Bozsik have provided special
                    assistance in treating the Hungarian neuropteran literature. For ten years in the 1990's and 2000's
                    Agostino Letardi provided annual lists of errata and references not found in earlier versions of the
                    Bibliography. These were very helpful in developing and correcting the Bibliography citation list
                    during that period and particularly emphasized the Italian literature. More recently, we have shared
                    information and collaborated on the production of periodic literature lists for the International
                    Association of Neuropterology’s newsletter, Lacewing News, which has helped to keep the Bibliography
                    up to date. The assistance of Vladimir Makarkin has been indispensable in treating the Russian
                    neuropterological literature. He has cheerfully responded to my numerous requests for translations,
                    transliterations and corrections of Russian citations, and has provided copies of many important
                    works treating both extant and fossil neuropterans. Florian Weihrauch has provided special
                    assistance with some neuropterological literature in German. Andy Whittington has provided
                    information derived from the collection of neuropterological literature contained in the Royal
                    Scottish Museum, contributing to the documentation of European, and particularly British,
                    neuropterists of the late 19th and early 20th centuries. Peter McEwen, Lara Senior and John Ruberson
                    have each, at different times, provided assistance with citations on economic neuropterology. Andy
                    Whittington, Agostino Letardi and Levente Abrahám have provided special assistance in obtaining
                    permissions from publishers in their countries in order to help develop the digital library aspects
                    of the Bibliography. </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Special thanks is due to my good friend and
                    colleague Norm Penny who has supported and encouraged the Bibliography project throughout our long
                    association. In late 1994 Norm helped push the Bibliography in the direction of electronic
                    publication by arranging for the first online version (Version 4.0) to be implemented as a gopher
                    site on a server at the California Academy of Sciences. All subsequent versions of the Bibliography
                    have been delivered digitally, which greatly facilitated the extension of the Bibliography in the
                    early 1990's into the digital library realm when easily distributable digital facsimile files in PDF
                    format became increasingly popular and available. The continued support and encouragement of fellow
                    bibliophile Horst Aspöck has also been much appreciated over the years. </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    During my years as a postdoctoral fellow (1991-1995) at the Smithsonian Institution (National Museum
                    of Natural History), Oliver Flint provided unrestricted access to the extensive collection of
                    neuropterid reprints then under his care. Access to this collection was especially important for
                    documenting the separates of publications by Longinos Navás.
                </p><p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Graduate Students</i></b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>Several graduate students have provided substantive
                    assistance with different versions of the Bibliography. Joshua Jones and Krishna Tadi were largely
                    responsible for the technical development of the first Lacewing Digital Library-based interface of
                    the Bibliography (version 8.0). That general interface has been continued and extended in subsequent
                    versions. Most of the graphical and design elements of this interface were initially produced by
                    Joshua and implemented by Joshua and Krishna. Joshua also played an important role in helping to
                    conceptualize the organization and presentation of page and data elements for the interface. Krishna
                    undertook primarily responsibility for the more technical aspects of site networking, the ColdFusion
                    scripting originally associated with the site's searching and reporting functionality, and back-end
                    database development and maintenance. For version 9.0, Rakesh Parvataneni was primarily
                    responsibility for technical implementation. Several other students (see the LDL Project Team Page)
                    have contributed more recently to the development and maintenance of subsequent versions of the
                    Bibliography as part of their work on multiple LDL modules.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>In 1997, computer science students Murtaza Sonaseth
                    and Ravi Wijayaratne were responsible for the technical development of Bibliography Version 6.0.
                    That version, with an adjustable, three-frame, single screen, user interface was the first
                    interactively searchable version of the Bibliography and was developed as part of a graduate course
                    in computer-based Information Storage and Retrieval at Texas A&amp;M University.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>In 2018, Sam Howard completed a major new
                    literature search for additional references relating to the Neuropterida, which resulted in the
                    addition of {'>'}1000 new citations to the Bibliography. Those additions significantly increased the
                    depth and breadth of Bibliography coverage in a number of areas, particularly the global use of
                    lacewings in economic entomology and references that contain relatively minor neuropterid content
                    but which are critical for understanding what is known about the breadth of neuropterid insect
                    interactions with other living species.</p>
                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Undergraduate Students</i></b>
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Many undergraduate student workers have provided various kinds of assistance on the Bibliography
                    project over the years. Perhaps the most significant of these contributions was
                    made by a long line of students who, from 2004 to 2018, carefully produced, checked and
                    processed the majority of the PDF files now linked to the Bibliography; those files were
                    produced mostly during the period before PDFs of the historical entomological literature
                    became more widely available and accessible. Other students have been involved in
                    securing copies of papers for verification of bibliographic data, building the digital library of
                    the Bibliography from a wide variety of disparate sources, and managing and organizing the
                    physical literature collection that still partially underlays the Bibliography. For each of their
                    separate contributions to the Bibliography I would like to thank the following students (listed
                    alphabetically; current students are asterisked [*]): Sadie Arguello, Sarah Bailey, Lauren
                    Bersano, David Chaszar, Lisa Doty, John Dumont, Chyla Evert, Victoria Fielding, Sukhtej
                    Gill, Ritisha Gupta, Shawn Hanrahan, Kevin Henson, Akash Krisna, Franklin Leung, Wilson
                    Lu, John McLemore, Diego Menses, Landon Proctor, Bethany Reyes, Allision Rocha,
                    Chase Smolik, Alex Strawn, and Jessi Sutton.
                </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Libraries and Librarians</i></b>
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>A special debt of gratitude is due the many
                    librarians who have assisted me over the period of many years with the bibliographic research that
                    is incorporated in the Bibliography. As a graduate student, postdoc and faculty member,
                    respectively, I have made particularly significant use of the resources, facilities and services of
                    the libraries of Cornell University, the Smithsonian Institution (especially the National Museum of
                    Natural History) and Texas A&amp;M University. Wolfgang Brunnbauer kindly provided access to a
                    number of old and rare works contained in the library of the Naturhistorisches Museum Wien in 2005.
                    The dedicated individuals associated with the libraries of these institutions in particular, but
                    also the staffs of many other libraries in the United States and Europe that I have visited, have
                    provided me with uniformly courteous and helpful services and with access to the collections under
                    their care. As access to scientific literature resources becomes increasingly digital, it is
                    unfortunate that the process of obtaining that information is also becoming increasingly faceless.
                    It is all too easy to overlook the people who stand behind the new and old library services that we
                    use. We must not forget the importance of libraries and the librarians that guide and nurture those
                    resources.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Carolina Deleon and Zheng Ye 'Lan' Yang of the
                    Texas A&amp;M University Libraries have provided space for and oversight of the student workers who,
                    over the course of many years, have produced most of the PDF files of scanned neuropterid literature
                    that are linked to the Bibliography.</p>
                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Cooperating
                    Organizations </i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Many organizations from around the world have
                    granted permissions that allow the Bibliography to make available as digital copies selected works
                    that they have published. It is a pleasure to acknowledge the substantive cooperation that these
                    organizations have provided in granting such permissions. A list of cooperating societies,
                    institutions and publishers can be found on the <a href="CoopOrga" target="_top">Cooperating
                        Organizations</a> page, together with links to their respective web sites (where available).
                </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Graphics</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Some of the icons used in the Bibliography were
                    designed by <a href="http://p.yusukekamiyamane.com" target="_blank">Yusuke Kamiyamane</a> and are
                    licensed under a <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank">Creative
                        Commons Attribution 3.0 License</a>.</p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Funding</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Versions 9.0 through 11.0 of the Bibliography were supported in part by the U.S. <a
                    href="http://www.nsf.gov/" target="_top">National Science Foundation (NSF)</a> through the <a
                    href="http://www.nsf.gov/div/index.jsp?div=DEB" target="_top">Division of Environmental Biology
                    (DEB)</a> program <a
                    href="http://www.nsf.gov/funding/pgm_summ.jsp?pims_id=5451&amp;org=DEB&amp;from=home" target="_top">Partnerships
                    for Enhancing Expertise in Taxonomy (PEET)</a>, grant #0933588.
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <span style={{marginTop: "5px",}}>Versions 7.04 to 8.00 of the Bibliography were supported in part by the U.S. <a
                        href="http://www.nsf.gov/" target="_top">National Science Foundation</a> through <a
                        href="http://www.nsf.gov/div/index.jsp?div=DEB"
                        target="_top">Division of Environmental Biology</a>, <a
                        href="http://www.nsf.gov/funding/pgm_summ.jsp?pims_id=12825&amp;org=DEB&amp;from=home"
                        target="_top">Systematic Biology and Biodiversity Inventories</a> grant #0416206. Support from this source facilitated the production of a large number PDF files that comprise a substantial portion of the primary and secondary taxonomic literature on the Neuropterida of the world. The substantive support provided under this grant allowed the Bibliography to expand from its initial emphasis on bibliographic citations  into a significant digital library that provides open access to a large body of scientific literature  relevant to the global neuropterological research community. </span>
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Versions 6.x of the Bibliography were supported in part by the <a
                    href="http://www.thecb.state.tx.us/" target="_top">Texas Higher Education Coordinating
                    Board</a> through <a href="http://www.arpatp.com/" target="_top">Advanced Research Program</a> grant
                    #999902-139. Host support for these versions was provided by the Center for the Study of Digital
                    Libraries, a unit of the Texas A&amp;M University Department of Computer Sciences.&nbsp;
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>In-kind support for the Bibliography project has
                    been provided over the years by the <a href="http://entowww.tamu.edu/" target="_top">Department of
                        Entomology</a> of <a href="http://www.tamu.edu/" target="_top">Texas A&amp;M University</a>, <a
                        href="http://taes.tamu.edu/" target="_top">Texas A&amp;M AgriLife Research</a> (formerly the
                    Texas Agricultural Experiment Station), and the <a href="http://library.tamu.edu/" target="_top">Texas
                        A&amp;M University Libraries</a>. </p>
            </div>
        </div>
         </>
};
export default Acknowledgments;