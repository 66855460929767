import React, {useEffect, useState} from "react";
import CustomSearch, {SearchProp} from "../../utils/CustomSearch";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import callAPI from "../../utils/APIhelpers";
import FaunaTaxonList from "../../utils/OnServerStartLoadedJsons/FaunaTaxonList.json";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json";
import {tblLdlModule} from "../../../../database/models/init-models";
import {LDLModule} from "../../utils/OnServerStartLoadedOther/ModuleHelp";


const FaunaSearch = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const [ListParams, setListParams] = useState({FaunalList: [{val: "", dis: ""}]});

    const navigate = useNavigate();
    const location = useLocation();
    const savedSearch = location.state as any | undefined;
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);

    // @ts-ignore
    const myGeo:tblLdlModule = LDLallowedModules[paths[0]];

    useEffect(() => {
        // @ts-ignore
        callAPI("/api/general/getFaunaListByCountryID", {CountryID: myGeo.GeogID}, (error: boolean, outData: {
            FaunalList: { val: string, dis: string }[]
        } | Response) => {
            if (error) {
                console.log(outData);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setListParams(outData);
            }
        })
    }, [location]);
    const handleSearchResults = (feildData: SearchProp[]) => {
        let statesCopy: any = location.state ? location.state : {};
        statesCopy["searchProps"] = feildData;
        navigate("./Results", {state: statesCopy});

    }


    return <>

        {<CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
            {
                title: "Define Fauna",
                sections: [{
                    middle: true, title: "Faunal List Parameters", feilds: [
                        {
                            sectionName: "Geographic Scope",
                            criteria: ListParams.FaunalList,
                            criteriaVal: String(myGeo.ID==LDLModule.World?ListParams.FaunalList[0].val:myGeo.GeogID),
                            mediumCriteria: true,
                            description: "(Select a geographic scope for the faunal list.)"
                        },
                        {
                            sectionName: "Taxonomic Scope",
                            criteria: FaunaTaxonList,
                            criteriaVal: "12996",
                            mediumCriteria: true,
                            description: "(Select a taxonomic scope for the faunal list.)"
                        },
                        {
                            sectionName: "Extant / Extinct",
                            criteria: [{val:"0",dis:"show extant and extinct taxa"}, {val:"1",dis:"show extant taxa only"}, {val:"2",dis:"show extinct taxa only"}],
                            criteriaVal: "0",
                            mediumCriteria: true,
                            description: "(Show only living taxa, only fossil taxa, or both in the faunal list?)"
                        }, {
                            sectionName: "Lowest List Rank",
                            criteria: [{val: "0", dis: "species"}, {val: "1", dis: "subspecies"}],
                            criteriaVal: "1",
                            mediumCriteria: true,
                            description: "(Show subspecies, or only species, in the faunal list?)"
                        },
                        {
                            sectionName: "Subgenera",
                            criteria: [{val: "1", dis: "show subgenera in species names"}, {val: "0", dis: "do not show subgenera in species names"}],
                            criteriaVal: "1",
                            mediumCriteria: true,
                            description: "(Show subgeneric names in the faunal list?)"
                        },

                    ]
                }]
            }}/>}


    </>
};


export default FaunaSearch;