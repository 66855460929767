import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Link, Outlet, useLocation, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../../Shared/_Layout";
import nameTranslator from "../../../utils/nameTranslator";
import LDLallowedModules from "../../../utils/OnServerStartLoadedJsons/LDLallowedModules.json"
import "../../../Content/Faunas/Faunas.css"
import {BottomFooterName, Icon, IconLink} from "../../../utils/CustomRecord";
import FaunaCountry from "../../../utils/OnServerStartLoadedJsons/FaunaCountry.json";
import {tblLdlModule} from "../../../../../database/models/tblLdlModule";

const _FaunasLayout = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);

    const location = useLocation();
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);
    // @ts-ignore
    console.log(paths[0])
    // @ts-ignore
    const MyModule:tblLdlModule=LDLallowedModules[paths[0]];
    // @ts-ignore
    const myPoliticalUnit:tblLdlFaunaPoliticalUnits|undefined=FaunaCountry[MyModule.GeogID];
    // @ts-ignore
    const GeogName=myPoliticalUnit?<>{myPoliticalUnit.DefiniteArticle} {myPoliticalUnit.PoliticalUnitName}</>:paths[0]
    // @ts-ignore
    const ShortGeogName=myPoliticalUnit?myPoliticalUnit.PoliticalUnitName:paths[0]



    return <>


        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}><main className={"Faunas"}  style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">

                <Outlet context={[[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],<></>,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]]}></Outlet>
            </main></td>
        </tr>
        </tbody>

        <tfoot ref={footerRef} id={"mainFooter"}>
        <tr>

            <td colSpan={2}>            <nav>
                <span className="module" style={{color: "#9B6702",}}><BottomFooterName currentPathData={currentPathData}/> </span>
                <a href={"/" + paths[0] + "/Main"} target="_top">Main </a>
                {paths[0] != "Faunas" && <>
                    | <a href={"/" + paths[0] + "/Introduction"} target="_top">Introduction </a>
                    | <a href={"/" + paths[0] + "/DefineFauna"} target="_top">Define Fauna </a>
                    | <a href={"/" + paths[0] + "/Acknowledgments"} target="_top">Acknowledgments </a>
                    | <a href={"/" + paths[0] + "/References"} target="_top">References </a>
                    | <a href={"/" + paths[0] + "/Versions"} target="_top">Versions </a>
                    | {verifiedUser?<a href={"/Authenticate/LoggedIn"} target="_top"> Logged In </a>:<a href={"/Authenticate/Login"} target="_top"> Log In </a>}|
                    {PDFAccess?<text style={{color:"green"}}> PDF+</text>:<text style={{color:"red"}}> PDF{(!isPublic&&verifiedUser)&&<>(<a href={"/Authenticate/Migration"} title={"upgrade using old module login to get new demo-site with PDF+ login"}>upgrade</a>)</>}</text>}</>}
            </nav>
        </td>

        </tr>
        </tfoot>
    </>
};
export default _FaunasLayout;