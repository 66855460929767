import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4"
import LDLconfig from "./utils/OnServerStartLoadedJsons/LDLconfig.json";

const isPublic:boolean=LDLconfig.isPublic;

ReactGA.initialize(isPublic?"G-715HSCQVEY":"G-EEYM3GCF3R")

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
