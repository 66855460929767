import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHolder,
    Record,
    
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlBibNeurTaxa} from "../../../../database/models/tblLdlBibNeurTaxa";


const BibloTaxa = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlBibNeur>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getTaxaByBiblioPk", _props, (error: boolean, outData: tblLdlBibNeur | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                // console.log(outData)
                setResultData(outData);
            }
        })
    }, []);


    return <>{resultData &&
        <Record  title={<>Taxon Citations <><IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
            href={`mailto:j-oswald@tamu.edu?subject=Bibliography of the Neuropterida: comment/correction (r#${resultData.BibObjID})`}
            IconID={LDLIcons.General_Email}/></></>}>
            <ListHolder>
                <ListElement title={"Bibliographic Record Links"} element={<>
                    {
                        resultData.LinkedFigs?.length &&
                        <IconLink  href={"../Figure"} IconID={LDLIcons.BotN_FigureCitations}
                                  HistoryLocStates={location.state}
                                  newStates={{"BibObjID": resultData.BibObjID}}/>}
                </>}/>
                <ListElement title={`Reference Citation (r#${resultData.BibObjID})`} element={resultData.FullCite}/>
            </ListHolder>
                <i>
                    <>Taxon Citations ({resultData.LinkedTaxon?.length}).</>
                </i>
                {resultData.LinkedTaxon ? <EasyTable downloadable={true} autoBottomMaxHeight={true} downloadName={`Taxon_Citations_BotN_r#${resultData.BibObjID}.csv`} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData.LinkedTaxon}>
                    <EasyTableElement width={"0%"} title={"Links"}
                                      elementFromValue={(val: tblLdlBibNeurTaxa) => <>{(resultData.LinkedEDocs && resultData.LinkedEDocs[0] && (resultData.LinkedEDocs[0].PublicEDoc == -1 || (resultData.LinkedEDocs[0].PrivateEDoc == -1 && PDFAccess))) && <>
                                          <IconLink newWindow={true} href={resultData.LinkedEDocs[0].EDocLink}
                                                    IconID={LDLIcons.General_OpenFile_Work} /><IconLink newWindow={true}
                                          href={"/PageViewer/Pages"} IconID={LDLIcons.Viewer_OpenFile}
                                          newStates={{"BibObjID": resultData.BibObjID}}/></>}</>}
                                      desc={"if available, link(s) to the publication containing the taxon citation"}/>
                    <EasyTableElement width={"5%"} title={"Page"}
                                      elementFromValue={(val: tblLdlBibNeurTaxa) => val.SpCitePageNo}
                                      desc={"the page on which the taxon citation occurs (or starts)"}/>
                    <EasyTableElement width={"50%"} title={"Taxon"}
                                      elementFromValue={(val: tblLdlBibNeurTaxa) => val.SpCiteTaxonName}
                                      desc={"the cited scientific name (corrected only for misspellings), followed by its valid scientific name in the Lacewing Digital Library (LDL) standard taxonomy (if different)"}/>
                    <EasyTableElement width={"20%"} title={"Topics"}
                                      elementFromValue={(val: tblLdlBibNeurTaxa) => val.SpCiteAnnot}
                                      desc={"keywords that highlight selected subject matter content contained in the taxon treatment of the listed taxon"}/>
                    <EasyTableElement width={"20%"} title={"Notes"}
                                      elementFromValue={(val: tblLdlBibNeurTaxa) => val.SpCiteNotes}
                                      desc={"miscellaneous notes, if any"}/>
                </EasyTable> : <></>}
        </Record>}
    </>
};


export default BibloTaxa;