import React, {useEffect, useRef, useState} from "react";
import "../../lib/bootstrap2.3.2/bootstrap/css/bootstrap.min.css"
import "./_Layout.css"
import "../../Content/PagedList.css"
import "../../Content/site.min.css"
import ReactGA from "react-ga4";

import {Link, Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";

import nameTranslator from "../../utils/nameTranslator";
import Icons from "../../utils/OnServerStartLoadedJsons/Icons.json"
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {
    AlowedLICheaker,
    IconLink,
    InitPathData,
    PathData,
    PathDataElement,
    PathTitle, PathTitleIcon,
    UpperLeftNav, UpperRightNav
} from "../../utils/CustomRecord";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";
import LDLAllowedModulesByID from "../../utils/OnServerStartLoadedJsons/LDLAllowedModulesByID.json"
import FaunaCountry from "../../utils/OnServerStartLoadedJsons/FaunaCountry.json";
import {LDLModule} from "../../utils/OnServerStartLoadedOther/ModuleHelp";
import generatePDF from "react-to-pdf";




export type layoutContextType = [[boolean, React.Dispatch<React.SetStateAction<boolean>>],[boolean, React.Dispatch<React.SetStateAction<boolean>>], [(JSX.Element[] | undefined), React.Dispatch<React.SetStateAction<JSX.Element[] | undefined>>], [(JSX.Element[] | undefined), React.Dispatch<React.SetStateAction<JSX.Element[] | undefined>>],boolean,[React.MouseEventHandler<HTMLBodyElement> | undefined,React.Dispatch<React.SetStateAction<React.MouseEventHandler<HTMLBodyElement> | undefined>>],JSX.Element,{height: number, width: number},number,[(JSX.Element | undefined), React.Dispatch<React.SetStateAction<JSX.Element | undefined>>],[PathData | undefined,React.Dispatch<React.SetStateAction<PathData | undefined>>]]


const _Layout = (_props: { isPublic: boolean }) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const searchEntries=Array.from(searchParams.entries());
    const allParams = (searchEntries&&searchEntries.length)?Object.fromEntries(searchEntries.map((val)=>[val[0],JSON.parse(val[1])])):undefined;
    const currentPath = location.pathname;
    const [PDFAccess, setPDFAccess] = useState(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [username, setUsername] = useState<string>("");
    const [expires, setExpires] = useState<string>("");
    const [headerStyleA, setHeaderStyleA] = useState<any>()
    const [headerStyleB, setHeaderStyleB] = useState<any>()
    const [navDat, setNavDat] = useState<JSX.Element[]>()
    const [divDat, setDivDat] = useState<JSX.Element[]>()
    const [afterTitle, setAfterTitle] = useState<JSX.Element>()
    const [onClickFunction,setOnClickFunction]=useState<React.MouseEventHandler<HTMLBodyElement>|undefined>(undefined)
    const navigate = useNavigate();
    useEffect(() => {
        if(allParams){
            console.log(allParams)
            navigate(currentPath,{state:allParams})
        }
    }, [allParams]);
    const [verifiedAuthState, setVerifiedAuthState] = useState(false);
    const [verifiedUser, setVerifiedUser] = useState(false);
    const paths = currentPath.split('/').slice(1);
    useEffect(() => {
        const checkIfSessionValid = async () => {
            fetch("/api/users/isLoggedIn", {
                method: "GET",
            })
                .then((response) => {
                    if (!response.ok) {
                        // If the response status is not in the range 200-299
                        const error = new Error(response.statusText);
                        (error as any).response = response; // Attach the response object to the error
                        throw error;
                    }
                    console.log(response)
                    return response.json();
                })
                .then((responseJson) => {
                    // Handle the successful response here
                    console.log(responseJson)
                    if(_props.isPublic) {
                        console.log(responseJson,"public")
                        setVerifiedAuthState(true);
                        setVerifiedUser(true);
                        if(responseJson?.user.PdfAccess == -1) {
                            setPDFAccess(true);
                        }
                        setUsername(responseJson?.user.UserName)
                        setExpires(responseJson.expires);
                    }else if (!_props.isPublic&&responseJson?.user.DemoAccess == -1){
                        setVerifiedAuthState(true);
                        setVerifiedUser(true);
                        if(responseJson?.user.PdfAccess == -1) {
                            setPDFAccess(true);
                        }
                        setUsername(responseJson?.user.UserName)
                        setExpires(responseJson.expires);
                    }
                    if (currentPath === "/") {
                        navigate("/HomePage/MainContent");
                    }

                })
                .catch((error) => {
                    console.log("Error:", error);

                    if (error.response) {
                        if (!_props.isPublic) {
                            console.log("Unauthorized - Redirecting to /Authenticate/Login");
                            navigate("/Authenticate/Login");
                        }
                    } else {
                        // Handle other errors as needed
                        console.log("An error occurred:", error.message);
                    }
                });

        };

        if (currentPath === "/") {
            navigate("/HomePage/MainContent");
        } else {
            const SubModules=currentPath.split('/').slice(1)
            const moduleName = SubModules[0];
            if (moduleName != "Authenticate" && SubModules[0]!="Migration") {
                // @ts-ignore
                const module: tblLdlModule = LDLallowedModules[moduleName];
                console.log(module)
                if (!((_props.isPublic&&module&&module.ShowLdlReactPublic==-1)||(!_props.isPublic))) {
                    navigate("/HomePage/MainContent");
                }
            }
        }
        if ((!(_props.isPublic) && verifiedUser) || (currentPath == "/Authenticate/Login") || (_props.isPublic)) {
            setVerifiedAuthState(true);
        }
        if (currentPath !== "/Authenticate/Login"&&currentPath !== "/Authenticate/LoginFailure") checkIfSessionValid();
    }, [currentPath]);

    const getStyleS = () => {

        const e = [];
        for (var t = 1; t <= 13; t++) {
            e.push("banner_" + t + ".jpg");
        }
        const n = Math.floor(Math.random() * e.length);
        const a = require("../../Content/HomePage/Graphics/TopBanner/" + e[n])
        const o = require("../../Content/HomePage/Graphics/TopBanner/repeat.jpg")
        return {background: "url('" + a + "') no-repeat fixed 0% 0% , url('" + o + "') repeat-x fixed 1% 0%"};
    };
    const [styleS,setStyleS]=useState(getStyleS())
    const parentNav = (page: any, treePath: string, textpaths: any[], nextpath?: string) => {

        if (page["UnSeenParent"]) {
            // @ts-ignore
            parentNav(nameTranslator[page["UnSeenParent"]], page["UnSeenParent"], textpaths);
        }

        const display = page["TabDisplay"].replace("$" + treePath + "$", page["ShortName"]).replace("&" + treePath + "&", page["TabName"]);
        const sections = display.split("%" + treePath + "%");
        if (sections.length > 1) {

            sections.map((text: string, index: number) => {
                if (index != sections.length - 1) {
                    textpaths.push(<IconLink href={treePath+(page["MainTab"]?page["MainTab"]:"/Main")} IconID={page["IconContentRef"]}/>)
                }
                if (nextpath != page["MainTab"]) {
                    textpaths.push(<Link state={location.state} to={treePath + page["MainTab"]}

                                         style={{textDecoration: "none", fontSize: "12px"}}>{text}</Link>)
                } else {
                    textpaths.push(text)
                }

            })

        } else {
            if (nextpath != page["MainTab"] || (nextpath == page["MainTab"] && page["SubLinks"] && page["SubLinks"][page["MainTab"]] && page["SubLinks"][page["MainTab"]] != "#INHERIT#")) {
                textpaths.push(<Link state={location.state} to={treePath + page["MainTab"]}
                                     style={{textDecoration: "none", fontSize: "12px"}}>{display}</Link>)
            } else {
                textpaths.push(display)
            }
        }
        if (nextpath != page["MainTab"] || (nextpath == page["MainTab"] && page["SubLinks"] && page["SubLinks"][page["MainTab"]] && page["SubLinks"][page["MainTab"]] != "#INHERIT#")) {
            textpaths.push(" > ")
        }

    }
    const [currentPathData,setCurrentPathData]=useState<PathData>()

    useEffect(() => {
        setCurrentPathData((prev)=>InitPathData(currentPath,prev))
        console.log(currentPathData);

        const path = currentPath
        let paths = path.split("/");
        if (paths.length > 0 && paths[1] != '' && paths[1] != 'Authenticate') {
            const textpaths: any[] = []
            // @ts-ignore
            let page = nameTranslator["/" + paths[1]];
            const treePath = "/" + paths[1];

            if (!page) {
                // @ts-ignore
                const module:tblLdlModule=LDLallowedModules[paths[1]];
                if(module) {
                    page = {
                        "IconContentRef": (module && module.MainIconID) ? module.MainIconID : LDLIcons.LDL_Main,
                        ShortName: "",
                        "TabName": module.UpperLeftModuleName ? (`${module.MainIconID != LDLIcons.General_DefaultIcon ? (String("%/" + paths[1] + "%") + " ") : ""}${module.UpperLeftModuleName}`) : paths[1],
                        "DisplayName": module.UpperRightModuleName ? module.UpperRightModuleName.replaceAll(String("%icon%"), String("%/" + paths[1] + "%")) : String("&/" + paths[1] + "&"),
                        "TabDisplay": String("&/" + paths[1] + "&"),
                        "UnSeenParent": module.GeogID ? "/Faunas" : "/HomePage",
                        "MainTab": "/Main"
                    }
                    if (module.GeogID) {
                        page["SubLinks"] = {
                            "/Main": "#INHERIT#",
                            "/DefineFauna": {
                                "TabName": "Define Fauna",
                                "TabDisplay": "&/DefineFauna&"
                            },
                            "/Results": {
                                "TabName": "Faunal List",
                                "TabDisplay": "%/Results% &/Results&",
                                "IconContentRef": (module && module.MainIconID) ? (module.MainIconID + 1) : LDLIcons.LDL_Main
                            }
                        }
                    }
                }else{
                    page = {
                        "IconContentRef": LDLIcons.LDL_Main,
                        ShortName: "",
                        "TabName": paths[1],
                        "DisplayName": String("&/" + paths[1] + "&"),
                        "TabDisplay": String("&/" + paths[1] + "&"),
                        "UnSeenParent": "/HomePage",
                        "MainTab": "/Main"
                    }
                }
            }else{
                // @ts-ignore
                const module:tblLdlModule=LDLallowedModules[paths[1]];
                if(module){
                    page["IconContentRef"]= (module&&module.MainIconID)?module.MainIconID:LDLIcons.LDL_Main;
                    page["TabName"]= module.UpperLeftModuleName?(`${module.MainIconID!=LDLIcons.General_DefaultIcon?(String("%/" + paths[1] + "%")+" "):""}${module.UpperLeftModuleName}`):paths[1];
                    page["DisplayName"]= module.UpperRightModuleName?module.UpperRightModuleName.replaceAll(String("%icon%"),String("%/" + paths[1] + "%")):String("&/" + paths[1] + "&");
                    page["TabDisplay"]= String("&/" + paths[1] + "&");
                }
            }

            if (paths[2]) {
                // @ts-ignore
                parentNav(page, treePath, textpaths, "/" + paths[2], false);
            } else {
                // @ts-ignore
                parentNav(page, treePath, textpaths);
            }

            const usedPaths: string[] = [];


            paths.map((path, index) => {
                const pathlink = "/" + path
                // @ts-ignore
                if (index > 1 && (pathlink != page["MainTab"] || (pathlink == page["MainTab"] && page["SubLinks"] && page["SubLinks"][page["MainTab"]] && page["SubLinks"][page["MainTab"]] != "#INHERIT#"))) {
                    // console.log(page)
                    //console.log(pathlink)
                    let subPage: any = page["SubLinks"] ? page["SubLinks"][pathlink] : undefined;
                    if (index == paths.length - 1) {
                        if (subPage && subPage["TabDisplay"]) {
                            const display = subPage["TabDisplay"].replace("$" + pathlink + "$", subPage["ShortName"] ? subPage["ShortName"] : "").replace("&" + pathlink + "&", subPage["TabName"]);
                            const sections = display.split("%" + pathlink + "%");
                            if (sections.length > 1) {
                                sections.map((text: string, index: number) => {
                                    textpaths.push(text)
                                    if (index != sections.length - 1) {
                                        textpaths.push(<IconLink HistoryLocStates={location.state} href={treePath + usedPaths.join("") + pathlink} IconID={subPage["IconContentRef"]}/>)
                                    }
                                })

                            } else if (!subPage || (subPage != "#INHERIT#")) {
                                textpaths.push(display)
                            }
                        } else {
                            textpaths.push(path)
                        }
                    } else {
                        if (subPage && subPage["TabDisplay"]) {
                            const display = subPage["TabDisplay"].replace("$" + pathlink + "$", subPage["ShortName"] ? subPage["ShortName"] : "").replace("&" + pathlink + "&", subPage["TabName"]);
                            const sections = display.split("%" + pathlink + "%");
                            if (sections.length > 1) {

                                sections.map((text: string, index: number) => {
                                    textpaths.push(<Link state={location.state}
                                                         to={treePath + usedPaths.join("") + pathlink}
                                                         style={{
                                                             textDecoration: "none",
                                                             fontSize: "12px"
                                                         }}>{text}</Link>)
                                    if (index != sections.length - 1) {
                                        textpaths.push(<IconLink HistoryLocStates={location.state} href={treePath + usedPaths.join("") + pathlink} IconID={subPage["IconContentRef"]}/>)
                                    }
                                })

                            } else {
                                textpaths.push(<Link state={location.state}
                                                     to={treePath + usedPaths.join("") + pathlink}
                                                     style={{
                                                         textDecoration: "none",
                                                         fontSize: "12px"
                                                     }}>{display}</Link>)
                            }
                        } else {
                            textpaths.push(<Link state={location.state} to={treePath + usedPaths.join("") + pathlink}
                                                 style={{textDecoration: "none", fontSize: "12px"}}>{path}</Link>)
                        }


                    }
                    if (index != paths.length - 1 && ("/" + paths[index + 1]) != page["MainTab"]) {
                        textpaths.push(" > ")
                        usedPaths.push(pathlink);
                    }
                }

            });
            const divPaths: any[] = []
            const display = page["DisplayName"].replace("$" + treePath + "$", page["ShortName"]).replace("&" + treePath + "&", page["TabName"]);
            console.log(page)
            console.log(treePath)
            const sections = display.split("%" + treePath + "%");
            if (sections.length > 1) {
                sections.map((text: string, index: number) => {
                    divPaths.push(text)
                    if (index != sections.length - 1) {
                        divPaths.push(<IconLink href={treePath+(page["MainTab"]?page["MainTab"]:"/Main")} IconID={page["IconContentRef"]}/>)
                    }
                })

            } else {
                divPaths.push(display)
            }

            setNavDat(textpaths)
            setDivDat(divPaths)
        } else {
            setNavDat([])
            setDivDat([<>{"Lacewing Digital Library (LDL)"}</>])
        }
        ReactGA.send({hitType: "pageview",page:currentPath,title:currentPath.split("/").slice(1).join(" > ")})
        if(onClickFunction) {
            setOnClickFunction(undefined)
        }
        if(afterTitle){
            setAfterTitle(undefined)
        }
         }, [currentPath]);


    const [currentWindow,setCurrentWindow]=useState({height:window.innerHeight,width:window.innerWidth})
    window.onresize=(e)=>{setCurrentWindow({height:window.innerHeight,width:window.innerWidth})}
    // @ts-ignore
    const refB=useRef<HTMLElement>(null)
    const refA=useRef<HTMLDivElement>(null)
    const [scrollAmount,setScrollAmount]=useState(0)

    const header=<tr>
        <td className={"navMenu"} colSpan={2} style={currentPath=="/HomePage/MainContent"?styleS:{backgroundImage:""}}>
            <div style={{position:"relative",top:"-1px",width:"100vw",overflow:"visible"}}>
                <div ref={refA} onScroll={(e)=>
                    //@ts-ignore
                    setScrollAmount(e.target.scrollLeft)}  style={{position:"fixed",top:refB.current?.offsetHeight?(refB.current.offsetHeight-60):undefined,maxWidth:"100vw",overflow:"auto",height:"40px"}}>
                    <div style={{minWidth:refB.current?.clientWidth}}>
                        <br/>
                    </div>
                </div>
                <nav onWheel={(e)=>{
                    if(refA.current) {
                        refA.current.scrollLeft +=e.deltaX
                    }
                } } ref={refB} style={{width:"fit-content"}}>
                    <ul style={{marginLeft:"0px",position:"relative",right:scrollAmount}}>
                        <AlowedLICheaker isPublic={_props.isPublic}>
                            <li><a className="header-nav-link" href="/HomePage/MainContent">Home</a></li>

                            <li><a className="header-nav-link" href="/Biblio/Main">&nbsp;Bibliography</a>
                                <ul>
                                    <AlowedLICheaker isPublic={_props.isPublic}>
                                    <li><Link className="header-nav-link" to="/PageViewer/Pages" state={{"BibObjID":1}}>File Viewer</Link></li>
                                    </AlowedLICheaker>
                                </ul>
                            </li>

                            <li><a className="header-nav-link" href="/Serials/Main">&nbsp;Serials</a></li>

                            <li><a className="header-nav-link" href="/SpeciesCatalog/Main">&nbsp;Species</a></li>

                            <li><a className="header-nav-link" href="/GenusCatalog/Main">&nbsp;Genera</a></li>

                            <li style={{position:"relative"}}>
                                <a className="header-nav-link" href="/Faunas/Main">&nbsp;Faunas</a>
                                <ul id="second"  style={{position:"absolute"}}>
                                    <AlowedLICheaker isPublic={_props.isPublic}>
                                        <li style={{borderBottom: "1px solid white", marginBottom: "3px",}}><a
                                            className="header-nav-link" href="/Faunas/Main">About</a></li>
                                        <li style={{borderBottom: "1px solid white", marginBottom: "7px",}}><a
                                            className="header-nav-link" href="/World/Main">World</a></li>
                                        {/*all countries should be listed in alphabetical order*/}
                                        <>{_props.isPublic ?
                                            // @ts-ignore
                                            Object.entries(LDLAllowedModulesByID).map((val)=>({name:String(FaunaCountry[val[1].GeogID]?FaunaCountry[val[1].GeogID].PoliticalUnitName:val[1].LdlModuleReference),dat:val[1]}))
                                                .sort((val1,val2)=>val1.name.localeCompare(val2.name)).map((val)=> {

                                                if(val.dat.GeogID&&val.dat.ShowLdlReactPublic==-1||(val.dat.ShowLdlReactDemo==-1&&(!_props.isPublic))) {
                                                    return [<li><a className="header-nav-link" href={`/${val.dat.LdlModuleReference}/Main`}>{val.name}</a></li>];
                                                }else{
                                                    return []
                                                }
                                            }).flat(): (()=>{


                                                // @ts-ignore
                                                const data =Object.entries(LDLAllowedModulesByID).map((val)=>({dat:val[1],name:FaunaCountry[val[1].GeogID]?FaunaCountry[val[1].GeogID].PoliticalUnitName:val[1].LdlModuleReference}))
                                                    .sort((val1,val2)=>val1.name.localeCompare(val2.name))
                                                let buckets:{[key:string]:(any[])} = {};
                                                data.forEach((val)=>{
                                                    if(Number(val.dat.ID)!=LDLModule.World&&val.dat.GeogID) {
                                                        if (!buckets[val.name[0]]) {
                                                            buckets[val.name[0]] = [];
                                                        }
                                                        // @ts-ignore
                                                        buckets[val.name[0]].push(val)
                                                    }
                                                })
                                                return Object.entries(buckets).map((val,num)=> {
                                                    return <li ><a className="header-nav-link"  style={{color:"white"}}>{val[0]}</a>
                                                        <div>
                                                            <ul style={{maxHeight:`calc(90vh - ${145+20*num}px)`,overflowY:"auto",overflowX:"hidden",borderLeft:"0px",borderTop:"2.5px solid #A0A0A0"}} id="second">
                                                                {val[1].map((val)=>{
                                                                    if(val.dat.ShowLdlReactPublic==-1||(val.dat.ShowLdlReactDemo==-1&&(!_props.isPublic))) {
                                                                        return [<li style={{marginBottom: "3px",display:"block"}}>
                                                                            <a className="header-nav-link" href={`/${val.dat.LdlModuleReference}/Main`}>{val.name}</a>
                                                                        </li>]
                                                                    }
                                                                    return []
                                                                }).flat()}

                                                            </ul>
                                                        </div>
                                                    </li>;
                                                })

                                            })()}</>
                                        {/*<li><a className="header-nav-link" href="/Albania/Main">Albania</a></li>
                                    <li><a className="header-nav-link" href="/Angola/Main">Angola</a></li>
                                    <li><a className="header-nav-link" href="/Botswana/Main">Botswana</a></li>
                                    <li><a className="header-nav-link" href="/Brazil/Main">Brazil</a></li>
                                    <li><a className="header-nav-link" href="/Bulgaria/Main">Bulgaria</a></li>
                                    <li><a className="header-nav-link" href="/Chile/Main">Chile</a></li>
                                    <li><a className="header-nav-link" href="/China/Main">China</a></li>
                                    <li><a className="header-nav-link" href="/Colombia/Main">Colombia</a></li>
                                    <li><a className="header-nav-link" href="/France/Main">France</a></li>
                                    <li><a className="header-nav-link" href="/Germany/Main">Germany</a></li>
                                    <li><a className="header-nav-link" href="/Honduras/Main">Honduras</a></li>
                                    <li><a className="header-nav-link" href="/Ireland/Main">Ireland</a></li>
                                    <li><a className="header-nav-link" href="/Italy/Main">Italy</a></li>
                                    <li><a className="header-nav-link" href="/Kenya/Main">Kenya</a></li>
                                    <li><a className="header-nav-link" href="/Lesotho/Main">Lesotho</a></li>
                                    <li><a className="header-nav-link" href="/Macedonia/Main">Macedonia</a></li>
                                    <li><a className="header-nav-link" href="/Mexico/Main">Mexico</a></li>
                                    <li><a className="header-nav-link" href="/Namibia/Main">Namibia</a></li>
                                    <li><a className="header-nav-link" href="/Poland/Main">Poland</a></li>
                                    <li><a className="header-nav-link" href="/Serbia/Main">Serbia</a></li>
                                    <li><a className="header-nav-link" href="/Slovenia/Main">Slovenia</a></li>
                                    <li><a className="header-nav-link" href="/SouthAfrica/Main">South Africa</a></li>
                                    <li><a className="header-nav-link" href="/Swaziland/Main">Swaziland</a></li>
                                    <li><a className="header-nav-link" href="/Switzerland/Main">Switzerland</a></li>
                                    <li><a className="header-nav-link" href="/Tanzania/Main">Tanzania</a></li>
                                    <li><a className="header-nav-link" href="/UnitedKingdom/Main">United Kingdom</a>
                                    </li>
                                    <li><a className="header-nav-link" href="/UnitedStates/Main">United States</a></li>
                                    <li><a className="header-nav-link" href="/Zambia/Main">Zambia</a></li>
                                    <li><a className="header-nav-link" href="/Zimbabwe/Main">Zimbabwe</a></li>*/}
                                    </AlowedLICheaker>
                                </ul>
                            </li>

                            <li><a className="header-nav-link" href="/NeuroDirectory/Main">&nbsp;Directory</a></li>

                            <li>
                                <a className="header-nav-link" href="/Interactions/Main">&nbsp;Interactions</a>
                                <ul>
                                    <li><a className="header-nav-link" href="/Interactions/Main">Predator / Prey</a></li>
                                </ul>

                            </li>

                            <li><a className="header-nav-link" href="/KeysDichot/Main">&nbsp;Keys</a></li>

                            <li><a className="header-nav-link" href="/Metakey/Main">&nbsp;Metakey</a></li>

                            <li><a className="header-nav-link" href="/Keys/Main">&nbsp;OldKeys</a></li>

                            <li><a className="header-nav-link" href="/Downloads/Main">&nbsp;Downloads</a></li>


                            <li><a className="header-nav-link" href="/Classification/Main">&nbsp;Classification</a></li>
                            <li><a className="header-nav-link" href="/Testing/Main">&nbsp;Testing</a></li>
                        </AlowedLICheaker>
                    </ul>

                </nav>

            </div>
        </td>
    </tr>
    const headerRef= useRef<HTMLTableSectionElement>(null)
    const [height,setHeight]=useState(0)
    useEffect(() => {
        if (!headerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const hdist=headerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight((hdist?hdist:0))
        });
        resizeObserver.observe(headerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,headerRef]);
    return (<>
        <header>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <PathTitleIcon currentPathData={currentPathData}/>
            <title><PathTitle currentPathData={currentPathData}/></title>
        </header>

        <body style={{height:"100vh",zIndex:1}} onClick={onClickFunction} id={paths[1]} className={paths[0]}>
        <table className={"MainTable"}>
            <thead ref={headerRef} id={"mainHeader"}>
            {header}
            <tr>
                <td className={"sub-left-cell"}>
                    <nav>
                        <div className="sub-nav" id="sub-left"><UpperLeftNav currentPathData={currentPathData}/></div>
                    </nav>
                </td>
                <td className={"sub-right-cell"}>
                    <nav>
                        <div className="sub-nav" id="sub-right"><UpperRightNav currentPathData={currentPathData}/></div>
                    </nav>
                </td>
            </tr>
            </thead>
            {verifiedAuthState && <Outlet context={[[PDFAccess, setPDFAccess],[verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],_props.isPublic,[onClickFunction,setOnClickFunction],<></>,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]]}/>
                }
        </table>






        </body>
        <div className={"printOnly"} style={{position:"fixed",bottom:"15px",left:"10px"}}>
            LDL Download {new Date().getFullYear()}
        </div>
    </>)
};

export default _Layout;