import React, {useEffect, useState} from "react";
import {proccessSearch, SearchOutputProps, SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {layoutContextType} from "../Shared/_Layout";


import {
    EasyTable,
    EasyTableElement,
    Icon, IconButton,
    IconLink, ListElement, ListHolder,
    Record,
    
    SearchCriteria
} from "../../utils/CustomRecord";
import {tblLdlFaunaNidaByPoliticalUnit} from "../../../../database/models/tblLdlFaunaNidaByPoliticalUnit";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import nameTranslator from "../../utils/nameTranslator";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json";
import LDLUsedBibObjID from "../../utils/OnServerStartLoadedJsons/LDLUsedBibObjID.json";
import FaunaCountry from "../../utils/OnServerStartLoadedJsons/FaunaCountry.json";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";


enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}


interface FaunaSearchProps extends SearchOutputProps {
    extant: { Val: string },
    OrdeTaxObjID?: { Val: string },
    FamiTaxObjID?: { Val: string },
    GeographyID: { Val: string },
    ProjectID?: { Val: string },
    CountryID?: { Val: string },
    IsSubs: { Val: string }
}

const conversionTable = {
    "Extant / Extinct": "extant",
    "Geographic Scope": "GeographyID",
    "Taxonomic Scope": "FamiTaxObjID",
    "Lowest List Rank": "IsSubs"
}
const FaunaResult = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlBibNeur[] | any>()
    const [currentClickedButton,setCurrentClickedButton]=useState(-1);
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any | undefined;
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);

    // @ts-ignore
    const myGeo:tblLdlModule = LDLallowedModules[paths[0]]
    const [subs,subg]=_props.searchProps.filter((val: { sectionName: string; })=>val&&val.sectionName=="Lowest List Rank"||val.sectionName=="Subgenera").map((val: { criteriaVal: any; })=>Number(val.criteriaVal))
    //console.log(subs,nsubg,_props.searchProps)
    useEffect(() => {
        //console.log(_props)
        let out: FaunaSearchProps = Object()


        out = proccessSearch(_props?.searchProps, conversionTable, out) as FaunaSearchProps;
        //console.log(out)
        // @ts-ignore
        if (myGeo.BibObjectID&&LDLUsedBibObjID[myGeo.BibObjectID]) {
            Object(out)["ProjectID"] = {Val: myGeo.BibObjectID};
        }
        // @ts-ignore
        callAPI("/api/general/getFaunaRankByID", {TaxObjID: String(Object(out)["FamiTaxObjID"].Val)}, (error: boolean, outData: number | Response) => {
            if (error) {
                console.log(outData)
            } else {
                const rankval = outData;

                if (rankval != 24) {
                    if (rankval == 18) {
                        Object(out)["OrdeTaxObjID"] = {...Object(out)["FamiTaxObjID"]};
                    }
                    delete Object(out)["FamiTaxObjID"];
                }
                Object(out)["CountryID"] = {Val: myGeo.GeogID};
                console.log(out);
                callAPI("/api/general/doFaunaSearch", out, (error: boolean, outData: tblLdlFaunaNidaByPoliticalUnit[] | Response) => {
                    if (error) {
                        console.log("error")
                    } else {
                        setResultData(outData)
                        console.log(outData)


                    }

                });
            }
        })

    }, []);
    //@ts-ignore
    const PageName = myGeo.GeogID?FaunaCountry[myGeo.GeogID].PoliticalUnitName:"";
    useEffect(() => {
        if(!onClickFunction) {
            setOnClickFunction(() => {
                return (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                   setCurrentClickedButton(-1);
                }
            })
        }
    }, [currentPath,onClickFunction]);
    //console.log(`subs ${!!subs} subg ${!nsubg}`,_props.searchProps)
     return <>
        {(resultData) &&
            <Record title={<>Neuropterida (Insecta) of {PageName} <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                href={`mailto:${myGeo.FaunaAbout?myGeo.FaunaAbout.EmailAddress:"j-oswald@tamu.edu"}?subject=World Neuropterida Fanuas: comment/correction for ${PageName} Faunal List page`}
                IconID={LDLIcons.General_Email}
                HistoryLocStates={location.state}
            /></>}>
                <SearchCriteria searchCriteria={_props.searchProps}>
                <i>
                    <>Results Records ({resultData.length}).</>
                </i>
                </SearchCriteria>
                <EasyTable downloadable={true} downloadName={`${myGeo.LdlModuleReference}_Faunal_List.csv`} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData}>
                    <EasyTableElement width={"0%"} align={"left"} title={"Order"} removeBottomLineIfEmpty={true}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index, array) => {
                                          if (!array[index - 1] || array[index - 1] && array[index - 1].Orde != val.Orde) {
                                              return <>{val.Orde}</>
                                          } else {
                                              return undefined
                                          }
                                      }} desc={"taxonomic order to which the indicated species/subspecies belongs"}/>
                    <EasyTableElement width={"0%"} align={"left"} title={"Family"} removeBottomLineIfEmpty={true}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index, array) => {
                                          if (!array[index - 1] || array[index - 1] && array[index - 1].Family != val.Family) {
                                              return <>{val.Family}</>
                                          } else {
                                              return undefined
                                          }
                                      }} desc={"taxonomic family to which the indicated species/subspecies belongs"}/>
                    <EasyTableElement width={"20%"} align={"left"} title={`Species${subs?"/Subspecies":""}`} removeBottomLineIfEmpty={true}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index,array:tblLdlFaunaNidaByPoliticalUnit[]) => {
                                          return [((index-1<0)||val.Genus!=array[index-1].Genus)?(val.Genus):(undefined),<>{val.Extinct == -1 && <>&dagger;</>}{subg?(subs?val.GenuSubgSpecSubs:val.GenuSubgSpec):(subs?val.GenuSpecSubs:val.GenuSpec)}</>]
                                      }}
                                      desc={"name of a species or subspecies reported as occurring in the list area"}/>
                    <EasyTableElement width={"10%"} align={"left"} title={"Nativity"}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index) => {
                                          return <>{val.Nativity}</>
                                      }}
                                      desc={"presumed nativity of the indicated species/subspecies in the list area"}/>
                    <EasyTableElement width={"20%"}   align={"left"} title={"Distribution"}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index) => {
                                          return <>{(subs&&val.IsSubs==-1)?val.GeogDistSummSpecOnly:val.GeogDistSumm}</>
                                      }}
                                      desc={"summary statement of the known global range of the indicated species/subspecies"}/>
                   <EasyTableElement isSymbol={true} minWidth={"16px"} width={"0%"} align={"left"} title={<>{myGeo.MainIconID&&<Icon IconID={LDLIcons.WNF_SubcountryList}/>}</>}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index) => {
                                         return <>{currentClickedButton==index?<IconButton IconID={LDLIcons.General_HideList} onClick={(e)=>{setCurrentClickedButton(-1);e.stopPropagation();}}/>:<IconButton IconID={LDLIcons.General_ShowList} onClick={(e)=>{setCurrentClickedButton(index);e.stopPropagation();}}/>}
                                             {(currentClickedButton==index)&&
                                                      <div style={{position:"relative",float:"right",top:"0px",left:"calc(-100% + 10px)",display:"block",textIndent:"0px"}}>
                                                          <div style={{position:"absolute",minWidth:"10rem",fontSize:"11px",backgroundClip:"padding-box",borderRadius:"4px",width:"fit-content",backgroundColor:"#fff",paddingLeft:"4px",paddingRight:"16px",boxSizing:"border-box",border:"1px solid rgba(0,0,0,0.15)",textAlign:"left",whiteSpace:"nowrap",top:"0px",left:"0px",display:"block"}}>
                                                              <a style={{color:"black"}} href={"/"+paths[0]+"/Main"}><b>{PageName}</b></a>
                                                              {val.OtherNidaPoliticalUnits?.map((val) => <><br/><span style={{
                                                                  whiteSpace: "nowrap",
                                                                  textAlign: "left",
                                                                  paddingLeft:"4%"
                                                              }}>{val.PoliticalUnit?.PoliticalUnitNameFeature2}</span></>)}
                                                          </div>
                                                      </div>}
                                                  </>
                                          }} desc={"province occurrence lists for the species/subspecies"}/>
                    <EasyTableElement width={"15%"} align={"left"} title={"Occurrence"}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index) => {
                                          return <>{val.Occurrence}</>
                                      }} desc={"known temporal occurrence of the species/subspecies"}/>
                    <EasyTableElement width={"12%"} align={"left"} title={"Stratigraphy"}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index) => {
                                          return <>{val.LithDistSumm}</>
                                      }}
                                      desc={"for extinct species/subspecies known from fossils, the geological formation(s) of occurrence."}/>
                    <EasyTableElement isSymbol={true} minWidth={"22px"} paddingLeft={"11px"} textIndent={"0px"} align={"left"} title={<IconLink href={"/SpeciesCatalog/Main"} IconID={LDLIcons.NSW_Main}/>}
                                      elementFromValue={(val: tblLdlFaunaNidaByPoliticalUnit, index) => {
                                          return <IconLink href={"/SpeciesCatalog/MonographRecord"}
                                                           IconID={LDLIcons.NSW_MonographicRecord}
                                                           newStates={{"CombObjID": val.CombObjID}}>{val.LeastTaxObjID!=val.NswLeastTaxObjID&&<>&deg;</>}</IconLink>
                                      }} desc={"Neuropterida Species of the World, Monograph Record page link"}/>
                </EasyTable>
            </Record>

        }
    </>
};


export default FaunaResult;