export const AcknowledgmentsTEXT = {
    World: <><p style={{textAlign: 'justify'}}>
        add text here
    </p>

        <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Graduate Students</i></b></p>
        <p style={{marginTop: "5px", textAlign: "justify",}}>Technical implementation of the<i>World Neuropterida by
            Country</i> web resource was undertaken primarily by Li-ming Ye and Yuan-xing Yin.</p>
        <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Funding</i></b></p>
        <p style={{marginTop: "5px", textAlign: "justify",}}></p>
        <p style={{marginTop: "5px", textAlign: "justify",}}>Development of the World Neuropterida by Country web
            resource was supported in part by the U.S. <a href="http://www.nsf.gov/" target="_top">National Science
                Foundation (NSF)</a> through the <a href="http://www.nsf.gov/div/index.jsp?div=DEB" target="_top">Division
                of Environmental Biology (DEB)</a> program <a
                href="http://www.nsf.gov/funding/pgm_summ.jsp?pims_id=5451&amp;org=DEB&amp;from=home" target="_top">Partnerships
                for Enhancing Expertise in Taxonomy (PEET)</a>, grant #0933588. The National Science Foundation funds
            research and education in most fields of science and engineering. Grantees are wholly responsible for
            conducting their project activities and preparing the results for publication. Thus, the Foundation does not
            assume responsibility for such findings or their interpretation.</p>
        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the <a
            href="http://entomology.tamu.edu/" target="_top">Department of Entomology</a> of <a
            href="http://www.tamu.edu/" target="_top"> Texas A&amp;M University</a>,
            <a href="http://agriliferesearch.tamu.edu/" target="_top"> Texas A&amp;M AgriLife Research</a>(formerly the
            Texas Agricultural Experiment Station), and the <a href="http://library.tamu.edu/" target="_top">Texas
            A&amp;M Libraries</a>.
        </p>
        <p style={{textAlign: 'justify'}}>
            <br/>
        </p></>,
    Albania: <><p style={{textAlign: 'justify'}}>
        I thank the Ministry of Environment, Forests and Water of the Republic of Albania for
        permission to collect insects in Albanian national parks, and Ms. Elvana Ramaj for her help in issuing the
        authorization. The Balkan expeditions organized by the Department of Biology of the University of Maribor were
        supported in part by the research project Biodiversity of the Neuropterida on the Balkans (RP BioDiv
        Neuropterida Balkan – ALBH 2013) and in part by the Slovenian Research Agency through an Infrastructure Program
        grant (No. IP-0552). I thank the following colleagues and friends for their assistance in the field: F.
        Janžekovič, T. Klenovšek, V. Klokočovnik, J. Podlesnik, P. Jakšić and A. Nahirnić. I am particularly indebted to
        Hubert and Renate Rausch (Scheibbs, Austria) for their valuable contributions during the preparation of this
        checklist.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            My sincere thanks to John Oswald for general design, editing, and implementation of the Neuropterida of
            Albania as a module within the World Neuropterida Faunas initiative of the Lacewing Digital Library web
            portal, and to Yongyan Liu and Jiacheng Liu for technical implementation of the project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Ireland: <><p style={{textAlign: 'justify'}}>
        I thank John Oswald for inviting me to contribute to the Lacewing Digital Library’s World Neuropterida Faunas
        project, and for general design, editing, and implementation of the Neuropterida of Ireland as a module within
        the Lacewing Digital Library web portal. Thanks also to Yongyan Liu for his technical implementation of the
        project.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Italy: <><p style={{textAlign: 'justify'}}>
        Several colleagues have contributed substantially to the occurrence data summarized in this work. We are
        particularly indebted to Roberto A. Pantaleoni (University of Sassari, Italy) and Rinaldo Nicoli Aldini
        (University of Piacenza, Italy), for their contributions in recent years.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            We thank John Oswald for general design, editing and implementation of the Neuropterida of Italy as a module
            within the Lacewing Digital Library web portal, and Tian-jian Wang and Yongyan Liu for their technical
            implementation of the project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            Initial Web implementation of the Neuropterida of Italy was supported in part by the U.S. National Science
            Foundation (NSF) through a Partnerships for Enhancing Expertise in Taxonomy (PEET) grant (#0933588, to John
            D. Oswald). The National Science Foundation funds research and education in most fields of science and
            engineering. Grantees are wholly responsible for conducting their project activities and preparing the
            results for publication. Thus, the Foundation does not assume responsibility for such findings or their
            interpretation.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Slovenia: <><p style={{textAlign: 'justify'}}>
        Many entomologists have collected Neuropterida in Slovenia in recent decades, and many of the resulting
        specimens have been deposited/vouchered in the author’s entomological research collection. I thank the following
        individuals in particular for access to specimens that they have collected, which have greatly aided in the
        preparation of this checklist: S. Brelih(†), J. Carnelutti(†), M. Gogala, B. Horvat(†), F. Janžekovič, M. Jež,
        J. Kamin, C. Krušnik(†), T. Novak, A. Podobnik, S. Polak, I. Sivec, M. Štangelj, and M. Zavec.
        <br/>
        <br/>
        My thanks to John Oswald for general design, editing and implementation of the Neuropterida of Slovenia as a
        module within the Lacewing Digital Library web portal, and to Tian-jian Wang for technical implementation of the
        project.
        <br/>
        <br/>
        Web implementation of the Neuropterida of Slovenia was supported in part by the U.S. National Science Foundation
        (NSF) through a Partnerships for Enhancing Expertise in Taxonomy (PEET) grant (#0933588, to John D. Oswald). The
        National Science Foundation funds research and education in most fields of science and engineering. Grantees are
        wholly responsible for conducting their project activities and preparing the results for publication. Thus, the
        Foundation does not assume responsibility for such findings or their interpretation.
        <br/>
        <br/>
        General in-kind support for Lacewing Digital Library projects has been provided over the years by the Department
        of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
    </p></>,
    SouthAfrica: <><p style={{textAlign: "justify"}}>
        Data set development for this project was supported by the Global Biodiversity Information Facility (GBIF), the
        South African Biodiversity Information Facility (SABIF), and especially by the JRS Biodiversity Foundation,
        Seattle, USA, who are gratefully acknowledged.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify"}}>
            All the persons and institutions mentioned above are sincerely thanked for their contributions to the
            Lacewing project, and museum curators for access to collections of Neuropterida under their care.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify"}}>
            I am particularly indebted to my colleague, Brian Kenyon (Johannesburg) for years of collaboration in the
            design and implementation of the relational databases that underpin this project and for his exceptional
            skills as a programmer and insight into what is required of biological databases.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify"}}>
            We thank Yongyan Liu for technical implementation of the project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify"}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Switzerland: <><p style={{textAlign: 'justify'}}>
        We are very thankful to John Oswald for general design, editing and implementation of the Neuropterida of
        Switzerland as a module within the Lacewing Digital Library web portal, and to Hayoung Jeon, Muxi Leng, and
        Dongqing ‘Serena’ Yang for technical implementation of the project.
    </p>
        <p style={{marginTop: "5px", textAlign: "justify",}}>
            We thank the ‘Info fauna’ foundation (formerly the Centre Suisse de Cartographie de la Faune) and its
            primary funding agency the Federal Office for the Environment (FOEN, Bern) for their support of recent
            efforts to identify and/or re-verify the identities of all Neuropterida specimens contained in the major
            Swiss museum collections. The ‘Info fauna’ foundation manages and develops the primary Swiss national faunal
            databases for most animal groups, including all invertebrates. Its Neuropterida dataset consists primarily
            of internally-developed data and data derived from the 2004 transfer of an earlier database created at the
            Swiss Federal Research Institute WSL by Martin K. Obrist (with its Neuropterida data sourced primarily from
            the private collection of P. Duelli and various WSL research projects).
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Brazil: <><p style={{textAlign: 'justify'}}>
        I thank the Ministry of Environment, Forests and Water of the Republic of Albania for
        permission to collect insects in Albanian national parks, and Ms. Elvana Ramaj for her help in issuing the
        authorization. The Balkan expeditions organized by the Department of Biology of the University of Maribor were
        supported in part by the research project Biodiversity of the Neuropterida on the Balkans (RP BioDiv
        Neuropterida Balkan – ALBH 2013) and in part by the Slovenian Research Agency through an Infrastructure Program
        grant (No. IP-0552). I thank the following colleagues and friends for their assistance in the field: F.
        Janžekovič, T. Klenovšek, V. Klokočovnik, J. Podlesnik, P. Jakšić and A. Nahirnić. I am particularly indebted to
        Hubert and Renate Rausch (Scheibbs, Austria) for their valuable contributions during the preparation of this
        checklist.
    </p>

        <p style={{textAlign: 'justify', marginTop: "5px"}}>
            My sincere thanks to John Oswald for general design, editing, and implementation of the Neuropterida of
            Albania as a module within the World Neuropterida Faunas initiative of the Lacewing Digital Library web
            portal, and to Yongyan Liu and Jiacheng Liu for technical implementation of the project.
        </p>

        <p style={{textAlign: 'justify', marginTop: "5px"}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    China: <><p style={{textAlign: 'justify'}}>
        Several colleagues have contributed substantially to the occurrence data summarized in this work. I am
        particularly indebted to Roberto A. Pantaleoni (University of Sassari, Italy) and Rinaldo Nicoli Aldini
        (University of Piacenza, Italy), for their contributions in recent years.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            I thank John Oswald for general design, editing and implementation of the Neuropterida of Italy as a module
            within the Lacewing Digital Library web portal, and Tian-jian Wang for his technical implementation of the
            project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            Web implementation of the Neuropterida of Italy was supported in part by the U.S. National Science
            Foundation (NSF) through a Partnerships for Enhancing Expertise in Taxonomy (PEET) grant (#0933588, to John
            D. Oswald). The National Science Foundation funds research and education in most fields of science and
            engineering. Grantees are wholly responsible for conducting their project activities and preparing the
            results for publication. Thus, the Foundation does not assume responsibility for such findings or their
            interpretation.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    France: <><p style={{textAlign: 'justify'}}>
        Several colleagues have contributed substantially to the occurrence data summarized in this work. I am
        particularly indebted to Roberto A. Pantaleoni (University of Sassari, Italy) and Rinaldo Nicoli Aldini
        (University of Piacenza, Italy), for their contributions in recent years.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            I thank John Oswald for general design, editing and implementation of the Neuropterida of Italy as a module
            within the Lacewing Digital Library web portal, and Tian-jian Wang for his technical implementation of the
            project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            Web implementation of the Neuropterida of Italy was supported in part by the U.S. National Science
            Foundation (NSF) through a Partnerships for Enhancing Expertise in Taxonomy (PEET) grant (#0933588, to John
            D. Oswald). The National Science Foundation funds research and education in most fields of science and
            engineering. Grantees are wholly responsible for conducting their project activities and preparing the
            results for publication. Thus, the Foundation does not assume responsibility for such findings or their
            interpretation.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Honduras: <> <p style={{textAlign: 'justify'}}>
        I thank the Ministry of Environment, Forests and Water of the Republic of Albania for
        permission to collect insects in Albanian national parks, and Ms. Elvana Ramaj for her help in issuing the
        authorization. The Balkan expeditions organized by the Department of Biology of the University of Maribor were
        supported in part by the research project Biodiversity of the Neuropterida on the Balkans (RP BioDiv
        Neuropterida Balkan – ALBH 2013) and in part by the Slovenian Research Agency through an Infrastructure Program
        grant (No. IP-0552). I thank the following colleagues and friends for their assistance in the field: F.
        Janžekovič, T. Klenovšek, V. Klokočovnik, J. Podlesnik, P. Jakšić and A. Nahirnić. I am particularly indebted to
        Hubert and Renate Rausch (Scheibbs, Austria) for their valuable contributions during the preparation of this
        checklist.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            My sincere thanks to John Oswald for general design, editing, and implementation of the Neuropterida of
            Albania as a module within the World Neuropterida Faunas initiative of the Lacewing Digital Library web
            portal, and to Yongyan Liu and Jiacheng Liu for technical implementation of the project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    NorthMacedonia: <><p style={{textAlign: 'justify'}}>
        Several colleagues have contributed substantially to the occurrence data summarized in this work. I am
        particularly indebted to Roberto A. Pantaleoni (University of Sassari, Italy), Rinaldo Nicoli Aldini (University
        of Piacenza, Italy), and Davide Badano (National Research Council, Italy), for their contributions in recent
        years.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            I thank John Oswald for general design, editing and implementation of the Neuropterida of Italy as a module
            within the Lacewing Digital Library web portal, and Tian-jian Wang for his technical implementation of the
            project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            Web implementation of the Neuropterida of Italy was supported in part by the U.S. National Science
            Foundation (NSF) through a Partnerships for Enhancing Expertise in Taxonomy (PEET) grant (#0933588, to John
            D. Oswald). The National Science Foundation funds research and education in most fields of science and
            engineering. Grantees are wholly responsible for conducting their project activities and preparing the
            results for publication. Thus, the Foundation does not assume responsibility for such findings or their
            interpretation.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Mexico: <><p style={{textAlign: 'justify'}}>
        I thank the Ministry of Environment, Forests and Water of the Republic of Albania for
        permission to collect insects in Albanian national parks, and Ms. Elvana Ramaj for her help in issuing the
        authorization. The Balkan expeditions organized by the Department of Biology of the University of Maribor were
        supported in part by the research project Biodiversity of the Neuropterida on the Balkans (RP BioDiv
        Neuropterida Balkan – ALBH 2013) and in part by the Slovenian Research Agency through an Infrastructure Program
        grant (No. IP-0552). I thank the following colleagues and friends for their assistance in the field: F.
        Janžekovič, T. Klenovšek, V. Klokočovnik, J. Podlesnik, P. Jakšić and A. Nahirnić. I am particularly indebted to
        Hubert and Renate Rausch (Scheibbs, Austria) for their valuable contributions during the preparation of this
        checklist.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            My sincere thanks to John Oswald for general design, editing, and implementation of the Neuropterida of
            Albania as a module within the World Neuropterida Faunas initiative of the Lacewing Digital Library web
            portal, and to Yongyan Liu and Jiacheng Liu for technical implementation of the project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    Serbia: <><p style={{textAlign: 'justify'}}>
        Several colleagues have contributed substantially to the occurrence data summarized in this work. I am
        particularly indebted to Roberto A. Pantaleoni (University of Sassari, Italy), Rinaldo Nicoli Aldini (University
        of Piacenza, Italy), and Davide Badano (National Research Council, Italy), for their contributions in recent
        years.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            I thank John Oswald for general design, editing and implementation of the Neuropterida of Italy as a module
            within the Lacewing Digital Library web portal, and Tian-jian Wang for his technical implementation of the
            project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            Web implementation of the Neuropterida of Italy was supported in part by the U.S. National Science
            Foundation (NSF) through a Partnerships for Enhancing Expertise in Taxonomy (PEET) grant (#0933588, to John
            D. Oswald). The National Science Foundation funds research and education in most fields of science and
            engineering. Grantees are wholly responsible for conducting their project activities and preparing the
            results for publication. Thus, the Foundation does not assume responsibility for such findings or their
            interpretation.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,
    UnitedStates: <><p style={{textAlign: 'justify'}}>
        I thank the Ministry of Environment, Forests and Water of the Republic of Albania for
        permission to collect insects in Albanian national parks, and Ms. Elvana Ramaj for her help in issuing the
        authorization. The Balkan expeditions organized by the Department of Biology of the University of Maribor were
        supported in part by the research project Biodiversity of the Neuropterida on the Balkans (RP BioDiv
        Neuropterida Balkan – ALBH 2013) and in part by the Slovenian Research Agency through an Infrastructure Program
        grant (No. IP-0552). I thank the following colleagues and friends for their assistance in the field: F.
        Janžekovič, T. Klenovšek, V. Klokočovnik, J. Podlesnik, P. Jakšić and A. Nahirnić. I am particularly indebted to
        Hubert and Renate Rausch (Scheibbs, Austria) for their valuable contributions during the preparation of this
        checklist.
    </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            My sincere thanks to John Oswald for general design, editing, and implementation of the Neuropterida of
            Albania as a module within the World Neuropterida Faunas initiative of the Lacewing Digital Library web
            portal, and to Yongyan Liu and Jiacheng Liu for technical implementation of the project.
        </p>

        <p style={{marginTop: "5px", textAlign: "justify",}}>
            General in-kind support for Lacewing Digital Library projects has been provided over the years by the
            Department of Entomology of Texas A&M University, Texas A&M AgriLife Research, and the Texas A&M Libraries.
        </p></>,


}



