import {useOutletContext} from "react-router-dom";
import {layoutContextType} from "./_Layout";
import React, {useEffect, useState} from "react";
import callAPI from "../../utils/APIhelpers";
import {tblLdlProjectReferences} from "../../../../database/models/tblLdlProjectReferences";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    Record,
    TableLegendElement,
    TableLegendSection
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import LDLAllowedModulesByID from "../../utils/OnServerStartLoadedJsons/LDLAllowedModulesByID.json"

export const Reference = (_props: { ModuleID: number }) => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlProjectReferences[]>()

    // @ts-ignore
    const moduleNumber = LDLAllowedModulesByID[_props.ModuleID].BibObjectID

    useEffect(() => {
        callAPI("/api/general/getReferencesByProjectID", {ProjID: moduleNumber}, (error: boolean, outData: tblLdlProjectReferences[] | Response) => {
            if (error) {
                console.log(outData)
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
            }
        })
    }, [_props]);

    return <>{resultData && <Record title={"References"}>
        <EasyTable autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData} extraLegendChildren={<TableLegendSection title={"Abbreviations"}>
            <TableLegendElement title={"r#0000"} element={"Bibliography of the Neuropterida reference number"}/>
        </TableLegendSection>}>
            <EasyTableElement minWidth={"50px"} title={"Links"}
                              elementFromValue={(val:tblLdlProjectReferences) => <><IconLink newWindow={true} IconID={LDLIcons.BotN_BibliographicRecord}
                                                                     href={"/Biblio/Record"}
                                                                     newStates={{"BibObjID": val.BibObjID}}/>
                                  {(val.LinkPublic == -1 || (val.LinkPrivate == -1 && PDFAccess)) ?
                                      <><IconLink newWindow={true} href={val.EDocPath}
                                                  IconID={LDLIcons.General_OpenFile_Work}/>
                                          <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                    IconID={LDLIcons.Viewer_OpenFile}
                                                    newStates={{"BibObjID": val.BibObjID}}/></> : <>&nbsp;&ndash;&nbsp;&ndash;</>}</>}/>
            <EasyTableElement width={"90%"} title={"Citation"} elementFromValue={(val:tblLdlProjectReferences) => <>{val.Citation}</>}/>
        </EasyTable>
    </Record>}</>
}