const AddUpdate = () => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Add/Update</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    To maintain the focus, accuracy and integrity of <i>Neuropterists Directory</i> data, all additions
                    and modifications to directory entries are made by the editor.
                </p>
                <p className="header-paragraph-2">
                    <b>To update your existing directory entry ... </b>
                </p>
                <p className="indented-paragraph" style={{textAlign: 'justify'}}>
                    (1) click the button “Email Editor to Add/Update a Neuropterists Directory Entry” located
                    below;<br/>
                    (2) copy and paste all or part of the text of your current directory entry into the e-mail
                    message; <br/>
                    (3) edit the copied text to reflect the need changes; <br/>
                    (4) send the message to the Editor. Digital photos and other supplementary files may be sent to the
                    editor as e-mail attachments (5 Mb maximum).
                </p>
                <p className="header-paragraph-2">
                    <b>To request to be added to the <i>Neuropterists Directory</i> ... </b>
                </p>
                <p className="indented-paragraph" style={{textAlign: 'justify'}}>
                    (1) click the button “Email Editor to Add/Update a Neuropterists Directory Entry” located
                    below; <br/>
                    (2) copy and paste the text of a sample web entry from the directory into the e-mail Message (the
                    entry of the current editor is a good sample entry); <br/>
                    (3) edit the copied text to reflect the information that you would like to show in your
                    Neuropterists Directory entry; <br/>
                    (4) send the message to the Editor. Digital photos and other supplementary files may be sent to the
                    editor as e-mail attachments (5 Mb maximum).
                    Only individuals, not businesses, will be added to the directory.
                    Individuals requesting addition to the directory should have an interest in insects in the orders
                    Neuroptera, Megaloptera, Raphidioptera, and/or Glosselytrodea,
                    and may receive e-mail and other materials (e.g., newsletters, flyers) directed to this community of
                    entomologists.
                </p>
                <br/>
                <p style={{textAlign: "left", marginTop: "20px",}}>
                    <a style={{color: "white", width: "380px",}}
                       href="mailto:j-oswald@tamu.edu?subject=Add/Update a Neuropterists Directory Entry"
                       className="btn btn-info" target="_top">Email Editor to Add/Update a Neuropterists Directory
                        Entry</a>&nbsp;&nbsp;&nbsp;
                </p>
            </div>
        </div>

         </>
};
export default AddUpdate;