import React, {useEffect, useRef, useState} from "react";
import {Link, Outlet, useLocation, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../../Shared/_Layout";
import nameTranslator from "../../../utils/nameTranslator";
import LDLallowedModules from "../../../utils/OnServerStartLoadedJsons/LDLallowedModules.json"
import "../../../Content/Faunas/Faunas.css"
import {BottomFooterName, Icon, IconLink} from "../../../utils/CustomRecord";
import FaunaCountry from "../../../utils/OnServerStartLoadedJsons/FaunaCountry.json";
import {tblLdlModule} from "../../../../../database/models/tblLdlModule";

const _KeysLayout = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);
   const location = useLocation();
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);

    console.log(navDat)
    return <>



        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}><main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">

                <Outlet context={[[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],<></>,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]]}></Outlet>
            </main></td>
        </tr>
        </tbody>

        <tfoot ref={footerRef} id={"mainFooter"}>
        <tr>

            <td colSpan={2}>            <nav>
                <span className="module" style={{color: "#9B6702",}}><BottomFooterName currentPathData={currentPathData}/> </span>
                <a href={"/KeysDichot/Main"} target="_top">About </a>
            </nav>
        </td>

        </tr>
        </tfoot>
    </>
};
export default _KeysLayout;