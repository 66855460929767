/**
 this file was made by Bryce Gredig (the64bitdude) 2024 for the lacewing digital library
 to provide a simple structure to all future pages and includes
 @author Bryce Gredig (the64bitdude) 2024
 @see IconLink
 @see ButtonLink
 @see IconRawLink
 @see Icon
 @see IconButton
 @see ListHeader
 @see ListHolder
 @see LargeListHolder
 @see LargeListTitle
 @see LargeListSubTitle
 @see LargeListHeader
 @see LargeListElement
 @see ListElement
 @see Record
 @see SelectFromArray
 @see RowTable
 @see RowTableElement
 @see TableLegendHolder
 @see TableLegendSection
 @see TableLegendElement
 @see ResultRecordTableHolder
 @see TableHeaderHolder
 @see TableHeaderRow
 @see TableHeaderElement
 @see TableEmptyHeaderElement
 @see TableBody
 @see TableRow
 @see SubTitle
 @see TableCell
 @see EasyTable
 @see EasyTableElement
 @see EasyTableSection
 @see SplitPageHolder
 @see DetailsTable
 @see SearchCriteria
 @see AlowedLICheaker
 @see ParagraphDesc
 @see ParagraphHeader
 @see SafeHTML
 @see RemoveIfLastSame
 @see TableEndLine
 @see LDLPDFViewer
 @see LDLPDFPageViewer
 @see ScanPDFForText
 @see ScanCanvasForText
 @see ScanPDFPageForText
 @see getPageCount
 @see getPagesText
 @see getPagesImages
 @see getPageSizeBytes
 @see IgnoreEmptySimple
 @see IgnoreEmpty
 @see ItalicIEHF
 @see ParaIEHF
 @see PDFSizeBytes
 @see PDFSizeKiloBytes
 @see PDFSizeMegaBytes
 @see FileSizeBytes
 @see FileSizeKiloBytes
 @see FileSizeMegaBytes
 */
import React, {FC, ReactElement, useEffect, useLayoutEffect, useRef, useState} from "react";
import Icons from "./OnServerStartLoadedJsons/Icons.json";
import {Link, useLocation} from "react-router-dom";
import {LDLIcons} from "./OnServerStartLoadedOther/IconsHelp";
import {Property} from 'csstype'
import {SearchProp} from "./CustomSearch";
import LDLallowedModules from "./OnServerStartLoadedJsons/LDLallowedModules.json";
import _ from "lodash";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {Document, Page, pdfjs} from "react-pdf";
import Tesseract from "tesseract.js";
import {tblLdlModule} from "../../../database/models/tblLdlModule";
import {Model, WhereOptions} from "sequelize";
import nameTranslator from "./nameTranslator";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


/**
 @param {Object} _props
 @param {string} _props.href - link
 @param {boolean} _props.newWindow - does it pop out into a new tab
 @param {Object} [_props.newStates] - states to be added to location.state to be transferred to linked component (or nothing if href is not pointing to a route on this site)
 @param {Object} [_props.HistoryLocStates] - states to be added from location.state (normally just plug in location.state) to be transferred to linked component (or nothing if href is not pointing to a route on this site)
 @param {LDLIcons} _props.IconID - The ID of the Icon img that is going to act as the link
 @param {(ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]=} _props.children - any text or component after Icon that is still part of the link
 @returns {JSX.Element} - outputs an Icon that when clicked goes to the specified link with the specified states
 */
export const IconLink = (_props: { href?: string, downloadFileName?: string, newWindow?: boolean, newStates?: any, IconID: LDLIcons, HistoryLocStates?: any, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    const states = (_props.HistoryLocStates) ? {..._props.HistoryLocStates, ...(_props.newStates ? _props.newStates : {})} : (_props.newStates ? _props.newStates : undefined);

    return <>{_props.href && <Link download={_props.downloadFileName} target={_props.newWindow ? "_blank" : undefined}
                                   rel={_props.newWindow ? "noopener noreferrer" : undefined} relative={"path"}
                                   to={_props.newWindow && states ? {pathname: _props.href, search: Object.entries(states).map((val: any[]) => `${val[0]}=${JSON.stringify(val[1])}`).join("&")} : _props.href}
                                   state={states}><img
        src={Icons[_props.IconID].IconLink} className={"standard-icon"}
        title={Icons[_props.IconID].DefaultTooltip}/>{_props.children}</Link>}</>
}
/**
 @param {Object} _props
 @param {string} _props.href - link
 @param {Object} [_props.newStates] - states to be added to location.state to be transferred to linked component (or nothing if href is not pointing to a route on this site)
 @param {Object} [_props.HistoryLocStates] - states to be added from location.state (normally just plug in location.state) to be transferred to linked component (or nothing if href is not pointing to a route on this site)
 @param {(ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]=} _props.children - any text or component inside the button
 @returns {JSX.Element} - outputs an Button that when clicked goes to the specified link with the specified states and the specified inner display component
 */
export const ButtonLink = (_props: { href?: string, newStates?: any, HistoryLocStates?: any, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    return <>{_props.href && <Link relative={"path"} className={"btn btn-info"} to={_props.href}
                                   state={(_props.HistoryLocStates) ? {..._props.HistoryLocStates, ...(_props.newStates ? _props.newStates : {})} : (_props.newStates ? _props.newStates : {})}>{_props.children}</Link>}</>
}
/**
 @param {Object} _props
 @param {string} _props.href - link
 @param {LDLIcons} _props.IconID - The ID of the Icon img that is going to act as the link
 @returns {JSX.Element} - outputs an Icon that when clicked goes to the specified link
 */
export const IconRawLink = (_props: { href?: string, IconID: LDLIcons }) => {

    return <>{_props.href && <a href={_props.href}><img
        src={Icons[_props.IconID].IconLink} className={"standard-icon"}
        title={Icons[_props.IconID].DefaultTooltip}/></a>}</>
}
/**
 @param {Object} _props
 @param {LDLIcons} _props.IconID - The ID of the Icon img that is going to act as the link
 @param {string} [_props.filter] - containerStyle filter for the image if desired
 @param {string} [_props.altText] - alternate hover over text from what is stated on from ID in database
 @returns {JSX.Element} - outputs an Icon
 */
export const Icon = (_props: { IconID?: LDLIcons, filter?: string, altText?: string }) => {

    return <>{_props.IconID && <img style={{filter: _props.filter}}
                                    src={Icons[_props.IconID].IconLink} className={"standard-icon"}
                                    title={_props.altText ? _props.altText : Icons[_props.IconID].DefaultTooltip}/>}</>
}
/**
 @param {Object} _props
 @param {LDLIcons} _props.IconID - The ID of the Icon img that is going to act as the button
 @param {(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void} _props.onClick - event for when the button is clicked
 @returns {JSX.Element} - outputs an Icon that when clicked calls the onClick function
 */
export const IconButton = (_props: { IconID: LDLIcons, onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void ,type?:string}) => {

    return <a type={_props.type} onClick={_props.onClick}><img
        src={Icons[_props.IconID].IconLink} className={"standard-icon"}
        title={Icons[_props.IconID].DefaultTooltip}/></a>
}
/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} _props.title - Title of list in record
 @param {LDLIcons} [_props.width] - width of container
 @returns {JSX.Element} - outputs a list with a title (made for record pages)
 */
export const ListHeader = (_props: { title: JSX.Element | string | number | undefined, width?: string, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    return <div className={"separate-element text-indent-table"} style={{textAlign: "left", width: _props.width}}>
        <i>{_props.title}</i>
        <table style={{marginLeft: "0px", marginRight: "0px", width: "100%"}}>
            <tbody style={{lineHeight: "1.4em"}}>
            {Array.isArray(_props.children) ? _props.children?.map((child) => {
                return <tr>
                    <td valign="top">
                        {child}
                    </td>
                </tr>
            }) : <tr>
                <td valign="top">
                    {_props.children}
                </td>
            </tr>}
            </tbody>
        </table>
    </div>;
}
/**
 @param {Object} _props
 @param {LDLIcons} [_props.width] - width of container
 @returns {JSX.Element} - outputs a list (made for record pages)
 */
export const ListHolder = (_props: { children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[], width?: string }) => {
    return <div className={"separate-element text-indent-table"} style={{textAlign: "left", width: _props.width}}>
        <table style={{marginLeft: "0px", marginRight: "0px", width: "100%"}}>
            <tbody style={{lineHeight: "1.4em"}}>
            {Array.isArray(_props.children) ? _props.children?.map((child) => {
                return <tr>
                    <td valign="top" style={{paddingLeft: "1em"}}>
                        {child}
                    </td>
                </tr>
            }) : <tr>
                <td valign="top" style={{paddingLeft: "1em"}}>
                    {_props.children}
                </td>
            </tr>}
            </tbody>
        </table>
    </div>;
}
/**
 @param {Object} _props
 @param {LDLIcons} [_props.width] - width of container
 @returns {JSX.Element} - outputs a larger list (made for Directory record pages)
 */
export const LargeListHolder = (_props: { children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[], width?: string }) => {
    return <div className={"separate-element text-indent-table"} style={{textAlign: "left", width: _props.width}}>
        <table style={{marginLeft: "0px", marginRight: "0px", width: "100%"}}>
            <tbody style={{lineHeight: "1.4em"}}>
            {_props.children && Array.of(_props.children).flat().map((child) => {
                return <>
                    <tr>
                        <>
                            {child}
                        </>
                    </tr>
                </>
            })}
            </tbody>
        </table>
    </div>;
}
/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} _props.title - title of large list
 @returns {JSX.Element} - outputs a title for inside a large list (made for Directory record pages)
 */
export const LargeListTitle = (_props: { title: JSX.Element | string | number | undefined }) => {
    return <td className={"style5"}>{_props.title}</td>
}
/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} _props.title - subtitle of large list
 @returns {JSX.Element} - outputs a subtitle for inside a large list (made for Directory record pages)
 */
export const LargeListSubTitle = (_props: { title: JSX.Element | string | number | undefined }) => {
    return <td className={"nickname"}><span className={"small"}>{_props.title}</span></td>
}
/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} _props.title - title of large list
 @param {boolean=} _props.noPaddingTop - toggle for top padding
 @returns {JSX.Element} - outputs a large list with a title (different type than LargeList)  (made for Directory record pages)
 */
export const LargeListHeader = (_props: { title: JSX.Element | string | number | undefined, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[], noPaddingTop?: boolean }) => {
    return <>{_props.children ? <td>
        <div id="about">
            <div id="abouttitle" style={{paddingTop: _props.noPaddingTop ? "0px" : undefined}}>{_props.title}:</div>
            <div id="aboutdata">
                {_props.children && Array.of(_props.children).flat().map((child) => {
                    return <p className="data" style={{marginTop: _props.noPaddingTop ? "5px" : undefined}}>{child}</p>
                })}
            </div>
        </div>
    </td> : <td className={"style6"}>{_props.title}</td>}</>
}
/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} _props.title - large list element name
 @param {JSX.Element | string | number | undefined} _props.element - large list element data/desc
 @returns {JSX.Element} - outputs a large element with a title  (made for Directory record pages)
 */
export const LargeListElement = (_props: { title: JSX.Element | string | number | undefined, element: JSX.Element | string | number | undefined }) => {
    return <><span className="style3">{_props.title}:</span> {_props.element}</>
}
/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} _props.title - list element name
 @param {JSX.Element | string | number | undefined} _props.element - list element data/desc
 @returns {JSX.Element} - outputs a list (made for record pages)
 */
export const ListElement = (_props: { title: JSX.Element | string | number | undefined, element: JSX.Element | string | number | undefined }) => {
    return <>{(_props.element != null && String(_props.element) != "" && String(_props.element) != "(none)") && <>
        <i>{_props.title}</i>:&nbsp; {_props.element}</>}</>
}


/**
 @param {Object} _props
 @param {JSX.Element | string | number | undefined} [_props.title] - Record Page Title
 @param {JSX.Element | string | number | undefined} [_props.subTitle] - Record Page SubTitle
 @param {boolean} [_props.margins] - toggle for extra margins (default = false)
 @returns {JSX.Element} - Is the main format container for almost all pages outputs title subtitle and if it has extra margins accordingly
 */
export const Record = (_props: {
    title?: JSX.Element | string | number | undefined,
    margins?: boolean,
    paddingTop?:Property.PaddingTop,
    alignHeader?: Property.TextAlign,
    subTitle?: JSX.Element | string | number | undefined,
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    return <div className={"content"} style={_props.margins ? {marginLeft: "5%", marginRight: "5%"}:{}}>
        <div className={"text-body"} style={{paddingTop:_props.paddingTop}}>
            {_props.title &&
                <h1 style={{textAlign: _props.alignHeader ? _props.alignHeader : "center"}}>{_props.title}</h1>}
            {_props.subTitle &&
                <div style={{textAlign: _props.alignHeader ? _props.alignHeader : "center"}}>{_props.subTitle}</div>}
            {Array.isArray(_props.children) ? _props.children?.map((child) => {
                return child;
            }) : _props.children}
        </div>
    </div>
}
/**
 @param {Object} _props
 @param {number} _props.defaultSelectedIndex - array index to be defaulted to
 @param {JSX.Element | string | number | undefined} [_props.defaultOption] - extra option not part of the array to whichs value is -1
 @param {any[]} _props.arrayInput - array to select from option value is always index from this array and the children of that option if defined by optionRender
 @param {(val: any, index: number) => JSX.Element} _props.selectRender - function to render what is below the select based on its index and value
 @param {(val: any, index: number) => JSX.Element} _props.optionRender - function for what to render in the select box based on its index and value
 @param {(val: any, index: number) => JSX.Element} [_props.selectRenderAbove] - function to render what is above the select based on its index and value
 @param {string} [_props.width] - the width of the select
 @returns {JSX.Element} - Is the main format container for almost all pages outputs title subtitle and if it has extra margins accordingly
 */
export const SelectFromArray = (_props: {
    defaultSelectedIndex: number,
    defaultOption?: JSX.Element | string | number | undefined,
    arrayInput: any[],
    displaySelect?: boolean,
    selectRender: (val: any, index: number) => JSX.Element,
    optionRender: (val: any, index: number) => JSX.Element,
    selectRenderAbove?: (val: any, index: number) => JSX.Element,
    width?: string
}) => {
    const [selectedIndex, setSelectedIndex] = useState(_props.defaultSelectedIndex);
    return <div style={{textAlign: "left"}}>
        <div className={"separate-element text-indent-table"} style={{display:"inherit"}}>
            {_props.selectRenderAbove && _props.selectRenderAbove(selectedIndex != -1 ? _props.arrayInput[selectedIndex] : null, selectedIndex)}
            {(_props.displaySelect || _props.displaySelect == undefined) &&
                <select className={"short-select-box"}
                        style={{marginBottom: '2px', width: (_props.width ? _props.width : "fit-content"), paddingRight: "30px", border: "1px solid darkgreen"}}
                        value={selectedIndex}
                        onChange={(e) => setSelectedIndex(Number(e.target.value))}>
                    {_props.defaultOption && <option value={-1}>{_props.defaultOption}</option>}
                    {_props.arrayInput.map((val, index) => {
                        return <option value={index}>{_props.optionRender(val, index)}</option>
                    })}
                </select>}
            {_props.selectRender && _props.selectRender(selectedIndex != -1 ? _props.arrayInput[selectedIndex] : null, selectedIndex)}
        </div>
    </div>
}
/**
 @param {Object} _props
 @param {Object} _props.selectOptions - set of selects that will be used together
 @param {number} _props.selectOptions.defaultSelectedIndex - array index to be defaulted to
 @param {JSX.Element | string | number | undefined} [_props.selectOptions.defaultOption] - extra option not part of the array to whichs value is -1
 @param {any[]} _props.selectOptions.arrayInput - array to select from option value is always index from this array and the children of that option if defined by optionRender
 @param {(valIndexPairs:{val:any,index:number}[]) => JSX.Element} _props.selectRender - function to render what is below the select based on its index and value
 @param {(val: any, index: number, selectIndex:number) => JSX.Element} _props.optionRender - function for what to render in the select box based on its index and value
 @param {(valIndexPairs:{val:any,index:number}[]) => JSX.Element} [_props.selectRenderAbove] - function to render what is above the select based on its index and value
 @param {string} [_props.width] - the width of the select
 @returns {JSX.Element} - Is the main format container for almost all pages outputs title subtitle and if it has extra margins accordingly
 */
export const SelectFromArrays = (_props: {
    selectOptions: {
        defaultSelectedIndex: number,
        defaultOption?: JSX.Element | string | number | undefined,
        arrayInput: any[]
    }[],
    selectRender: (valIndexPairs: { val: any, index: number }[]) => JSX.Element,
    optionRender: (val: any, index: number, selectIndex: number) => JSX.Element,
    selectRenderAbove?: (valIndexPairs: { val: any, index: number }[]) => JSX.Element,
    width?: string;
    containerWidth?:Property.Width;
}) => {
    const [selectedIndexes, setSelectedIndex] = useState(_props.selectOptions.map((select) => select.defaultSelectedIndex));
    return <div style={{textAlign: "left"}}>
        <div className={"separate-element text-indent-table"} style={{width:_props.containerWidth}} >
            {_props.selectRenderAbove && _props.selectRenderAbove(selectedIndexes.map((selectedIndex, selectIndex) => ({val: (selectedIndex != -1 ? _props.selectOptions[selectIndex].arrayInput[selectedIndex] : null), index: selectedIndex})))}
            {_props.selectOptions.map((selectData, selectIndex) =>
                <><select className={"short-select-box"}
                          style={{marginBottom: '2px', width: (_props.width ? _props.width : "fit-content"), paddingRight: "30px", border: "1px solid darkgreen"}}
                          value={selectedIndexes[selectIndex]}
                          onChange={(e) => setSelectedIndex((prev) => {
                              if (prev) {
                                  let temp = [...prev];
                                  temp[selectIndex] = Number(e.target.value)
                                  return temp;
                              }
                              return prev
                          })}>
                    {selectData.defaultOption && <option value={-1}>{selectData.defaultOption}</option>}
                    {selectData.arrayInput.map((val, index) => {
                        return <option value={index}>{_props.optionRender(val, index, selectIndex)}</option>
                    })}
                </select>{(selectIndex != _props.selectOptions.length - 1) && <>&nbsp;&nbsp;</>}</>)}
            {_props.selectRender && _props.selectRender(selectedIndexes.map((selectedIndex, selectIndex) => ({val: (selectedIndex != -1 ? _props.selectOptions[selectIndex].arrayInput[selectedIndex] : null), index: selectedIndex})))}
        </div>
    </div>
}


export const RowTable = (_props: { children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[], Legend?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    return <div className={"table-holder"}>
        <table className={"details-table main-table-body"}>
            {(_props.children && Array.isArray(_props.children)) ? (_props.children.map((child, index) => {


                if (index == 0) {
                    if (child && typeof child == 'object'&& child.props satisfies RowTableElementType) {
                        child =
                            <RowTableElement title={child.props.title} element={child.props.element} borderTop={true}/>
                    }
                    return <tr>
                        {child}
                    </tr>;
                } else if (Array.isArray(_props.children) && index == _props.children.length - 1) {
                    if (child && typeof child =='object'&& child.props satisfies RowTableElementType) {
                        child = <RowTableElement title={child.props.title} element={child.props.element}
                                                 borderBottom={true}/>
                    }
                    return <tr>{child}</tr>;
                } else {
                    return <tr>{child}</tr>;
                }
            })) : <tr>{_props.children}</tr>}
        </table>
        {_props.Legend}
    </div>

}


export interface RowTableElementType {
    title: JSX.Element | string | number | undefined,
    element: JSX.Element | string | number | undefined,
    borderTop?: boolean,
    borderBottom?: boolean
}

export const RowTableElement: FC<RowTableElementType> = (_props: {
    title: JSX.Element | string | number | undefined,
    element: JSX.Element | string | number | undefined,
    borderTop?: boolean,
    borderBottom?: boolean
}) => {
    return <>
        <td style={{
            width: "155px",
            textIndent: "0px",
            paddingLeft: "5px",
            paddingRight: "15px",
            borderTop: _props.borderTop ? "2px solid #000000" : "1px",
            borderBottom: _props.borderBottom ? "2px solid #000000" : "1px solid #000000",
            paddingTop: "4px",
            paddingBottom: "4px",
            backgroundColor: "#DDDDDD",
            textAlign: "right"
        }}><b>{_props.title}</b></td>
        <td style={{
            textIndent: "0px",
            paddingLeft: "5px",
            paddingRight: "15px",
            borderTop: _props.borderTop ? "2px solid #000000" : "1px",
            borderBottom: _props.borderBottom ? "2px solid #000000" : "1px solid #000000",
            paddingTop: "4px",
            paddingBottom: "4px",
            textAlign: "left"
        }}>{_props.element}</td>
    </>

}

export const TableLegendHolder = (_props: { leftItems?: JSX.Element | string | number | undefined, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[], colspan?: number }) => {
    const [showLeg, setShowLeg] = useState(false);

    return <table className="details-table headerAndFooter">
        <tbody>
        <tr>
            <td align={"left"} colSpan={_props.colspan}
                style={{paddingLeft: "0", textIndent: "0"}}>
                <p style={{marginTop: "0px"}}>
                    <><a className="table-text-toggle" onClick={(e) => setShowLeg((prev) => !prev)}><span
                        className="button"><img className="picts"
                                                src={showLeg ? Icons[LDLIcons.General_HideLegend].IconLink : Icons[LDLIcons.General_ShowLegend].IconLink}
                                                title={showLeg ? Icons[LDLIcons.General_HideLegend].DefaultTooltip : Icons[LDLIcons.General_ShowLegend].DefaultTooltip}/></span></a>{_props.leftItems}</>
                    <span className={"table-footer-text" + (showLeg ? "" : " hide-text")}>
                                               {_props.children}
                                </span>
                </p>
            </td>
        </tr>
        </tbody>
    </table>
}
const findChildren = (child: any) => (!!(child?.props?.LegendIdentifier) || !!(child?.props?.children?.find(findChildren)))

const flatWithChildren = (val: (string | number | false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined)) => {

    if (typeof val == 'object' && val.props?.children && !(val.props?.LegendIdentifier)) {
        //console.log(val)
        if (Array.isArray(val.props.children)) {
            if (val.props.children.includes(findChildren)) {
                return val.props.children.flatMap(flatWithChildren);
            }
        } else if (val.props.children.props?.LegendIdentifier) {
            return val.props.children;
        }
    }
    return val
}
export const TableLegendSection = (_props: {
    noUnderLine?: boolean;
    title: JSX.Element | string | number | undefined,
    children?: (ReactElement | false | undefined | string | number) | (ReactElement | false | undefined | string | number)[]
}) => {

    return <> <strong> {_props.noUnderLine ? <>{_props.title}</> :
        <u>{_props.title}</u>}</strong>: {Array.of(_props.children).flat().flatMap(flatWithChildren).filter((val) => !!val).sort((val1, val2) => {
        if (typeof val1 == 'object' && typeof val2 == 'object') {
            if (typeof val1.props.title == 'string') {
                if (typeof val2.props.title == 'string') {
                    return val1.props.title.localeCompare(val2.props.title)
                }
            }
        }
    }).map((child, index, a) => <>{child && <>{child}{index == a.length - 1 ? "." : ";"}</>}</>)}</>;
}
export const TableLegendElement = (_props: { title: JSX.Element | string | number | undefined, element: JSX.Element | string | number | undefined, LegendIdentifier?: string }) => {
    return <><strong><em> {_props.title}</em></strong> &ndash; {_props.element}</>
}
TableLegendElement.defaultProps = {LegendIdentifier: "LegendElement"}


export const ResultRecordTableHolder = React.forwardRef((_props: {
    maxHeight?: string,
    maxWidth?: string,
    noBorder?: boolean,
    float?: Property.Float,
    width?: string;
    paddingRight?: string;
    colSpan?: number,
    extraFooterData?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[],
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[],
    onCSVURLCreate?: (url: string) => void
}, ref?: React.Ref<HTMLDivElement>) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const [children,setChildren]=useState(_props.children);
    useEffect(() => {
        if(!([children].flat().length==[_props.children].flat().length)){
            //console.log(children,_props.children,[children].flat(),[_props.children].flat())
            setChildren(_props.children)
        }else if ([children].flat().length!=0){
            //console.log(children,_props.children)
            if(children&&Array.isArray(children)&&typeof children[1] =="object"&&children[1]?.props?.tableRowData){
                if(_props.children&&Array.isArray(_props.children)&&typeof _props.children[1] =="object"&&_props.children[1]?.props?.tableRowData){
                    if(children[1]?.props?.tableRowData !==_props.children[1]?.props?.tableRowData){
                        setChildren(_props.children);
                    }
                }
            }
        }

    }, [_props.children]);
    useEffect(() => {

        if (_props.onCSVURLCreate) {
            // @ts-ignore
            let data: any = Array.from(tableRef.current?.children).slice(0, 2).map((child: HTMLTableSectionElement) => Array.from(child.children).map((child) => Array.from(child.children).map((child) => `"${String(child?.innerText)}"`)));
            data[1] = data[1].map((val: any[]) => val.join(',')).join('\n');
            data = data.join('\n')
            data = "\uFEFF" + data
            const blob = new Blob([data], {type: 'text/csv'});
            const url = URL.createObjectURL(blob);
            _props.onCSVURLCreate(url)
        }
    }, [tableRef,children]);
    return <div ref={ref}
                style={{maxHeight: _props.maxHeight, borderTop: _props.noBorder ? "1px" : "2px solid black", float: _props.float, width: _props.width, maxWidth: _props.maxWidth, paddingRight: _props.paddingRight, borderBottom: _props.noBorder ? "1px" : "2px solid black"}}
                className={"limited-height-table-body-second"}>
        <table ref={tableRef} className="details-table main-table-body">
            {_props.children}
            <tfoot>
            {_props.extraFooterData}
            {/*<tr>
            <td containerStyle={{borderTop:_props.noBorder?"1px":"2px solid black",borderBottom:_props.noBorder?"1px":"2px solid white",padding:"0px"}} colSpan={_props.colSpan}/>
            </tr>*/}
            </tfoot>
        </table>
    </div>
})

export const TableHeaderHolder = (_props: {
    onHeaderElement?: ((val: HTMLTableSectionElement) => void)
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    const headRef = useRef<HTMLTableSectionElement>(null)
    useEffect(() => {
        if (_props.onHeaderElement && headRef.current) {
            _props.onHeaderElement(headRef.current)
        }
    }, [headRef]);
    return <thead ref={_props.onHeaderElement ? headRef : undefined} style={{position: "sticky", top: "0"}}>
    {_props.children}
    </thead>
}
export const TableHeaderRow = (_props: {
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    return <tr>{_props.children}</tr>;
}

export const TableHeaderElement = (_props: { title?: JSX.Element | string | number | undefined, colSpan?: number, rowSpan?: number, width?: string, maxHeight?: string, borderBottom?: boolean, align?: Property.TextAlign, paddingLeft?: string, paddingRight?: string, noBorderTop?: boolean, smallBorderTop?: boolean, whiteSpace?: Property.WhiteSpace }) => {
    return <th colSpan={_props.colSpan} rowSpan={_props.rowSpan}
               style={{maxHeight: _props.maxHeight, whiteSpace: _props.whiteSpace, textAlign: (_props.align ? _props.align : "left"), width: (_props.width ? _props.width : "2%"), paddingLeft: (_props.paddingLeft ? _props.paddingLeft : "5px"), paddingRight: _props.paddingRight, borderBottom: (_props.borderBottom ? "1px solid #000000" : "1px"), paddingTop: "4px", paddingBottom: "4px", borderTop: (_props.smallBorderTop ? "1px solid black" : (_props.noBorderTop ? "1px" : "2px solid black")), backgroundColor: "#DDDDDD"}}>
        {_props.title}
    </th>;
}
export const TableEmptyHeaderElement = (_props: { top?: boolean, rowSpan?: number }) => {
    return <th rowSpan={_props.rowSpan}
               style={{borderBottom: "1px solid #000000", width: "0%", maxWidth: "10px", minWidth: "10px", borderTop: _props.top ? "2px solid black" : "1px", backgroundColor: "#DDDDDD"}}/>;
}
export const TableBody = (_props: { tableRowData: any[], rowRender: (val: any, index: number, array: any[], fixed?: boolean, selected?: boolean, currectIndex?: number, setCurrentIndex?: React.Dispatch<React.SetStateAction<number>>) => JSX.Element, defaultSelectIndex?: number }) => {
    const [currentIndex, setCurrentIndex] = useState(_props.defaultSelectIndex != undefined ? _props.defaultSelectIndex : -1)
    return <tbody>
    {_props.tableRowData.map((val, i, a) => _props.rowRender(val, i, a, false, currentIndex == i, currentIndex, setCurrentIndex))}
    </tbody>

}

export const TableRow = (_props: {
    fixed?: boolean,
    selected?: boolean,
    headerOffset?: number,
    autoFocus?: boolean,
    onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    const trRef = useRef<HTMLTableRowElement>(null);
    useEffect(() => {
        if (_props.autoFocus && trRef.current) {
            //@ts-ignore
            trRef.current.scrollIntoView({block: "nearest", behavior: "auto"})


        }
    }, [trRef, _props.headerOffset]);
    return <tr ref={_props.autoFocus ? trRef : undefined} onClick={_props.onClick}
               style={{textAlign: "left", scrollMarginTop: _props.headerOffset, verticalAlign: "top", position: _props.fixed ? "sticky" : "initial", bottom: _props.fixed ? "0px" : "initial", backgroundColor: _props.fixed ? "white" : undefined}}
               className={`without-border${_props.selected ? " highlightRow" : ""}`}>
        {_props.children}
    </tr>
}


export const SubTitle = (_props: { align?: Property.TextAlign, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    return <div style={{textAlign: _props.align ? _props.align : "center"}}>{_props.children}</div>
}

export const TableCell = (_props: {
    colSpan?: number,
    width?: string,
    minWidth?: string,
    align?: Property.TextAlign,
    valign?: Property.VerticalAlign,
    noBorderTop?: boolean,
    extraBorderTop?: boolean,
    whiteSpace?: Property.WhiteSpace,
    borderBottom?: boolean,
    textIndent?: Property.TextIndent,
    noPadding?: boolean,
    paddingLeft?: string,
    paddingRight?: string,
    paddingBottom?: string,
    paddingTop?: string,
    element?: JSX.Element | string | number | undefined | (JSX.Element | string | number | undefined)[],
}) => {
    return <td colSpan={_props.colSpan}
               style={{width: _props.width, whiteSpace: _props.whiteSpace, textIndent: _props.textIndent ? _props.textIndent : "-0.5em", minWidth: _props.minWidth, paddingLeft: (_props.paddingLeft ? _props.paddingLeft : (_props.noPadding ? "0px" : `calc(calc( -1 * ${_props.textIndent ? _props.textIndent : "-0.5em"}) + 5px)`)), paddingRight: (_props.paddingRight ? _props.paddingRight : _props.noPadding ? "0px" : "5px"), paddingTop: (_props.paddingTop ? _props.paddingTop : _props.noPadding ? "0px" : "2px"), paddingBottom: (_props.paddingBottom ? _props.paddingBottom : _props.noPadding ? "0px" : "2px"), textAlign: (_props.align ? (_props.align) : "left"), verticalAlign: _props.valign, borderTop: _props.noBorderTop ? "1px" : (_props.extraBorderTop ? "2px solid black" : "1px solid #000000"), borderBottom: (_props.borderBottom ? "2px solid #000000" : "1px")}}>{_props.element}</td>
}

const EasyHandleHeaderChildren = (val: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>, index: number, RowOutputs: JSX.Element[][], RowIndex: number, height: number, _props: { width?: string; align?: Property.TextAlign; borderBottom?: boolean; maxHeight?: string; children?: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>> | React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>[]; tableRowData: any[] | undefined; extraLegendChildren?: JSX.Element | string | number | undefined; extraHeaders?: JSX.Element | string | number | undefined }): JSX.Element => {

    if (!val.props) {
        return <></>
    }
    let size = 0;
    let extraSpace = undefined;
    RowOutputs.forEach((val) => {
        val.forEach((val) => {
            if (val.props.colSpan == undefined) {
                size--
            }

        })
    })
    if (val.props.children) {
        extraSpace = <TableEmptyHeaderElement top={false} rowSpan={height}/>
        if (RowOutputs.length - 1 <= RowIndex + 1) {
            RowOutputs.push([]);
        }
        const offset = val.props.rowSpan ? val.props.rowSpan : 1;
        RowOutputs[RowIndex + offset] = [...RowOutputs[RowIndex + offset], ...Array.of(val.props.children).flat().map((element2, elementIndex2) => EasyHandleHeaderChildren(element2, elementIndex2, RowOutputs, RowIndex + offset, height, _props))];
    }
    RowOutputs.forEach((val) => {
        val.forEach((val) => {
            if (val.props.colSpan == undefined) {
                size++
            }

        })
    })

    return <><TableHeaderElement
        whiteSpace={val.props.whiteSpace}
        noBorderTop={true} borderBottom={true}
        paddingLeft={val.props.paddingLeft}
        paddingRight={val.props.paddingRight}
        rowSpan={!val.props.children && !val.props.rowSpan ? ((height - RowIndex) == 1 ? undefined : (height - RowIndex)) : val.props.rowSpan}
        colSpan={val.props.children ? (Array.isArray(val.props.children) ? (size) : 1) : val.props.colSpan}
        title={val.props.title} width={val.props.width}
        align={val.props.halign ? val.props.halign : (val.props.align ? val.props.align : (_props.align ? _props.align : "left"))}/>{extraSpace}</>

}

const EasyFlatChildren = (val: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>, index: number): ReactElement<EasyTableElementProps, FC<EasyTableElementProps>> | ReactElement<EasyTableElementProps, FC<EasyTableElementProps>>[] => {
    if (!val.props) {
        return [];
    }
    if (val.props.children) {
        return [...Array.of(val.props.children).flat().map(EasyFlatChildren).flat(), ...[<EasyTableElement
            title={val.props.title} desc={val.props.desc}/>]];
    }
    return val;
}
const EasyGetChildrenHeight = (val: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>): number => {
    if (!val.props) {
        return 0;
    }
    let myNum = val.props.rowSpan ? val.props.rowSpan : 1;
    if (val.props.children) {
        return myNum + Math.max(...Array.of(val.props.children).flat().map(EasyGetChildrenHeight));
    }
    return myNum;
}


export const EasyTable = React.forwardRef((_props: { width?: string,noHeaders?:boolean, noBorder?: boolean,downloadable?:boolean,downloadName?:string, LegendleftItems?: JSX.Element | string | undefined | number, paddingTop?: Property.PaddingTop, defaultSelectedIndex?: number, onEmptyText?: JSX.Element | string | number | undefined, maxWidth?: string, caption?: JSX.Element | string | number | undefined, paddingBottom?: Property.PaddingBottom, onCSVURLCreate?: (url: string) => void, currentBodyHeight?: number, currentWindow?: { height: number, width: number }, autoBottomMaxHeight?: boolean, float?: Property.Float, align?: Property.TextAlign, maxHeight?: string, children?: ReactElement<EasyTableElementProps, FC<EasyTableElementProps>> | ReactElement<EasyTableElementProps, FC<EasyTableElementProps>>[], tableRowData: any[] | undefined, fixedBottomRowData?: any, extraLegendChildren?: JSX.Element | string | number | undefined, extraSymbolsChildren?: JSX.Element | string | number | undefined | (JSX.Element | string | number | undefined)[], extraTopLegendChildren?: JSX.Element | string | number | undefined, extraHeaders?: JSX.Element | string | number | undefined }, ref?: React.Ref<HTMLDivElement>) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const children = _props.children ? Array.of(_props.children).flat() : undefined;
    const flatChildren = children?.map(EasyFlatChildren).flat()
    const childrenDesc = (flatChildren?.filter((val) => ((!!val.props?.desc) && (!val.props.isSymbol))));
    const childrenSymbolDesc = (flatChildren?.filter((val) => ((!!val.props?.desc) && (!!val.props.isSymbol))));
    let height: number = children ? Math.max(...children.map(EasyGetChildrenHeight)) : 1;
    let RowData: JSX.Element[][] = [[]]
    const [downloadURL,setDownloadURL]=useState("")
    const onCSVURLCreate = (url:string)=>{
        if(_props.onCSVURLCreate) {
            _props.onCSVURLCreate(url)
        }
        setDownloadURL(url);
    }
    const rowRender = (val: any, index: number, array: any[], fixed?: boolean, selected?: boolean, currentIndex?: number, setCurrentIndex?: React.Dispatch<React.SetStateAction<number>>) => {
        let lastLineStatus = false;
        return <TableRow headerOffset={headerHeight} autoFocus={index == _props.defaultSelectedIndex}
                         selected={selected} onClick={(e) => {
            if (setCurrentIndex) {
                setCurrentIndex(index)
            }
        }} fixed={!!fixed}>
            {flatChildren && flatChildren.map((element: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>, elementIndex: number): JSX.Element => {

                let elementVal = element.props.elementFromValue ? element.props.elementFromValue(val, index, array) : undefined;
                let elementDis = elementVal;
                if (Array.isArray(elementVal) && elementVal.length == 2) {
                    elementDis = elementVal[1];
                    elementVal = elementVal[0];
                }
                let lineStatus = ((elementVal == undefined) ? ((!!element.props.removeBottomLineIfEmpty) && !(element.props.ignoreIfLastLine && !lastLineStatus)) : (false))
                lastLineStatus = lineStatus;
                return <TableCell noBorderTop={(lineStatus || index == 0) || element.props.noBorderTop}
                                  align={element.props.align ? element.props.align : _props.align}
                                  valign={element.props.valign}
                                  textIndent={element.props.textIndent}
                                  extraBorderTop={!!fixed}
                                  whiteSpace={element.props.whiteSpace}
                                  noPadding={element.props.noPadding}
                                  paddingBottom={element.props.paddingBottom}
                                  paddingRight={element.props.paddingRight}
                                  paddingTop={element.props.paddingTop}
                                  paddingLeft={element.props.paddingLeft}
                                  width={element.props.width} minWidth={element.props.minWidth}
                                  element={elementDis}/>
            })}

        </TableRow>
    }
    const tableRef = React.createRef<HTMLDivElement>();
    const [heightChange, setHeightChange] = useState(0);

    useLayoutEffect(() => {
        if (tableRef && tableRef.current) {
            setHeightChange(((tableRef.current?.offsetTop) ? (tableRef.current.offsetTop) : (0)))
        }
    }, [tableRef.current, _props.currentBodyHeight, _props.currentWindow,_props.tableRowData]);
    const onHeaderElementLoad = (val: HTMLTableSectionElement) => {
        setHeaderHeight(val.clientHeight)
    }
    return <div className={"table-holder"}
                style={{width: _props.width, paddingTop: _props.paddingTop, maxWidth: _props.maxWidth, float: _props.float}}>
        {_props.caption ? <text className={"caption"}>{_props.caption}</text> : undefined}
        {_props.tableRowData && _props.tableRowData.length ? <>
            <ResultRecordTableHolder noBorder={_props.noBorder} onCSVURLCreate={onCSVURLCreate}
                                     ref={_props.autoBottomMaxHeight ? tableRef : ref}
                                     extraFooterData={_props.fixedBottomRowData ? rowRender(_props.fixedBottomRowData, _props.tableRowData.length, [_props.fixedBottomRowData], true) : undefined}
                                     maxHeight={_props.maxHeight ? _props.maxHeight : _props.autoBottomMaxHeight ? `calc(${_props.currentBodyHeight ? `${_props.currentBodyHeight}px` : "100vh"} - ${heightChange + 35}px${_props.paddingBottom ? (' - ' + _props.paddingBottom) : ""})` : undefined}
                                     colSpan={flatChildren?.length} maxWidth={_props.maxWidth}
                                     width={(/\d+\%/g).test(String(_props.width)) ? "100%" : _props.width}>
                {!_props.noHeaders&&<TableHeaderHolder onHeaderElement={onHeaderElementLoad}>
                    <>{_props.extraHeaders}</>
                    <TableHeaderRow>{children && children.map((val, index) => EasyHandleHeaderChildren(val, index, RowData, 0, height, _props))}</TableHeaderRow>
                    <>{RowData.map((val, index) => {
                        if (index != 0) {
                            return <TableHeaderRow>{val}</TableHeaderRow>
                        }
                    })}</>
                </TableHeaderHolder>}
                <TableBody defaultSelectIndex={_props.defaultSelectedIndex} tableRowData={_props.tableRowData}
                           rowRender={rowRender}/>
            </ResultRecordTableHolder>
            {<>{(!!(childrenDesc && !!childrenDesc?.length) || !!(childrenSymbolDesc && !!childrenSymbolDesc.length) || _props.extraLegendChildren || _props.extraSymbolsChildren || _props.extraTopLegendChildren) ?
                <TableLegendHolder leftItems={<>{[...[<>{_props.downloadable&&<IconLink newWindow={true} downloadFileName={_props.downloadName?_props.downloadName:"LDLDownload.csv"} href={downloadURL} IconID={LDLIcons.General_Download_Excelfile}/>}</>],...[_props.LegendleftItems].flat()]}</>} colspan={children?.length}>
                    <>{_props.extraTopLegendChildren}</>
                    {(!!_props.extraSymbolsChildren || !!(childrenSymbolDesc && !!childrenSymbolDesc.length)) &&
                        <TableLegendSection title={"Symbols"}>
                            <>{_props.extraSymbolsChildren}
                                {childrenSymbolDesc && !!(childrenSymbolDesc.length) && childrenSymbolDesc.map((val: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>, index: number): JSX.Element | undefined => {
                                    return (val.props.desc ? <TableLegendElement
                                        title={val.props.legendTitle ? val.props.legendTitle : val.props.title}
                                        element={val.props.desc ? val.props.desc : ""}/> : undefined)
                                })}</>
                        </TableLegendSection>}
                    {childrenDesc && !!(childrenDesc.length) && <TableLegendSection title={"Columns"}>

                        {(!!childrenDesc.length) && childrenDesc.map((val: React.ReactElement<EasyTableElementProps, React.FC<EasyTableElementProps>>, index: number): JSX.Element | undefined => {
                            return (val.props.desc ? <TableLegendElement
                                title={val.props.legendTitle ? val.props.legendTitle : val.props.title}
                                element={val.props.desc ? val.props.desc : ""}/> : undefined)
                        })}

                    </TableLegendSection>}
                    <>{_props.extraLegendChildren}</>
                </TableLegendHolder>:_props.LegendleftItems}</>}</> : _props.onEmptyText}
    </div>
})

export interface EasyTableElementProps {
    title?: JSX.Element | string | number | undefined;
    legendTitle?: JSX.Element | string | number | undefined;
    width?: string,
    halign?: Property.TextAlign,
    align?: Property.TextAlign,
    valign?: Property.VerticalAlign,
    minWidth?: string;
    elementFromValue?: (val: any, index: number, array: any[]) => JSX.Element | string | number | undefined | (JSX.Element | string | number | undefined)[];
    desc?: JSX.Element | string | number | undefined;
    removeBottomLineIfEmpty?: boolean;
    noBorderTop?: boolean;
    ignoreIfLastLine?: boolean;
    whiteSpace?: Property.WhiteSpace;
    colSpan?: number;
    rowSpan?: number;
    textIndent?: Property.TextIndent,
    paddingRight?: string;
    paddingLeft?: string;
    paddingBottom?: string;
    paddingTop?: string;
    noPadding?: boolean;
    isSymbol?: boolean;
    children?: ReactElement<EasyTableElementProps, FC<EasyTableElementProps>> | ReactElement<EasyTableElementProps, FC<EasyTableElementProps>>[];
}

export const EasyTableElement: FC<EasyTableElementProps> = (_props: EasyTableElementProps) => {
    return <></>
}

export interface EasyTableSectionProps extends EasyTableElementProps {
    children: ReactElement<EasyTableElementProps, FC<EasyTableElementProps>> | ReactElement<EasyTableElementProps, FC<EasyTableElementProps>>[];
}

export const EasyTableSection: FC<EasyTableSectionProps> = (_props: EasyTableSectionProps) => {
    return <></>
}


export const SplitPageHolder = (_props: {
    leftWidth?: string,
    rightWidth?: string,
    rightAlign?: "left" | "right" | "center" | "justify" | "char" | undefined,
    resizer?: boolean,
    children: (ReactElement)[]
}) => {
    const [leftX, setLeftX] = useState(-1);
    const [margin, setMargin] = useState(0);
    const divRef = useRef(null);
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        setMargin(Object(divRef.current).offsetLeft)
    }, [])


    return <div ref={divRef}>
        <table style={{width: "100%"}}>
            <tr>
                <td style={{verticalAlign: "top", width: _props.leftWidth ? (leftX != -1 ? (leftX - margin + "px") : _props.leftWidth) : (leftX != -1 ? (leftX - margin + "px") : "70%")}}>
                    {_props.children[0]}
                </td>
                {_props.resizer && <td className={"DraggableBar"} onDrag={(e) => {
                    if (mouseDown) {
                        if (e.buttons == 0) {
                            console.log("no");
                        }
                        setLeftX(Math.max(margin, e.pageX))
                    }
                }} onDragEnd={(e) => {
                    if (mouseDown) {
                        if (e.buttons == 0) {
                            console.log("no");
                        }
                        setLeftX(Math.max(margin, e.pageX))
                    }
                    setMouseDown(false);
                }} onDragStart={(e) => {
                    setMouseDown(true)
                }}
                                       style={{cursor: "ew-resize", padding: "0px", borderRight: "10px solid transparent", borderLeft: "10px solid transparent", backgroundClip: "content-box", height: "100%", width: "4px", minWidth: "4px", maxWidth: "4px", backgroundColor: "rgb(155, 103, 2)"}}/>}
                <td align={_props.rightAlign} style={{verticalAlign: "top", paddingTop: "0", width: _props.rightWidth}}>
                    {_props.children.slice(1)}
                </td>
            </tr>
        </table>

    </div>
}


export const DetailsTable = (_props: {
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    return <table className={"details-table"}>
        {_props.children}
    </table>
}
export const SearchCriteriaTWO = (_props: { searchCriteria: { title: string | number | JSX.Element | undefined, data: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[] }[], children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    const location = useLocation()
    return <table className="criteria">
        <tr>
            <td align="left" style={{textIndent: "-2em", paddingLeft: "2em"}}><i>Search Criteria</i>{": "}
                {_props.searchCriteria.length ? _props.searchCriteria.map((s, i) => {
                    return <><i>{s.title}</i>: {s.data.map((val) => {
                        switch (val.DataType) {
                            case "Input":
                                return <>"{val.data}" </>;
                                break;
                            case "Select":
                                return <>{val.data} </>;
                                break;
                            case "CheckBox":
                                return <>{val.data ? "true" : "false"} </>;
                                break;
                            default:
                                return <></>;
                                break;
                        }

                    })}{i == _props.searchCriteria.length - 1 ? "" : ":"} </>
                }) : <text style={{color: "red"}}>No search criteria entered. Click <Link relative={"path"}
                                                                                          state={location.state}
                                                                                          to={".."}>here</Link> to
                    search again.</text>}

            </td>
        </tr>
        {Array.of(_props.children).flat().map((child) =>
            <tr>
                <td>
                    {child}
                </td>
            </tr>
        )}
    </table>
}
export const SearchCriteria = (_props: { searchCriteria: SearchProp[], children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    return <table className="criteria">
        <tr>
            <td align="left" style={{textIndent: "-2em", paddingLeft: "2em"}}><i>Search Criteria</i>{": "}
                {_props.searchCriteria.map((s, i) => {
                    if (s.input && s.inputData && s.inputData != "" && s.criteriaVal) {
                        return <>{i != 0 ? "; " : ""}<i>{s.sectionName}</i>{": " + s.criteriaVal + " \"" + s.inputData + "\""}</>;
                    } else if (s.criteriaVal && s.criteria && !s.input) {

                        const val = s.criteria.find((value) => (value == s.criteriaVal) || (typeof value == "object" && (String(value.val) == String(s.criteriaVal))));
                        // @ts-ignore
                        return <>{i != 0 ? "; " : ""}<i>{s.sectionName}</i>{": " + ((typeof val == 'object') ? (val.dis) : (val))}</>;
                    } else if (s.checkmark && s.inputData == "true") {
                        // @ts-ignore
                        return <>{i != 0 ? "; " : ""}<i>{s.sectionName}</i>{": " + s.inputData}</>;
                    } else if (s.input && s.inputData && s.inputData != "") {
                        // @ts-ignore
                        return <>{i != 0 ? "; " : ""}<i>{s.sectionName}</i>{": " + s.inputData}</>;
                    }
                })}

            </td>
        </tr>
        {Array.of(_props.children).flat().map((child) =>
            <tr>
                <td>
                    {child}
                </td>
            </tr>
        )}
    </table>
}

export const AlowedLICheaker = (_props: {
    isPublic?: boolean
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    return <>{Array.of(_props.children).flat().filter((val) => {
        if (typeof val == "object" && val.type == "li" && val.props.children) {
            return Array.of(val.props.children).flat().findIndex((val: any) => {

                if (val.type == "a") {
                    // @ts-ignore
                    const module: tblLdlModule = LDLallowedModules[val.props.href.split('/').slice(1)[0]];
                    if ((module && module.ShowLdlReactPublic == -1) || (module && module.ShowLdlReactDemo == -1 && !_props.isPublic) || (!module && !_props.isPublic)) {
                        return false
                    }
                    return true;
                }
                return false;
            }) == -1;

        }
        return true;
    })}</>
}

export const ParagraphDesc = (_props: {
    children?: (ReactElement | false | undefined | string) | (ReactElement| string | false | undefined)[]
}) => {
    return <p className="header-paragraph-1" style={{marginRight: "17px", textAlign: "justify"}}>{_props.children}</p>
}

export const ParagraphHeader = (_props: {
    title?: JSX.Element | string | number | undefined;
    children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[]
}) => {
    return <><p className="header-paragraph-2" id={String(_props.title)}>
        <i><b>
            <br/>
            {_props.title}
        </b></i>
    </p>
        {_props.children}
    </>
}
export const SafeHTML = (_props: { html: string | undefined }) => {
    const htmlRef = useRef<any>();
    useEffect(() => {
        if (htmlRef.current == null) return
        if (_props.html) htmlRef.current.innerHTML = _props.html;
    }, [htmlRef, _props.html]);
    return <div ref={htmlRef}/>
}

export const RemoveIfLastSame = (elementFromValue: (val: any, index: number, array: any[]) => JSX.Element | string | number | undefined |{val:any,dis:any}, lastElementFromValue?: (val: any, index: number, array: any[]) => JSX.Element | string | number | undefined | {val:any,dis:any}) => {
    return (val: any, index: number, array: any[]) => {
        const a =  elementFromValue(val, index, array);
        const b = !array[index - 1]?undefined:(lastElementFromValue ? lastElementFromValue(array[index - 1], index - 1, array) : elementFromValue(array[index - 1], index - 1, array));
        if (!array[index - 1] || array[index - 1] && !_.isEqual(typeof a =="object"?('val' in a?(a.val):a):a,typeof b =="object"?('val' in b?(b.val):b):b)) {
            return (typeof a == "object"&&('dis' in a))?a.dis:a;
        } else {
            return undefined
        }
    }
}


export const TableEndLine = (_props: {marginTop?:Property.MarginTop,marginBottom?:Property.MarginBottom, colspan?: number, small?: boolean, width?: string }) => {
    return <table className="details-table headerAndFooter"
                  style={{width: _props.width ? _props.width : "fill-available",marginTop:_props.marginTop,marginBottom:_props.marginBottom}}>
        <tbody>
        <tr>
            <td align={"left"} colSpan={_props.colspan}
                style={{borderTop: _props.small ? "1px solid #000000" : "2px solid #000000", paddingLeft: "0", textIndent: "0"}}/>
        </tr>
        </tbody>
    </table>
}
export const TableRowEndLine = (_props: { colspan?: number, small?: boolean }) => {
    return <tr>
        <td align={"left"} colSpan={_props.colspan}
            style={{borderTop: _props.small ? "1px solid #000000" : "2px solid #000000", paddingLeft: "0", textIndent: "0"}}/>
    </tr>
}


export const LDLPDFViewer = (_props: { href?: string, width?: string, height?: string, startPage?: number }) => {


    return <>
        <embed key={_props.href + (_props.startPage ? ("#page=" + _props.startPage) : "") + "#navpanes=1"}
               type={"application/pdf"} style={{minWidth: "501px"}} width={_props.width} height={_props.height}
               src={_props.href + (_props.startPage ? ("#page=" + _props.startPage) : "") + "#navpanes=1"}/>
    </>
}
export const LDLPDFPageViewer = (_props: { href?: string,zoom?:number, width?: number, height?: number, PageNumber: number }) => {
    const [text, setText] = useState<any | undefined>();
    const [hiddenText, setHiddenText] = useState(true);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [zoom,setZoom]=useState(1)
    const uniqueid = useRef(_props.PageNumber + ":" + _props.href +":" + zoom);
    uniqueid.current = _props.PageNumber + ":" + _props.href  +":"+ zoom;
    useEffect(() => {
        setText(undefined);
    }, [_props.PageNumber, _props.href]);

    useEffect(() => {
        if(_props.zoom){
            setZoom(_props.zoom)
        }
    }, [_props.zoom]);
    return <table>
        <tr>
        {text ?
            <td width={"0%"} style={{whiteSpace:"nowrap"}} align={"left"}>
                <a onClick={(e) => setHiddenText((prev) => !prev)}>
                    <p>{hiddenText ? <>Show raw text</> : <>Hide raw text</>}</p></a>
            </td>
        :
            <td width={"0%"} style={{whiteSpace:"nowrap"}}  align={"left"}>
                <p>text loading...</p>
            </td>
        }
            <td width={"100%"} align={"right"} style={{whiteSpace:"nowrap"}}>
                resolution : {Math.round(zoom*100)/100}
                <button className={"btn btn-info"} onClick={()=>{setText(undefined);setZoom((prev)=>prev*1.25);}}>
                    +
                </button>
                <button disabled={zoom*0.8<=.05} className={"btn btn-info"} onClick={()=>{setText(undefined);setZoom((prev)=>(prev*0.8>=.05?(prev*0.8):prev))}}>
                    -
                </button>
            </td>
            </tr>
        <tr>
            <td colSpan={2}>
                <view style={{zoom : `${1/zoom}`}}><Document file={_props.href}>
                    <Page scale={zoom} canvasRef={canvasRef} onRenderSuccess={(e) => {
                        //console.log(canvasRef)
                        if (canvasRef.current) {
                            canvasRef.current?.getContext("2d")?.scale(4,4);
                            ScanCanvasForText(canvasRef.current, (n, funiqueID) => {

                                console.log(n, funiqueID, uniqueid.current);
                                if (funiqueID == uniqueid.current) {
                                    setText(n.data)
                                }

                            }, _props.PageNumber + ":" + _props.href + ":" + zoom);

                        }
                    }} renderTextLayer={false} width={_props.width} height={_props.height} renderAnnotationLayer={false}
                          pageNumber={_props.PageNumber}>
                        {text && <div onCopy={(e) => {
                            const selection = window.getSelection()?.getRangeAt(0)

                            if (selection) {
                                let startContainer = selection.startContainer
                                let endContainer = selection.endContainer
                                //@ts-ignore
                                const startID: string = startContainer.nodeType == 3 ? (startContainer.parentNode?.id) : (startContainer?.id)
                                //@ts-ignore
                                const endID: string = endContainer.nodeType == 3 ? (endContainer.parentNode?.id) : (endContainer?.id)

                                const start = /^(\d+?)\:(\d+?)\:(\d+?)\:/g.exec(startID)?.slice(1)
                                const end = /^(\d+?)\:(\d+?)\:(\d+?)\:/g.exec(endID)?.slice(1);
                                const range = [
                                    {start: start ? Number(start[0]) : 0, end: end ? Number(end[0]) : text.paragraphs[text.paragraphs.length - 1].lines[text.paragraphs[text.paragraphs.length - 1].lines.length - 1].words.length - 1},
                                    {start: start ? Number(start[1]) : 0, end: end ? Number(end[1]) : text.paragraphs[text.paragraphs.length - 1].lines.length - 1},
                                    {start: start ? Number(start[2]) : 0, end: end ? Number(end[2]) : text.paragraphs.length - 1}
                                ]
                                let data: string[][][] = text.paragraphs.slice(range[2].start, range[2].end + 1).map((val: { lines: any; }) => val.lines.map((val: { words: any; }) => val.words.map((val: { text: string; }) => val.text)))
                                if (data.length - 1 == 0) {
                                    data[0] = data[0].slice(range[1].start, range[1].end + 1)
                                } else {
                                    data[0] = data[0].slice(range[1].start)
                                    data[data.length - 1] = data[data.length - 1].slice(0, range[1].end + 1)
                                }
                                if ((data.length - 1 == 0) && (data[0].length - 1 == 0)) {
                                    data[0][0] = data[0][0].slice(range[0].start, range[0].end + 1)
                                } else {
                                    data[0][0] = data[0][0].slice(range[0].start)
                                    data[data.length - 1][data[data.length - 1].length - 1] = data[data.length - 1][data[data.length - 1].length - 1].slice(0, range[0].end + 1)
                                }
                                const lastWordLength = data[data.length - 1][data[data.length - 1].length - 1][data[data.length - 1][data[data.length - 1].length - 1].length - 1].length
                                let out = data.map((val) => val.map((val) => val.join(" ")).join("\n")).join("\n\n")
                                out = out.slice(selection.startOffset, end ? (out.length - lastWordLength + selection.endOffset) : out.length)

                                navigator.clipboard.writeText(out)
                                //console.log(out,range,data,start,end,startID,endID,selection)

                            }

                        }}
                                     style={{position: "absolute", left: 0, top: 0, width: canvasRef.current?.width, height: canvasRef.current?.height}}>{
                            text.paragraphs.map((para: any, pIndex: number) => {

                                return <div
                                    style={{position: "absolute", left: para.bbox.x0, top: para.bbox.y0, width: (para.bbox.x1 - para.bbox.x0), height: (para.bbox.y1 - para.bbox.y0)}}
                                    id={pIndex + ":" + _props.href + ":" + _props.PageNumber}>{para.lines.map((line: any, lIndex: number) => {

                                    return <span
                                        style={{position: "absolute", left: line.bbox.x0 - para.bbox.x0, top: line.bbox.y0 - para.bbox.y0, width: (line.bbox.x1 - line.bbox.x0), height: (line.bbox.y1 - line.bbox.y0)}}
                                        id={lIndex + ":" + pIndex + ":" + _props.href + ":" + _props.PageNumber}>{line.words.map((word: any, wIndex: number) => {

                                        return <span
                                            id={wIndex + ":" + lIndex + ":" + pIndex + ":" + _props.href + ":" + _props.PageNumber}
                                            style={{lineHeight: "1em", textAlign: "justify", color: `transparent`, verticalAlign: "top", position: "absolute", left: word.bbox.x0 - line.bbox.x0, width: (word.bbox.x1 - word.bbox.x0), height: (word.bbox.y1 - word.bbox.y0), fontSize: `${line.bbox.y1 - line.bbox.y0}px`}}>{word.text}</span>
                                    })}</span>
                                })}</div>
                            })}
                        </div>  }
                    </Page>

                </Document></view>

            </td>
        </tr>
        <tr hidden={hiddenText}>
            {text && <td colSpan={2}><div style={{whiteSpace: "pre-line"}}>{text.text}</div></td>}
        </tr>
    </table>;
}

export const ScanPDFForText = (href: string | undefined, out: (s: any) => void) => {
    if (href) {
        pdfjs.getDocument(href)
            .promise
            .then((doc) => Promise.all(Array.from(Array(doc.numPages).keys())
                .map((i) => doc.getPage(i + 1).then((page) => {
                    const viewport = page.getViewport({scale: 2});
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    if (context) {
                        return page.render({canvasContext: context, viewport}).promise.then((e) => {
                            return Tesseract.recognize(
                                canvas,
                                'eng'
                            )

                        })
                    }
                    return Tesseract.recognize(
                        canvas,
                        'eng'
                    )


                })))).then((e) => out(e))
    }
}

export const ScanCanvasForText = (canvas: HTMLCanvasElement, out: (s: any, uniqueID?: string) => void, uniqueID?: string) => {
    Tesseract.recognize(canvas).then((e) => out(e, uniqueID))


}
export const ScanPDFPageForText = (href: string | undefined, pageNumber: number, out: (s: any) => void) => {
    if (href) {
        pdfjs.getDocument(href)
            .promise
            .then((doc) => doc.getPage(pageNumber).then((page) => {
                const viewport = page.getViewport({scale: 2});
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                if (context) {
                    return page.render({canvasContext: context, viewport}).promise.then((e) => {
                        return Tesseract.recognize(
                            canvas,
                            'eng'
                        )

                    })
                }
                return Tesseract.recognize(
                    canvas,
                    'eng'
                )


            })).then((e) => out(e))
    }
}

export const getPageCount = (href: string, out: (n: number) => void) => {
    pdfjs.getDocument(href).promise.then((e) => out(e._pdfInfo.numPages)).catch((reason) => console.log(reason))
}
export const getPagesText = (href: string | undefined, out: (s: any) => void) => {
    if (href) {
        pdfjs.getDocument(href)
            .promise
            .then((e) => Promise.all(Array.from(Array(e.numPages).keys())
                .map((val) => e.getPage(val + 1).then((v) => v.getTextContent()))))
            .then((p) => out(p))
    }
}
export const getPagesImages = (href: string | undefined, out: (s: any) => void) => {
    if (href) {
        pdfjs.getDocument(href)
            .promise
            .then((e) => Promise.all(Array.from(Array(e.numPages).keys())
                .map((val) => e.getPage(val + 1).then((v) => v))))
            .then((p) => out(p))
    }
}
export const getPageSizeBytes = (href: string | undefined, out: (s: any) => void) => {
    if (href) {
        pdfjs.getDocument(href).promise.then((e) => e.getMetadata()).then((e) => out(Object(e)['contentLength']))
    }
}
export const IgnoreEmptySimple = (input: (string | undefined)) => {
    return input != '$' ? input : undefined;
}
export const IgnoreEmpty = (...input: (string | undefined | { val: string | undefined, dis: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] })[]) => {
    return input.map((val) => (typeof val == 'object' ? (val.val != '$' ? [val.dis, " "] : undefined) : (val != '$' ? [val, " "] : undefined))).flat();
}
export const ItalicIEHF = (input: (string | undefined)) => {
    return {val: input, dis: <i>{input}</i>}
}
export const ParaIEHF = (input: (string | undefined)) => {
    return {val: input, dis: <>({input})</>}
}
export const ParaItalicIEHF = (input: (string | undefined)) => {
    return {val: input, dis: <i>({input})</i>}
}
export const PDFSizeBytes = (_props: { href: string | undefined, guessSize?: number }) => {
    const [size, setSize] = useState(_props.guessSize);
    if (_props.href) {
        pdfjs.getDocument(_props.href).promise.then((e) => e.getMetadata()).then((e) => setSize(Object(e)['contentLength']))
    }
    return <>{size}</>
}
export const PDFSizeKiloBytes = (_props: { href: string | undefined, guessSize?: number }) => {
    const [size, setSize] = useState(_props.guessSize);
    if (_props.href) {
        pdfjs.getDocument(_props.href).promise.then((e) => e.getMetadata()).then((e) => setSize(Object(e)['contentLength'] / 1024))
    }
    return <>{Math.floor(size ? size : 0)}</>
}
export const PDFSizeMegaBytes = (_props: { href: string | undefined, guessSize?: number }) => {
    const [size, setSize] = useState(_props.guessSize);
    if (_props.href) {
        pdfjs.getDocument(_props.href).promise.then((e) => e.getMetadata()).then((e) => setSize(Object(e)['contentLength'] / 1048576))
    }
    return <>{Math.floor(size ? size : 0)}</>
}

export const FileSizeBytes = (_props: { href: string | undefined, guessSize?: number }) => {
    const [size, setSize] = useState(_props.guessSize);
    if (_props.href) {
        fetch(_props.href).then((res) => res.blob()).then((blob) => setSize(blob.size))
    }
    return <>{size}</>
}
export const FileSizeKiloBytes = (_props: { href: string | undefined, guessSize?: number }) => {
    const [size, setSize] = useState(_props.guessSize);
    if (_props.href) {
        fetch(_props.href).then((res) => res.blob()).then((blob) => setSize(Math.ceil(blob.size / 1024))).catch((reason) => console.log(reason))
    }
    return <>{size ? size : <>&ndash;</>}</>
}
export const FileSizeMegaBytes = (_props: { href: string | undefined, guessSize?: number }) => {
    const [size, setSize] = useState(_props.guessSize);
    if (_props.href) {
        fetch(_props.href).then((res) => res.blob()).then((blob) => setSize(Math.ceil(blob.size / 1048576)))
    }
    return <>{size}</>
}

export const CornerIcon = (_props: { offsetX?: Property.Width, offsetY?: Property.Height, children?: (ReactElement | false | undefined | string) | (ReactElement | false | undefined | string)[] }) => {
    return <div style={{position: "relative"}}>
        <div
            style={{position: "absolute", right: _props.offsetX ? _props.offsetX : "0px", top: _props.offsetY ? _props.offsetY : "-30px"}}>{_props.children}</div>
    </div>
}
export const Op = {
    eq: "Op.eq",
    startsWith: "Op.startsWith",
    substring: "Op.substring",
    endsWith: "Op.endsWith",
    and: "Op.and",
    or: "Op.or"
};

export const SOp = {
    "is": Op.eq,
    "begins with": Op.startsWith,
    "contains": Op.substring,
    "ends with": Op.endsWith,
    "and": Op.and,
    "or": Op.or
}

export function OpFilter<T extends Model>(val: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[], attribute: (keyof T["_attributes"]) | (keyof T["_attributes"])[], attributeCombinationType?: "or" | "and"): WhereOptions<T["_attributes"]> | undefined {

    if (val[1].data && val[0].DataType != "Unknown" && val[0].DataType == "Select" && val[0].data && val[0].data in SOp) {

        if (Array.isArray(attribute)) {
            //@ts-ignore
            return {[attributeCombinationType ? SOp[attributeCombinationType] : Op.or]: attribute.map((att) => ({[att]: {[SOp[String(val[0].data)]]: val[1].data}}))}
        }
        //@ts-ignore
        return {[attribute]: {[SOp[String(val[0].data)]]: val[1].data}};

    }
    return undefined
}

export function EqFilter<T extends Model>(val: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[], attribute: (keyof T["_attributes"]) | (keyof T["_attributes"])[], attributeCombinationType?: "or" | "and"): WhereOptions<T["_attributes"]> | undefined {
    if (val[0].data != undefined && val[0].data != "") {
        if (Array.isArray(attribute)) {
            //@ts-ignore
            return {[attributeCombinationType ? SOp[attributeCombinationType] : Op.or]: attribute.map((att) => ({[att]: val[0].data}))}
        }
        //@ts-ignore
        return {[attribute]: val[0].data};
    }
    return undefined
}

export function SelectTrueFilter<T extends Model>(val: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[], attributes: (keyof T["_attributes"])[] | (keyof T["_attributes"])[][], attributeCombinationType?: "or" | "and"): WhereOptions<T["_attributes"]> | undefined {
    if (val[0].data != "" && val[0].data != 0 && val[0].data != "0" && val[0].DataType != "Unknown") {
        //@ts-ignore
        if (Array.isArray(attributes[val[0].data])) {
            //@ts-ignore
            if (attributes[val[0].data].length) {
                //@ts-ignore
                return {[attributeCombinationType ? SOp[attributeCombinationType] : Op.or]: attributes[val[0].data].map((att) => ({[att]: -1}))}
            } else {
                return undefined
            }
        }
        //@ts-ignore
        return {[attributes[val[0].data]]: -1};
    }
    return undefined
}


export function EasySearch<T extends Model>(_props: {searchOnChange?:boolean,inLineSearch?:boolean, onSearch?: (searchData: { searchStateData: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[][][], displayData: { title: string | number | JSX.Element | undefined, data: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[] }[], apiData: WhereOptions<T["_attributes"]>[] }) => void, defaultSearchStates?: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[][][], children?: (ReactElement<EasySearchSectionProps<T>, FC<EasySearchSectionProps<T>>> | false | undefined) | (ReactElement<EasySearchSectionProps<T>, FC<EasySearchSectionProps<T>>> | false | undefined)[] }) {
    const [stateData, setStateData] = useState<({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[][][]>(_props.defaultSearchStates ? _props.defaultSearchStates : (Array.of(_props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>>[]).map((s) => (Array.of(s?.props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>[]).map((f) => Array.of(f?.props.children).flat().map((element) => {
        switch (element?.props.DataType) {
            case "Input":
                return {DataType: "Input", data: element.props.inputValue};
            case "Select":
                return {DataType: "Select", data: element.props.useIndexAsValues ? element.props.options?.indexOf(element.props.defaultValue) : element.props.defaultValue};
            case "CheckBox":
                return {DataType: "CheckBox", data: element.props.defaultChecked};
            default:
                return {DataType: "Unknown", data: undefined};
        }
    }))))
    const reset = () => {
        document.getElementById("SEARCHFocus")?.focus()
        setStateData((Array.of(_props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>>[]).map((s) => (Array.of(s?.props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>[]).map((f) => Array.of(f?.props.children).flat().map((element) => {
            switch (element?.props.DataType) {
                case "Input":
                    return {DataType: "Input", data: element.props.inputValue};
                case "Select":
                    return {DataType: "Select", data: element.props.useIndexAsValues ? element.props.options?.indexOf(element.props.defaultValue) : element.props.defaultValue};
                case "CheckBox":
                    return {DataType: "CheckBox", data: element.props.defaultChecked};
                default:
                    return {DataType: "Unknown", data: undefined};
            }
        }))));
    }
    const handleFunctions = () => {
        console.log("clicked")
        if (_props.onSearch) {
            const data = {
                searchStateData: stateData, displayData: (Array.of(_props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>>[])
                    .flatMap((s, si) => (Array.of(s?.props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>[])
                        .flatMap((f, fi) => {

                            return {
                                title: f.props.title, data: Array.of(f.props.children).flat().map((e, ei, el) => {
                                    const out = stateData[si][fi][ei];
                                    //console.log(out,e)
                                    switch (out.DataType) {
                                        case "Input":
                                            if (out.data == e?.props.inputValue || out.data == "") {
                                                return undefined
                                            }

                                            break;
                                        case "Select":
                                            if ((out.data == "0" && e?.props.useIndexAsValues) || (out.data == e?.props.defaultValue && el.length == 1)) {
                                                return undefined
                                            } else if (e?.props.useIndexAsValues && !e?.props.convertFunction) { // @ts-ignore
                                                return {DataType: "Select", data: e?.props.options[out.data]}
                                            }

                                            break;
                                        case "CheckBox":
                                            if (out.data == e?.props.defaultChecked) {
                                                return undefined
                                            }

                                            break;
                                        default:
                                            return undefined;
                                    }
                                    if (e?.props.convertFunction) {
                                        const dataOut = e.props.convertFunction(out.data)
                                        if (out.DataType == "Select" && (typeof dataOut == "string" || typeof dataOut == "number")) {
                                            return {DataType: "Select", data: dataOut}
                                        } else if (typeof dataOut == "string" || typeof dataOut == "number") {
                                            return {DataType: "Input", data: dataOut}
                                        } else if (typeof dataOut == "boolean") {
                                            return {DataType: "CheckBox", data: dataOut}
                                        } else if (dataOut == undefined) {
                                            return undefined
                                        }
                                    }
                                    return out
                                })
                            }

                        }).filter((val) => val.data.findIndex((d) => d == undefined) == -1)), //@ts-ignore
                apiData: (Array.of(_props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>>[]).flatMap((section, sIndex) => (Array.of(section?.props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>[]).flatMap((field, fIndex): WhereOptions<T["_attributes"]> | undefined => {
                    if (field?.props.feildFunction) {
                        return field.props.feildFunction(Array.of(field.props.children).flat().map((val, eIndex) => {
                            const out = stateData[sIndex][fIndex][eIndex];
                            if (val?.props.convertFunction) {
                                const dataOut = val.props.convertFunction(out.data)
                                if (out.DataType == "Select" && (typeof dataOut == "string" || typeof dataOut == "number")) {
                                    return {DataType: "Select", data: dataOut}
                                } else if (typeof dataOut == "string" || typeof dataOut == "number") {
                                    return {DataType: "Input", data: dataOut}
                                } else if (typeof dataOut == "boolean") {
                                    return {DataType: "CheckBox", data: dataOut}
                                } else if (dataOut == undefined) {
                                    return {DataType: "Unknown", data: dataOut}
                                }

                            }
                            return out
                        }), sIndex, fIndex, stateData);
                    }
                    return undefined;
                })).filter((val) => !!val)
            }
            if (data.apiData.length) {
                //@ts-ignore
                _props.onSearch(data)
            }
        }
    }
    const DataContainer = (element: ReactElement<EasySearchDataProps, FC<EasySearchDataProps>> | undefined, eIndex:number, eArray:(React.ReactElement<EasySearchDataProps, React.FC<EasySearchDataProps>> | undefined)[],field: React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>,fIndex:number, section: false | React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>> | undefined,sIndex:number,jsxElement:JSX.Element)=>{
        return<>{_props.inLineSearch?jsxElement:<td colSpan={element?.props.colSpan}
                  style={{paddingRight: "10px", textAlign: "left"}}>{jsxElement}</td>}</>;
    }
    const FieldContainer = (field: React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>,fIndex:number, section: false | React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>> | undefined,sIndex:number,jsxElement:JSX.Element)=>{
        return <>{_props.inLineSearch?<>{field?.props.title}:{jsxElement}</>:<tr>
            <td colSpan={field.props.titleColSpan} className={"DF1row align-right"}>
                <b>{field?.props.title}</b>:
            </td>
            {jsxElement}
            {!(field?.props.collapseDesc) &&
                <td colSpan={field.props.descColSpan} style={{textAlign: "left"}}>{field?.props.desc}</td>}
        </tr>}</>;
    }
    const SectionContainer = ( section: false | React.ReactElement<EasySearchSectionProps<T>, React.FC<EasySearchSectionProps<T>>> | undefined,sIndex:number,jsxElement:JSX.Element)=>{
        return<>{_props.inLineSearch?jsxElement:<>{section && <>
            <tr>
                <td className={"DF1row align-right SearchSection"}><b>{section?.props.title}</b></td>
            </tr>
            {jsxElement}
            <tr>
                <td colSpan={4} align={"center"} style={{fontSize: "small"}}>{section?.props.comment}</td>
            </tr>
        </>}</>}</>
    }
    const SearchContainer = (jsxElement:JSX.Element,handleFunctions:()=>void,reset:()=>void)=>{
    return <>{_props.inLineSearch?<>{jsxElement}{!_props.searchOnChange&&<IconButton IconID={LDLIcons.LDL_Search} onClick={handleFunctions}/>}</>:<form>
        <table>
            {jsxElement}
            <tr>
                <td></td>
                <td colSpan={999}>
                    <button style={{width: "120px", marginRight: "10px", marginTop: "10px",}} onClick={handleFunctions} type={"submit"} className="btn btn-info">Search</button>
                    <a style={{color: "white", width: "100px", marginTop: "10px",}} className="btn btn-info" onClick={reset}>Reset</a>
                   </td>
            </tr>
        </table>
    </form>}</>
    }
    useEffect(() => {
        if(_props.searchOnChange){
            handleFunctions();
        }
    }, [stateData]);

    return SearchContainer(<>{Array.of(_props.children).flat().map((section, sIndex) => {
        return SectionContainer(section,sIndex, <>{section&&(Array.of(section?.props.children).flat().filter((val) => !!val) as React.ReactElement<EasySearchFieldProps<T>, React.FC<EasySearchFieldProps<T>>>[]).map((field, fIndex) => {

            const onElementChange = (data: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined }), eIndex: number) => {
                setStateData((prev) => {
                    if (prev) {
                        const temp = [...prev];
                        temp[sIndex][fIndex][eIndex] = data;
                        return temp;
                    }
                    return prev
                })
            }


            return FieldContainer(field,fIndex,section,sIndex, <>{Array.of(field?.props.children).flat().map((element, eIndex, eArray) => {
                const value = stateData[sIndex][fIndex][eIndex];
                let newElement = <></>;
                switch (element?.props.DataType) {
                    case "Input": {
                        newElement = <input className={_props.inLineSearch?"small-input":undefined} id={element?.props.focus ? "SEARCHFocus" : undefined}
                                            autoFocus={element?.props.focus} style={{width: "100%"}}
                                            value={value.DataType == "Input" && value.data ? value.data : (element.props.inputValue ? element.props.inputValue : "")}
                                            defaultValue={element.props.inputValue}

                                            onChange={(e) => onElementChange({DataType: "Input", data: e.target.value}, eIndex)}
                                            type={element?.props.isNumber?"number":"text"}/>
                    }

                        break;
                    case "Select": {
                        newElement = <select className={_props.inLineSearch?"short-select-box":undefined} id={element?.props.focus ? "SEARCHFocus" : undefined}
                                             autoFocus={element?.props.focus} style={_props.inLineSearch?undefined:{width: "100%"}}
                                             value={value.DataType == "Select" ? value.data : undefined}
                                             defaultValue={element.props.useIndexAsValues ? element.props.options?.indexOf(element.props.defaultValue) : element.props.defaultValue}
                                             onChange={(e) => onElementChange({DataType: "Select", data: e.target.value}, eIndex)}>
                            {element.props.options?.map((option, oIndex) => <option
                                value={(option && typeof option == 'object' && 'val' in option) ? option.val : (element.props.useIndexAsValues ? oIndex : option)}>{(option && typeof option == 'object' && 'dis' in option) ? option.dis : option}</option>)}
                        </select>
                    }

                        break;
                    case "CheckBox": {
                        newElement = <input className={_props.inLineSearch?"small-input":undefined} id={element?.props.focus ? "SEARCHFocus" : undefined}
                                            autoFocus={element?.props.focus}
                                            style={{marginBottom: "7px", marginLeft: "5px"}}
                                            checked={value.DataType == "CheckBox" ? value.data : undefined}
                                            defaultChecked={element.props.defaultChecked} type={"checkbox"}
                                            onChange={(e) => onElementChange({DataType: "CheckBox", data: e.target.checked}, eIndex)}/>
                    }

                        break;
                    default:

                }
                //console.log(eIndex,eArray.length-1,field)
                return DataContainer(element,eIndex,eArray,field,fIndex,section,sIndex,<>{newElement}{(field?.props.collapseDesc && (eIndex == (eArray.length - 1))) && <> {field?.props.desc}</>}</>);
            })}</>)
        })}</>)
    })}</>,handleFunctions,reset);
}

export interface EasySearchFieldProps<T extends Model> {
    title?: JSX.Element | string | number | undefined;
    desc?: JSX.Element | string | number | undefined;
    descColSpan?: number;
    titleColSpan?: number;
    collapseDesc?: boolean;
    feildFunction?: (feildData: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[], sindex: number, findex: number, allFeildData: ({ DataType: "Input" | "Select", data: string | number | undefined } | { DataType: "CheckBox", data: boolean | undefined } | { DataType: "Unknown", data: undefined })[][][]) => WhereOptions<T["_attributes"]> | undefined;
    children?: ReactElement<EasySearchDataProps, FC<EasySearchDataProps>> | ReactElement<EasySearchDataProps, FC<EasySearchDataProps>>[];
}

export interface EasySearchCallProps {
    colSpan?: number,
    focus?: boolean
}


export type EasySearchDataProps =
    { convertFunction?: (data: string | number | undefined | boolean) => string | number | boolean | undefined }
    & EasySearchInputProps
    & EasySearchSelectProps
    & EasySearchCheckBoxProps

export interface EasySearchInputProps extends EasySearchCallProps {
    convertFunction?: (data: string | number | undefined) => string | number | boolean | undefined
    DataType?: string;
    isNumber?:boolean;
    inputValue?: string;
}

export interface EasySearchSelectProps extends EasySearchCallProps {
    convertFunction?: (data: string | number | undefined) => string | number | boolean | undefined
    DataType?: string;
    useIndexAsValues?: boolean;
    options?: (string | number | undefined | { val: (string | number), dis: JSX.Element | string | number | undefined })[]
    defaultValue?: string | number;
}

export interface EasySearchCheckBoxProps extends EasySearchCallProps {
    convertFunction?: (data: boolean | undefined) => string | number | boolean | undefined
    DataType?: string;
    defaultChecked?: boolean;
}

export const EasySearchSelect: FC<EasySearchSelectProps> = (_props: EasySearchSelectProps) => {
    return <></>
}
EasySearchSelect.defaultProps = {DataType: "Select"}


export const EasySearchCheckBox: FC<EasySearchCheckBoxProps> = (_props: EasySearchCheckBoxProps) => {
    return <></>
}
EasySearchCheckBox.defaultProps = {DataType: "CheckBox"}


export const EasySearchInput: FC<EasySearchInputProps> = (_props: EasySearchInputProps) => {
    return <></>
}
EasySearchInput.defaultProps = {DataType: "Input"}


export function EasySearchField<T extends Model>(_props: EasySearchFieldProps<T>) {
    return <></>
}

export interface EasySearchSectionProps<T extends Model> {
    title?: JSX.Element | string | number | undefined;
    comment?: JSX.Element | string | number | undefined;
    children: (false | undefined | ReactElement<EasySearchFieldProps<T>, FC<EasySearchFieldProps<T>>>) | (false | undefined | ReactElement<EasySearchFieldProps<T>, FC<EasySearchFieldProps<T>>>)[];
}

export function EasySearchSection<T extends Model>(_props: EasySearchSectionProps<T>) {
    return <></>
}




type EasyCSSUnit = number | `${number}${"px" | "%"}`;

type EasyCSSPosition = "relative" | "fixed" | "absolute" | "unset";
type EasyCSSPadding =
    EasyCSSUnit
    | `${EasyCSSUnit} ${EasyCSSUnit}`
    | `${EasyCSSUnit} ${EasyCSSUnit} ${EasyCSSUnit}`
    | `${EasyCSSUnit} ${EasyCSSUnit} ${EasyCSSUnit} ${EasyCSSUnit}`

const EasyCSSUnitToPx = (unit: EasyCSSUnit | undefined, parentUnit: number): number => {
    if (typeof unit == "string") {
        if (unit.endsWith('%')) {
            return parentUnit * Number(unit.slice(0, unit.length - 1)) / 100
        } else {
            return Number(unit.slice(0, unit.length - 2))
        }
    } else {
        return unit ? unit : 0
    }
}


type EasyGraphicElementPropsPx = {
    [Property in keyof EasyGraphicElementContainerProps as Exclude<Property, "padding"> & Exclude<Property, "margin">]: number
};
const EasyGraphicElementCSSToPx = (props: EasyGraphicElementContainerProps | undefined, parentBounds: { width: number, height: number, x: number, y: number }): EasyGraphicElementPropsPx => {
    const out: EasyGraphicElementPropsPx & { padding?: { paddingTop?: number, paddingRight?: number, paddingBottom?: number, paddingLeft?: number } | number, margin?: { marginTop?: number, marginRight?: number, marginBottom?: number, marginLeft?: number } | number } = props ? Object.fromEntries(Object.entries(props).map((val) => {
        let out = undefined;
        switch (val[0]) {
            case "left":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "right":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "top":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            case "bottom":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            case "width":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "height":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            case "padding":
                out = val[1] ? (String(val[1]).includes(" ") ? Object.fromEntries((val[1].split(" ").map((val: EasyCSSUnit, index: number) => {
                    switch (index) {
                        case 0:
                            return ["paddingTop", EasyCSSUnitToPx(val, parentBounds.height)];
                        case 1:
                            return ["paddingRight", EasyCSSUnitToPx(val, parentBounds.width)];
                        case 2:
                            return ["paddingBottom", EasyCSSUnitToPx(val, parentBounds.height)];
                        case 3:
                            return ["paddingLeft", EasyCSSUnitToPx(val, parentBounds.width)];
                    }
                    return []
                }).filter((val: string | any[]) => !!val.length))) : EasyCSSUnitToPx(val[1], parentBounds.height)) : 0;
                break;
            case "paddingTop":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            case "paddingLeft":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "paddingRight":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "paddingBottom":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            case "margin":
                out = val[1] ? (String(val[1]).includes(" ") ? Object.fromEntries((val[1].split(" ").map((val: EasyCSSUnit, index: number) => {
                    switch (index) {
                        case 0:
                            return ["marginTop", EasyCSSUnitToPx(val, parentBounds.height)];
                        case 1:
                            return ["marginRight", EasyCSSUnitToPx(val, parentBounds.width)];
                        case 2:
                            return ["marginBottom", EasyCSSUnitToPx(val, parentBounds.height)];
                        case 3:
                            return ["marginLeft", EasyCSSUnitToPx(val, parentBounds.width)];
                    }
                    return []
                }).filter((val: string | any[]) => !!val.length))) : EasyCSSUnitToPx(val[1], parentBounds.height)) : 0;
                break;
            case "marginTop":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            case "marginLeft":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "marginRight":
                out = EasyCSSUnitToPx(val[1], parentBounds.width);
                break;
            case "marginBottom":
                out = EasyCSSUnitToPx(val[1], parentBounds.height);
                break;
            default:
                return [];
                break;
        }
        return [val[0], out]
    }).filter((val) => !!val.length)) : {}
    if (out?.padding && typeof out.padding == "object") {
        if (out?.paddingTop == undefined) {
            out.paddingTop = out.padding.paddingTop
        }
        if (out?.paddingRight == undefined) {
            out.paddingRight = out.padding.paddingRight != undefined ? out.padding.paddingRight : out.padding.paddingTop
        }
        if (out?.paddingBottom == undefined) {
            out.paddingBottom = out.padding.paddingBottom != undefined ? out.padding.paddingBottom : out.padding.paddingTop
        }
        if (out.paddingLeft == undefined) {
            out.paddingLeft = out.padding.paddingLeft != undefined ? out.padding.paddingLeft : out.padding.paddingRight != undefined ? out.padding.paddingRight : out.padding.paddingTop
        }
    } else if (out?.padding && typeof out.padding != "object") {
        if (out?.paddingTop == undefined) {
            out.paddingTop = out.padding
        }
        if (out?.paddingRight == undefined) {
            out.paddingRight = out.padding;
        }
        if (out?.paddingBottom == undefined) {
            out.paddingBottom = out.padding;
        }
        if (out.paddingLeft == undefined) {
            out.paddingLeft = out.padding;
        }
    }
    if (out?.margin && typeof out.margin == "object") {
        if (out?.marginTop == undefined) {
            out.marginTop = out.margin.marginTop
        }
        if (out?.marginRight == undefined) {
            out.marginRight = out.margin.marginRight != undefined ? out.margin.marginRight : out.margin.marginTop
        }
        if (out?.marginBottom == undefined) {
            out.marginBottom = out.margin.marginBottom != undefined ? out.margin.marginBottom : out.margin.marginTop
        }
        if (out.marginLeft == undefined) {
            out.marginLeft = out.margin.marginLeft != undefined ? out.margin.marginLeft : out.margin.marginRight != undefined ? out.margin.marginRight : out.margin.marginTop
        }
    } else if (out?.margin && typeof out.margin != "object") {
        if (out?.marginTop == undefined) {
            out.marginTop = out.margin
        }
        if (out?.marginRight == undefined) {
            out.marginRight = out.margin;
        }
        if (out?.marginBottom == undefined) {
            out.marginBottom = out.margin;
        }
        if (out.marginLeft == undefined) {
            out.marginLeft = out.margin;
        }
    }
    delete out.padding;
    delete out.margin;
    return out;
}
export const EasyGraphic = (_props: {debug?:boolean, width?: Property.Width, height?: Property.Height, children: (false | undefined | ReactElement<EasyGraphicType, FC<EasyGraphicType>>) | (false | undefined | ReactElement<EasyGraphicType, FC<EasyGraphicType>>)[]; }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {

        if (canvasRef.current) {
            let canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            if (ctx) {
                Array.of(_props.children).flat().forEach((child) => {
                    if (typeof child == "object" && child.props.drawFunction) {
                        child.props.drawFunction(ctx, canvas, child.props,_props.debug)
                    }
                })

            }
        }
    }, [canvasRef,_props]);
    return <canvas ref={canvasRef} width={_props.width} height={_props.height}/>
}

interface EasyGraphicDefaultProps {
    drawFunction?: ((ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, _props: EasyGraphicType,debug?:boolean) => void)
}

type EasyGraphicType =
    EasyGraphicDefaultProps
    & EasyGraphicHistogramProps
    & EasyGraphicLineGraphProps
    & EasyGraphicGraphProps;

interface EasyGraphicElementContainerProps {
    left?: EasyCSSUnit;
    right?: EasyCSSUnit;
    top?: EasyCSSUnit;
    bottom?: EasyCSSUnit;
    width?: EasyCSSUnit;
    height?: EasyCSSUnit;
    padding?: EasyCSSPadding;
    paddingTop?: EasyCSSUnit;
    paddingLeft?: EasyCSSUnit;
    paddingRight?: EasyCSSUnit;
    paddingBottom?: EasyCSSUnit;
    margin?: EasyCSSPadding;
    marginTop?: EasyCSSUnit;
    marginLeft?: EasyCSSUnit;
    marginRight?: EasyCSSUnit;
    marginBottom?: EasyCSSUnit;
}
type EasyBoundingBoxData=Exclude<EasyGraphicElementPropsPx, "margin">&Exclude<EasyGraphicElementPropsPx, "padding">&{viewPort: {width: number, height: number, x: number, y: number},boundingBox: {x: number, y: number, width: number, height: number},paddedBox: {x: number, y: number, width: number, height: number},innerBox: {x: number, y: number, width: number, height: number}}

const getBoundingBoxes = (viewPort: { width: number, height: number, x: number, y: number }, containerStyle?: EasyGraphicElementContainerProps):EasyBoundingBoxData => {
    const cssToPx = EasyGraphicElementCSSToPx(containerStyle, viewPort);
    cssToPx.width = EasyCSSUnitToPx(containerStyle?.width, viewPort.width - ((cssToPx?.paddingRight ? cssToPx.paddingRight : 0) + (cssToPx?.paddingLeft ? cssToPx.paddingLeft : 0) + (cssToPx?.marginLeft ? cssToPx.marginLeft : 0) + (cssToPx?.marginRight ? cssToPx.marginRight : 0)))
    cssToPx.height = EasyCSSUnitToPx(containerStyle?.height, viewPort.height - ((cssToPx?.paddingTop ? cssToPx.paddingTop : 0) + (cssToPx?.paddingBottom ? cssToPx.paddingBottom : 0) + (cssToPx?.marginTop ? cssToPx.marginTop : 0) + (cssToPx?.marginBottom ? cssToPx.marginBottom : 0)))
    const tempWidth = (cssToPx?.width ? cssToPx.width : 0) + (cssToPx?.paddingRight ? cssToPx.paddingRight : 0) + (cssToPx?.paddingLeft ? cssToPx.paddingLeft : 0) + (cssToPx?.marginLeft ? cssToPx.marginLeft : 0) + (cssToPx?.marginRight ? cssToPx.marginRight : 0);
    const tempHeight = (cssToPx?.height ? cssToPx.height : 0) + (cssToPx?.paddingTop ? cssToPx.paddingTop : 0) + (cssToPx?.paddingBottom ? cssToPx.paddingBottom : 0) + (cssToPx?.marginTop ? cssToPx.marginTop : 0) + (cssToPx?.marginBottom ? cssToPx.marginBottom : 0);
    const BoundingBox = {
        x: viewPort.x + ((cssToPx.left ?
            (cssToPx.left) :
            (cssToPx.right ?
                (viewPort.width - tempWidth - cssToPx.right) :
                (0))))
        , y: viewPort.y + (cssToPx.top ?
            (cssToPx.top) :
            (cssToPx.bottom ?
                (viewPort.height - tempHeight - cssToPx.bottom) :
                (0))), width: tempWidth, height: tempHeight
    };
    const PaddedBox = {
        x: BoundingBox.x + (cssToPx?.marginLeft ? cssToPx.marginLeft : 0)
        , y: BoundingBox.y + (cssToPx?.marginTop ? cssToPx.marginTop : 0), width: BoundingBox.width - (cssToPx?.marginRight ? cssToPx.marginRight : 0) - (cssToPx?.marginLeft ? cssToPx.marginLeft : 0), height: BoundingBox.height - (cssToPx?.marginBottom ? cssToPx.marginBottom : 0) - (cssToPx?.marginTop ? cssToPx.marginTop : 0)
    };
    const InnerBox = {
        x: PaddedBox.x + (cssToPx?.paddingLeft ? cssToPx.paddingLeft : 0)
        , y: PaddedBox.y + (cssToPx?.paddingTop ? cssToPx.paddingTop : 0), width: PaddedBox.width - (cssToPx?.paddingRight ? cssToPx.paddingRight : 0) - (cssToPx?.paddingLeft ? cssToPx.paddingLeft : 0), height: PaddedBox.height - (cssToPx?.paddingBottom ? cssToPx.paddingBottom : 0) - (cssToPx?.paddingTop ? cssToPx.paddingTop : 0)
    };
    return {viewPort: viewPort, boundingBox: BoundingBox, paddedBox: PaddedBox, innerBox: InnerBox, ...cssToPx};
}


type EasyCSSTextElement =
    string
    | { title?: string, position?: EasyCSSPosition, textAlign?: CanvasTextAlign, rotation?: number, fontColor?: string | CanvasGradient | CanvasPattern, textBaseLine?: CanvasTextBaseline, font?: Property.Font, containerStyle?: EasyGraphicElementContainerProps };

const EasyGraphicText = (textData: EasyCSSTextElement|undefined, ctx: CanvasRenderingContext2D, viewPort: { width: number, height: number, x: number, y: number },defaultText?:EasyCSSTextElement,debug?:boolean) => {
    const text = defaultText?{...(typeof defaultText=="string"?{title:defaultText}:defaultText),...(typeof textData=="string"?{title:textData}:textData)}:textData
    const containerStyle = (typeof defaultText!="string"&&defaultText?.containerStyle)?{...defaultText.containerStyle,...(typeof textData!="string"&&textData?.containerStyle)?textData.containerStyle:{}}:((typeof textData!="string"&&textData?.containerStyle)?textData.containerStyle:undefined)
    //console.log(text)
    ctx.font = `${viewPort.height}px Arial`
    ctx.textAlign = "center"
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#000000";
if(text) {
    if (typeof text != "string") {
        const textBoundingBox = getBoundingBoxes(viewPort, containerStyle)
        if (debug) {
            ctx.fillStyle = "#ff9999"
            ctx.fillRect(textBoundingBox.viewPort.x, textBoundingBox.viewPort.y, textBoundingBox.viewPort.width, textBoundingBox.viewPort.height)
            ctx.fillStyle = "#99ff99"
            ctx.fillRect(textBoundingBox.boundingBox.x, textBoundingBox.boundingBox.y, textBoundingBox.boundingBox.width, textBoundingBox.boundingBox.height)
            ctx.fillStyle = "#9999ff"
            ctx.fillRect(textBoundingBox.paddedBox.x, textBoundingBox.paddedBox.y, textBoundingBox.paddedBox.width, textBoundingBox.paddedBox.height)
            ctx.fillStyle = "#ffff99"
            ctx.fillRect(textBoundingBox.innerBox.x, textBoundingBox.innerBox.y, textBoundingBox.innerBox.width, textBoundingBox.innerBox.height)
        }
        const fontHeight = Math.sqrt(Math.pow(Math.sin((text.rotation ? text.rotation : 0) * Math.PI / 180) * textBoundingBox.innerBox.width, 2) + Math.pow(Math.cos((text.rotation ? text.rotation : 0) * Math.PI / 180) * textBoundingBox.innerBox.height, 2))
        const fontWidth = Math.sqrt(Math.pow(Math.sin((text.rotation ? text.rotation : 0) * Math.PI / 180) * textBoundingBox.innerBox.height, 2) + Math.pow(Math.cos((text.rotation ? text.rotation : 0) * Math.PI / 180) * textBoundingBox.innerBox.width, 2))

        ctx.font = text.font ? text.font : `${fontHeight}px Arial`;
        ctx.textAlign = text.textAlign ? text.textAlign : "center";
        ctx.textBaseline = text.textBaseLine?text.textBaseLine:"middle";

        let centeringOffsetX = 0;
        let centeringOffsetY = 0;
        switch (ctx.textAlign) {
            case "center":
                centeringOffsetX = textBoundingBox.innerBox.width / 2;
                break;
            case "left":
                centeringOffsetX = 0;
                break;
            case "right":
                centeringOffsetX = textBoundingBox.innerBox.width;
                break;
        }
        switch (String(ctx.textBaseline)) {
            case "middle":
                centeringOffsetY = textBoundingBox.innerBox.height / 2;
                break;
            case "bottom":
                centeringOffsetY = textBoundingBox.innerBox.height;
                break;
            case "top":
                centeringOffsetY = 0;
                break;
        }
        //console.log(centeringOffsetY,ctx.textBaseline)
        ctx.translate(textBoundingBox.innerBox.x + centeringOffsetX, (textBoundingBox.innerBox.y + centeringOffsetY))
        ctx.rotate((text.rotation ? text.rotation : 0) * Math.PI / 180)
        ctx.fillStyle = text.fontColor ? text.fontColor : "#000000";
        if (text.title) {
            ctx.fillText(text.title, 0, 0, fontWidth)
        }
        ctx.rotate(-(text.rotation ? text.rotation : 0) * Math.PI / 180)
        ctx.translate(-(textBoundingBox.innerBox.x + centeringOffsetX), -(textBoundingBox.innerBox.y + centeringOffsetY))

    } else {
        ctx.fillText(text, viewPort.x + viewPort.width / 2, (viewPort.y + viewPort.height / 2))
    }
}
}

type EasyCSSGraphLineElement={isVertical?:boolean,startInterval?:EasyCSSUnit,LabelStrings?:string[], lineColor?: string | CanvasGradient | CanvasPattern;underGraph?:boolean;subOverGraph?:boolean; snapTopClosestInterval?:boolean;textStyle?:EasyCSSTextElement;paddingRight?:EasyCSSUnit;paddingLeft?:EasyCSSUnit;marginRight?:EasyCSSUnit;marginLeft?:EasyCSSUnit; intervalType?:"fixed"|"percent"|"noText";topIntervalValue?:EasyCSSUnit, interval?:EasyCSSUnit,subInterval?:EasyCSSUnit};

const EasyGraphLine=(lineData:EasyCSSGraphLineElement, ctx: CanvasRenderingContext2D,container:EasyBoundingBoxData,defaultGraphLineData?:EasyCSSGraphLineElement,debug?:boolean):[{val:number,dis:number},{val:number,dis:number},number,number,number]=>{
    const graphLineData = defaultGraphLineData?{...defaultGraphLineData,...lineData}:lineData;

    let interalValue = EasyCSSUnitToPx(graphLineData.interval,graphLineData.isVertical?container.innerBox.width:container.innerBox.height);

    const interalNumber = interalValue;
    const leftlineContainer=getBoundingBoxes(graphLineData.isVertical?{x:container.innerBox.x,y:container.paddedBox.y,height:container?.paddingTop?container.paddingTop:0,width:container.innerBox.width}:{x:container.paddedBox.x,y:container.innerBox.y,height:container.innerBox.height,width:container?.paddingLeft?container.paddingLeft:0},graphLineData.isVertical?{paddingBottom:graphLineData.paddingLeft,marginBottom:graphLineData.marginLeft,width:"100%",height:"100%"}:{paddingRight:graphLineData.paddingLeft,marginRight:graphLineData.marginLeft,width:"100%",height:"100%"})
    const rightlineContainer=getBoundingBoxes(graphLineData.isVertical?{x:container.innerBox.x,y:container.paddedBox.y+container.innerBox.height,height:container?.paddingBottom?container.paddingBottom:0,width:container.innerBox.width}:{x:container.innerBox.x+container.innerBox.width,y:container.innerBox.y,height:container.innerBox.height,width:container?.paddingRight?container.paddingRight:0},graphLineData.isVertical?{paddingTop:graphLineData.paddingRight,marginTop:graphLineData.marginRight,width:"100%",height:"100%"}:{paddingLeft:graphLineData.paddingRight,marginLeft:graphLineData.marginRight,width:"100%",height:"100%"})
    //console.log(leftlineContainer,rightlineContainer)
    if(debug) {
        ctx.fillStyle = "#ff9999"
        ctx.fillRect(leftlineContainer.viewPort.x, leftlineContainer.viewPort.y, leftlineContainer.viewPort.width, leftlineContainer.viewPort.height)
        ctx.fillStyle = "#99ff99"
        ctx.fillRect(leftlineContainer.boundingBox.x, leftlineContainer.boundingBox.y, leftlineContainer.boundingBox.width, leftlineContainer.boundingBox.height)
        ctx.fillStyle = "#9999ff"
        ctx.fillRect(leftlineContainer.paddedBox.x, leftlineContainer.paddedBox.y, leftlineContainer.paddedBox.width, leftlineContainer.paddedBox.height)
        ctx.fillStyle = "#ffff99"
        ctx.fillRect(leftlineContainer.innerBox.x, leftlineContainer.innerBox.y, leftlineContainer.innerBox.width, leftlineContainer.innerBox.height)
    }
    if(debug) {
        ctx.fillStyle = "#ff9999"
        ctx.fillRect(rightlineContainer.viewPort.x, rightlineContainer.viewPort.y, rightlineContainer.viewPort.width, rightlineContainer.viewPort.height)
        ctx.fillStyle = "#99ff99"
        ctx.fillRect(rightlineContainer.boundingBox.x, rightlineContainer.boundingBox.y, rightlineContainer.boundingBox.width, rightlineContainer.boundingBox.height)
        ctx.fillStyle = "#9999ff"
        ctx.fillRect(rightlineContainer.paddedBox.x, rightlineContainer.paddedBox.y, rightlineContainer.paddedBox.width, rightlineContainer.paddedBox.height)
        ctx.fillStyle = "#ffff99"
        ctx.fillRect(rightlineContainer.innerBox.x, rightlineContainer.innerBox.y, rightlineContainer.innerBox.width, rightlineContainer.innerBox.height)
    }
    const bottomIntervalVal=(graphLineData.startInterval?EasyCSSUnitToPx(graphLineData.startInterval,(graphLineData.isVertical?container.innerBox.width:container.innerBox.height)):0);
    const topIntervalVal=(graphLineData.topIntervalValue?EasyCSSUnitToPx(graphLineData.topIntervalValue, (graphLineData.isVertical?container.innerBox.width:container.innerBox.height)):(graphLineData.isVertical?container.innerBox.width:container.innerBox.height)) - bottomIntervalVal;
  console.log(topIntervalVal,bottomIntervalVal,interalValue,"inta")
    if(graphLineData.snapTopClosestInterval||graphLineData.topIntervalValue||graphLineData.startInterval){
        interalValue = ((graphLineData.isVertical?container.innerBox.width:container.innerBox.height)/(graphLineData.snapTopClosestInterval?Math.ceil( topIntervalVal/interalValue):(topIntervalVal/interalValue)));
    }
    console.log(topIntervalVal,bottomIntervalVal,interalValue,"inta")
    const subInteralNumber=EasyCSSUnitToPx(graphLineData.subInterval,interalValue);
    const subInteralValue=interalValue*EasyCSSUnitToPx(graphLineData.subInterval,interalValue)/interalNumber;
    let count = 0;
    const drawlineOn=(i:number,isSubLine?:boolean)=>{
            if (graphLineData.isVertical) {
                if (graphLineData.underGraph || (!graphLineData.subOverGraph&&isSubLine)) {
                    ctx.beginPath()
                    ctx.moveTo(i, leftlineContainer.innerBox.y + leftlineContainer.innerBox.height)
                    ctx.lineTo(i, leftlineContainer.boundingBox.y + leftlineContainer.boundingBox.height)
                    ctx.stroke()
                    ctx.beginPath()
                    ctx.moveTo(i, rightlineContainer.boundingBox.y)
                    ctx.lineTo(i, rightlineContainer.boundingBox.y + (rightlineContainer?.paddingTop ? rightlineContainer.paddingTop : 0) + (rightlineContainer?.marginTop&&!isSubLine ? rightlineContainer.marginTop : 0))
                    ctx.stroke()
                } else {
                    ctx.moveTo(i, leftlineContainer.innerBox.y + leftlineContainer.innerBox.height)
                    ctx.lineTo(i, rightlineContainer.boundingBox.y + (rightlineContainer?.paddingTop ? rightlineContainer.paddingTop : 0) + (rightlineContainer?.marginTop&&!isSubLine ? rightlineContainer.marginTop : 0))
                    ctx.stroke()
                }
            } else {
                if (graphLineData.underGraph || (!graphLineData.subOverGraph&&isSubLine)) {
                    ctx.beginPath()
                    ctx.moveTo(leftlineContainer.innerBox.x + leftlineContainer.innerBox.width+(leftlineContainer?.marginRight&&isSubLine?(leftlineContainer.marginRight):0), i)
                    ctx.lineTo(leftlineContainer.boundingBox.x + leftlineContainer.boundingBox.width, i)
                    ctx.stroke()
                    ctx.beginPath()
                    ctx.moveTo(rightlineContainer.boundingBox.x, i)
                    ctx.lineTo(rightlineContainer.boundingBox.x + (rightlineContainer?.paddingLeft ? rightlineContainer.paddingLeft : 0) + ((rightlineContainer?.marginLeft&&!isSubLine) ? rightlineContainer.marginLeft : 0), i)
                    ctx.stroke()
                } else {
                    ctx.moveTo(leftlineContainer.innerBox.x + leftlineContainer.innerBox.width, i)
                    ctx.lineTo(rightlineContainer.boundingBox.x + (rightlineContainer?.paddingLeft ? rightlineContainer.paddingLeft : 0) + (rightlineContainer?.marginLeft&&!isSubLine ? rightlineContainer.marginLeft : 0), i)
                    ctx.stroke()
                }
            }
    }

    for(let i =graphLineData.isVertical?(container.innerBox.x):(container.innerBox.y+container.innerBox.height);graphLineData.isVertical?(Math.round(i) <= container.innerBox.x+container.innerBox.width):(Math.round(i) >= container.innerBox.y);graphLineData.isVertical?(i+=interalValue):(i-=interalValue)) {
        ctx.strokeStyle=graphLineData.lineColor?graphLineData.lineColor:"#000000"
        ctx.fillStyle=graphLineData.lineColor?graphLineData.lineColor:"#000000"
        drawlineOn(i)
        if(((graphLineData.isVertical?(Math.round(i) <= Math.round(container.innerBox.x+container.innerBox.width-interalValue)):(i!=(container.innerBox.y+container.innerBox.height))))&&graphLineData.subInterval){for(let v =graphLineData.isVertical?(i):(i+interalValue);graphLineData.isVertical?(v<i+interalValue):(v>i);graphLineData.isVertical?(v+=subInteralValue):(v-=subInteralValue)){
            drawlineOn(v,true)
        }}


        if(graphLineData.intervalType) {
            let textString=undefined;
            switch (graphLineData.intervalType) {
                case "fixed":textString=String((interalNumber*count)+bottomIntervalVal);break;
                case "percent":textString=`${(interalValue*count + bottomIntervalVal)*100/(graphLineData.isVertical?container.innerBox.width:container.innerBox.height)}%`;break;
            }
            if(textString){
                let yAlignOffset:EasyCSSUnit=interalValue / 2;
                if(graphLineData?.textStyle && typeof graphLineData.textStyle !='string') {
                    if(graphLineData.isVertical) {
                        switch (graphLineData.textStyle?.textAlign) {
                            case "center":
                                yAlignOffset = interalValue / 2;
                                break;
                            case "left":
                                yAlignOffset = 0;
                                break;
                            case "right":
                                yAlignOffset = interalValue;
                                break;
                        }
                    }else{
                        switch (graphLineData.textStyle?.textBaseLine) {
                            case "middle":
                                yAlignOffset = interalValue / 2;
                                break;
                            case "top":
                                yAlignOffset =0;
                                break;
                            case "bottom":
                                yAlignOffset = interalValue;
                                break;
                        }
                    }
                }

                EasyGraphicText(graphLineData.textStyle,ctx,graphLineData.isVertical?{x:i-yAlignOffset,y:rightlineContainer.innerBox.y,height:rightlineContainer.innerBox.height,width:interalValue}:{x:leftlineContainer.innerBox.x,y:i-yAlignOffset,height:interalValue,width:leftlineContainer.innerBox.width},{title:graphLineData.LabelStrings&&graphLineData.LabelStrings[count]?graphLineData.LabelStrings[count]:textString,fontColor:ctx.strokeStyle,font:"10px Ariel",textAlign:graphLineData.isVertical?"center":"right",textBaseLine:graphLineData.isVertical?"top":"middle",containerStyle:graphLineData.isVertical?{width:"100%",height:"100%"}:{width:"100%",paddingRight:"2px",height:"100%"}},debug)
            }
        }
        count++;
    }
    return [{dis:interalNumber,val:interalValue},{dis:subInteralNumber,val:subInteralValue},interalValue/interalNumber,topIntervalVal+bottomIntervalVal,bottomIntervalVal]
}

interface EasyGraphicGraphProps {
    xLabel?: EasyCSSTextElement;
    yLabel?: EasyCSSTextElement;
    horizontalLines?: EasyCSSGraphLineElement;
    verticalLines?: EasyCSSGraphLineElement;
    containerStyle?: EasyGraphicElementContainerProps;
    graphColor?: string | CanvasGradient | CanvasPattern;


}

export const EasyGraphicGraph = (_props: EasyGraphicGraphProps) => {
    return <></>
}
EasyGraphicGraph.defaultProps = {
    drawFunction: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, _props: EasyGraphicGraphProps,debug?:boolean) => {
   EasyGraphDefaultFunction(ctx,canvas,_props) ;
    }
}

interface EasyGraphicHistogramProps extends EasyGraphicGraphProps {
    data?:{[key:string]:number|number[]}
    barWidth?:EasyCSSUnit;
    stacking?:boolean;
    barColor?: (string | CanvasGradient | CanvasPattern)|(string | CanvasGradient | CanvasPattern)[];
    axis?:"x"|"y";
    barOffset?:EasyCSSUnit;
    valueType?:"axisVal"|"index";
    strokeColor?:(string | CanvasGradient | CanvasPattern);

}

export const EasyGraphicHistogram = (_props: EasyGraphicHistogramProps) => {
    return <></>
}

EasyGraphicHistogram.defaultProps = {
    drawFunction: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, _props: EasyGraphicHistogramProps,debug?:boolean) => {
        const barBackroundColorStyle: string | CanvasGradient | CanvasPattern = "#883b65"
        const objects =_props.data?Object.entries(_props.data):[];


        const [graphContainer,valueScaling,interval,subInterval,intervalLimits]=EasyGraphDefaultFunction(ctx,canvas,_props,debug)


        const len=((objects[0][1]&&Array.isArray(objects[0][1])&&objects[0][1].length)?(objects[0][1].length):(1));
        for(let j = 0;j<(_props.stacking?1:len);j++) {
            //console.log(j)

            let count = 0;
            let usedCount = 0;
            let currentDisplayVal = intervalLimits.x.min
            const barWidthValue = EasyCSSUnitToPx(_props.barWidth?_props.barWidth:"100%",subInterval.x.val ? subInterval.x.val : interval.x.val);
           const barOffsetValue= EasyCSSUnitToPx(_props.barOffset,subInterval.x.val ? subInterval.x.val : interval.x.val);
            for (let i = graphContainer.innerBox.x; Math.round(i) <= graphContainer.innerBox.x + graphContainer.innerBox.width; i += subInterval.x.val ? subInterval.x.val : interval.x.val) {
                if(!_props.stacking) {
                    if (Array.isArray(_props.barColor) && _props.barColor[j]) {
                        ctx.fillStyle = _props.barColor[j]
                        console.log(ctx.fillStyle)
                    } else if (!Array.isArray(_props.barColor) && _props.barColor) {
                    } else {
                        ctx.fillStyle = `rgba(255, ${j * 255 / len}, ${(len - j) * 255 / len}, ${1 / len})`
                    }
                }

                ctx.strokeStyle = _props.strokeColor?_props.strokeColor:"#000000"
                //console.log("test", objects[count] ? objects[count][1] : "unknow");
                // @ts-ignore
                const data = _props.data[`${currentDisplayVal}`];
                //console.log(objects[count],count,i,i+interval.x.val)
                if (data) {
                    ctx.beginPath();
                    // @ts-ignore
                    const value = data;
                    // @ts-ignore
                    //console.log(_props.data[`${currentDisplayVal}`],currentDisplayVal,graphContainer.innerBox.x + count * subInterval.x.val)
                    //console.log(value)
                    if(_props.stacking){
                        if (Array.isArray(value)) {
                            let currentVal=0;
                            for(let k = 0;k<len;k++){

                            if (Array.isArray(_props.barColor) && _props.barColor[k]) {
                                ctx.fillStyle = _props.barColor[k]
                                console.log(ctx.fillStyle)
                            } else if (!Array.isArray(_props.barColor) && _props.barColor) {
                            } else {
                                ctx.fillStyle = `rgba(255, ${k * 255 / len}, ${(len - k) * 255 / len}, ${1 / len})`
                            }
                                ctx.beginPath();
                                ctx.rect(graphContainer.innerBox.x + count * (subInterval.x.val ? subInterval.x.val : interval.x.val) + j * barOffsetValue, graphContainer.innerBox.y + graphContainer.innerBox.height + currentVal, barWidthValue, -valueScaling.y * value[k])
                                ctx.fill();
                                ctx.stroke();
                                ctx.closePath();

                                currentVal-=valueScaling.y * value[k]
                            }
                                //@ts-ignore
                            //console.log(j)
                        } else {
                            ctx.rect(graphContainer.innerBox.x + count * (subInterval.x.val ? subInterval.x.val : interval.x.val) + j * barOffsetValue, graphContainer.innerBox.y + graphContainer.innerBox.height, barWidthValue, -valueScaling.y * value)
                        }
                    }else {
                        console.log("test")
                        if (Array.isArray(value)) {
                            ctx.rect(graphContainer.innerBox.x + count * (subInterval.x.val ? subInterval.x.val : interval.x.val) + j * barOffsetValue, graphContainer.innerBox.y + graphContainer.innerBox.height, barWidthValue, -valueScaling.y * value[j])
                            //@ts-ignore
                            //console.log(j)
                        } else {
                            ctx.rect(graphContainer.innerBox.x + count * (subInterval.x.val ? subInterval.x.val : interval.x.val) + j * barOffsetValue, graphContainer.innerBox.y + graphContainer.innerBox.height, barWidthValue, -valueScaling.y * value)
                        }
                    }
                    ctx.fill();
                    ctx.stroke();
                    ctx.closePath();
                    usedCount++;
                }
                //ctx.fillStyle=barColorStyle
                //ctx.fillRect(bIndex*barTotalSpace+barAlignToOffset+barOffset.x,Math.max(0,-barOffset.y)+maxHeightOffset+2,barWidth,maxGraphSize.height-bar[1]-maxHeightOffset-barOffset.y);
                count++;
                currentDisplayVal += (subInterval.x.dis ? subInterval.x.dis : interval.x.dis);
            }


        }
    }
}

interface EasyGraphicLineGraphProps extends EasyGraphicGraphProps {
    data?:{[key:string]:number|number[]}
    lineColor?: (string | CanvasGradient | CanvasPattern)|(string | CanvasGradient | CanvasPattern)[];
    axis?:"x"|"y";
    strokeColor?:(string | CanvasGradient | CanvasPattern);
}

export const EasyGraphicLineGraph = (_props: EasyGraphicLineGraphProps) => {
    return <></>
}
EasyGraphicLineGraph.defaultProps = {
    drawFunction: (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, _props: EasyGraphicLineGraphProps,debug:boolean) => {
       const objects =_props.data?Object.entries(_props.data):[];


        const [graphContainer,valueScaling,interval,subInterval,intervalLimits]=EasyGraphDefaultFunction(ctx,canvas,_props,debug)


        const len=((objects[0][1]&&Array.isArray(objects[0][1])&&objects[0][1].length)?(objects[0][1].length):(1));
            for(let j = 0;j<len;j++) {
                let started = false;
                let ended = false;
            //console.log(j)
            ctx.beginPath();
            let count = 0;
                let usedCount = 0;
            let currentDisplayVal=intervalLimits.x.min
            for (let i = graphContainer.innerBox.x; Math.round(i) <= graphContainer.innerBox.x + graphContainer.innerBox.width; i += subInterval.x.val?subInterval.x.val:interval.x.val) {
                if(Array.isArray(_props.lineColor)&&_props.lineColor[j]) {
                    ctx.fillStyle =_props.lineColor[j]

                }else if(!Array.isArray(_props.lineColor)&&_props.lineColor){
                    ctx.fillStyle = _props.lineColor;
                }else{
                    ctx.fillStyle = `rgba(255, ${j * 255 / len}, ${(len - j) * 255 / len}, ${1 / len})`
                }
                ctx.strokeStyle = _props.strokeColor?_props.strokeColor:"#000000"
                //console.log("test", objects[count] ? objects[count][1] : "unknow");
                // @ts-ignore
                const data =_props.data[`${currentDisplayVal}`];
                //console.log(objects[count],count,i,i+interval.x.val)
                if (data) {
                    if(!started){
                        ctx.lineTo(graphContainer.innerBox.x + count * (subInterval.x.val?subInterval.x.val:interval.x.val), graphContainer.innerBox.y + graphContainer.innerBox.height)
                        started = true;
                    }
                    // @ts-ignore
                    const value = data;
                    // @ts-ignore
                    //console.log(_props.data[`${currentDisplayVal}`],currentDisplayVal,graphContainer.innerBox.x + count * subInterval.x.val)
                    //console.log(value)
                    if (Array.isArray(value)) {
                        ctx.lineTo(graphContainer.innerBox.x + count * (subInterval.x.val?subInterval.x.val:interval.x.val), graphContainer.innerBox.y + graphContainer.innerBox.height - valueScaling.y * value[j])
                    //@ts-ignore
                        console.log(j)
                    } else {
                        ctx.lineTo(graphContainer.innerBox.x + count * (subInterval.x.val?subInterval.x.val:interval.x.val), graphContainer.innerBox.y + graphContainer.innerBox.height - valueScaling.y * value)
                    }
                    usedCount++;
                }else{
                    if(!ended&&usedCount>objects.length){
                        ctx.lineTo(graphContainer.innerBox.x + count * (subInterval.x.val?subInterval.x.val:interval.x.val), graphContainer.innerBox.y + graphContainer.innerBox.height)
                        ended = true;
                    }
                }
                //ctx.fillStyle=barColorStyle
                //ctx.fillRect(bIndex*barTotalSpace+barAlignToOffset+barOffset.x,Math.max(0,-barOffset.y)+maxHeightOffset+2,barWidth,maxGraphSize.height-bar[1]-maxHeightOffset-barOffset.y);
                count++;
                currentDisplayVal+=(subInterval.x.dis?subInterval.x.dis:interval.x.dis);
            }
            if(!ended) {
                ctx.lineTo(graphContainer.innerBox.x + graphContainer.innerBox.width, graphContainer.innerBox.y + graphContainer.innerBox.height)
            }
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
        }


    }
}
const EasyGraphDefaultFunction = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, _props: EasyGraphicGraphProps,debug?:boolean):[EasyBoundingBoxData,{x:number,y:number},{x:{dis:number,val:number},y:{dis:number,val:number}},{x:{dis:number,val:number},y:{dis:number,val:number}},{x:{max:number,min:number},y:{max:number,min:number}}] => {
    const container = getBoundingBoxes({height: canvas.clientHeight, width: canvas.clientWidth, x: 0, y: 0}, _props.containerStyle)
    const valueScaling={x:1,y:1};
    const interval={x:{dis:0,val:0},y:{dis:0,val:0}}
    const subInterval={x:{dis:0,val:0},y:{dis:0,val:0}}
    const intervalLimits={x:{max:0,min:0},y:{max:0,min:0}}
    if(debug) {
        ctx.fillStyle = "#ff9999"
        ctx.fillRect(container.viewPort.x, container.viewPort.y, container.viewPort.width, container.viewPort.height)
        ctx.fillStyle = "#99ff99"
        ctx.fillRect(container.boundingBox.x, container.boundingBox.y, container.boundingBox.width, container.boundingBox.height)
        ctx.fillStyle = "#9999ff"
        ctx.fillRect(container.paddedBox.x, container.paddedBox.y, container.paddedBox.width, container.paddedBox.height)
        ctx.fillStyle = "#ffff99"
        ctx.fillRect(container.innerBox.x, container.innerBox.y, container.innerBox.width, container.innerBox.height)
    }
    ctx.fillStyle = _props.graphColor?_props.graphColor:"#c0c0c0"
    ctx.fillRect(container.innerBox.x, container.innerBox.y, container.innerBox.width, container.innerBox.height)
    if (_props.xLabel) {
        EasyGraphicText(_props.xLabel,ctx,{height: (container?.marginBottom) ? container.marginBottom : 0, width: container.innerBox.width, x: container.innerBox.x, y: container.paddedBox.y + container.paddedBox.height},"",debug)
    }
    if (_props.yLabel) {
        EasyGraphicText(_props.yLabel,ctx,{height: container.innerBox.height, width: container?.marginLeft?container.marginLeft:0, x: container.boundingBox.x, y: container.innerBox.y},{title:"",rotation:-90,containerStyle:{height:"100%",padding:10,margin:10,width:"100%"}},debug)
    }
    if(_props.horizontalLines){
        const [intervalY,subIntervalY,valueScaleY,maxY,minY]=EasyGraphLine(_props.horizontalLines,ctx,container,{},debug)
        interval.y=intervalY;
        subInterval.y=subIntervalY;
        valueScaling.y=valueScaleY;
        intervalLimits.y={max:maxY,min:minY};
    }
    if(_props.verticalLines){
        const [intervalX,subIntervalX,valueScaleX,maxX,minX]=EasyGraphLine(_props.verticalLines,ctx,container,{isVertical:true},debug)
        interval.x=intervalX;
        subInterval.x=subIntervalX;
        valueScaling.x=valueScaleX;
        intervalLimits.x={max:maxX,min:minX};
    }
        return [container,valueScaling,interval,subInterval,intervalLimits];
}

const getAllParents = (pathName: string, MainPathName: string): { pathName: string, isModule: boolean } | { pathName: string, isModule: boolean }[] => {
    if (pathName == MainPathName) {
        console.log(pathName, true)
        const outData = {pathName: pathName, isModule: true}
        let inData = nameTranslator['/' + pathName]
        // @ts-ignore
        const myModule: tblLdlModule | undefined = LDLallowedModules[pathName];
        if (myModule?.GeogID) {
            if (!inData) {
                inData = {IconContentRef: 0, MainTab: "", ShortName: "", TabDisplay: "", TabName: "", DisplayName: ""}
            }
            inData["UnSeenParent"] = "/Faunas"
        }
        if (inData && inData.UnSeenParent) {
            console.log(pathName, true, "a")
            const parentName = inData.UnSeenParent.slice(1);
            const relatives: { pathName: string, isModule: boolean } | { pathName: string, isModule: boolean }[] = getAllParents(parentName, parentName);
            console.log(pathName, true, "b", parentName, relatives)
            if (Array.isArray(relatives)) {
                relatives.push(outData)
                console.log(pathName, true, "c", parentName, relatives)
                return relatives;
            } else {
                return [relatives, outData]
            }
        }
        return outData;
    }
    console.log(pathName, false)
    return {pathName: pathName, isModule: false}

}
export type PathData = { [p: string]: PathDataElement };

export interface PathDataElement {
    UpperLeftModuleName?: string | JSX.Element,
    UpperRightModuleName?: string | JSX.Element,
    BottomModuleName?:string | JSX.Element;
    TabText?: string,
    MainIconID?: number,
    PathLink?: string
}

export const InitPathData = (currentPath: string, oldPathData?: PathData): PathData => {
    const paths = currentPath.split('/').slice(1);
    const MainPathName = paths[0];
    const oldPathDataEntries = oldPathData ? (Object.entries(oldPathData)) : undefined
    let currentPathList = `/${MainPathName}`
    console.log(paths, MainPathName)
    const pathDataEntries = paths.flatMap((pathName) => getAllParents(pathName, MainPathName));
    console.log(pathDataEntries)
    const outPathDataEntries = pathDataEntries.map((pathModule, index) => {
        //@ts-ignore
        const myModule: tblLdlModule | undefined = pathModule.isModule ? LDLallowedModules[pathModule.pathName] : LDLallowedModules[MainPathName];
        let myNameTranslator = pathModule.isModule ? nameTranslator['/' + pathModule.pathName] : nameTranslator['/' + MainPathName];
        if (myModule?.GeogID && !myNameTranslator) {
            if (!myNameTranslator) {
                myNameTranslator = {
                    IconContentRef: myModule.MainIconID ? myModule.MainIconID : 81,
                    MainTab: "/Main",
                    ShortName: "",
                    TabDisplay: `%icon% &/${pathModule.isModule ? pathModule.pathName : MainPathName}&`,
                    TabName: myModule.TabText ? myModule.TabText : "",
                    DisplayName: myModule.UpperRightModuleName ? myModule.UpperRightModuleName : "",
                    SubLinks: {
                        "/Main": "#INHERIT#",
                        "/DefineFauna": {
                            "TabName": "Define Fauna",
                            "TabDisplay": "&/DefineFauna&"
                        },
                        "/Results": {
                            "TabName": "Faunal List",
                            "TabDisplay": "%/Results% &/Results&",
                            "IconContentRef": myModule.MainIconID ? (myModule.MainIconID + 1) : 81
                        }

                    }
                }
            }

        }
        const inData: { "DisplayName"?: string, "TabName": string, "TabDisplay": string, "ShortName"?: string, "IconContentRef"?: number, "MainTab"?: string, "UnSeenParent"?: string, "SubLinks"?: { [key: string]: { "TabName": string, "TabDisplay": string, "IconContentRef"?: number, "ShortName"?: string } | string } } | string | undefined = (pathModule.isModule ? (myNameTranslator) : (myNameTranslator?.SubLinks?.['/' + pathModule.pathName]));
        const outData: PathDataElement = {};
        if (inData) {
            if (typeof inData == "object") {
                outData.UpperLeftModuleName = inData.TabDisplay.replaceAll(`&/${pathModule.pathName}&`, inData.TabName).replaceAll(`%/${pathModule.pathName}%`, "").trim()
                if (pathModule.isModule) {
                    outData.PathLink = `/${pathModule.pathName}${inData.MainTab}`
                    outData.UpperRightModuleName = inData.DisplayName?.replaceAll(`$/${pathModule.pathName}$`, inData.ShortName ? inData.ShortName : "")?.replaceAll(`%/${pathModule.pathName}%`, `%icon%`).trim()
                    outData.TabText = inData.TabName;
                } else {
                    outData.PathLink = `${currentPathList}/${pathModule.pathName}`
                    currentPathList = outData.PathLink;
                }
                if (inData.IconContentRef) {
                    outData.MainIconID = inData.IconContentRef;
                }
            } else {
                outData.PathLink = "%NOT A REAL PATH%"
            }
        } else {
            outData.UpperRightModuleName = pathModule.pathName;
            outData.UpperLeftModuleName = pathModule.pathName;
            outData.TabText = pathModule.pathName;
            if(pathModule.isModule){
                outData.BottomModuleName=`${pathModule.pathName} (${pathModule.pathName})`;
            }
            outData.PathLink = `${currentPathList}/${pathModule.pathName}`;
            currentPathList = outData.PathLink;
        }
        if (pathModule.isModule && myModule) {
            outData.MainIconID = myModule.MainIconID;
            outData.TabText = myModule.TabText;
            outData.UpperLeftModuleName = myModule.UpperLeftModuleName;
            outData.UpperRightModuleName = myModule.UpperRightModuleName;
            outData.BottomModuleName=myModule.BottomModuleName;
        }

        if (oldPathDataEntries && oldPathDataEntries[index] && oldPathDataEntries[index][0] == pathModule.pathName) {
            oldPathDataEntries[index][1].PathLink = outData.PathLink
            return [pathModule.pathName, {...oldPathDataEntries[index][1]}];
        }
        return [pathModule.pathName, outData]
    }).filter(([pathName, pathData]) => (typeof pathData == "object" ? (pathData?.PathLink != "%NOT A REAL PATH%") : true))
    //@ts-ignore
    outPathDataEntries[outPathDataEntries.length - 1][1]["PathLink"] = undefined;

    return Object.fromEntries(outPathDataEntries)
}

export const UpdatePathData = (newPathData?: PathData, oldPathData?: PathData) => {
    if (oldPathData && newPathData) {
        const tempPathData = {...oldPathData};
        Object.entries(newPathData).forEach(([pathName, pathData]) => {
            tempPathData[pathName] = {...tempPathData[pathName], ...pathData};
        })
        return tempPathData;
    } else if (newPathData) {
        return newPathData;
    }
    return oldPathData;
}
export const UpperLeftNav = (_props: { currentPathData?: PathData }) => {
    const location = useLocation();
    return <>{_props.currentPathData ? Object.entries(_props.currentPathData).map(([pathName, pathData], index, array) => {
        const displayData = <>{(pathData.MainIconID && pathData.MainIconID != LDLIcons.General_DefaultIcon) &&
            <Icon IconID={pathData.MainIconID}/>} {pathData.UpperLeftModuleName}</>
        return <>{pathData.PathLink ? <Link to={pathData.PathLink}
                                            state={location.state}>{displayData}</Link> : displayData}{index != array.length - 1 && " > "}</>;
    }) : undefined}</>;


}
export const UpperRightNav = (_props: { currentPathData?: PathData }) => {
    const location = useLocation();
    const paths = location.pathname.split('/').slice(1);
    const pathData = _props.currentPathData ? _props.currentPathData[paths[0]] : undefined;
    //console.log(_props.currentPathData)
    return <>{pathData ? <>{typeof pathData.UpperRightModuleName == "object" ? pathData.UpperRightModuleName : `%icon%${pathData.UpperRightModuleName}`.split('%icon%').slice(1).flatMap((val, index, array) => <>{val}{(index != array.length - 1 && pathData.MainIconID && pathData.MainIconID != LDLIcons.General_DefaultIcon) && <>{pathData.PathLink ?
        <IconLink HistoryLocStates={location.state} href={pathData.PathLink} IconID={pathData.MainIconID}/> :
        <Icon IconID={pathData.MainIconID}/>}</>}</>)}</> : undefined}</>;


}
export const BottomFooterName = (_props: { currentPathData?: PathData }) => {
    const location = useLocation();
    const paths = location.pathname.split('/').slice(1);
    const pathData = _props.currentPathData ? _props.currentPathData[paths[0]] : undefined;
    //console.log(_props.currentPathData)
    return <>{pathData ? <>{typeof pathData.BottomModuleName == "object" ? pathData.BottomModuleName : `%icon%${pathData.BottomModuleName}`.split('%icon%').slice(1).flatMap((val, index, array) => <>{val}{(index != array.length - 1 && pathData.MainIconID && pathData.MainIconID != LDLIcons.General_DefaultIcon) && <>{pathData.PathLink ?
        <IconLink HistoryLocStates={location.state} href={pathData.PathLink} IconID={pathData.MainIconID}/> :
        <Icon IconID={pathData.MainIconID}/>}</>}</>)}</> : undefined}</>;


}

const JSXToString = (element: JSX.Element | undefined) => {
    if (element && element?.props?.children) {
        if (Array.isArray(element.props.children)) {
            return element.props.children.flatMap(JSXToString)
        } else {
            return element.props.children
        }
    } else {
        return element
    }
}

export const PathTitle = (_props: { currentPathData?: PathData }) => {
    const location = useLocation();
    const paths = location.pathname.split('/').slice(1);
    //console.log(_props.currentPathData)
    const pathDataEntries = _props.currentPathData ? Object.entries(_props.currentPathData) : undefined
    const MainIndex = pathDataEntries ? pathDataEntries.findIndex((val) => val[0] == paths[0]) : -1
    return <>{pathDataEntries ? pathDataEntries.filter((val, index, array) => (index == array.length - 1 || index == (MainIndex == -1 ? 0 : MainIndex))).map(([pathName, pathData], index, array) => <>{index == 0 ? pathData.TabText : (typeof pathData.UpperLeftModuleName == 'string' ? pathData.UpperLeftModuleName : JSXToString(pathData.UpperLeftModuleName))}{index != array.length - 1 && " - "}</>) : undefined}</>;


}
export const PathTitleIcon = (_props: { currentPathData?: PathData }) => {
    const location = useLocation();
    const paths = location.pathname.split('/').slice(1);
    const pathData = _props.currentPathData ? _props.currentPathData[paths[0]] : undefined;
    //console.log(pathData,paths,_props.currentPathData)
    useEffect(() => {
        const element = document.getElementById("website_icon") as HTMLLinkElement | null;
        if (element) {
            //@ts-ignore
            element.href = `${(pathData?.MainIconID) ? (Icons[pathData?.MainIconID].IconLink) : "/%PUBLIC_URL%/favicon.jpg"}`
            //@ts-ignore
            element.title = pathData?.MainIconID ? Icons[pathData?.MainIconID].DefaultTooltip : "LDL Icon";
        }
    }, [pathData]);

    //@ts-ignore
    return <></>;

}

