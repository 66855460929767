import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI, {callGETAPI} from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasySearch, EasySearchField, EasySearchSection, EasySearchSelect,
    EasyTable,
    EasyTableElement, EqFilter, IconLink,
    ParagraphDesc,
    Record,
    RemoveIfLastSame, SelectFromArray,
    TableLegendElement,
    TableLegendSection
} from "../../utils/CustomRecord";
import {tblLdlSpCat} from "../../../../database/models/tblLdlSpCat";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import SpeciesFamilies from "../../utils/OnServerStartLoadedJsons/SpeciesFamilies.json";


const Species_Families = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();
    const [HCFami,setHCFami]=useState("$");
    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlSpCat[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getSpCatFamilies",{HCFami:HCFami}, (error: boolean, outData: tblLdlSpCat[] | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, [HCFami]);
        const getCountFromString=(str:string|undefined,count:number[][],index:number)=>{
            if(str&&str.includes("H")) {
                count[index][0]++
            }
            if(str&&str.includes("†")) {
                count[index][1]++
            }
        }
        const [counts,setCounts]=useState<number[][]>()
    useEffect(() => {
        const counts = [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]];
        const uniqueNames:{[key:string]:boolean}[]=[{},{},{},{},{},{},{},{},{}]
        resultData?.forEach((val)=>{
            if(val.HCFami&&!(uniqueNames[0][val.HCFami])) {
                getCountFromString(val.HCFamiSymb, counts, 0);
                uniqueNames[0][val.HCFami]=true;
            }
            if(val.HCSubf&&!(uniqueNames[1][val.HCSubf])) {
                getCountFromString(val.HCSubfSymb, counts, 1);
                uniqueNames[1][val.HCSubf]=true;
            }
            if(val.HCTrib&&!(uniqueNames[2][val.HCTrib])) {
                getCountFromString(val.HCTribSymb, counts, 2);
                uniqueNames[2][val.HCTrib]=true;
            }
            if(val.HCSubt&&!(uniqueNames[3][val.HCSubt])) {
                getCountFromString(val.HCSubtSymb, counts, 3);
                uniqueNames[3][val.HCSubt]=true;
            }
            if(val.HCGenu&&!(uniqueNames[4][val.HCGenu])) {
                getCountFromString(val.HCGenuSymb, counts, 4);
                uniqueNames[4][val.HCGenu]=true;
            }
            if(val.HCSubg&&!(uniqueNames[5][val.HCSubg])) {
                getCountFromString(val.HCSubgSymb, counts, 5);
                uniqueNames[5][val.HCSubg]=true;
            }
            if(val.HCSpgr&&!(uniqueNames[6][val.HCSpgr])) {
                getCountFromString(val.HCSpgrSymb, counts, 6);
                uniqueNames[6][val.HCSpgr]=true;
            }
            if(val.HCSpec&&!(uniqueNames[7][val.HCSpec])) {
                getCountFromString(val.HCSpecSymb, counts, 7);
                uniqueNames[7][val.HCSpec]=true;
            }
            if(val.HCSubs&&!(uniqueNames[8][val.HCSubs])) {
                getCountFromString(val.HCSubsSymb, counts, 8);
                uniqueNames[8][val.HCSubs]=true;
            }

        })
        setCounts(counts)
    }, [resultData]);
        return <>
        <Record title={"Families"}>
            <ParagraphDesc>
            </ParagraphDesc>

            <EasyTable   downloadable={true} downloadName={`LDL_Species_Families_Table_Family_${HCFami=="$"?"[not assigned to family]":HCFami}.csv`} fixedBottomRowData={counts?{HCFami:<>{counts[0][0]}<sup><i>H</i></sup>, {counts[0][1]}<sup><i>†</i></sup></>,HCSubf:<>{counts[1][0]}<sup><i>H</i></sup>, {counts[1][1]}<sup><i>†</i></sup></>,HCTrib:<>{counts[2][0]}<sup><i>H</i></sup>, {counts[2][1]}<sup><i>†</i></sup></>,HCSubt:<>{counts[3][0]}<sup><i>H</i></sup>, {counts[3][1]}<sup><i>†</i></sup></>,HCGenu:<>{counts[4][0]}<sup><i>H</i></sup>, {counts[4][1]}<sup><i>†</i></sup></>,HCSubg:<>{counts[5][0]}<sup><i>H</i></sup>, {counts[5][1]}<sup><i>†</i></sup></>,HCSpgr:<>{counts[6][0]}<sup><i>H</i></sup>, {counts[6][1]}<sup><i>†</i></sup></>,HCSpec:<>{counts[7][0]}<sup><i>H</i></sup>, {counts[7][1]}<sup><i>†</i></sup></>,HCSubs:<>{counts[8][0]}<sup><i>H</i></sup>, {counts[8][1]}<sup><i>†</i></sup></>}:{}} caption={<>Table 00. Classifications of the Neuropterida Families of the World.&nbsp;&nbsp;Select a Family: <EasySearch<tblLdlSpCat> searchOnChange={true} onSearch={(val)=>console.log(
               //@ts-ignore
                setHCFami(val.apiData[0].HCFami))} inLineSearch={true}>
                <EasySearchSection>
                    <EasySearchField feildFunction={(val)=>EqFilter<tblLdlSpCat>(val,"HCFami")} title={"Select a Family"}>
                        <EasySearchSelect options={SpeciesFamilies.map((val)=>({val:val,dis:val=="$"?"[not assigned to family]":val}))}/>
                    </EasySearchField>
                </EasySearchSection>
            </EasySearch> Record Count ({resultData?.length})</>} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData} extraLegendChildren={<>
                <TableLegendSection title={"Symbols"}>
                    <TableLegendElement title={<sup><i>H</i></sup>}
                                        element={<>taxon contains only living species, or both living species and
                                            species that became extinct (i.e., are last documented by specimens that
                                            died) during the Holocene (i.e., ‘Recent’ species)</>}/>
                    <TableLegendElement title={<sup><i>†</i></sup>}
                                        element={<>taxon contains only species that became extinct (i.e., are last
                                            documented by specimens that died) prior to the Holocene (i.e., ‘fossil’
                                            species)</>}/>
                    <TableLegendElement title={<sup><i>H†</i></sup>}
                                        element={<>taxon contains one or more ‘Recent’ species and one or more ‘fossil’
                                            species</>}/>
                </TableLegendSection>
                <TableLegendSection title={"Notes"}>
                    <>For the purposes of this work, a ‘fossil’ is defined as the remains (cast, impression, mineral
                        replacement, etc.) of an organism (or any of its parts) that is believed to have died prior to
                        the beginning of the Holocene Epoch (i.e., before 0.0118 Ma; Geologic Time Scale 2012, Gradstein
                        et al. 2012). This table is designed to provide detailed information on the classifications used in the NSW for each recognized family of the superorder Neuropterida.
                    </>
                </TableLegendSection>
            </>}>
                <EasyTableElement  title={"Family"} removeBottomLineIfEmpty={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCFami == "string" ? <>{val.HCFami != "$" ? <>{val.HCFami}<sup><i>{val.HCFamiSymb}</i></sup></>:"[not assigned to family]"}</>:<>{val.HCFami}</>}</>)}/>
                <EasyTableElement  title={"Subfamily"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCSubf == "string" ? <>{val.HCSubf != "$" && <>{val.HCSubf}<sup><i>{val.HCSubfSymb}</i></sup></>}</>:<>{val.HCSubf}</>}</>)}/>
                <EasyTableElement title={"Tribe"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCTrib == "string" ? <>{val.HCTrib != "$" && <>{val.HCTrib}<sup><i>{val.HCTribSymb}</i></sup></>}</>:<>{val.HCTrib}</>}</>)}/>
                <EasyTableElement title={"Subtribe"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCSubt == "string" ? <>{val.HCSubt != "$" && <>{val.HCSubt}<sup><i>{val.HCSubtSymb}</i></sup></>}</>:<>{val.HCSubt}</>}</>)}/>
                <EasyTableElement title={"Genus"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCGenu == "string" ? <>{val.HCGenu != "$" && <i>{val.HCGenu}<sup><i>{val.HCGenuSymb}</i></sup></i>}</>:<>{val.HCGenu}</>}</>)}/>
                <EasyTableElement title={"Subgenus"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCSubg == "string" ? <>{val.HCSubg != "$" && <i>{val.HCSubg}<sup><i>{val.HCSubgSymb}</i></sup></i>}</>:<>{val.HCSubg}</>}</>)}/>
                <EasyTableElement title={"Species Group"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => <>{typeof val.HCSpgr == "string" ? <>{val.HCSpgr != "$" && <i>({val.HCSpgr})<sup><i>{val.HCSpgrSymb}</i></sup></i>}</>:<>{val.HCSpgr}</>}</>)}/>
                <EasyTableElement title={"Species"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => (typeof val.HCSpec == "string" ?({val:val.HCSpec,dis:<i>{val.HCSpec != "$" && <>{val.CurrSpCatComb?.CombObjID&&<IconLink newWindow={true} IconID={LDLIcons.NSW_MonographicRecord} newStates={{"CombObjID":val.CurrSpCatComb?.CombObjID}} href={"/SpeciesCatalog/MonographRecord"}/>}{val.HCSpec}<sup><i>{val.HCSpecSymb}</i></sup></>}</i>}):<>{val.HCSpec}</>))}/>
                <EasyTableElement title={"Subspecies"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCat) => (typeof val.HCSubs == "string" ?({val:val.HCSubs,dis:<i>{val.HCSubs != "$" && <>{val.CurrSpCatComb?.CombObjID&&<IconLink newWindow={true} IconID={LDLIcons.NSW_MonographicRecord} newStates={{"CombObjID":val.CurrSpCatComb?.CombObjID}} href={"/SpeciesCatalog/MonographRecord"}/>}{val.HCSubs}<sup><i>{val.HCSubsSymb}</i></sup></>}</i>}):<>{val.HCSubs}</>))}/>
                <EasyTableElement title={"Author, Year"} removeBottomLineIfEmpty={true} ignoreIfLastLine={true} elementFromValue={(val:tblLdlSpCat)=>val.CurrSpCatComb?.LParenToRParen}/>
            </EasyTable>
        </Record></>
};


export default Species_Families;