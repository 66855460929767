import React, {useEffect, useState} from "react";
import {callGETAPI} from "../../utils/APIhelpers";
import {tblLdlFaunaAbout} from "../../../../database/models/tblLdlFaunaAbout";
import {EasyTable, EasyTableElement, IconLink} from "../../utils/CustomRecord";
import {useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import LDLAllowedModulesByID from "../../utils/OnServerStartLoadedJsons/LDLAllowedModulesByID.json"
import {tblLdlModule} from "../../../../database/models/tblLdlModule";

// @ts-ignore
const Keys_Main = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [Model, setModel] = useState<tblLdlFaunaAbout[]>()
    useEffect(() => {
        console.log("test")
        callGETAPI("/api/general/getFaunaAbout", (error: boolean, outData: tblLdlFaunaAbout[] | Response) => {
            if (error) {
                console.log("error")
            } else if(!(outData instanceof Response)){
                console.log(outData)
                setModel(outData);
            }
        });

    }, []);


    return <>


        <div className="content">
            <div className="text-body">
                <h1>World Neuropterida Faunas</h1>
                <p className="subheader">
                    Faunal Lists of the Extant and Extinct Neuropterida<br/>
                    (Insecta: Neuroptera, Megaloptera, Raphidioptera <br/>and Glosselytrodea)
                    of the World
                    <br/>
                </p>

                <p style={{textAlign: "center"}}>
                    Editor
                </p>

                <p style={{textAlign: "center"}}>
                    John D. Oswald
                    <br/>
                    Department of Entomology, Texas A&M University
                </p>

                {/* JDOswald 2020.07.01 commenting out the Introduction button here because this page does not (yet?) have any text for an Introduction page; I think that the Introduction link in the bottom navigation bar was removed or commented out earlier.
                <p containerStyle={{textAlign:"center"}}>
                    <a containerStyle={{width:"100px",color:"white",}} href="Introduction" className="btn btn-info">
                        Introduction
                    </a>
                </p>
        */}

                <p style={{textAlign: "left"}}>
                    The Lacewing Digital Library’s World Neuropterida Faunas project provides a venue
                    for the publication of interactively generated faunas of varied geographic extent and depth.
                    Individual country faunas, authored by one or more regional experts, and will provide data to at
                    least to the level of state or province for a single country. A world fauna will contain
                    country-level faunal data for all of the countries of the world, with a special focus on data for
                    countries not represented by separate country faunas.
                    A list of the faunas that have been publically released to date is given in the table below,
                    together with other faunas that are currently in development. If you would like to contribute to the
                    World Neuropterida Faunas project&mdash;by collaborating on an existing module,
                    or by developing a new country module&mdash;please <a
                    href="mailto:j-oswald@tamu.edu?subject=LDL%20World%20Neuropterida%20Faunas%20Inquiry">contact the
                    editor </a>
                    or one of the lead authors shown below.
                </p>
                {Model&&<center><EasyTable  width={"90%"} tableRowData={Model}>
                    <EasyTableElement align={"center"} width={"13%"} title={"Geographic Scope"} elementFromValue={(val:tblLdlFaunaAbout)=>val.GeographicArea}/>
                    <EasyTableElement align={"center"} width={"3%"} title={"Links"} elementFromValue={(val:tblLdlFaunaAbout)=>{
                        // @ts-ignore
                        const dat:tblLdlModule=LDLAllowedModulesByID[val.LdlModuleID];
                        return <>{(dat&&(dat.ShowLdlReactPublic==-1||(dat.ShowLdlReactDemo==-1&&!isPublic)))?<>{<IconLink href={"/"+dat.LdlModuleReference+"/Main"} IconID={dat.MainIconID?dat.MainIconID:0}/>}</>:<></>}</>}}/>
                    <EasyTableElement align={"center"} width={"13%"} title={"Development Status"} elementFromValue={(val:tblLdlFaunaAbout)=>val.DevelopmentStatus}/>
                    <EasyTableElement align={"center"} width={"18%"} title={"Administrative Units"} elementFromValue={(val:tblLdlFaunaAbout)=>val.FaunaUnits}/>
                    <EasyTableElement align={"center"} width={"16%"} title={"Lead Author"} elementFromValue={(val:tblLdlFaunaAbout)=>val.CorrespondingAuthor}/>
                    <EasyTableElement align={"center"} width={"17%"} title={"E-mail"} elementFromValue={(val:tblLdlFaunaAbout)=>{
                        var email = "mailto:" + val.EmailAddress + "?subject=LDL%20World%20Neuropterida%20Faunas:%20" + val.GeographicArea + "%20module";
                        return <a href={email}>{val.EmailAddress}</a>}}/>
                </EasyTable></center>}
            </div>
        </div>
    </>
};

export default Keys_Main;