import React, {useEffect, useRef, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import "../../Content/Downloads/Downloads.css"

const Down_Main = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);

    return <>


        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}>
        <main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">
            <div className="content">
                <div className="text-body">
                    <h1>Downloads</h1>
                    <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                        The <em>Lacewing Digital Library</em> (LDL) downloads page provides access to downloadable
                        Neuropterida-related files and data sets that have been developed for a variety of purposes. For
                        additional information about particular downloads contact their individual authors or
                        custodians.
                    </p>
                    <hr className="rule1"/>
                    <p className="downloaditem">
                        <b>Neuropterida Species of the World: a dataset for collection curators (Version 1.00) </b>
                    </p>
                    <h4>Author/Custodian/Contact</h4>
                    <p className="indented-paragraph">John D. Oswald &nbsp;<a href="mailto:j-oswald@tamu.edu"
                                                                              style={{width: "200px", color: "white",}}
                                                                              className="btn btn-info">Email
                        Comments/Corrections</a></p>
                    {/*Email Comments/Corrections*/}
                    <h4>Overview</h4>
                    <p className="indented-paragraph" style={{textAlign: 'justify'}}>This dataset contains taxonomic,
                        nomenclatural and distributional information on the Neuropterida species of the world. The data
                        are presented in a variety of formats and fields designed to be useful for collection curators
                        and managers, particularly for determining the taxonomic and nomenclatural status of individual
                        names, for printing unit tray header cards, and for incorporation into collection-based
                        taxonomic datasets. The dataset includes information on 16,460 name combinations (any unique
                        combination of genus- and species-group names) that have been used for extant or fossil
                        species-group taxa and that are currently, or have been formerly, included in the superorder
                        Neuropterida. </p>
                    <h4>Data Source</h4>
                    <p className="indented-paragraph"> World Neuropterida Database [NeurDat]</p>
                    <h4>Available Formats</h4>
                    <p className="indented-paragraph">
                        Access database [.mdb]; Excel spreadsheet [.xls]; Word document file [.doc] (convertible to a
                        plain text file [.txt])
                    </p>

                    <ul className="results ">
                        <li className="item">
                            <a className="thumbnail"
                               href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058AccessVer1-17619.accdb"><img
                                src={require("../../Content/Downloads/graphics/access.jpg")} alt="PDF Database"
                                width="45" height="45" style={{border: "0"}}/></a>
                            <a className="title"
                               href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058AccessVer1-17619.accdb">Access
                                Database </a>| <span>Version 1.00</span> | <span>Derived from data source:</span> <span
                            className="date">24.x.2007</span> | <span>Posted to downloads page:</span><span
                            className="date"> 24.x.2007</span>
                            <span className="description"><b>Access file name:</b> NeurSpWorldDataCollCurators.mdb; <b>Data table name:</b> tblLdlDownloadNidaSpGpNames.</span>
                        </li>
                    </ul>
                    <table>
                        <tbody>
                        <tr>
                            <td width="90px"></td>
                            <td>
                                <a className="downloadbutton"
                                   href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058AccessVer1-17619.accdb"><b>Download</b>
                                    <i>16.3MB</i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <ul className="results ">
                        <li className="item">
                            <a className="thumbnail"
                               href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058ExcelVer1-17620.xls"><img
                                src={require("../../Content/Downloads/graphics/excel.jpg")} alt="Excel Database"
                                width="45" height="45" style={{border: "0"}}/></a>
                            <a className="title"
                               href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058ExcelVer1-17620.xls">Excel
                                Spreadsheet </a>| <span>Version 1.00</span> | <span>Derived from data source:</span>
                            <span className="date">24.x.2007</span> | <span>Posted to downloads page:</span><span
                            className="date">26.x.2007</span>
                            <span
                                className="description"><b>Excel file name:</b> NeurSpWorldDataCollCurators.xls.</span>
                        </li>
                    </ul>
                    <table>
                        <tbody>
                        <tr>
                            <td width="90px"></td>
                            <td>
                                <a className="downloadbutton"
                                   href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058ExcelVer1-17620.xls"><b>Download</b>
                                    <i>6.70MB</i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <ul className="results ">
                        <li className="item">
                            <a className="thumbnail"
                               href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058WordVer1-17621.doc"
                               target="_top"><img src={require("../../Content/Downloads/graphics/word.jpg")}
                                                  alt="Delimited text file" width="45" height="45"
                                                  style={{border: "0"}}/></a>
                            <a className="title"
                               href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058WordVer1-17621.doc"
                               target="_top">Word File </a> | <span>Version 1.00</span> | <span>Derived from data source:</span>
                            <span className="date">24.x.2007</span> | <span>Posted to downloads page:</span><span
                            className="date">26.x.2007</span>
                            <span
                                className="description"><b>Word file name:</b> NeurSpWorldDataCollCurators.doc; <b>Notes:</b> This file is a character-delimited text file (delimiter character: @) saved as a Word document. To produce a character-delimited text file from this Word document, save the Word file to your disk, open the file in Word, then resave the file as a “Plain Text” (.txt) file.</span>
                        </li>
                    </ul>
                    <table>
                        <tbody>
                        <tr>
                            <td width="90px"></td>
                            <td>
                                <a className="downloadbutton"
                                   href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2007ref18058WordVer1-17621.doc"
                                   target="_top"><b>Download</b> <i>9.27MB</i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                </div>
            </div>
        </main>
            </td></tr></tbody>
        <tfoot ref={footerRef} id={"mainFooter"}>

        </tfoot>
    </>
};
export default Down_Main;