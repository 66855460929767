import React, {useEffect, useRef, useState} from "react";
import {Outlet, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../../Shared/_Layout";
import "../../../Content/NeurDirec/NeurDirec.css"
import {BottomFooterName} from "../../../utils/CustomRecord";

const _NeuroLayout = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);

    return <>



        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}><main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">

                <Outlet context={[[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],<></>,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]]}></Outlet>
            </main></td>
        </tr>
        </tbody>

        <tfoot ref={footerRef} id={"mainFooter"}>
        <tr>

            <td colSpan={2}>            <nav>
                <span className="module" style={{color: "#9B6702",}}><BottomFooterName currentPathData={currentPathData}/>  </span>
                <a href="/NeuroDirectory/Main" target="_top">Main </a>
                | <a href="/NeuroDirectory/Introduction" target="_top">Introduction </a>
                | <a href="/NeuroDirectory/Acknowledgments" target="_top">Acknowledgments </a>
                | <a href="/NeuroDirectory/Search" target="_top">Search </a>
                | <a href="/NeuroDirectory/AddUpdate" target="_top">Add/Update </a>
                | <a href="/NeuroDirectory/Contacts" target="_top">Contacts </a>
                |
                {verifiedUser?<a href={"/Authenticate/LoggedIn"} target="_top"> Logged In </a>:<a href={"/Authenticate/Login"} target="_top"> Log In </a>}|
{PDFAccess?<text style={{color:"green"}}> PDF+</text>:<text style={{color:"red"}}> PDF{(!isPublic&&verifiedUser)&&<>(<a href={"/Authenticate/Migration"} title={"upgrade using old module login to get new demo-site with PDF+ login"}>upgrade</a>)</>}</text>}
            </nav>
        </td>

        </tr>
        </tfoot>
    </>
};
export default _NeuroLayout;