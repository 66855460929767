import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {Link, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    IconLink,
    ListElement,
    ListHolder,
    Record,

    TableLegendElement,
    TableLegendSection, UpdatePathData
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlMetakeyTerminals} from "../../../../database/models/tblLdlMetakeyTerminals";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";


const SpeciesKeyRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlSpCatComb>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getSpeciesKeyTerminalsByPk", {CombObjID: _props["CombObjID"]}, (error: boolean, outData: tblLdlSpCatComb | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
                setCurrentPathData((prev)=>UpdatePathData({MonographRecord:{UpperLeftModuleName:<>Monograph Record &ndash; <i>{outData.ASCurrGenu} {outData.ASCurrSpec} {outData.ASCurrSubs != "$" && outData.ASCurrSubs}</i></>}},prev))

            }
        })
    }, []);


    return <>
        {resultData &&
            <Record title={<>Identification Keys <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                href={"mailto:j-oswald@tamu.edu?subject=Neuropterida Species of the World: comment/correction for Identification Keys page \"" + resultData.ASCurrGenu + " " + resultData.ASCurrSpec + (resultData.ASCurrSubs != "$" ? (" " + resultData.ASCurrSubs) : "") + " " + resultData.CurrCombAuthDate + "\""}
                IconID={LDLIcons.General_Email}
                HistoryLocStates={location.state}
            /></>}>
                <ListHolder>
                    <ListElement title={"Monograph Record Links"} element={<>
                        {resultData.TaxonCites?.length != 0 &&
                            <IconLink href={"../Taxa"} IconID={LDLIcons.NSW_TaxonCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.FigureCites?.length != 0 &&
                            <IconLink href={"../Figure"} IconID={LDLIcons.NSW_FigureCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.PhyloTrees?.length != 0 &&
                            <IconLink href={"../PhylogeneticTree"} IconID={LDLIcons.NSW_PhylogeneticTrees}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}</>}/>
                </ListHolder>
                {resultData.TerminalTaxon && <>
                    <i>
                        <>Identification Key Records ({resultData.TerminalTaxon.length}).</>
                    </i>
                    <EasyTable downloadable={true} downloadName={`${resultData.ASCurrGenu}_${resultData.ASCurrSpec}${resultData.ASCurrSubs != "$" ?("_"+resultData.ASCurrSubs):""}_Identification_Keys.csv`} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData.TerminalTaxon}
                               extraSymbolsChildren={<TableLegendElement title={"*"} element={"unavailable name;  ♂ – male; ♀ – female"}/>}
                               extraLegendChildren={<>
                                   <TableLegendSection title={"Abbreviations"}>
                                       <TableLegendElement title={"A"} element={"adult(s)"}/>
                                       <TableLegendElement title={"E"} element={"egg(s)"}/>
                                       <TableLegendElement title={"fami"} element={"family(ies)"}/>
                                       <TableLegendElement title={"gegr"} element={"genus group(s)"}/>
                                       <TableLegendElement title={"genu"} element={"genus(genera)"}/>
                                       <TableLegendElement title={"GLOS"} element={"order Glosselytrodea"}/>
                                       <TableLegendElement title={"inst"} element={"instar"}/>
                                       <TableLegendElement title={"L"} element={"larva(e), number indicates instar"}/>
                                       <TableLegendElement title={"MEGA"} element={"order Megaloptera"}/>
                                       <TableLegendElement title={"NEUR"} element={"order Neuroptera"}/>
                                       <TableLegendElement title={"NIDA"} element={"superorder Neuropterida"}/>
                                       <TableLegendElement title={"orde"} element={"order(s)"}/>
                                       <TableLegendElement title={"P"} element={"pupa(e)"}/>
                                       <TableLegendElement title={"PP"} element={"prepupa(e)"}/>
                                       <TableLegendElement title={"r#"}
                                                           element={"Bibliography of the Neuropterida (BotN) reference number"}/>
                                       <TableLegendElement title={"RAPH"} element={"order Raphidioptera"}/>
                                       <TableLegendElement title={"subf"} element={"subfamily(ies)"}/>
                                       <TableLegendElement title={"subg"} element={"subgenus(subgenera)"}/>
                                       <TableLegendElement title={"subo"} element={"suborder(s)"}/>
                                       <TableLegendElement title={"subs"} element={"subspecies"}/>
                                       <TableLegendElement title={"subt"} element={"subtribe(s)"}/>
                                       <TableLegendElement title={"spec"} element={"species"}/>
                                       <TableLegendElement title={"spgr"} element={"species group(s)"}/>
                                       <TableLegendElement title={"supf"} element={"superfamily(ies)"}/>
                                       <TableLegendElement title={"supo"} element={"superorder(s)"}/>
                                       <TableLegendElement title={"supt"} element={"supertribe(s)"}/>
                                       <TableLegendElement title={"trib"} element={"tribe(s)"}/>
                                   </TableLegendSection>
                                   <TableLegendSection title={"Notes"}>
                                       This table is designed to display information about identification keys that contain the active species or subspecies as a terminal taxon. These data have been compiled from identification keys contained in the scientific literature, and frequently contain links to that literature
                                   </TableLegendSection><TableLegendSection title={"Data Source"}>
                                   {resultData.SpCat?.DSSource}
                               </TableLegendSection><TableLegendSection title={"Data Timestamp"}>
                                   {resultData.SpCat?.DSDateTime}
                               </TableLegendSection>
                               </>}>
                        <EasyTableElement width={"0%"} title={"Key"}
                                          elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.KeyID}
                                          desc={"the key's World Neuropterida Metakey keyID number"}/>
                        <EasyTableElement width={"0%"} minWidth={"64px"} textIndent={"0px"} title={"Links"}
                                          elementFromValue={(val: tblLdlMetakeyTerminals) => <><IconLink
                                              newWindow={true}
                                              href={"/Metakey/Record"}
                                              IconID={LDLIcons.WNM_KeyRecord}
                                              newStates={{"KeyID": val.MetaKey.KeyID}}/>
                                              {(val.MetaKey.EDoc && val.MetaKey.EDoc.EDocLink && (val.MetaKey.EDoc.PublicEDoc || (val.MetaKey.EDoc.PrivateEDoc && PDFAccess))) ? <>
                                                  <IconLink newWindow={true} href={val.MetaKey.EDoc.EDocLink}
                                                            IconID={LDLIcons.General_OpenFile_Work}
                                                            />
                                                  <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                            newStates={{"BibObjID": val.MetaKey.BibObjID}}
                                                            IconID={LDLIcons.Viewer_OpenFile}/>
                                                  {val.MetaKey.KeyEDocPageID ?<IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                            newStates={{"BibObjID": val.MetaKey.BibObjID, "EDocPageID": val.MetaKey.KeyEDocPageID}}
                                                            IconID={LDLIcons.Viewer_OpenPage}/>:<>&nbsp;&ndash;</>}
                                                  </> : <>&nbsp;&ndash;&nbsp;&ndash;&nbsp;&ndash;</>}</>}
                                          desc={"links to the key's KeyRecord page and copies of the publication containing the key (where available and permissible)"}/>
                        <EasyTableElement width={"4%"} title={"Page"}
                                          elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.PagePlate}
                                          desc={"the page number on which the key starts"}/>
                        <EasyTableElement width={"7%"} title={"Terminal Taxon"}
                                          elementFromValue={(val: tblLdlMetakeyTerminals) => val.Comb0}/>
                        <EasyTableSection align={"center"} title={"Key Parameter"}>
                            <EasyTableElement width={"22%"} title={"Taxonomic Scope"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => <>
                                                  <i>{val.MetaKey.TaxScope1 != "$" && val.MetaKey.TaxScope1}</i> {val.MetaKey.TaxScope2}</>}
                                              desc={"the recorded taxonomic scope of the key"}/>
                            <EasyTableElement  width={"18%"} title={"Geographic Scope"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.GeographicScope}
                                              desc={"the recorded geographic scope of the key"}/>
                            <EasyTableElement width={"0%"} whiteSpace={"nowrap"} paddingRight={"10px"} title={"Year"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.PubYear}
                                              desc={"the year the key was published or made available"}/>
                            <EasyTableElement width={"5%"} title={"Terminals"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.TerminalsShort}
                                              desc={"the number of unique Neuropterida terminal taxa contained in the key and their rank(s), keys to life stages are reported as “life stages”"}/>
                            <EasyTableElement width={"3%"} title={"Stage"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.LifeStageShort}
                                              desc={"the life stage(s) treated in the key"}/>
                            <EasyTableElement width={"0%"} whiteSpace={"nowrap"} paddingRight={"10px"} title={"Sex"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.Sex}
                                              desc={"the sex(es) treated in the key"}/>
                            <EasyTableElement width={"5%"} title={"Language"}
                                              elementFromValue={(val: tblLdlMetakeyTerminals) => val.MetaKey.Language}
                                              desc={"the language used in the key"}/>
                        </EasyTableSection>
                    </EasyTable>
                </>}
            </Record>}
    </>
};


export default SpeciesKeyRecord;