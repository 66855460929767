import React from "react";

const MetaKey_Main = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>World Neuropterida Metakey</h1>
                <p className="subheader">
                    An Interactive Index to the Identification Keys of the Extant and Fossil<br/>
                    Neuroptera, Megaloptera, Raphidioptera and Glosselytrodea<br/>
                    (Insecta: Neuropterida) of the World
                </p>

                <p style={{textAlign: "center"}}>
                    John D. Oswald<br/>
                    Department of Entomology, Texas A&amp;M University<br/>
                    College Station, Texas 77843-2475 USA
                </p>

                <p style={{textAlign: "center"}}>
                    <a style={{width: "120px", color: "white",}} href="Introduction"
                       className="btn btn-info">Introduction</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href="ScopeSearch" className="btn btn-info">Scope
                        Search</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "180px", color: "white",}} href="TerminalTaxonSearch" className="btn btn-info">Terminal
                        Taxon Search</a>
                </p>
                <p style={{textAlign: 'justify'}}>
                    <i><u>Abstract</u></i>: Metadata that characterize {">"}4000 separate identification keys that
                    contain terminal taxa currently placed in the insect orders Neuroptera, Megaloptera, Raphidioptera,
                    and/or Glosselytrodea are organized and delivered as a “metakey”&mdash;an interactive index to the
                    global corpus of Neuropterida identification keys designed to help individuals discover and access
                    keys that match their needs. The set of keys included in the metakey is believed to represent a
                    substantial majority of the keys that have been published to date, worldwide, to identify
                    neuropterid insects to genus level or below. For each included key, data have been captured about
                    its geographic and taxonomic scopes; the sexes and life stages that it treats; its language, format,
                    and number of couplets; and its location in the literature (reference citation, year of publication,
                    and first page of key). Each key has also been scored for the scientific names and ranks of its
                    Neuropterida terminal taxa ({">"}25,000 in total). Interactive search functionality is provided that
                    enables the display of lists of keys that match user-input criteria from one or more of these
                    metadata axes. Terminal taxa may be searched using the scientific names originally included in each
                    key, or by their current scientific names in the Lacewing Digital Library standard taxonomy. Direct
                    links to digital copies of publications containing keys are provided where available and
                    permissible.

                    <br/>
                    <br/>
                    <i><u>Cite this Publication as</u></i>: Oswald, J. D. World Neuropterida Metakey. <a
                    href="/HomePage/Publications" target="_top"><i>Lacewing Digital Library, Research
                    Publication</i></a> No. 5. http://lacewing.tamu.edu/Metakey/Main. Accessed on [fill in date of last
                    site access].
                    <br/>
                    <br/>
                    <small>(Note: The different modules of the Lacewing Digital Library are individually authored,
                        constitute distinct publications, and should be cited separately. For information on the current
                        version and release of this publication see the <a href="/Metakey/Versions">Versions</a> page.)</small>
                    <br/>
                    <br/>
                    <i><u>Author Contact Information</u></i>: (JDO, corresponding author) <a
                    href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.
                    <br/>
                    <br/>
                    <i><u>Keywords</u></i>: insect identification, identification keys
                </p>

                <p className="author-reserve-markup">
                    © John D. Oswald, {new Date().getFullYear()} <br/>
                    All rights reserved.
                </p>
            </div>
        </div>




    </>
};
export default MetaKey_Main;