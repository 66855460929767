import React from "react";

const Class_Main = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>A Classification of World Neuropterida</h1>
                <p className="subheader">
                    A Catalogue and Monograph of the<br/>
                    Species and Subspecies of the<br/>
                    Extant and Extinct<br/>
                    Neuroptera, Megaloptera, Raphidioptera, and Glosselytrodea<br/>
                    (Insecta: Neuropterida) of the World
                </p>
                <br/>
                <p style={{textAlign: "center"}}>
                    John D. Oswald<br/>
                    Department of Entomology, Texas A&amp;M University<br/>
                    College Station, Texas 77843-2475 USA
                </p>

                <p style={{textAlign: "center"}}>
                    <a style={{width: "120px", color: "white",}} href="Introduction"
                       className="btn btn-info">Introduction</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href="SimpleSearch" className="btn btn-info">Simple
                        Search</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href="AdvancedSearch" className="btn btn-info">Advanced
                        Search</a>
                </p>
                <p style={{textAlign: 'justify'}}>
                    <u><i>Abstract</i></u>: The species-group names of the insect superorder Neuropterida (orders
                    Neuroptera, Megaloptera, Raphidioptera, and Glosselytrodea) are catalogued and its valid species and
                    subspecies are monographed. Coverage of the group is worldwide in scope and includes both extant and
                    extinct taxa. Individual catalogue record entries are provided for all known available scientific
                    names in the superorder, and separate monograph record entries are provided for all valid species
                    and subspecies. The latter records aggregate nomenclatural and all other available data by valid
                    taxon. Extensive information is provided in the following main areas: basic nomenclatural and
                    bibliographic data on all available (and selected unavailable) names, taxonomic status of all names,
                    classification and synonyms of all valid taxa, summary statement and country-level distribution data
                    and extant/extinct status of valid taxa, primary type and type locality data, and chrono- and
                    lithostratigraphic data. Available resources for identifying taxa using published keys are
                    summarized, as is information about many of the illustrations of each valid taxon that have been
                    published in the global scientific literature. More than 2500 original notes are provided on a wide
                    variety of topics. Monograph and Catalogue records are extensively referenced and provide links to
                    cited primary literature where available and permissible. This is a technical scientific catalogue
                    and monograph produced primarily for use by professional and advanced avocational
                    entomologists.<br/>
                    <br/>
                    <i><u>Cite this Publication as</u></i>: Oswald, J. D. Neuropterida Species of the World. <a
                    href="/HomePage/Publications" target="_top"><i>Lacewing Digital Library, Research
                    Publication</i></a> No. 1. http://lacewing.tamu.edu/SpeciesCatalog/Main. Last accessed [fill in date
                    of last site access].
                    <br/>
                    <br/>
                    <small>(Note: The different modules of the Lacewing Digital Library are individually authored,
                        constitute distinct publications, and should be cited separately. For information on the current
                        version and release of this publication see the <a
                            href="/SpeciesCatalog/Versions">Versions</a> page.)</small>
                    <br/>
                    <br/>
                    <i><u>Author Contact Information</u></i>: (JDO, corresponding author) <a
                    href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.
                    <br/>
                    <br/>
                    <i><u>Keywords</u></i>: taxonomy, synonymy, nomenclature, illustrations, identification keys,
                    geographic distribution, lithostratigraphic distribution, temporal distribution.
                </p>
            </div>

            <div className="text-body">
                <p className="author-reserve-markup">
                    © John D. Oswald, {new Date().getFullYear()} <br/>
                    All rights reserved.
                </p>
            </div>
        </div>


       </>
};
export default Class_Main;