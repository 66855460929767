import React from "react";

const Species_Acknowledgments = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>Acknowledgments</h1>
                <p style={{textAlign: 'justify'}}>
                    Catalogues and monographs are built on the foundational research of those who have gone before. It
                    is fitting here to acknowledge the accomplishments and accumulated scientific knowledge of the many
                    previous workers, both living and past, who have produced the thousands of publications upon which
                    this work is based. The success of their efforts to understand and document the earth’s Neuropterida
                    fauna is what eventually requires the development of global syntheses, such as this work, in order
                    to facilitate ready access to accumulated knowledge and to provide a scaffold and contextual
                    framework for the support and guidance of future research.
                </p>
                <p style={{textAlign: 'justify'}}>
                    During work on this publication it has been my great pleasure to communicate with and learn from
                    almost all neuropteran systematists active since the early 1980’s. I am indebted to each for their
                    freely given contributions of literature, facts and advice, which have all contributed in some way
                    to the development of this work. The following colleagues have been particularly helpful and
                    supportive over the years as reliable sources of information and advice on neuropterid topics great
                    and small: Horst Aspöck, Ulrike Aspöck, Stephen Brooks, Oliver Flint, Herbert Hölzel, Vladimir
                    Makarkin, Mervyn Mansell, Victor Monserrat, Timothy New, Michael Ohl, Norman Penny, Lionel Stange,
                    and György Sziráki. Vladimir Makarkin has provided especially invaluable assistance in matters
                    concerning fossil neuropterids and Russian literature.
                </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Web Development</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Markup and coding for versions 5.0 and 6.0 are primarily the work of Tianjian Wang; his coding for
                    the interactive Monograph Record page, first released in version 6.0, is especially significant. The
                    code for version 4.0 was entirely rewritten from version 3.0 by Li-ming Ye, Yuan-xing Yin, and
                    Jia-hao Zhang, who navigated the treacherous waters of substantial changes in back-end databases,
                    servers, and scripting languages as part of a general upgrade of the Lacewing Digital Library site
                    in 2014-2015. Krishna Tadi and Joshua Jones were primarily responsible for the technical development
                    of version 2.0, the first version developed under the broad banner of the Lacewing Digital Library.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Some of the icons used in the NSW were designed by <a href="http://p.yusukekamiyamane.com"
                                                                          target="_blank">Yusuke Kamiyamane</a> and are
                    licensed under a <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank">Creative
                    Commons Attribution 3.0 License</a>.
                </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Funding</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Versions 3.0 and 4.0 were supported in part by the U.S. <a href="http://www.nsf.gov/" target="_top">National
                    Science Foundation (NSF)</a> through the <a href="http://www.nsf.gov/div/index.jsp?div=DEB"
                                                                target="_top">Division of Environmental Biology
                    (DEB)</a> program <a
                    href="http://www.nsf.gov/funding/pgm_summ.jsp?pims_id=5451&amp;org=DEB&amp;from=home" target="_top">Partnerships
                    for Enhancing Expertise in Taxonomy (PEET)</a>, grant #0933588. Version 2.0 was supported in part by
                    U.S. National Science Foundation grant #0416206. The National Science Foundation funds research and
                    education in most fields of science and engineering. Grantees are wholly responsible for conducting
                    their project activities and preparing the results for publication.&nbsp;&nbsp;Thus, the Foundation
                    does not assume responsibility for such findings or their interpretation.
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    General in-kind support for this work has been provided over its long and continuous development
                    from the five institutions that its author has been most closely associated with as a graduate
                    student, postdoctoral fellow, and faculty member: Oregon State University and libraries (Corvallis,
                    Oregon), Cornell University and libraries (Ithaca, New York), the Smithsonian Institution and
                    libraries (Washington, District of Columbia), Texas A&M University and libraries, and Texas A&amp;M
                    AgriLife Research (College Station, Texas). The librarians of many other institutions have also
                    provided access to important materials under their care.
                </p>
                <p style={{textAlign: 'justify'}}>
                    <br/>
                </p>
            </div>
        </div>
    </>
};
export default Species_Acknowledgments;