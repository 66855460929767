export enum LDLModule{
	Albania=1, 
	Biblio=2, 
	Faunas=3, 
	HomePage=4, 
	Ireland=5, 
	Italy=6, 
	Metakey=7, 
	NeuroDirectory=8, 
	PageViewer=9, 
	Shared=11, 
	Slovakia=12, 
	SouthAfrica=13, 
	SpeciesCatalog=14, 
	Switzerland=15, 
	Serials=16, 
	GenusCatalog=17, 
	Interactions=18, 
	Keys=19, 
	Downloads=20, 
	Classification=21, 
	World=22, 
	Angola=23, 
	Botswana=24, 
	Brazil=25, 
	Bulgaria=26, 
	Chile=27, 
	China=28, 
	Colombia=29, 
	France=30, 
	Germany=31, 
	Honduras=32, 
	Kenya=33, 
	Lesotho=34, 
	Mexico=36, 
	Namibia=37, 
	Poland=38, 
	Serbia=39, 
	Swaziland=40, 
	Tanzania=41, 
	UnitedKingdom=42, 
	UnitedStates=43, 
	Zambia=44, 
	Zimbabwe=45, 
	Afghanistan=256, 
	Algeria=259, 
	Andorra=260, 
	AntiguaBarbuda=262, 
	Argentina=263, 
	Armenia=264, 
	Aruba=265, 
	Australia=266, 
	Austria=268, 
	Azerbaijan=269, 
	Bahamas=270, 
	Bahrain=271, 
	Bangladesh=272, 
	Barbados=273, 
	Belarus=274, 
	Belgium=275, 
	Belize=276, 
	Benin=277, 
	Bhutan=278, 
	Bolivia=279, 
	BosniaHerzegovina=280, 
	Brunei=283, 
	BurkinaFaso=285, 
	Burma=286, 
	Burundi=287, 
	CaboVerde=288, 
	Cambodia=289, 
	Cameroon=290, 
	Canada=291, 
	CentralAfricanRepublic=292, 
	Chad=293, 
	Comoros=297, 
	CostaRica=298, 
	CoteIvoire=299, 
	Croatia=300, 
	Cuba=301, 
	Curaçao=302, 
	Cyprus=303, 
	Czechia=304, 
	CongoDemRep=305, 
	Denmark=306, 
	Djibouti=307, 
	Dominica=308, 
	DominicanRepublic=309, 
	Ecuador=310, 
	Egypt=311, 
	ElSalvador=312, 
	EquatorialGuinea=313, 
	Eritrea=314, 
	Estonia=315, 
	Ethiopia=316, 
	FaroeIslands=317, 
	FederatedStatesMicronesia=318, 
	Fiji=319, 
	Finland=320, 
	Gabon=322, 
	Gambia=323, 
	Georgia=324, 
	Ghana=326, 
	Greece=327, 
	Greenland=328, 
	Grenada=329, 
	Guatemala=330, 
	Guinea=331, 
	GuineaBissau=332, 
	Guyana=333, 
	Haiti=334, 
	HolySee=335, 
	Hungary=337, 
	Iceland=338, 
	India=339, 
	Indonesia=340, 
	Iran=341, 
	Iraq=342, 
	Israel=344, 
	Jamaica=346, 
	Japan=347, 
	Jordan=348, 
	Kazakhstan=349, 
	Kiribati=351, 
	Kosovo=352, 
	Kuwait=353, 
	Kyrgyzstan=354, 
	Laos=355, 
	Latvia=356, 
	Lebanon=357, 
	Liberia=359, 
	Libya=360, 
	Liechtenstein=361, 
	Lithuania=362, 
	Luxembourg=363, 
	Madagascar=364, 
	Malawi=365, 
	Malaysia=366, 
	Maldives=367, 
	Mali=369, 
	Malta=370, 
	MarshallIslands=371, 
	Mauritania=372, 
	Mauritius=373, 
	Moldova=375, 
	Monaco=376, 
	Mongolia=377, 
	Montenegro=378, 
	Morocco=379, 
	Mozambique=380, 
	Nauru=382, 
	Nepal=385, 
	Netherlands=386, 
	NewZealand=387, 
	Nicaragua=388, 
	Niger=389, 
	Nigeria=390, 
	Niue=391, 
	NorthKorea=392, 
	NorthMacedonia=393, 
	Norway=394, 
	Oman=395, 
	Pakistan=397, 
	Palau=398, 
	Palestine=400, 
	Panama=401, 
	PapuaNewGuinea=402, 
	Paraguay=403, 
	Peru=404, 
	Philippines=405, 
	Portugal=407, 
	Qatar=408, 
	CongoRep=409, 
	Romania=410, 
	Russia=411, 
	Rwanda=412, 
	SaintKittsNevis=413, 
	SaintLucia=414, 
	SaintVincentGrenadines=415, 
	Samoa=416, 
	SanMarino=417, 
	SaoTomePrincipe=418, 
	SaudiArabia=419, 
	Senegal=420, 
	Seychelles=422, 
	SierraLeone=423, 
	Singapore=424, 
	SintMaartin=425, 
	Slovenia=427, 
	SolomonIslands=428, 
	Somalia=429, 
	SouthKorea=431, 
	SouthSudan=432, 
	Spain=433, 
	SriLanka=434, 
	Sudan=435, 
	Suriname=436, 
	Sweden=438, 
	Syria=440, 
	Tajikistan=441, 
	Thailand=443, 
	TimorLeste=444, 
	Togo=445, 
	Tonga=446, 
	TrinidadTobago=447, 
	Tunisia=448, 
	Turkey=449, 
	Turkmenistan=450, 
	Tuvalu=451, 
	Uganda=452, 
	Ukraine=453, 
	UnitedArabEmirates=454, 
	Uruguay=457, 
	Uzbekistan=458, 
	Vanuatu=459, 
	Venezuela=460, 
	Vietnam=461, 
	WesternSahara=462, 
	Yemen=463, 
	KeysDichot=464, 
	Testing=465
}