

const callAPI = async (apiDirName: RequestInfo | URL,data:any,handle: (error: boolean, outData: any | Response) => any)=>{
    try {
        const response = await fetch(apiDirName, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        if(response.ok){

            const result = await response.json();
            if(response.status==401) {
                handle(true, result);
            }else{
                handle(false, result);
            }
        }else{
            handle(true,response);
        }

    }catch (e) {
        console.warn(e);
    }
}
export const callGETAPI = async (apiDirName: RequestInfo | URL,handle: (error: boolean, outData: any | Response) => any)=>{
    try {
        const response = await fetch(apiDirName, {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
            }
        });

        if(response.ok){
            const result = await response.json();
            console.log(result)
            if(response.status==401) {
                handle(true, result);
            }else{
                handle(false, result);
            }
        }else{
            handle(true,response);
        }

    }catch (e) {
        console.warn(e);
    }
}


export default callAPI;