import React, {useEffect, useState} from "react";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    ButtonLink,
    LargeListElement,
    LargeListHeader,
    LargeListHolder,
    LargeListSubTitle,
    LargeListTitle,
    Record,
    SplitPageHolder
} from "../../utils/CustomRecord";
import {tblLdlNeurDir} from "../../../../database/models/tblLdlNeurDir";


const DirectoryRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlNeurDir>()
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const _props = location.state as any;

    useEffect(() => {
        callAPI("/api/general/getNeurDirByPk", {AgentID: _props["AgentID"]}, (error: boolean, outData: tblLdlNeurDir | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, []);


    function getPrintDate(date: Date) {
        return (date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + date.getUTCFullYear() + " " + String(12 - (24 - date.getUTCHours()) % 12).padStart(2, '0') + ":" + String(date.getUTCMinutes()).padStart(2, '0') + ":" + String(date.getUTCSeconds()).padStart(2, '0') + " " + (date.getUTCHours() <= 12 ? 'AM' : 'PM')
    }

    return <>
        {(resultData) && <Record>
            <SplitPageHolder leftWidth={"50%"}>
                <center>
                    <div style={{width: "fit-content"}}>
                        <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                            <img style={{border: "1px solid black"}} src={resultData.PhotoPath}/>
                        </div>
                        {resultData.Dead == 0 && <>
                            <hr/>
                            <LargeListHolder>
                                <LargeListHeader noPaddingTop={true} title={"Contact Information"}>
                                    <LargeListElement title={"Phone (work)"} element={resultData.Phone1}/>
                                    <LargeListElement title={"Phone (other)"} element={resultData.Phone2}/>
                                    <LargeListElement title={"Fax"} element={resultData.Fax}/>
                                    <LargeListElement title={"Email (work)"} element={<a
                                        href={`mailto:${resultData.EmailA}`}>{resultData.EmailA}</a>}/>
                                    <LargeListElement title={"Email (other)"} element={<a
                                        href={`mailto:${resultData.EmailB}`}>{resultData.EmailB}</a>}/>
                                    <LargeListElement title={"URL (person)"}
                                                      element={<a href={resultData.URL1}>{resultData.URL1text}</a>}/>
                                    <LargeListElement title={"URL (project)"}
                                                      element={<a href={resultData.URL2}>{resultData.URL2text}</a>}/>
                                </LargeListHeader>
                            </LargeListHolder>
                        </>}
                        <hr/>
                        <p style={{color: "#900"}}>Last
                            Update: {resultData.Updates && <>{getPrintDate(new Date(resultData.Updates))}</>}
                            <ButtonLink href={"/NeuroDirectory/AddUpdate"} newStates={{}}>Update</ButtonLink></p>
                    </div>
                </center>
                <Record>

                    <LargeListHolder>
                        <LargeListTitle title={resultData.Head1}/>
                        <LargeListSubTitle title={resultData.Head2}/>
                        <LargeListHeader title={resultData.Title}/>
                        <LargeListHeader title={"About"}>
                            <LargeListElement title={"Interests"} element={resultData.SerInterests}/>
                            <LargeListElement title={"Research Emphases"} element={resultData.ResEmp}/>
                            {resultData.Dead == 0 &&
                                <LargeListElement title={"Current Projects"} element={resultData.CurrProj}/>}
                            {resultData.Bio != null && <LargeListElement title={"Notes"} element={resultData.Bio}/>}
                        </LargeListHeader>
                    </LargeListHolder>
                </Record>
            </SplitPageHolder>
        </Record>
        }
    </>
};


export default DirectoryRecord;