import React, {useEffect, useState} from "react";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    Icon,
    IconLink,
    IgnoreEmpty, ItalicIEHF,
    ParaIEHF, ParaItalicIEHF,
    Record,
    
    RemoveIfLastSame,
    SearchCriteria, SearchCriteriaTWO, TableLegendSection
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";
import {proccessSearch, SearchOutputProps} from "../../utils/CustomSearch";

enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface SpeciesSearchProps {
    ASOrde?: { filter: filterType, Val: string },
    ASFami?: { filter: filterType, Val: string },
    ASSubf?: { filter: filterType, Val: string },
    ASTrib?: { filter: filterType, Val: string },
    ASSubt?: { filter: filterType, Val: string },
    ASOrigGenu?: { filter: filterType, Val: string },
    ASCurrGenu?: { filter: filterType, Val: string },
    GenAll?: { filter: filterType, Val: string },
    ASOrigSubg?: { filter: filterType, Val: string },
    ASCurrSubg?: { filter: filterType, Val: string },
    SubAll?: { filter: filterType, Val: string },
    GenOrSubAll?: { filter: filterType, Val: string },
    CombGenuName?: { filter: filterType, Val: string },
    CombSubgName?: { filter: filterType, Val: string },
    CombGenAll?: { filter: filterType, Val: string },
    CombSpgrName?: { filter: filterType, Val: string },
    CombSpecName?: { filter: filterType, Val: string },
    CombSubsName?: { filter: filterType, Val: string },
    CombInfrName?: { filter: filterType, Val: string },
    CombSubiName?: { filter: filterType, Val: string },
    CombAll?: { filter: filterType, Val: string },
    ASAuth?: { filter: filterType, Val: string },
    ASYear?: { filter: filterType, Val: string },
    ASJour?: { filter: filterType, Val: string },
    GenTax?: { Val: string },
    SpecTax?: { Val: string },
    ASBibObjNum?: { Val: string },
    CombObjID?: { Val: string },
    NameStatus?: { Val: string },//ASAvail , ASUnavail , ASValid , ASAvail and ASInvalid
    ExtantExtinct?: { Val: string },//ASExtant , ASFossil ,ASExtant and ASFossil
    GenOrSub?: { filter: filterType, Val: string },
    useOr?: string
}

const conversionTable = {
    "Order": "ASOrde",
    "Family": "ASFami",
    "Subfamily": "ASSubf",
    "Tribe": "ASTrib",
    "Subtribe": "ASSubt",
    "Original Genus": "ASOrigGenu",
    "Current Genus": "ASCurrGenu",
    "Original or Current Genus": "GenAll",
    "Original Subgenus": "ASOrigSubg",
    "Current Subgenus": "ASCurrSubg",
    "Original or Current Subgenus": "SubAll",
    "Original or Current, Genus or Subgenus": "GenOrSubAll",
    "Combination Genus": "CombGenuName",
    "Combination Subgenus": "CombSubgName",
    "Combination Genus or Subgenus": "CombGenAll",
    "Combination (Species Group)": "CombSpgrName",
    "Combination Species": "CombSpecName",
    "Combination Subspecies": "CombSubsName",
    "Combination Infrasubspecies": "CombInfrName",
    "Combination Subinfrasubspecies": "CombSubiName",
    "Combination Any Species-Group Name": "CombAll",
    "Author": "ASAuth",
    "Year": "ASYear",
    "Journal": "ASJour",
    "Genus# is": "GenTax",
    "Species# is": "SpecTax",
    "Reference# is": "ASBibObjNum",
    "Combination# is": "CombObjID",
    "Name Status": "NameStatus",
    "Extant / Extinct": "ExtantExtinct",
    "Genus or Subgenus": "GenOrSub",
    "Species, Subspecies or Variety": "CombAll",
    "and/or": "useOr"
}
const SpeciesResult = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlSpCatComb[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any | undefined;


    useEffect(() => {
 console.log(_props)
        callAPI("/api/general/doSpeciesSearch", _props?.searchProps.apiData, (error: boolean, outData: tblLdlSpCatComb[] | Response) => {
            if (error) {
                console.log("error")
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData)
            }

        });
    }, []);


    return <>{(resultData) && <>

        <Record title={"Search Results"}>
            <SearchCriteriaTWO searchCriteria={_props?.searchProps.displayData}>
            <i>
                <>Results Records ({resultData.length}).</>
            </i>
            </SearchCriteriaTWO>
            <EasyTable extraLegendChildren={<TableLegendSection title={"Notes"}><>This table is designed to display records that match a set of user-input search criteria. Genus-group names (or fragments) are matched to strings in columns Genus and (Subgenus), and species-group names (or fragments) are matched to strings in column Epithet Record. The table provides icon-based links to two sets of pages: Epithet Records (<Icon IconID={LDLIcons.NSW_EpithetRecord}/>) and Monograph Records (<Icon IconID={LDLIcons.NSW_MonographicRecord}/>). Epithet Record pages aggregate data on the basis of individual species-group epithets; these pages are designed primarily to deliver data in the domain of 'strict nomenclature', particularly data on epithet availability status and nomenclatural types. Monograph Record pages aggregate data by valid (or 'treated as valid') taxon name; these pages are designed to deliver data organized taxonomically based on the LDL Standard Taxonomy</></TableLegendSection>} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData}>
                <EasyTableElement halign={"left"}  width={"0%"}  align={"left"} title={"Genus"} removeBottomLineIfEmpty={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCatComb) => <>{IgnoreEmpty(ItalicIEHF(val.CombGenuName))}</>)}
                                  desc={"genus name of a combination that matches the active search criteria"}/>
                <EasyTableElement halign={"right"}  paddingRight={"5px"} width={"0%"} align={"right"} title={"(Subgenus)"} ignoreIfLastLine={true}
                                  removeBottomLineIfEmpty={true}
                                  elementFromValue={RemoveIfLastSame((val: tblLdlSpCatComb) => <>{IgnoreEmpty(ParaItalicIEHF(val.CombSubgName))}</>)}
                                  desc={"subgenus name of a combination that matches the active search criteria"}/>
                <EasyTableSection align={"left"} title={"Epithet Record"} desc={<> epithet citation(s) and Epithet Record page link(s) ({<Icon
                    IconID={LDLIcons.NSW_EpithetRecord}/>})</>}>
                    <EasyTableElement width={"35%"} title={"(species group) species Author, year"}
                                      elementFromValue={(val: tblLdlSpCatComb) => <>{IgnoreEmpty(ParaIEHF(val.CombSpgrName), {
                                          val: val.CombSpecName,
                                          dis: <><IconLink href={"./Record"}
                                                           newStates={{"SpecNum": val.CombSpecTaxObjNum}}
                                                           HistoryLocStates={location.state}
                                                           IconID={LDLIcons.NSW_EpithetRecord}/> <i>{val.CombSpecName}</i></>
                                      }, val.CombSubsRankAbbrev, {
                                          val: val.CombSubsName,
                                          dis: <><IconLink href={"./Record"}
                                                           newStates={{"SpecNum": val.CombSubsTaxObjNum}}
                                                           HistoryLocStates={location.state}
                                                           IconID={LDLIcons.NSW_EpithetRecord}/> <i>{val.CombSubsName}</i></>
                                      }, val.CombInfrRankAbbrev, {
                                          val: val.CombInfrName,
                                          dis: <><IconLink href={"./Record"}
                                                           newStates={{"SpecNum": val.CombInfrTaxObjNum}}
                                                           HistoryLocStates={location.state}
                                                           IconID={LDLIcons.NSW_EpithetRecord}/> <i>{val.CombInfrName}</i></>
                                      }, val.CombSubiRankAbbrev, {
                                          val: val.CombSubiName,
                                          dis: <><IconLink href={"./Record"}
                                                           newStates={{"SpecNum": val.CombSubiTaxObjNum}}
                                                           HistoryLocStates={location.state}
                                                           IconID={LDLIcons.NSW_EpithetRecord}/> <i>{val.CombSubiName}</i></>
                                      })}{val.CombLParen}{val.CombAuth}, {val.CombDate}{val.CombRParen}</>}/>
                </EasyTableSection>
                <EasyTableSection align={"left"} title={"Monograph Record"} desc={<>taxon name citation and Monograph Record page link ({<Icon
                    IconID={LDLIcons.NSW_MonographicRecord}/>})</>}>
                    <EasyTableElement width={"35%"} title={"Current name (short form)"}
                                      elementFromValue={(val: tblLdlSpCatComb) => <><IconLink href={"./MonographRecord"}
                                                                                              newStates={{"CombObjID": val.CombObjID}}
                                                                                              HistoryLocStates={location.state}
                                                                                              IconID={LDLIcons.NSW_MonographicRecord}/> {IgnoreEmpty(ItalicIEHF(val.ASCurrGenu),ItalicIEHF(val.ASCurrSpec),ItalicIEHF(val.ASCurrSubs),val.CurrCombAuthDate)}</>}/>
                </EasyTableSection>
                <EasyTableSection align={"left"} title={"Higher Classification"}
                                  desc={"order and family to which the taxon (Monograph Record) and epithet (Epithet Record) are assigned in the Lacewing Digital Library (LDL) Standard Taxonomy"}>
                    <EasyTableElement  width={"20%"} align={"left"} title={"Order : Family"}
                                      elementFromValue={(val: tblLdlSpCatComb) => <>{val.ASOrde} : {val.ASFami}</>}/>
                </EasyTableSection>
            </EasyTable>
        </Record>
    </>
    }
    </>
};


export default SpeciesResult;