import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {Link, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHeader,
    Record,

    RowTable,
    RowTableElement,
    SelectFromArray,
    TableLegendElement,
    TableLegendHolder,
    TableLegendSection, UpdatePathData
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCat} from "../../../../database/models/tblLdlSpCat";
import {tblLdlSpCatTypeSpmn} from "../../../../database/models/tblLdlSpCatTypeSpmn";
import {tblLdlSpCatTypeNomAct} from "../../../../database/models/tblLdlSpCatTypeNomAct";
import {tblLdlSpCatTypeExtract} from "../../../../database/models/tblLdlSpCatTypeExtract";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";



const SpeciesEpiRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();
    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlSpCat>()
    const [monoData, setMonoData] = useState<tblLdlSpCatComb>()

    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        if(_props["CombObjID"]&&currentPath.includes("MonographRecord")){
            callAPI("/api/general/getMonoByPk",{CombObjID:_props["CombObjID"]},(error: boolean, outData: tblLdlSpCatComb | Response)=>{
                if(error){
                    console.log(error)
                } else if (!(outData instanceof Response)) {
                    console.log(outData)
                    setMonoData(outData)
                    setCurrentPathData((prev)=>UpdatePathData({MonographRecord:{UpperLeftModuleName:<>Monograph Record &ndash; <i>{outData.ASCurrGenu} {outData.ASCurrSpec} {outData.ASCurrSubs != "$" && outData.ASCurrSubs}</i></>}},prev))

                }
            })
        }
        callAPI("/api/general/getSpecByPk", {TaxObjID: _props["SpecNum"]}, (error: boolean, outData: tblLdlSpCat | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
                setCurrentPathData((prev)=>UpdatePathData({Record:{UpperLeftModuleName:<>Epithet Record &ndash;<i>{outData.SNSpecName && <> {outData.SNSpecName.includes("*")?(outData.SNSpecName.split('*')[0]+"*"):outData.SNSpecName.split(' ')[0]}</>}</i> {outData.OrigCombAuthYear} [<i>{outData.SROrigComb && <>{outData.SROrigComb.split(' ')[0]}</>}</i>]</>}},prev))

            }
        })
    }, []);


    return <>
        {resultData &&
            <Record alignHeader={"left"}
                    title={<>
                        <i>{resultData.SNSpecName && <>{resultData.SNSpecName.includes("*")?(resultData.SNSpecName.split('*')[0]+"*"):resultData.SNSpecName.split(' ')[0]}</>}</i> {resultData.OrigCombAuthYear} [<i>{resultData.SROrigComb && <>{resultData.SROrigComb.split(' ')[0]}</>}</i>] <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                        href={"mailto:j-oswald@tamu.edu?subject=Neuropterida Species of the World: comment/correction for Epithet Record page " + resultData.SNSpecName?.split(' ')[0] + " " + resultData.OrigCombAuthYear + " [" + resultData.SROrigComb?.split(' ')[0] + "]"}
                        IconID={LDLIcons.General_Email}
                        HistoryLocStates={location.state}
                    /></>}>
                <ListHeader title={"Taxonomy"}>
                    <ListElement title={"Classification"} element={"LDL Standard Taxonomy"}/>
                    <ListElement title={"Taxonomic Status"} element={resultData.SRStat}/>
                    <ListElement title={"Status Citation"} element={resultData.SRSpecCite}/>
                    <ListElement title={"Current Taxon"}
                                 element={<><IconLink href={_props["CombObjID"]&&currentPath.includes("MonographRecord")?"../../MonographRecord":"../MonographRecord"}
                                                      IconID={LDLIcons.NSW_MonographicRecord}
                                                      HistoryLocStates={location.state}
                                                      newStates={{"CombObjID": resultData.CurrNameCombObjID}}/> {resultData.SRCurrName}</>}/>
                    <ListElement title={"Higher Classification"} element={resultData.SRClas}/>
                    <ListElement title={"Taxonomic Notes"} element={resultData.SynObjNotesTaxPublic}/>
                </ListHeader>
                <ListHeader title={"Nomenclature"}>
                    <ListElement title={"Nomenclatural Status"} element={resultData.NomStatus}/>
                    <ListElement title={"Epithet (masculine)"} element={resultData.SNSpecName}/>
                    <ListElement title={"Nomenclatural Gender Forms"} element={resultData.SNGendForm}/>
                    <ListElement title={"Epithet in Original Combination"} element={resultData.SROrigComb}/>
                    <ListElement title={"Original Citation"}
                                 element={<><IconLink href={"/Biblio/Record"} IconID={LDLIcons.BotN_BibliographicRecord}
                                                      newStates={{"BibObjID": resultData.OrigCombBibObjID}}/>&nbsp;
                                     {(resultData.EDocs && resultData.EDocs[0] && (resultData.EDocs[0].PublicEDoc || (resultData.EDocs[0].PrivateEDoc && PDFAccess))) &&
                                         <><IconLink href={resultData.EDocs[0].EDocLink}
                                                     IconID={LDLIcons.General_OpenFile_Work}
                                                     /> <IconLink href={"/PageViewer/Pages"}
                                                                                IconID={LDLIcons.Viewer_OpenFile}
                                                                                newStates={{"BibObjID": resultData.OrigCombBibObjID}}/></>
                                     } {resultData.SNOrigCite}</>}/>
                    <ListElement title={"Verified"} element={resultData.SNVer}/>
                    <ListElement title={"Nomenclatural Notes"} element={resultData.SNNomNote}/>
                </ListHeader>
                <ListHeader title={"Nomenclatural Type"}>
                    <ListElement title={"Type Statement"} element={resultData.SNPrimType}/>
                    <ListElement title={"Type Locality (interpreted and standardized)"} element={resultData.SNTypeLoc}/>
                    <ListElement title={"Type Notes"} element={resultData.SNTypeNote}/>
                </ListHeader>
                {resultData.Specimens &&
                    <SelectFromArray arrayInput={resultData.Specimens}
                                     defaultSelectedIndex={resultData.Specimens?.length ? 0 : -1}
                                     defaultOption={"Select a specimen"}
                                     displaySelect={!!resultData.Specimens?.length}
                                     selectRenderAbove={(val: tblLdlSpCatTypeSpmn | null, index) => {
                                         return <i>Type Specimens
                                             ({!!resultData.Specimens?.length?<>{resultData.Specimens?.length} total; {index + 1} displayed</>:0}).  </i>
                                     }}
                                     optionRender={(val: tblLdlSpCatTypeSpmn, index) => {
                                         return <>{val.TypeKind}, {val.Sex} — LDL AgentID {val.SpecimenAgentID}</>
                                     }}
                                     selectRender={(val: tblLdlSpCatTypeSpmn | null, index) => {
                                         return <>
                                         {val&&<RowTable Legend={<TableLegendHolder>
                                                 <TableLegendSection title={"Fields"}>
                                                     <TableLegendElement title={"Age"}
                                                                         element={"the chronostratigraphic age of the specimen"}/>
                                                     <TableLegendElement title={"Collector(s)"}
                                                                         element={"the collector or collectors associated with the specimen"}/>
                                                     <TableLegendElement title={"Date Collected / Reared"}
                                                                         element={"the date, or date range during which, the specimen was collected or reared"}/>
                                                     <TableLegendElement title={"Locality (quote)"}
                                                                         element={"a literature quotation of the locality from which the specimen was collected, usually (but not always) taken from the publication containing the original description of the taxon"}/>
                                                     <TableLegendElement
                                                         title={"Locality (interpreted and standardized)"}
                                                         element={"an interpreted and standardized statement of the collection locality, typically written with modern geopolitical units and often containing clarifying data on geographical feature names and approximated georeferencing data; latitude and longitude data provided in square brackets [] have been derived from a variety of secondary sources, but most commonly from Google Earth; decimal-degree lat/long data are generally reported to three digits after the decimal point, which represents a compromise between the risk of over-precisely locating a collecting locality and the ability to accurately locate a named feature on a map"}/>
                                                     <TableLegendElement title={"Notes"}
                                                                         element={"general notes pertaining to the specimen"}/>
                                                     <TableLegendElement title={"LDL AgentID"}
                                                                         element={"the AgentID assigned to the specimen in the Lacewing Digital Library database"}/>
                                                     <TableLegendElement title={"Stratum"}
                                                                         element={"for fossils, the lithostratigraphic stratum associated with the specimen, typically reported to formation level"}/>
                                                     <TableLegendElement title={"Sex"}
                                                                         element={"the sex of the specimen"}/>
                                                     <TableLegendElement title={"Type Kind"}
                                                                         element={"the kind of type (or non-type) represented by the specimen"}/>
                                                 </TableLegendSection>
                                                 <TableLegendSection title={"Notes"}>
                                                     <>{"This table is designed to display synoptic data for the type specimen(s) of individual species-group names (epithets). The data displayed may not yet fully reflect corresponding data shown in the Nomenclatural Acts and Literature Extracts tables below. Verifying and updating type information is an active area of data improvement for the Neuropterida Species of the World. You may report any issues noted by using the Comments & Corrections custom email link at the bottom of the Epithet Record page. The current focus of the Type Specimens table is to present synoptic data for primary type specimens. If a single primary type specimen has not yet been validly fixed from a multi-specimen type series, data are sometimes reported for multiple syntypes. But generally, data for non-primary type specimens (e.g., paratypes and paralectotypes) are not reported"}</>
                                                 </TableLegendSection>
                                             </TableLegendHolder>}>
                                                 <RowTableElement title={"Type Kind"}
                                                                  element={<>{val ? val.TypeKind : "-"}</>}/>
                                                 <RowTableElement title={"Sex"} element={<>{val ? val.Sex : "-"}</>}/>
                                                 <RowTableElement title={"Locality (quote)"}
                                                                  element={<>{val ? val.TypeLocalityQuote : "-"}</>}/>
                                                 <RowTableElement title={"Locality (interpreted and standardized)"}
                                                                  element={<>{val ? val.TypeLocalityInterpreted : "-"}</>}/>
                                                 <RowTableElement title={"Date Collected/Reared"}
                                                                  element={<>{val ? val.DateCollectedReared : "-"}</>}/>
                                                 <RowTableElement title={"Age"}
                                                                  element={<>{val ? val.Chronostrat : "-"}</>}/>
                                                 <RowTableElement title={"Stratum"}
                                                                  element={<>{val ? val.Lithostrat : "-"}</>}/>
                                                 <RowTableElement title={"Notes"}
                                                                  element={<>{val ? val.SpecimenNote : "-"}</>}/>
                                                 <RowTableElement title={"LDL AgentID"}
                                                                  element={<>{val ? val.SpecimenAgentID : "-"}</>}/>
                                             </RowTable>}


                                             <EasyTable paddingTop={"5px"} caption={<i>Specimen Parts
                                                 ({(val?.SpecimenParts) ? (val.SpecimenParts.length) : "0"}).</i>} extraLegendChildren={
                                                 <TableLegendSection title={"Notes"}>
                                                 <>This table is designed to display information on specific parts of type (or non-type) specimens. It provides a uniform structure for reporting data on individual specimen parts (e.g., morphological structures, parts/counterparts, molecular extracts), preservation (including different techniques for preserving extant and fossil materials, and documenting structure absences/losses), repositories (current, past, and future repository collections), and primary identifiers (e.g., type codes, collection specimen identifiers, barcodes). The level of detail reported varies from specimen to specimen</>
                                             </TableLegendSection>} tableRowData={val?.SpecimenParts}>
                                                 <EasyTableElement title={"Category"} width={"6%"}
                                                                   elementFromValue={(val) => val.Category}
                                                                   desc={"a general record category assigned to the specimen part"}/>
                                                 <EasyTableElement title={"Stage"} width={"8%"}
                                                                   elementFromValue={(val) => val.LifeStage}
                                                                   desc={"the life stage of the specimen part"}/>
                                                 <EasyTableElement title={"Part"} width={"9%"}
                                                                   elementFromValue={(val) => val.PartDescription}
                                                                   desc={"a descriptor of the specimen part, generally a morphological descriptor"}/>
                                                 <EasyTableElement title={"Preservation"} width={"13%"}
                                                                   elementFromValue={(val) => val.PreservationKind}
                                                                   desc={"the method of preservation/preparation of the specimen part"}/>
                                                 <EasyTableElement title={"Repository"} width={"53%"}
                                                                   elementFromValue={(val) => val.Collection}
                                                                   desc={"the current repository collection of the specimen part, sometimes preceded by information on intended future repository collections and/or followed by information on past repository collections"}/>
                                                 <EasyTableElement title={"Identifier (primary)"} width={"11%"}
                                                                   elementFromValue={(val) => val.Identifier}
                                                                   desc={"the primary identifier associated with the specimen part, generally an identifier assigned by the current repository collection"}/>
                                             </EasyTable>
                                         </>
                                     }}

                    />}
                {resultData.SpCatTypeNomActs && <SelectFromArray arrayInput={["Show All Acts", "Hide All Acts"]}
                                                                 defaultSelectedIndex={resultData.SpCatTypeNomActs.length ? 0 : 1}
                                                                 displaySelect={!!resultData.SpCatTypeNomActs.length}
                                                                 selectRenderAbove={(val: string, index) => {
                                                                     return <i>Type-Related Nomenclatural Acts
                                                                         ({!!resultData.SpCatTypeNomActs?.length?<>{resultData.SpCatTypeNomActs?.length} total; {index ? 0 : resultData.SpCatTypeNomActs?.length} displayed</>:0}).  </i>
                                                                 }}
                                                                 optionRender={(val: string, index) => {
                                                                     return <>{val}</>
                                                                 }}
                                                                 selectRender={(val: string, index) => {
                                                                     if (val == "Show All Acts") {
                                                                         return <>{resultData.SpCatTypeNomActs &&
                                                                             <EasyTable
                                                                                 tableRowData={resultData.SpCatTypeNomActs}
                                                                                 extraLegendChildren={
                                                                                     <TableLegendSection title={"Notes"}>
                                                                                         <>This table is designed to display a synoptic chronological history of the major nomenclatural acts associated with the designation and fixation of the primary type specimens of individual species group names (epithets). Data for this field are actively being added to the database underlying the NSW, and appropriate data for many epithets remain to be input. At the current stage of data entry, absence of a record in this table should NOT be interpreted to mean that a particular epithet lacks a primary type specimen that has been validly fixed. If no records are reported in this table, check the Type-Related Literature Extracts table for additional information related to type designations and fixations</>
                                                                                     </TableLegendSection>}
                                                                             >
                                                                                 <EasyTableElement title={"Date"}
                                                                                                   width={"1%"}
                                                                                                   elementFromValue={(val:tblLdlSpCatTypeNomAct) => val.ActDateCite}
                                                                                                   desc={"the date of publication of the work (or work part) that contains the act (data derived from the Bibliography of the Neuropterida, which dates works in accordance with Article 21 of the International Code of Zoological Nomenclature)"}/>
                                                                                 <EasyTableElement
                                                                                     title={"Act (standard)"}
                                                                                     width={"40%"}
                                                                                     elementFromValue={(val:tblLdlSpCatTypeNomAct) => val.ActText}
                                                                                     desc={"a standardized nomenclatural act statement"}/>
                                                                                 <EasyTableElement
                                                                                     title={"Act Status (interpreted)"}
                                                                                     width={"50%"}
                                                                                     elementFromValue={(val:tblLdlSpCatTypeNomAct) => val.ActStatusText}
                                                                                     desc={"an interpretation of the status of the nomenclatural act"}/>
                                                                                 <EasyTableElement title={"Source"}
                                                                                                   width={"10%"}
                                                                                                   elementFromValue={(val:tblLdlSpCatTypeNomAct) => val.ActSourceCite}
                                                                                                   desc={"a short bibliographic citation to the work in which the act appears"}/>
                                                                                 <EasyTableElement title={"Page"}
                                                                                                   minWidth={"40px"}
                                                                                                   width={"1%"}
                                                                                                   elementFromValue={(val:tblLdlSpCatTypeNomAct) => val.ActPageCite}
                                                                                                   desc={"the designation for the page on which the nomenclatural act appears (or starts); clarification and disambiguation information may be given in square brackets []"}/>
                                                                                 <EasyTableElement title={"Links"}
                                                                                                   minWidth={"60px"}
                                                                                                   width={"1%"}
                                                                                                   elementFromValue={(val: tblLdlSpCatTypeNomAct) => <>
                                                                                                       {(val.LinkBibObjID) &&
                                                                                                           <IconLink
                                                                                                               href={"/Biblio/Record"}
                                                                                                               IconID={LDLIcons.BotN_BibliographicRecord}
                                                                                                               newStates={{"BibObjID": val.LinkBibObjID}}/>}
                                                                                                       &nbsp;
                                                                                                       {(val.LinkEDocID && val.LinkedEDoc?.EDocLink && (val.LinkedEDoc.PublicEDoc || (val.LinkedEDoc.PrivateEDoc && PDFAccess))) ?
                                                                                                           <><IconLink
                                                                                                               href={val.LinkedEDoc.EDocLink}
                                                                                                               IconID={LDLIcons.General_OpenFile_Work}
                                                                                                               /> <IconLink
                                                                                                                   href={"/PageViewer/Pages"}
                                                                                                                   IconID={LDLIcons.Viewer_OpenFile}
                                                                                                                   newStates={{"BibObjID": val.LinkBibObjID}}/></> : <>-
                                                                                                               -</>}</>}
                                                                                                   desc={"one or more icon-based links to bibliographic citation data and/or digital files"}/>
                                                                             </EasyTable>}
                                                                         </>
                                                                     }
                                                                     return <></>;
                                                                 }}

                />}
                {resultData.SpCatTypeExtracts &&
                    <SelectFromArray arrayInput={["Show All Extracts", "Hide All Extracts"]}
                                     defaultSelectedIndex={resultData.SpCatTypeExtracts.length ? 0 : 1}
                                     displaySelect={!!resultData.SpCatTypeExtracts.length}
                                     selectRenderAbove={(val: string, index) => {
                                         return <i>Type-Related Literature Extracts
                                             ({!!resultData.SpCatTypeExtracts?.length?<>{resultData.SpCatTypeExtracts?.length} total; {index ? 0 : resultData.SpCatTypeExtracts?.length} displayed</>:0}).  </i>
                                     }}
                                     optionRender={(val: string, index) => {
                                         return <>{val}</>
                                     }}
                                     selectRender={(val: string, index) => {
                                         if (val == "Show All Extracts") {
                                             return <>{resultData.SpCatTypeExtracts &&
                                                 <EasyTable tableRowData={resultData.SpCatTypeExtracts}
                                                            extraLegendChildren={
                                                                <TableLegendSection title={"Notes"}>
                                                                    <>This table is designed to display a chronological listing of texts that have been extracted from the published scientific literature and that contain information related to the 'types' of individual species-group names (epithets). Extracts originally published in languages other than English are frequently supplemented with full or partial English translations. The text extracts documented in this table are a primary source of information for the standardized data that are displayed in the other 'types tables' on specific Epithet Record pages. It can generally be assumed that if a type-data source is not represented by a record in the literature extracts table, then data from that source have not yet been incorporated into type-related data displays and statements contained on a specific Epithet Record page</>
                                                                </TableLegendSection>}>
                                                     <EasyTableElement title={"Date"} width={"1%"}
                                                                       elementFromValue={(val) => val.DateCite}
                                                                       desc={"the date of publication of the work (or work part) that contains the extract (data derived from the Bibliography of the Neuropterida, which dates works in accordance with Article 21 of the International Code of Zoological Nomenclature)"}/>
                                                     <EasyTableElement title={"Extract"} width={"90%"}
                                                                       elementFromValue={(val) => val.ExtractText}
                                                                       desc={"one or more type-related text quotes extracted from the cited source; clarifying information may be given in square brackets []"}/>
                                                     <EasyTableElement title={"Source"} width={"10%"}
                                                                       elementFromValue={(val) => val.SourceCite}
                                                                       desc={"a short bibliographic citation to the work in which the citation appears"}/>
                                                     <EasyTableElement title={"Page"} minWidth={"40px"} width={"1%"}
                                                                       elementFromValue={(val) => val.PageCite}
                                                                       desc={"the designation for the page on which the text extract appears (or starts); clarification and disambiguation information may be given in square brackets []"}/>
                                                     <EasyTableElement title={"Links"} minWidth={"60px"} width={"1%"}
                                                                       elementFromValue={(val: tblLdlSpCatTypeExtract) => {
                                                                           return <>{(val.LinkBibObjID) &&
                                                                               <IconLink href={"/Biblio/Record"}
                                                                                         IconID={LDLIcons.BotN_BibliographicRecord}

                                                                                         newStates={{"BibObjID": val.LinkBibObjID}}/>}&nbsp;{(val.LinkEDocID && val.LinkedEDoc?.EDocLink && (val.LinkedEDoc.PublicEDoc || (val.LinkedEDoc.PrivateEDoc && PDFAccess))) ?
                                                                               <><IconLink
                                                                                   href={val.LinkedEDoc.EDocLink}
                                                                                   IconID={LDLIcons.General_OpenFile_Work}
                                                                                   /> <IconLink
                                                                                   href={"/PageViewer/Pages"}
                                                                                   IconID={LDLIcons.Viewer_OpenFile}
                                                                                   newStates={{"BibObjID": val.LinkBibObjID}}/></> : <>-
                                                                                   -</>}</>
                                                                       }}
                                                                       desc={"one or more links to publication citation data and/or digital files"}/>
                                                 </EasyTable>}
                                             </>
                                         }
                                         return <></>;
                                     }}

                    />}
                <ListHeader title={"Data"}>
                    <ListElement title={"Source"} element={resultData.DSSource}/>
                    <ListElement title={"Timestamp"} element={resultData.DSDateTime}/>
                </ListHeader>
            </Record>}
    </>
};


export default SpeciesEpiRecord;