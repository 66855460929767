import React from "react";

const LoginFailure = (_props: { isPublic: boolean }) => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Login{!_props.isPublic && <> (LDL-Demo)</>}</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    <i>Lacewing Digital Library{!_props.isPublic && <>, Development
                        Version</>}</i>{!_props.isPublic ? <> (LDL-Demo)</> : <> (LDL)</>}
                </p>
                <hr className="rule1" style={{marginBottom: "4px", marginTop: "4px",}}/>

                <p style={{color: "#FF0000"}}>The username and/or password you entered is incorrect. Click <a
                    href="/Authenticate/Login" target="_self">here</a> to try again.</p>

            </div>
        </div>
    </>
};
export default LoginFailure;