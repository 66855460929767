export interface MainTEXTType {
    [key: string]: {
        abstract: JSX.Element,
        Cite: JSX.Element,
        Author: JSX.Element,
        names: JSX.Element[][],
        author: JSX.Element
    }
}

export const MainTEXT: MainTEXTType = {
    World: {
        abstract: <>Faunal lists of the Neuropterida taxa known to occur (extant species and subspecies), and known to
            have occurred (extinct species and subspecies),
            in each of the 200+ countries of the world are presented.
            This is the first attempt ever to deliver a unified suite of Neuropterida faunal lists for all of the
            country-sized land areas of the globe.
            All faunal lists are interactively generated based upon user-selected options for five fauna-definition
            parameters.
            The parameter options selected are used to generate lists of consistent and parallel format from an
            underlying dataset of 15,500+ unique species-by-country distribution records.
            Each faunal list gives the following information for each listed taxon: (1) higher classNameification [order
            and family],
            (2) current name [species or subspecies], (3) presumed nativity to list area, (4) broader geographic
            distribution, (5) temporal occurrence,
            (6) lithostratigraphy [for fossils, where available], and (7) taxonomic status.
            List taxonomy and nomenclature follow the Neuropterida Species of the World catalogue.</>,
        Cite: <>Oswald, J. D. 2016. World Neuropterida by Country. Version 1.0. URL:
            http://lacewing.tamu.edu/Faunas/World. Last accessed [fill in date site last accessed].</>,
        Author: <>(JDO, corresponding author) <a href="mailto:j-oswald{tamu.edu">j-oswald@tamu.edu</a>.</>,
        names: [[<>John D. Oswald</>, <>Department of Entomology, Texas A&amp;M University</>, <> College Station, Texas
            77843-2475 USA</>]],
        author: <>John D. Oswald</>
    },
    Albania: {
        abstract: <>An updated checklist of the Neuropterida (Raphidioptera, Megaloptera, and
            Neuroptera) of Albania is provided. The checklist presented here is based on literature
            records, personal collections, and previously unpublished data obtained from specimens
            preserved in natural history collections. A total of 89 extant species are listed, placed in
            48 genera, 13 families, and three orders. No Neuropterida fossils or extinct species are
            currently known from Albania.</>,
        Cite: <>Devetak, D. Neuropterida of Albania. <a href="/HomePage/Publications" target="_blank"><i>Lacewing Digital
            Library, Research Publication</i></a> No. 8. https://lacewing.tamu.edu/Albania/Main. Last accessed [fill in
            date of last site access].</>,
        Author: <>(DD, corresponding author) <a className="email" style={{color: "#00C"}}
                                                href="mailto:dusan.devetak@guest.arnes.si">dusan.devetak@guest.arnes.si</a>.</>,
        names: [[<>Dušan Devetak</>, <>Department of Biology, University of Maribor</>, <>Koroška cesta 160, SI-2000
            Maribor, SLOVENIA</>]],
        author: <>Dušan Devetak</>
    },
    Ireland: {
        abstract: <>An inventory of the fauna of Neuropterida that occurs in Ireland is provided. The inventory contains
            only extant species, as no fossil neuropterids have yet been discovered in Ireland. The faunal records are
            divided into the 31 local authorities of Ireland. A brief description of the geography of the country is
            given, together with a discussion of how neuropterids may have colonized Ireland and a short history of
            research on Irish Neuropterida.</>,
        Cite: <>Jepson, J. E. Neuropterida of Ireland. <a href="/HomePage/Publications" target="_blank"><i>Lacewing
            Digital Library, Research Publication</i></a> No. 6. https://lacewing.tamu.edu/Ireland/Main. Last accessed
            [fill in date of last site access].</>,
        Author: <>(JJ, corresponding author) <a className="email"
                                                href="mailto:LacewingRS@gmail.com">LacewingRS@gmail.com</a>.</>,
        names: [[<>James E. Jepson</>, <>University of Manchester</>, <>Williamson Building, Oxford
            Road</>, <>Manchester, M13 9PL, UNITED KINGDOM</>]],
        author: <>James Jepson</>
    },
    Italy: {
        abstract: <>Faunal lists of the extant Neuropterida species and subspecies known to occur in each of the 20
            administrative regions of Italy are presented. No Neuropterida fossils or extinct species are currently
            known from Italy.</>,
        Cite: <>Letardi, A; Badano, D. Neuropterida of Italy. <a href="/HomePage/Publications" target="_blank"><i>Lacewing
            Digital Library, Research Publication</i></a> No. 3. https://lacewing.tamu.edu/Italy/Main. Last accessed
            [fill in date of last site access].</>,
        Author: <>(AL, corresponding author) <a href="mailto:agostino.letardi@enea.it">agostino.letardi@enea.it</a>;
            (DB, author) <a href="mailto:davide.badano@gmail.com">davide.badano@gmail.com</a>.</>,
        names: [[<>Agostino Letardi</>, <>ENEA, Italian National Agency for New Technologies, Energy and Sustainable
            Economic Development</>, <>Via Anguillarese 301, I-00123 Rome, ITALY</>], [<>Davide Badano</>, <>DISTAV,
            Dipartimento di Scienze delle Terra dell'Ambiente e della Vita, University of Genoa,</>, <>Corso Europa 26,
            I-16132 Genoa, ITALY</>]],
        author: <>Agostino Letardi & Davide Badano</>
    },
    Slovenia: {
        abstract: <>An updated checklist of the Neuropterida (Raphidioptera, Megaloptera, and Neuroptera) of Slovenia is
            provided. Although the oldest documented record of a neuropteran occurring in Slovenia dates back to 1685,
            new occurrence records continue to be discovered. The checklist presented here is based on literature
            records, personal collections, and previously unpublished data obtained from specimens preserved in natural
            history collections. A total of 112 extant species are listed, placed in 42 genera, 12 families, and three
            orders. No Neuropterida fossils or extinct species are currently known from Slovenia.</>,
        Cite: <>Devetak, D. Neuropterida of Slovenia. <a href="/HomePage/Publications" target="_blank"><i>Lacewing Digital
            Library, Research Publication</i></a> No. 4. https://lacewing.tamu.edu/Slovenia/Main. Last accessed [fill in
            date of last site access].</>,
        Author: <>(DD, corresponding author) <a className="email"
                                                href="mailto:dusan.devetak@guest.arnes.si">dusan.devetak@guest.arnes.si</a>.</>,
        names: [[<>Dušan Devetak</>, <>Department of Biology, University of Maribor</>, <>Koroška cesta 160, SI-2000
            Maribor, SLOVENIA</>]],
        author: <>Dušan Devetak</>
    },
    SouthAfrica: {
        abstract: <>An inventory is provided for the Neuropterida taxa that occur in South Africa. It includes a
            discussion of the taxa relative to the political boundaries (provinces) and natural major biomes of the
            country, and the biomes represented in each province. A brief history of the documentation and exploration
            of the South African Neuropterida fauna is followed by an outline of the approaches used in the current
            documentation of the fauna.</>,
        Cite: <>Mansell, M. W.; Oswald, J. D. Neuropterida of South Africa. <a href="/HomePage/Publications"
                                                                               target="_blank"><i>Lacewing Digital
            Library, Research Publication</i></a> No. 7. https://lacewing.tamu.edu/SouthAfrica/Main. Last accessed [fill
            in date of last site access].</>,
        Author: <>(MWM, corresponding author) <a className="email" href="mailto:mansel@mweb.co.za">mansel@mweb.co.za</a>;
            (JDO) <a className="email" href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a></>,
        names: [[<>Mervyn W. Mansell</>, <>Department of Zoology and Entomology, University of Pretoria</>, <>Pretoria,
            0002, SOUTH AFRICA</>], [<>John D. Oswald</>, <>Department of Entomology, Texas A&M University</>, <>College
            Station, Texas 77843-2475 USA</>]],
        author: <>Mansell & Oswald</>
    },
    Switzerland: {
        abstract: <>A checklist of the Neuropterida (Raphidioptera, Megaloptera, and Neuroptera) of Switzerland is
            provided. The inventory presently contains only extant species. Fossil neuropterids have recently been
            discovered in southern Switzerland, but are not yet described. The faunal records are divided into the 26
            political cantons of Switzerland. A short description of the alpine geography and the glacial past of
            Switzerland is given. The main contributors to the knowledge of Swiss Neuropterida are discussed.</>,
        Cite: <>Duelli, P.; Monnerat, C.; Koch, B. Neuropterida of Switzerland. <a href="/HomePage/Publications"
                                                                                   target="_blank"><i>Lacewing Digital
            Library, Research Publication</i></a> No. 9. https://lacewing.tamu.edu/Switzerland/Main. Last accessed [fill
            in date of last site access].</>,
        Author: <>(PD, corresponding author) <a className="email" style={{color: "#00C",}}
                                                href="mailto:peter.duelli@wsl.ch">peter.duelli@wsl.ch</a>; (CM,
            author) <a className="email" style={{color: "#00C"}}
                       href="mailto:Christian.Monnerat@unine.">christian.monnerat@infofauna.ch</a>; (BK, author) <a
                className="email" style={{color: "#00C"}} href="mailto:baerbel.koch@ti.ch">baerbel.koch@ti.ch </a></>,
        names: [[<>Peter Duelli</>, <>Swiss Federal Research Institute WSL</>, <>CH-8903 Birmensdorf,
            SWITZERLAND</>], [<>Christian Monnerat</>, <>Info Fauna</>, <>Bellevaux 51, CH-2000 Neuchâtel,
            SWITZERLAND</>], [<>Bärbel Koch</>, <>Museo Cantonale di Storia Naturale</>, <>6901 Lugano,
            SWITZERLAND</>]],
        author: <>The Authors</>
    },
    Brazil: {
        abstract: <></>,
        Cite: <>Conteras Ramos, A.; Cancino López, R. J.; Marquez López, Y.; Ardila Camacho, A.; Califre Martins, C.;
            Oswald, J. D. Neuropterida of Brazil. <a href="/HomePage/Publications" target="_blank"><i>Lacewing Digital
                Library, Research Publication</i></a> No. 000. http://lacewing.tamu.edu/Mexico/Main. Last accessed [fill
            in date of last site access].</>,
        Author: <>(ACR, corresponding author) <a className="email" style={{color: "#00C"}}
                                                 href="mailto:acontreras@ib.unam.mx">acontreras@ib.unam.mx</a>;
            (RJCL) <a className="email" style={{color: "#00C"}}
                      href="mailto:cancinorodolfoj@gmail.com">cancinorodolfoj@gmail.com</a>;
            (YML) <a className="email" style={{color: "#00C"}}
                     href="mailto:yeseniamarquez23@gmail.com">yeseniamarquez23@gmail.com</a>;
            (AAC) <a className="email" style={{color: "#00C"}}
                     href="mailto:aardilac88@gmail.com">aardilac88@gmail.com</a>;
            (CCM) <a className="email" style={{color: "#00C"}}
                     href="mailto:calebcalifre@gmail.com">calebcalifre@gmail.com</a>;
            (JDO) <a className="email" style={{color: "#00C"}}
                     href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.</>,
        names: [[<>Atilano CONTRERAS RAMOS</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510 Ciudad
            de México, MEXICO</>], [<>Rodolfo J. CANCINO LÓPEZ</>, <>Departamento de Zoologia, Instituto de
            Biología-UNAM</>, <>04510 Ciudad de México, MEXICO</>], [<>Yesenia MARQUEZ LÓPEZ</>, <>Universidad Autónoma
            Metropolitana-Iztapalapa</>, <>09340 Ciudad de México, MEXICO</>], [<>Adrian ARDILA
            CAMACHO</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510 Ciudad de México,
            MEXICO</>], [<>Caleb CALIFRE MARTINS</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510
            Ciudad de México, MEXICO</>], [<>John OSWALD</>, <>Department of Entomology, Texas A&M
            University</>, <>College Station, Texas 77843-2475 USA</>]],
        author: <>The Authors</>
    },
    China: {
        abstract: <></>,
        Cite: <>Liu, X.-y.; Oswald, J. D. Neuropterida of China. <a href="/HomePage/Publications" target="_blank"><i>Lacewing
            Digital Library, Research Publication</i></a> No. 00. https://lacewing.tamu.edu/China/Main. Last accessed
            [fill in date of last site access].</>,
        Author: <>(XYL, corresponding author) <a className="email" style={{color: "#00C"}}
                                                 href="mailto:liuxingyue@cau.edu.cn">liuxingyue@cau.edu.cn</a>; (JDO) <a
            className="email" href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.</>,
        names: [[<>Xing-yue LIU</>, <>Department of Entomology, China Agricultural University</>, <>Beijing 100193
            CHINA</>], [<>John D. OSWALD</>, <>Department of Entomology, Texas A&M University</>, <>College Station,
            Texas 77843-2475 USA</>]],
        author: <>Liu & Oswald</>
    },
    France: {
        abstract: <></>,
        Cite: <>Giacomino, M.; Thierry, D.; Tillier, P; Canard, M. Neuropterida of France. <a
            href="/HomePage/Publications" target="_blank"><i>Lacewing Digital Library, Research Publication</i></a> No.
            000. https://lacewing.tamu.edu/France/Main. Last accessed [fill in date of last site access].</>,
        Author: <>(MG, corresponding author)<a
            href="mailto:giacomino.matthieu@hotmail.fr"> giacomino.matthieu@hotmail.fr</a>; (DT) <a
            href="mailto:dominique.thierry@wanadoo.f">dominique.thierry@wanadoo.fr</a>; (PT) <a
            href="mailto:p.tillier.entomo@free.fr">p.tillier.entomo@free.fr</a>; (MC) <a
            href="mailto:michel.canard@wanadoo.fr">michel.canard@wanadoo.fr</a>.</>,
        names: [[<>Matthieu Giacomino</>, <>30 rue du Moulin aux Moines</>, <>F-53940 Saint-Berthevin
            FRANCE</>], [<>Dominique Thierry</>, <>Institut de Recherche Fondamental et Appliqueé</>, <>Université
            Catholique de I'Ouest</>, <>44 rue Rabelais, F-49000 Angers FRANCE</>], [<>Pierre Tillier</>, <>8 rue
            d'Aire</>, <>F-95660 Champagne-sur-Oise FRANCE</>], [<>Michel Canard</>, <>47 Chemin Flou de
            Rious</>, <>F-31400 Toulouse FRANCE</>]],
        author: <>The Authors</>
    },
    Honduras: {
        abstract: <></>,
        Cite: <>Murillo Bustillo, M. A.; Contreras Ramos, A.; Oswald, J. D. Neuropterida of Honduras. <a
            href="/HomePage/Publications" target="_blank"><i>Lacewing Digital Library, Research Publication</i></a> No.
            000. https://lacewing.tamu.edu/Honduras/Main. Last accessed [fill in date of last site access].</>,
        Author: <>(MAMB, corresponding author) <a className="email" style={{color: "#00C"}}
                                                  href="mailto:martinalfredo92@hotmail.com">martinalfredo92@hotmail.com</a>;
            (ACR) <a className="email" style={{color: "#00C"}}
                     href="mailto:acontreras@ib.unam.mx">acontreras@ib.unam.mx</a>; (JDO) <a className="email"
                                                                                             style={{color: "#00C"}}
                                                                                             href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.</>,
        names: [[<>Martin Alfredo MURILLO BUSTILLO</>, <>Instituto Tecnológico Superior de Tela (ITST)</>, <>Universidad
            Nacional Autónoma de Honduras (UNAH)</>, <>Tela, Atlántida, HONDURAS</>], [<>Atilano CONTRERAS
            RAMOS</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510 Ciudad de México,
            MEXICO</>], [<>John David OSWALD</>, <>Department of Entomology, Texas A&M University</>, <>College Station,
            Texas 77843-2475 USA</>]],
        author: <>The Authors</>
    },
    NorthMacedonia: {
        abstract: <></>,
        Cite: <>Devetak, D. 2018. Neuropterida of Macedonia. URL: http://lacewing.tamu.edu/Faunas/Macedonia. Last
            accessed [fill in date site last accessed].</>,
        Author: <>(DD, corresponding author) <a className="email"
                                                href="mailto:dusan.devetak@guest.arnes.si">dusan.devetak@guest.arnes.si</a>.</>,
        names: [[<>Dušan Devetak</>, <>Department of Biology, University of Maribor</>, <>Koroška cesta 160, SI-2000
            Maribor, SLOVENIA</>]],
        author: <>Dušan Devetak</>
    },
    Mexico: {
        abstract: <></>,
        Cite: <>Conteras Ramos, A.; Cancino López, R. J.; Marquez López, Y.; Ardila Camacho, A.; Califre Martins, C.;
            Oswald, J. D. Neuropterida of Mexico. <a href="/HomePage/Publications" target="_blank"><i>Lacewing Digital
                Library, Research Publication</i></a> No. 000. http://lacewing.tamu.edu/Mexico/Main. Last accessed [fill
            in date of last site access].</>,
        Author: <>(ACR, corresponding author) <a className="email" style={{color: "#00C"}}
                                                 href="mailto:acontreras@ib.unam.mx">acontreras@ib.unam.mx</a>;
            (RJCL) <a className="email" style={{color: "#00C"}}
                      href="mailto:cancinorodolfoj@gmail.com">cancinorodolfoj@gmail.com</a>;
            (YML) <a className="email" style={{color: "#00C"}}
                     href="mailto:yeseniamarquez23@gmail.com">yeseniamarquez23@gmail.com</a>;
            (AAC) <a className="email" style={{color: "#00C"}}
                     href="mailto:aardilac88@gmail.com">aardilac88@gmail.com</a>;
            (CCM) <a className="email" style={{color: "#00C"}}
                     href="mailto:calebcalifre@gmail.com">calebcalifre@gmail.com</a>;
            (JDO) <a className="email" style={{color: "#00C"}}
                     href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.</>,
        names: [[<>Atilano CONTRERAS RAMOS</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510 Ciudad
            de México, MEXICO</>], [<>Rodolfo J. CANCINO LÓPEZ</>, <>Departamento de Zoologia, Instituto de
            Biología-UNAM</>, <>04510 Ciudad de México, MEXICO</>], [<>Yesenia MARQUEZ LÓPEZ</>, <>Universidad Autónoma
            Metropolitana-Iztapalapa</>, <>09340 Ciudad de México, MEXICO</>], [<>Adrian ARDILA
            CAMACHO</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510 Ciudad de México,
            MEXICO</>], [<>Caleb CALIFRE MARTINS</>, <>Departamento de Zoologia, Instituto de Biología-UNAM</>, <>04510
            Ciudad de México, MEXICO</>], [<>John OSWALD</>, <>Department of Entomology, Texas A&M
            University</>, <>College Station, Texas 77843-2475 USA</>]],
        author: <>The Authors</>
    },
    Serbia: {
        abstract: <></>,
        Cite: <>Devetak, D. 2018. Neuropterida of Serbia. URL: http://lacewing.tamu.edu/Faunas/Serbia. Last accessed
            [fill in date site last accessed].</>,
        Author: <>(DD, corresponding author) <a className="email"
                                                href="mailto:dusan.devetak@guest.arnes.si">dusan.devetak@guest.arnes.si</a>.</>,
        names: [[<>Dušan Devetak</>, <>Department of Biology, University of Maribor</>, <>Koroška cesta 160, SI-2000
            Maribor, SLOVENIA</>]],
        author: <>Dušan Devetak</>
    },
    UnitedStates: {
        abstract: <>A general introduction to the United States fauna of the insect superorder Neuropterida (orders
            Neuroptera, Megaloptera, Raphidioptera, and Glosselytrodea) is presented and an interactive checklist
            generator provides functionality for displaying faunal checklists at three levels of political subdivision:
            (1) the entire United States,
            (2) individual states, territories, and possessions, and (3) county-level divisions.
            Faunal data has been synthesized from multiple sources&mdash;inculding literature records and selected
            personal and institutional natural history collections&mdash;
            and the incorporated data set is the most extensive compilation of Neuropterida faunal data yet assembled
            for the country.
            A total of 000 species (000 extant and 000 extinct) are reported from the states and territories of the
            United States.</>,
        Cite: <>Bowles, D. E.; Oswald, J. D. Neuropterida of the United States. <a href="/HomePage/Publications"
                                                                                   target="_blank"><i>Lacewing Digital
            Library, Research Publication</i></a> No. 000. https://lacewing.tamu.edu/UnitedStates/Main. Last accessed
            [fill in date of last site access].</>,
        Author: <>(DEB, corresponding author) <a className="email" style={{color: "#00C"}}
                                                 href="mailto:david_bowles@nps.gov">david_bowles@nps.gov</a>; (JDO) <a
            className="email" style={{color: "#00C"}} href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.</>,
        names: [[<>David E. Bowles</>, <>Department of Biology, Missouri State University</>, <>Springfield, Missouri
            65897 USA</>], [<>John D. Oswald</>, <>Department of Entomology, Texas A&M University</>, <>College Station,
            Texas 77843-2475 USA</>]],
        author: <>Bowles & Oswald</>
    },
    default: {abstract: <></>, Cite: <>Oswald, J. D. %MAINPAGEHEADER%. <a href="/HomePage/Publications" target="_blank"><i>Lacewing Digital Library, Research Publication</i></a> No. 000. https://lacewing.tamu.edu/%MODULENAME%/Main. Last accessed [fill in date of last site access].</>, Author: <></>, names: [[<>[Givenname Surname]</>, <>[Address line 1]</>, <>[Address line 2]</>]], author: <></>}
}
