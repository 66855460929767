import React from "react";

const INTER_Main = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>Neuropterida Associations</h1>
                <p className="subheader">
                    Associations of Extant<br/>
                    Neuroptera, Megaloptera, and Raphidioptera<br/>
                    (Insecta: Neuropterida)
                </p>

                <table style={{textAlign: "center"}} className="nametable">
                    <tbody>
                    <tr>
                        <td style={{textAlign: "center", paddingTop: "12px"}}>
                            John D. OSWALD<br/>
                            Department of Entomology, Texas A&M University<br/>
                            College Station, Texas 77843-2475 USA
                        </td>
                        <td width="50px"></td>
                        <td style={{textAlign: "center", paddingTop: "12px"}}>
                            Samuel Z. HOWARD<br/>
                            Department of Entomology, Texas A&M University<br/>
                            College Station, Texas 77843-2475 USA
                        </td>
                        <td width="50px"></td>
                        <td style={{textAlign: "center", paddingTop: "12px"}}>
                            Gary L. MILLER<br/>
                            USDA-ARS, Systematic Entomology Laboratory<br/>
                            Beltsville, Maryland 20705 USA
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p style={{textAlign: "center"}}>
                    <a style={{width: "120px", color: "white",}} href="Introduction"
                       className="btn btn-info">Introduction</a>
                    &nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href="ooooo" className="btn btn-info">Search1</a>
                    &nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href="ooooo" className="btn btn-info">Search2</a>
                </p>

                <p style={{textAlign: "left"}}>
                    <i><u>Abstract</u></i>: [abstract text to be added here...]
                    <br/>
                    <br/>
                    <i><u>Cite this Publication as</u></i>: Oswald, J. D.; Howard, S. Z.; Miller, G. L. Neuropterida
                    Associations. <a href="/HomePage/Publications" target="_top"><i>Lacewing Digital Library, Research
                    Publication</i></a> No. 000. https://lacewing.tamu.edu/Associations/Main. Accessed on [fill in date
                    site last accessed].
                    <br/>
                    <br/>
                    <small>(Note: The different modules of the Lacewing Digital Library constitute distinct publications
                        and should be cited separately. For information on the current version and release of this
                        publication see the <a href="/Associations/Versions">Versions</a> page.)</small>
                    <br/>
                    <br/>
                    <i><u>Author Contact Information</u></i>: (JDO, corresponding author) <a
                    href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>; (SZH) <a className="email"
                                                                                    style={{color: "#00C",}}
                                                                                    href="mailto:howardsamuel56{gmail.com">howardsamuel56@gmail.com</a>;
                    (GLM) <a className="email" style={{color: "#00C"}}
                             href="mailto:gary.miller@ars.usda.gov">gary.miller@ars.usda.gov</a>.
                    <br/>
                    <br/>
                    <i><u>Keywords</u></i>: [add keywords here]
                </p>

                <p className="author-reserve-markup">
                    © The Authors, 2020<br/>
                    All rights reserved.
                </p>
            </div>

        </div>
    </>
};
export default INTER_Main;