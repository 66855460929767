import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import {callGETAPI} from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    IconLink,
    ParagraphDesc,
    Record,
    SafeHTML
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlMetakeyVersions} from "../../../../database/models/tblLdlMetakeyVersions";


const MetaKey_Versions = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlMetakeyVersions[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callGETAPI("/api/general/getMetaKeyVersions", (error: boolean, outData: tblLdlMetakeyVersions[] | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, []);

    return <>{resultData &&
        <Record title={"Versions"}>
            <ParagraphDesc><>The World Neuropterida Metakey is an integrating resource that contains web site and
                database elements. Both classes of elements are updated episodically. Significant one-time or cumulative
                updates are treated as ‘versions’ and are numbered and documented in Table 1. Minor updates between
                versions are treated as ‘releases’ and only the active release is documented in Table 1. The states of
                selected web pages as they existed at the time of the initial release of each version are documented as
                page snapshots in Table 2.
            </>
            </ParagraphDesc>
            <EasyTable caption={<>Table 1. Summary and comparative data on the documented versions and active dataset of the World
                Neuropterida Metakey.</>} tableRowData={resultData}>
                <EasyTableElement align={"center"} title={"Version"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => <SafeHTML html={val.Version}/>}
                                  desc={"the number of the documented version"}/>
                <EasyTableElement align={"center"} title={"Date"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => val.DateDocumented}
                                  desc={"the release date of the documented version"}/>
                <EasyTableElement align={"center"} title={"Total Keys"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => val.TotalKeys}
                                  desc={"the total (cumulative) number of keys contained in the dataset of the documented version or the active dataset (undocumented version)"}/>
                <EasyTableElement align={"center"} title={"Total Terminal Taxa"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => <SafeHTML
                                      html={val.TotalTerminalTaxa}/>}
                                  desc={"the total (cumulative) number of terminal taxa contained in the dataset of the documented version or the active dataset (undocumented version)"}/>
                <EasyTableElement align={"center"} title={"Publications Checked for Keys"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => <SafeHTML
                                      html={val.PublicationsCheckedForKeys}/>}
                                  desc={"the total (cumulative) number of Bibliography of the Neuropterida publications that have been checked for keys in the documented version"}/>
                <EasyTableElement align={"center"} title={"Publications with Keys"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => <SafeHTML
                                      html={val.PublicationsWithKeys}/>}
                                  desc={"the total (cumulative) number of checked publications that contain keys in the documented version"}/>
                <EasyTableElement align={"center"} title={"Benchmarks"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => <SafeHTML html={val.Notes}/>}
                                  desc={"notable features or milestones associated with documented versions"}/>
            </EasyTable>

            <EasyTable caption={<>Table 2. World Neuropterida Metakey page archive.</>} tableRowData={resultData}>
                <EasyTableElement align={"center"} title={"Version"}
                                  elementFromValue={(val: tblLdlMetakeyVersions) => <SafeHTML html={val.Version}/>}/>
                <EasyTableSection align={"center"} title={"Metakey Page"}>
                    <EasyTableElement align={"center"} title={"Main"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageMainURL && val.PageMainURL != "-----" ?
                                          <IconLink href={val.PageMainURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageMainURL}/>}</>}/>
                    <EasyTableElement align={"center"} title={"Introduction"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageIntroductionURL && val.PageIntroductionURL != "-----" ?
                                          <IconLink href={val.PageIntroductionURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageIntroductionURL}/>}</>}/>
                    <EasyTableElement align={"center"} title={"Scope Search"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageScopeSearchURL && val.PageScopeSearchURL != "-----" ?
                                          <IconLink href={val.PageScopeSearchURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageScopeSearchURL}/>}</>}/>
                    <EasyTableElement align={"center"} title={"Terminal Taxon Search"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageTerminalTaxonSearchURL && val.PageTerminalTaxonSearchURL != "-----" ?
                                          <IconLink href={val.PageTerminalTaxonSearchURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageTerminalTaxonSearchURL}/>}</>}/>
                    <EasyTableElement align={"center"} title={"Acknowledgements"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageAcknowledgmentsURL && val.PageAcknowledgmentsURL != "-----" ?
                                          <IconLink href={val.PageAcknowledgmentsURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageAcknowledgmentsURL}/>}</>}/>
                    <EasyTableElement align={"center"} title={"References"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageReferencesURL && val.PageReferencesURL != "-----" ?
                                          <IconLink href={val.PageReferencesURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageReferencesURL}/>}</>}/>
                    <EasyTableElement align={"center"} title={"Versions"}
                                      elementFromValue={(val: tblLdlMetakeyVersions) => <>{val.PageVersionsURL && val.PageVersionsURL != "-----" ?
                                          <IconLink href={val.PageVersionsURL}
                                                    IconID={LDLIcons.General_OpenFile_Work}/> :
                                          <SafeHTML html={val.PageVersionsURL}/>}</>}/>
                </EasyTableSection>
            </EasyTable>

        </Record>}</>
};


export default MetaKey_Versions;