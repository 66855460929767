import React, {useEffect, useState} from "react";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyGraphic, EasyGraphicHistogram,
    EasyTable,
    EasyTableElement,
    IconLink,
    Record,
    
    SearchCriteria, SearchCriteriaTWO
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {proccessSearch, SearchOutputProps} from "../../utils/CustomSearch";


enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface BiblioSearchProps {
    Auth?: { filter: filterType, Val: string },
    NomYear?: { filter: filterType, Val: string },
    Title?: { filter: filterType, Val: string },
    Journal?: { filter: filterType, Val: string },
    VolPg?: { filter: filterType, Val: string },
    BibObjID?: { filter: filterType, Val: number },
    CiteType?: { Val: string },
    LangText?: { Val: string },
    EDocs?: { Val: boolean },
}

const conversionTable = {
    "Author": "Auth",
    "Year": "NomYear",
    "Title": "Title",
    "Journal": "Journal",
    "(Ser)Vol(No):Pg-Pg": "VolPg",
    "Reference #": "BibObjID",
    "Publication Type": "CiteType",
    "Primary Language": "LangText",
    "Digital Files": "EDocs"
}
const BibloSearch = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlBibNeur[] | any>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    useEffect(() => {
        let out: BiblioSearchProps = Object()
        //out = proccessSearch(_props?.searchProps, conversionTable, out as SearchOutputProps);

            callAPI("/api/general/doBiblioSearch",_props.searchProps.apiData , (error: boolean, outData: tblLdlBibNeur[] | Response) => {
                if (error) {
                    console.log("error")
                } else {
                    setResultData(outData)
                }

            });

            /*callAPI("/api/general/doBiblioSearch", out, (error: boolean, outData: tblLdlBibNeur[] | Response) => {
                if (error) {
                    console.log("error")
                } else {
                    setResultData(outData)
                }

            });*/

    }, []);


    return <>{resultData && <>
        <Record title={"Search Results"}>
            <SearchCriteriaTWO searchCriteria={_props.searchProps.displayData}>
            <i>
                <>Results Records ({resultData.length}).</>
            </i>
            </SearchCriteriaTWO>
            <EasyTable autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData}>
                <EasyTableElement width={"2%"} title={"Ref#"} elementFromValue={(val) => val.BibObjID}
                                  desc={"Bibliography of the Neuropterida reference number"}/>
                <EasyTableElement width={"3%"} minWidth={"45px"} title={"Links"}
                                  elementFromValue={(val: tblLdlBibNeur) => <><IconLink
                                      IconID={LDLIcons.BotN_BibliographicRecord}
                                      href={"./Record"}
                                      HistoryLocStates={location.state}
                                      newStates={{"BibObjID": val.BibObjID}}/>
                                      {(val.LinkedEDocs && val.LinkedEDocs[0] && val.LinkedEDocs[0].EDocLink && (val.LinkedEDocs[0].PublicEDoc || (val.LinkedEDocs[0].PrivateEDoc && PDFAccess))) ? <>
                                          <IconLink newWindow={true} href={val.LinkedEDocs[0].EDocLink}
                                                    IconID={LDLIcons.General_OpenFile_Work}
                                                    />
                                          <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                    IconID={LDLIcons.Viewer_OpenFile}
                                                    newStates={{"BibObjID": val.BibObjID}}/></> : <> – –</>}</>}
                                  desc={"link to Bibliographic Record page of work and, when available and permissible, standard non-Viewer and Viewer digital files of work (note: to view a complete list of a reference's available digital files, including supplemental documents when available, check its Bibliographic Record page)"}/>
                <EasyTableElement width={"15%"} title={"Author"} elementFromValue={(val) => val.Auth} desc={"author(s) of work"}/>
                <EasyTableElement width={"3%"} title={"Year"} elementFromValue={(val) => val.NomYear}
                                  desc={"nominal year(s) of publication of work, typically its imprint date(s) (may or may not be actual year(s) of issue of work; check Bibliographic Record page of work for details)"}/>
                <EasyTableElement width={"52%"} title={"Title"} elementFromValue={(val) => val.Title} desc={"title of work"}/>
                <EasyTableElement width={"19%"} title={"Journal"} elementFromValue={(val) => val.Journal}
                                  desc={"serial title of work"}/>
                <EasyTableElement width={"9%"} title={"Vol:Pg"} elementFromValue={(val) => val.VolPg}
                                  desc={"volume and page(s) of work, format: (series)volume(number):firstpage-lastpage"}/>
            </EasyTable>
        </Record>
    </>
    }
    </>
};


export default BibloSearch;