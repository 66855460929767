import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import {callGETAPI} from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    ParagraphDesc,
    ParagraphHeader,
    Record,
    SafeHTML, TableLegendElement,
    TableLegendSection
} from "../../utils/CustomRecord";
import {tblLdlBibNeurVersions} from "../../../../database/models/tblLdlBibNeurVersions";


const Biblo_Versions = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlBibNeurVersions[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callGETAPI("/api/general/getBiblioVersions", (error: boolean, outData: tblLdlBibNeurVersions[] | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, []);

    return <>{resultData &&
        <Record title={"Versions"}>
            <ParagraphDesc><>The Bibliography of the Neuropterida (BotN) is an integrating resource that contains web
                site and database elements. Both classes of elements are updated episodically. Significant one-time or
                cumulative updates are sequentially numbered as new ‘versions.’ Smaller updates – to data,
                functionality, or both – made between version breaks are treated as ‘releases.’ In the tables below, the
                states of the BotN on the dates of initial release of several of the versions, and sometimes the active
                release, are provided for a variety of documentary data. Table 1 provides information on release dates
                and selected benchmark metrics and highlights; Table 2 provides data on the size and scope (record and
                column counts) of the primary data tables used to deliver the BotN. These data briefly document the
                historical growth and development of the bibliography as a shared information resource for the global
                neuropterology community.
            </>
            </ParagraphDesc>

            <EasyTable extraLegendChildren={<TableLegendSection title={"Notes"}>
                <>This table displays a variety of data designed to document the general growth of the Bibliography of the Neuropterida project over time</>
            </TableLegendSection>} caption={<>Table 1. A comparison of the numbered versions of the Bibliography of the Neuropterida, summarizing its
                growth and development.</>} tableRowData={resultData}>
                <EasyTableElement textIndent={"0px"} width={"10%"} valign={"top"} align={"center"} title={"Version"}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML html={val.Version}/>}
                                  desc={"the number assigned to the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} legendTitle={"Release Date"} title={<>Release Date<br/><p className="small-font">(release XX.0)</p></>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => val.ReleaseDate}
                                  desc={"the initial release date of the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} title={"Works"}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => val.Citations}
                                  desc={"the number of works cited in the version or active release"}/>
                <EasyTableElement textIndent={"0px"}  width={"10%"} align={"center"} legendTitle={"Linked Files"} title={<>Linked Files<br/>Total / Works</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                                      html={val.LinkedFiles}/>}
                                  desc={" the number of files linked to the bibliography in the version or active release (total files and number of works with one or more files)"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} legendTitle={"Digital Pages"} title={<>Digital<br/>Pages</>} elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                    html={val.DigitalPages}/>}
                                  desc={"an estimate of the number of unique digital pages included in the linked file set of the version or active release, calculated from page counts for individual publications contained as digital documents linked to the Bibliography"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} legendTitle={"Taxon Citations"} title={<>Taxon<br/>Citations</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML html={val.AnnotTaxon}/>}
                                  desc={"the number of taxon citations included in the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"10%"} align={"center"} legendTitle={"Figure Citations"} title={<>Figure<br/>Citations</>} elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                    html={val.AnnotFigure}/>}
                                  desc={"the number of figure citations included in the version or active release"}/>
                <EasyTableElement textIndent={"0px"} width={"12%"} align={"center"} legendTitle={"Format"} title={<>Format<br/><p className="small-font">(program)</p></>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML html={val.Format}/>}
                                  desc={"the general presentation format of the version or release, and (below) its associated primary software program"}/>
                <EasyTableElement textIndent={"0px"} width={"18%"} align={"center"} title={"Benchmarks"}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML html={val.Notes}/>}
                                  desc={"notable version, or active release, events and/or milestones"}/>
            </EasyTable>
            <EasyTable extraLegendChildren={<TableLegendSection title={"Notes"}>
                <>This table displays data that trace growth in the primary datasets used to deliver the Bibliography of the Neuropterida to the web. Values represent table record counts (upper numbers) and table column counts (lower numbers)</>
            </TableLegendSection>} extraSymbolsChildren={<TableLegendElement title={<>"&ndash;"</>} element={"not available or not applicable"}/>} width={"77%"} caption={<>Table 2. Bibliography of the Neuropterida: principal data tables (upper number = table record count;
                lower number = table column count).</>} align={"center"} tableRowData={resultData.filter((val)=>val.SortNum&&val.SortNum>=170)}>
                <EasyTableElement textIndent={"0px"} align={"center"} title={<>Version</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML html={val.Version}/>}/>
                <EasyTableElement textIndent={"0px"} align={"center"} title={<>Basic<br/>Reference<br/>Data<br/>(BibNeur)</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                                      html={val.tblLdlBibNeur}/>}/>
                <EasyTableElement textIndent={"0px"} align={"center"} title={<>Electronic<br/>Document<br/>Data<br/>(EDocs)</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                                      html={val.tblLdlEDocs}/>}/>
                <EasyTableElement textIndent={"0px"} align={"center"} title={<>Taxon<br/>Citation<br/>Data<br/>(BibNeurTaxa)</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                                      html={val.tblLdlBibNeurTaxa}/>}/>
                <EasyTableElement textIndent={"0px"} align={"center"} title={<>Figure<br/>Citation<br/>Data<br/>(BibNeurFigs)</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                                      html={val.tblLdlBibNeurFigs}/>}/>
                <EasyTableElement textIndent={"0px"} align={"center"} title={<>Version<br/>Data<br/>(BibNeur<br/>Versions)</>}
                                  elementFromValue={(val: tblLdlBibNeurVersions) => <SafeHTML
                                      html={val.tblLdlBibNeurVersions}/>}/>
            </EasyTable>
            <ParagraphHeader title={"A Short History of the Bibliography of the Neuropterida"}>
                <ParagraphDesc><>Work on what has become the <i>Bibliography of the Neuropterida</i> (BotN) began in
                    1982. The earliest version of the bibliography consisted of a 3x5 card set of neuropterid-related
                    reference citations, which eventually grew to ca. 4000 type-written cards. With the increasing
                    availability of personal microcomputers in the mid 1980's, all data from this card set were re-keyed
                    as an ASCII text file in 1984 or 1985 and came to be managed as a suite of word-processor files,
                    first in MicroPro WordStar (running under the CP/M operating system) and later in Microsoft Word
                    (running under DOS and Windows). In late 1989 the then word-processor-based citations were parsed
                    into standardized bibliographic data fields using a Word macro and uploaded into a custom R:BASE 2.0
                    database, which was ported to R:Base 4.0 in ca. 1993. In mid 1996 the bibliography data files were
                    migrated from R:BASE into an early version of Microsoft Access. All subsequent management of the
                    master data files underlying the bibliography have utilized various versions of Access (upgraded
                    from Access 2003 to Access 2010 in August 2012). Beginning in 1994, a series of
                    publically-accessible versions of the bibliography have been made available on-line via the
                    Internet. The digital technologies used to develop and deliver these versions have changed over time
                    as databasing and Internet technologies have evolved. Functionality improvements and resource growth
                    in recent versions the bibliography, together with the obsolescence of some previously-used
                    development software, make it impracticable to provide continued on-line access to the early
                    versions of the bibliography. But, notes on many of these early versions are available on the <a
                        href="/Biblio/Versions/VersionNotes" target="_top">Version Notes</a> page.
                </>
                </ParagraphDesc>
            </ParagraphHeader>
        </Record>}</>
};


export default Biblo_Versions;