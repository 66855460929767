import React from "react";

const Interaction_Introduction = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>Introduction</h1>
                <div id="introtable">
                    <table className="normalfont">
                        <tr>
                            <td style={{border: "1pxsolid#000000",}}>
                                <p style={{marginTop: "13px", textAlign: "center"}}>Contents</p>
                                <ul>
                                    <li><a href="#introduction">Introduction</a></li>
                                    <li><a href="#datasets">Datasets</a></li>
                                    <li><a href="#datasetDevelopment">Dataset Development</a></li>
                                    <li><a href="#findingKeys">Finding Keys</a></li>
                                    <li><a href="#searchInterfacePages">Search Interface Pages</a></li>
                                    <li className="no-decoration">
                                        <ul>
                                            <li><a href="#scopeSearchPages">Scope Search</a></li>
                                            <li><a href="#taxonSearchPages">Terminal Taxon Search</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#searchTips">Search Tips</a></li>
                                    <li className="no-decoration">
                                        <ul>
                                            <li><a href="#scopeSearchTips">Scope Search</a></li>
                                            <li><a href="#taxonSearchTips">Terminal Taxon Search</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#searchResultPages">Search Result Pages</a></li>
                                    <li className="no-decoration">
                                        <ul>
                                            <li><a href="#keyListPage">Key List Page</a></li>
                                            <li><a href="#taxonListPage">Terminal Taxon / Key List Page</a></li>
                                            <li><a href="#keyRecordPage">Key Record Page</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#discussion">Discussion</a></li>
                                    <li className="no-decoration">
                                        <ul>
                                            <li><a href="#extendedUses">Extended Uses</a></li>
                                            <li><a href="#misidentifications">Misidentifications</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </td>
                            <td style={{width: "20px",}}></td>
                        </tr>
                    </table>
                </div>


                <p id="introduction" style={{marginTop: "5px", textAlign: "justify",}} className="anchor-tag">
                    The association of individual organisms with the names of the taxa to which they belong—the process
                    and result that we commonly call “identification”—is one of the most fundamental activities in the
                    biological sciences. Accurate identifications and the use of internationally agreed upon rules for
                    scientific nomenclature make it possible for the observations of countless researchers, made over
                    the course of centuries and across diverse spans of space (local, state, country, continent,
                    global), to be correlated and partitioned among the millions of species and higher taxa that occupy
                    or have occupied the earth.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Specimens of unknown identity can be identified in a variety of ways, but in the entomological
                    sciences perhaps the dominant method for most of the past two centuries has been through the use of
                    identification keys. Identification keys are devices that allow users to differentiate among a set
                    of included taxa (a key’s ‘terminal taxa’), to place those organisms within the context of a
                    particular classNameification (reflected in the taxonomy used for a key’s terminal taxa), and to
                    associate those organisms with one or more taxon names (generally scientific names). Print-based
                    identification keys have historically used morphological character information coded as written
                    texts, often supplemented with illustrations, to accomplish these closely coordinated objectives.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Identification keys are typically written by experts with specialized knowledge of a group of
                    organisms, are generally written to treat the taxa found in a particular area, and usually, to treat
                    specific sexes or life stages. These shared features, and other characteristics that are typical of
                    nearly all keys, can be used to define the ‘scope’ of a key. Because the majority of identification
                    keys are written and published by taxon experts, most are published in technical scientific journals
                    and are often difficult for the average identifier to access. Indeed, it is often hard for the
                    average identifier to even discover if a key exists that treats a particular group of taxa found in
                    a specific area. Identification keys can become outdated as new species are described, discovered,
                    or introduced in a particular area, or due to changes in the taxonomy of a group. Thus, keys need to
                    be updated and rewritten episodically in order to maintain their currency, and identifiers need to
                    be able to discover the most up-to-date key available for a particular group in a specific area.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Few general resources exist to assist identifiers in the process of efficiently locating the keys
                    that may be available and most appropriate for their identification needs. Print-based resources
                    that provide assistance in this area (e.g., Hollis 1980) are subject to becoming out of date, are
                    far from comprehensive, and typically provide only a few entry points into the relevant literature.
                    The World Neuropterida Metakey (WNM) has been developed primarily to address the need for a
                    convenient and broadly-based resource to help identifiers discover and access keys appropriate for
                    identifying neuropterid insects around the world. The WNM is not a key itself; rather, it is an
                    interactive tool that uses metadata captured about the scopes and terminal taxa of keys published
                    elsewhere to provide identifiers with a mechanism to discover, and often to directly access,
                    identification keys that may meet their identification needs.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    By tapping into and establishing new linkages among the datasets developed over the past three
                    decades for the Bibliography of the Neuropterida (Oswald 2016) and the Neuropterida Species of the
                    World (Oswald 2015), the WNM attempts to reduce the distance between identifiers and the technical
                    identification literature. In doing so, the WNM seeks to advance the goals of making it easier for
                    identifiers to make accurate identifications and to reduce the time lag between the collection of a
                    specimen and its identification.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="datasets"><b>Datasets</b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The interactive search functionality and tabular displays of key metadata found in the WNM utilize
                    two data tables—Keys and Terminal Taxa—which are derived programmatically from a larger body of
                    underlying data contained in the author’s World Neuropterida Database (WND). The Keys table contains
                    data primarily used for scope searches, search results pages, and key list data page displays; the
                    Terminal Taxa table contains data primarily used for terminal taxon searches. Additional information
                    about these two tables is provided below.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Keys Table</i>: Each key included in the WNM has a separate record in the Keys table and has been
                    scored for 10 standardized metadata fields. These fields capture data about the taxonomic and
                    geographic scopes of the key; the sexes and life stages that it treats; its language, format, and
                    number of couplets; and bibliographical information about its location in the literature. Each key
                    has also been separately scored for the scientific names and ranks of its Neuropterida terminal
                    taxa.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Most publications that that contain keys contain only one or a few keys, and, if multiple keys are
                    present, the individual keys can be easily differentiated based on their discrete domains and flow
                    structures. A few publications contain a larger number of keys. These keys generally treat the
                    subtaxa (e.g., genera or species) of a larger taxon (e.g., an order or family) in a series of
                    separate keys. These groups of keys with related domains may be called ‘key families’. Each of the
                    component keys in a key family typically has a separate header, flow structure (e.g., separate
                    couplet numbering), and taxonomic scope. The terminals in the component keys of key families may all
                    be taxon names, or some terminals may explicitly reference other keys in the same family by name or
                    number. For the purposes of the WNM, each of the individual component keys in a key family is
                    generally treated as a separate key (i.e., is represented by a separate record in the Keys table),
                    even if it contains only a single taxon (a ‘half couplet’). Similarly, separately-published ‘key
                    fragments’ (short key segments that are intended to correct, modify, or extend a previously
                    published key) are treated individually and are included as separate records in the Keys table.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Terminal Taxa Table</i>: Each terminal taxon explicitly named in a key is represented by a
                    separate record in the Terminal Taxa table. For the purposes of the WNM, a terminal taxon is defined
                    as any taxon that is explicitly stated by name (either as a scientific name or as a vernacular name
                    that can be converted to a scientific name) in a key and that represents an identification endpoint
                    of part of a key couplet (broadly construed to included ‘couplets’ consisting of more or fewer that
                    two endpoints). A terminal taxon may be associated with an internal node of a key, but only if it is
                    explicitly stated by name; unstated ‘implied’ terminal taxa that might logically be associated with
                    internal nodes are not recorded. Each taxon that occurs by name in a key as a terminal is recorded
                    only once in the Terminal Taxa table, even if the taxon keys out in more than one place in a key
                    (for instance, if variable taxa, or different sexes of the same taxon, key out at different
                    endpoints within the same key).
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The names of terminal taxa are recorded in the WNM as scientific names. Nomenclaturally available
                    names are recorded in their Code-compliant forms, i.e., with strictly nomenclatural errors corrected
                    (e.g., incorrect subsequent spellings, incorrect capitalizations, and incorrect species-group name
                    gender endings). Incorrect author and date attributions have also been corrected to their
                    Code-compliant forms. All nomenclatural errors have been corrected to match the name spellings used
                    in the Lacewing Digital Library standard nomenclature, based on the dataset of the Neuropterida
                    Species of the World catalogue (Oswald 2015) that was current when the Terminal Taxa table was
                    generated. Nomenclatural errors are specifically differentiated here from the taxonomic opinions
                    expressed by authors in their choice of combinations of genus- and species-group names, and their
                    choice of supergeneric taxon names and ranks. The processes used to capture data for the WNM correct
                    nomenclatural errors, but preserve the taxonomic opinions of key authors.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The scientific names of terminal taxa in the genus and species groups are recorded as “full
                    combinations”. For the purposes of the WNM a “full combination” consists of any genus- and/or
                    species-group names that are explicitly printed at the identification endpoint of a key couplet,
                    plus any other associated genus- and/or species-group names (e.g., subgenera or species-groups) that
                    are implicit given the taxonomic scope of the key, and/or that are implicit given the overall
                    taxonomy used in the work containing the key. Because the practices of different authors and
                    publications vary in how terminal taxa are actually displayed at the identification endpoints of
                    keys, the adoption of a “full combination” convention for recording terminal taxon names allows
                    terminal taxa to be captured, and subsequently searched, in a consistent and predictable manner.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The scientific names of terminal taxa above the genus group are recorded as uninominal names.
                    Semi-scientific vernacular names (e.g., Navás family-group names with Hispanicized spellings and
                    rank endings) are recorded using their associated proper scientific name forms (even if the
                    availability of the proper name form postdates the vernacular name form). Terminal taxon names
                    originally written as non-scientific vernacular names (in Latin or other scripts) have been
                    converted, to the best of the ability of the author, to their scientific name equivalents for
                    recording. Where that has (rarely) not been possible, those terminal taxa have been omitted from the
                    Terminal Taxon table. In many cases, where the name recorded in the Terminal Taxon table does not
                    precisely match the name form originally used by a key’s author a “[sic]” note containing the
                    original spelling has been appended to the recorded name. However, the two most common classNamees
                    of simple nomenclatural errors—incorrect capitalization and incorrect gender endings of
                    species-group names—are not annotated with “[sic]” citations unless the incorrect original name form
                    also contained another kind of error. Association of “[sic]” citations with names recorded in the
                    Terminal Taxa table began after data recording for the WNM project had already begun, so names
                    recorded for some keys will not contain “[sic]” citations as generally noted above.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="datasetDevelopment"><b>Dataset Development</b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The WNM currently includes data on {">"}3200 individual keys that contain terminal taxa presently
                    placed in the superorder Neuropterida. While it is not possible to know precisely how complete the
                    WNM’s datasets are relative to the total body of published Neuropterida keys, based on the extensive
                    examinations made to date (see below) of the primary taxonomic and other scientific literature of
                    the Neuropterida for identification keys, it is believed that the WNM’s datasets currently contain
                    information on at least a substantial majority of the identification keys that have ever been
                    published, worldwide, to identify insects in the orders Neuroptera, Megaloptera, Raphidioptera, and
                    Glosselytrodea to the level of genus or below. The author would appreciate being made aware of any
                    further keys, written in any language, that are presently missing from the WNM, so that they can be
                    added to future datasets.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The capture of metadata describing Neuropterida keys for the WNM project began in ca. 2012 and is
                    ongoing. Dataset development has proceeded along two general lines, proactive and retroactive. For
                    proactive capture, recent publications that have passed through the author’s hands over the past
                    several years have been regularly checked for keys, and metakey-relevant data have been captured for
                    new keys found. For retroactive capture, emphasis to date has been placed on checking taxonomic and
                    systematics-related publications for keys, with special emphasis on the {">"}2700 neuropterid
                    publications that contain descriptions of new neuropterid taxa ({">"}95% of these have now been
                    checked for keys and appropriate data captured). Work is now continuing to broaden the retroactive
                    capture of data on keys found in other sectors of the historical neuropterid literature, with an
                    emphasis on local and regional faunal publications and works containing title keywords that suggest
                    that a publication may contain keys. To date {">"}4500 distinct publications have been checked for
                    keys. Of those publications {">"}850 have been found to contain keys and their relevant key data
                    captured. For summary and comparative data on the active WNM datasets, and the datasets of each
                    documented WNM version, see the Versions page.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The ultimate goal of the WNM project is to document all keys relevant to the identification of
                    neuropterid insects worldwide, regardless of the language or script in which they were published.
                    However, keys published in languages that use the Latin script currently dominate the WNM datasets.
                    Keys published in languages that use other scripts (e.g., Arabic, Cyrillic, Han, Hangul) are
                    included in the WNM where known; but, the author’s familiarity with the entire Neuropterida
                    literatures written in such languages is less complete than that for Latin-script languages, so the
                    percentage of the total body of neuropterid keys written in those languages that are included in the
                    WNM is almost certainly less than that for Latin-script languages.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="findingKeys"><b>Finding Keys</b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The WNM provides two search interfaces to help users find keys—the Scope Search and the Terminal
                    Taxon Search interfaces. The Scope Search interface supports searches that are based on a
                    standardized set of parameters that describe a key’s inclusiveness; the Terminal Taxon Search
                    interface supports searches that are based on the scientific names of a key’s terminal taxa. Each
                    search returns a results page, from which the user may (1) review synoptic data about the set of
                    keys, if any, that match the search’s parameters, (2) link to a key’s Key Record page to view
                    detailed information about the key, including a complete bibliographic citation to the publication
                    containing the key, and a list of the key’s terminal taxa [giving both ‘original’ and ‘current’
                    names], and (3) link to a digital file of the publication containing the key, where available and
                    permissible.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="searchInterfacePages"><b>Search Interface Pages</b></p>

                <p className="header-paragraph-2 anchor-tag" id="scopeSearchPages"><b><i>Scope Search</i></b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The Scope Search interface is designed to locate keys of interest based primarily on their recorded
                    taxonomic and/or geographic scopes. Scope searches can be further refined by applying options from
                    five ‘restriction’ parameters – sex, life stage, language, key format, and year of publication. Use
                    the scope search interface to locate keys that pertain to a specific higher taxon (e.g., an order,
                    family, or genus), to a specific geographic area (e.g., a continent, country, state, or region), or
                    to one or more restriction parameter values (e.g., a specific language, a particular life stage, or
                    a specific key format). To help minimize unsuccessful searches, the taxonomic and geographic scope
                    parameters are implemented as combo boxes that are prefilled with the complete set of unique values
                    contained in the Keys table for these parameters. The language and key format restriction combo
                    boxes are populated in the same manner. The sex and life stage restriction combo boxes contain some
                    options that group related values found in the Keys table (e.g., keys pertaining to different
                    individual larval instars are all grouped under the life stage ‘larva’ option). The publication year
                    restriction line contains controls to limit search results to keys published in a specific year, or
                    before or after a specific year.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    To execute a custom scope search, select a non-default option from one or more of the parameter
                    combo boxes on the scope search page, then click the Search button. To browse a listing of all of
                    the keys included in the active metakey dataset, click the Reset button then the Search button.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Taxonomic Scope Parameter</i>: The taxonomic scope of a key is taken to be the summed taxonomic
                    domain of all of its included terminal taxa; taxonomic scope is recorded (and searchable) as the
                    scientific name that most closely corresponds to that summed taxonomic domain. Where clearly stated
                    by the key’s author, the taxonomic scope of a key is usually recorded as the taxonomic domain
                    explicitly stated by the author (corrected for strictly nomenclatural errors, but not adjusted for
                    current taxonomy or current hierarchal taxon rank). If the stated taxonomic domain of a key consists
                    of a heterogeneous combination of more or less discrete elements (e.g., “Key to the Hemerobiidae and
                    Ascalaphidae of …”), the key may be recorded either as a single key (i.e., with one Keys table
                    record) using a taxonomic scope that encompasses all of its elements (e.g., “Neuroptera”), or as
                    multiple keys (i.e., with multiple Keys table records), one for each of its discrete taxonomic
                    elements (e.g., “Hemerobiidae” and “Ascalaphidae”). If not clearly stated by the key’s author, a
                    key’s taxonomic scope is inferred from the context in which it was published, using, to the extent
                    possible, a taxonomic scope name contemporary with the publication date of the key. Note that
                    because the taxonomic scopes of keys are recorded using the taxon names employed by their original
                    authors, keys that contain taxa belonging to a current higher taxon may be recorded under a variety
                    of taxonomic scopes, which may differ by name and/or by rank. For example, keys that include species
                    currently placed in the Neotropical subgenus <i>Nodita</i> might be found under any of the following
                    (or other) taxonomic scopes: “<i>Nodita</i>”, “<i>Leucochrysa</i>”, “<i>Leucochrysa (Nodita)</i>”, “<i>Chrysopa</i>”,
                    “Chrysopini”, “Chrysopinae”, “Chrysopidae”, or “Neuroptera”.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Geographic Scope Parameter</i>: The geographic scope of a key is interpreted to be the geographic
                    area over which the key’s author intended it to be used. Geographic scope is recorded (and
                    searchable) as the English-language name or phrase (the ‘geographic scope statement’) that
                    corresponds to that geographic area. Where clearly stated by the key’s author, the geographic scope
                    of a key is generally taken to be the area explicitly stated. Where not clearly stated, a key’s
                    geographic scope is inferred from the context in which the key was published. Recorded geographic
                    scopes may, or may not, correspond to currently-recognized geographical or geopolitical units, and
                    the boundaries of recorded geographic scope areas of older keys may, or may not, correspond
                    precisely with the boundaries of current geographical or geopolitical units that share the same
                    name. Keys to taxa that occur in a particular area may be recorded under a variety of different
                    geographic scope statements. For example, keys that include taxa found in the current Western Cape
                    province of South Africa might be found under any of the following (or other) geographic scope
                    statements: “Western Cape”, “Cape Province”, “South Africa”, “Africa (southern)”, “Africa”,
                    “Afrotropical”, or “World”. Useful keys might also be located by searches using geographic scope
                    statements for adjacent or nearby areas, e.g., “Northern Cape”, “Namibia”, or “Eastern Cape”. Keys
                    that purport, or can be inferred, to contain terminal taxa that represent all of the global subtaxa
                    of a specific higher taxon (at one or more taxonomic ranks and at the point in time when the key was
                    published) are recorded with a geographic scope of “World”, even if the combined distribution ranges
                    of the terminal taxa are restricted to a small area of the globe. This allows keys that were
                    apparently comprehensive for a particular higher taxon at their time of publication to be
                    distinguished from intentionally regional or local keys.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Sex Restriction Parameter</i>: Use the sex restriction parameter to limit search results to keys
                    that contain characters pertinent to keying individuals of one or both sexes. The parameter options
                    are: (1) “any sex” (default), which applies no search restriction on the basis of sex; (2) “male”,
                    which restricts search display results to male-only and male-and-female keys, (3) “female”, which
                    restricts search display results to female-only and male-and-female keys, and (4) “male and female”,
                    which restricts search display results to only male-and-female keys. The sex scope of a key is
                    recorded based on the scope explicitly stated by the key’s author, if any, and/or the characters
                    contained in the key. Keys to adults that consist primarily of sex-linked traits (e.g., sex-specific
                    terminalic traits) are coded as male-only or female-only, as appropriate. Keys to adults that
                    consist primarily of non-sex-linked traits, and keys to immature stages, are coded as
                    male-and-female. Keys that contain a mix of sex-linked and non-sex-linked characters are coded based
                    on whether or not their terminal taxa are primarily distinguished by sex-specific characters or not.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Life Stage Restriction Parameter</i>: Use the life stage restriction parameter to limit search
                    results to keys that contain characters appropriate for keying individuals belonging to a particular
                    life stage, or group of life stages. The parameter options are: (1) “any life stage” (default),
                    which applies no search restriction on the basis of life stage; (2) “adult”, which restricts search
                    display results to keys that contain adult characters; (3) “pupa”, which restricts search display
                    results to keys that contain pupal characters; (4) “larva” [any instar; includes prepupa], which
                    restricts search display results to keys that contain larval characters; and (5) “egg” [including
                    egg masses/groups and embryos], which restricts search display results to keys that contain
                    egg-related characters. The life stage scope of a key is recorded based on the scope explicitly
                    stated by the key’s author and/or by examination of the characters included in the key. Keys that
                    treat more than one life stage (either in the same set of couplets, or in differentiated sets of
                    couplets) are recorded for all life stages included. In such cases, separate Keys Table records are
                    created for each of the major life stages included in the key (i.e., adult, pupa, larva, egg). In
                    the case of keys that contain characters pertaining to multiple larval instars, only one Key Table
                    record is created, but the instar scope of the key is recorded if it is stated by the key’s author,
                    or if it is interpretable from the context in which the key was published. Relatively few keys exist
                    to the non-adult life stages of neuropterid insects. Consequently, selection of a life stage
                    parameter option other than ‘any life stage’ may severely restrict the number of keys returned in a
                    search.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Language Restriction Parameter</i>: Use the language restriction parameter to limit search
                    results to keys written in a specific language. The parameter option combo box contains a list of
                    all languages recorded in the Keys Table. Similar keys written in more than one language in the same
                    publication (e.g., keys in parallel-language publications, or keys written in one language in the
                    body of a work but translated into another language in a summary or abstract) are treated and
                    recorded as separate keys (i.e., with separate Keys Table records). The WNM currently contains data
                    on keys written in ca. 15 languages. However, ca. 60% of included keys are written in English, so
                    selection of a language parameter option other than ‘any language’ (default) or ‘English’ will
                    strongly restrict the number of keys returned in a search.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Format Restriction Parameter</i>: Use the format restriction parameter to limit search results to
                    keys presented in a particular format. The parameter options are: (1) “any format” (default), which
                    applies no search restriction on the basis of key format; (2) “traditional text”, which restricts
                    search display results to text-only keys, and keys whose associated illustrations are not intimately
                    associated with the key text, e.g., keys with illustrations that are contained in, but scattered
                    throughout, the same publication, or keys whose illustrations are contained in other publications;
                    (2) “text and pictures”, which restricts search display results to keys whose text and illustrations
                    are intimately associated, e.g., keys with figures interleaved between text couplets or placed
                    beside couplet texts on the same or adjacent pages; (3) “primarily pictures”, which restricts search
                    display results to keys in which illustrations are the dominant mode of couplet expression and text
                    is minimal or absent, e.g., keys that represent ‘couplets’ as pairs of illustrations and direct key
                    flow with arrows; and (4) “key fragment”, which restricts search display results to fragmentary key
                    texts published as corrections, modifications, or augmentations of other keys. More than 95% of
                    included keys are traditional text keys, so selection of a format parameter option other than ‘any
                    format’ or ‘traditional text’ will severely restrict the number of keys returned in a search. The
                    format restriction parameter is provided primarily as a convenient way to locate ‘non-standard’ keys
                    for taxonomic scopes of very high rank (e.g., order or superorder).
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Publication Year Restriction Parameter</i>: Use the publication year restriction parameter to
                    limit search results by the year that a key was published. To use this restriction parameter, make a
                    selection from the parameter combo box, then enter a valid year (1500 to the current year) in the
                    text box. The parameter combo box options are: (1) “is before”, which restricts search display
                    results to keys published before the year entered in the parameter text box; (2) “is”, which
                    restricts search display results to keys published in the year entered in the parameter text box;
                    and (3) “is after” (default), which restricts search display results to keys published after the
                    year entered in the parameter text box. Use the “is after” option to find only recent keys; use the
                    “is before” option to find only older or historical keys. If the parameter text box contains no year
                    value, no publication year restriction is applied to the search.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="taxonSearchPages"><b><i>Terminal Taxon Search</i></b>
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The Terminal Taxon Search interface is designed to locate keys of interest based on the scientific
                    names of their included terminal taxa. Terminal taxon names can be searched using the scientific
                    names originally used by the keys’ authors (corrected only for strictly nomenclatural errors), by
                    their current scientific names, or by both name sets concurrently. Use the terminal taxon search
                    interface to locate keys that contain a specific taxon (e.g., a family, tribe, genus, or species) as
                    a key terminal. This functionality is useful for locating keys that contain terminal taxa that are
                    not readily located using a scope search (e.g., keys that contain terminal taxa that are currently
                    assigned to a genus for which no modern key has yet been written). The functionality may be
                    especially useful for locating keys that can be used to confirm a preliminary identification made by
                    a different means.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    To execute a custom terminal taxon search: (1) make a selection from the Terminal Taxon Name combo
                    box, (2) enter a search string in the Terminal Taxon Name text box, (3) select an option from the
                    Search Name Set combo box, then (4) click the Search button. Steps (1) and (3) are optional if their
                    displayed selections are already suitable.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Terminal Taxon Name</i>. The Terminal Taxon Name text box accepts scientific name text strings
                    for searching (against name strings contained in the Terminal Taxa table); the Terminal Taxon Name
                    combo box provides options for how the text string is used during the search. The combo box options
                    are: (1) “begins with”, which finds instances of the entered text string at the beginning of name
                    strings contain in table Terminal Taxa; (2) “contains” (default), which finds instances of the
                    entered text string anywhere within Terminal Taxa name strings; (3) “ends with”, which finds
                    instances of the entered text string at the end of Terminal Taxa name strings; (4) “whole string
                    is”, which finds instances where the entered text string matches a complete Terminal Taxa name
                    string; and (5) “one word is”, which finds instances where the entered text string matches any
                    complete word in a Terminal Taxa name string [this is useful, for example, to return instances of
                    ‘<i>Myrmeleon</i>’, but not ‘Myrmeleontidae’]. All searches are case insensitive.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Search Name Set</i>. Use the Search Name Set combo box to select the terminal taxon name set to
                    use for a search. The name set options are: (1) “scientific name used in key” [=‘original’ name
                    set], which matches Terminal Taxon Name search strings against the set of names originally used in
                    keys by their authors (as interpreted below); (2) “current scientific name” [=‘current’ name set]
                    (default), which matches search strings against a set of current scientific names derived from the
                    names originally used in the keys; or (3) “both name sets”, which matches search strings against
                    both the original and current name sets. Scientific names contained in the ‘original’ name set have
                    been corrected for strictly nomenclatural errors (e.g., incorrect subsequent spellings, incorrect
                    gender variant endings), but have not been updated with regard to taxonomic opinion (e.g., generic
                    combinations, synonyms). Scientific names found in the ‘current’ name set have been corrected for
                    nomenclatural errors, and their taxonomy has been updated to reflect the Lacewing Digital Library
                    standard taxonomy that was current when the Keys and Terminal Taxa tables for the active Metakey
                    version were generated. In addition to updating genus- and species-group name combinations to their
                    LDL standard forms, higher taxon names (i.e., names in the family and order groups) are represented
                    in the ‘current’ name set by their full within-group classNameification strings (e.g.,
                    Campylophlebiini is represented as Ascalaphidae: Haplogleniinae: Campylophlebiini), which
                    facilitates standardized searching for higher taxon names when using the ‘current’ name set. The
                    ‘current’ name set is produced programmatically from the ‘original’ name set using functionality
                    developed in the author’s World Neuropterida Database.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="searchTips"><b>Search Tips</b></p>

                <p className="header-paragraph-2 anchor-tag" id="scopeSearchTips"><b><i>Scope Search</i></b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <ul>
                        <li>
                            Avoid over-parameterizing searches. The default parameter options are set to maximize the
                            probability of obtaining a successful search. For best results, search initially using one
                            option selected from either the Taxonomic Scope or the Geographic Scope combo box, not both.
                            If that search returns too many keys, re-run the search with additional non-default options
                            selected from other parameters to narrow the results.
                        </li>
                        <li>
                            In general, use the non-default options of restriction parameters sparingly. Some
                            restriction parameter options will severely restrict the number of keys returned and/or
                            greatly increase to probability that no keys will be returned.
                        </li>
                        <li>If a search returns no keys:</li>
                        <li className="no-decoration">
                            <ul>
                                <li>reduce the number of non-default parameter options selected</li>
                                <li>try searching for taxa and/or geographies of larger scope</li>
                                <li>try searching for keys scoped for adjacent or nearby geographies</li>
                                <li>try searching via the Terminal Taxon Search interface</li>
                            </ul>
                        </li>
                        <li>Remember, no keys (or no recent keys) may exist for many taxa in many parts of the world.
                        </li>
                        <li>Click the Reset button to return all parameters to their default states/values.</li>
                    </ul>
                </p>

                <p className="header-paragraph-2 anchor-tag" id="taxonSearchTips"><b><i>Terminal Taxon Search</i></b>
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <ul>
                        <li>
                            When searching for species-group names, do not use exact match search options (‘whole string
                            is’ or ‘one word is’) and omit the nomenclatural gender ending of the name (typically the
                            last 1-3 letters) in the search string. This will increase the probability of returning keys
                            that contain nomenclatural gender ending variants of the same species-group name.
                        </li>
                        <li>
                            Use the ‘one word is’ search option to exclude certain cases of partial word matches. For
                            example, to find “<i>Myrmeleon</i>”, but not “Myrmeleontidae”.
                        </li>
                        <li>All text string searches are insensitive to letter case.</li>
                        <li>Click the Reset button to return all parameters to their default states/values.</li>
                    </ul>
                </p>

                <p className="header-paragraph-2 anchor-tag" id="searchResultPages"><b>Search Results Pages</b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Successfully executed searches result in the display of a search results page – a Key List page for
                    scope searches, and a Terminal Taxon / Key List page for terminal taxon searches. Both pages provide
                    links to Key Record pages, which contain information about individual keys.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="keyListPage"><b><i>Key List Page</i></b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The Key List page displays the results of a Scope Search. The parameter options used in the search
                    are displayed at the top of the page on the Search Criteria line, followed below by a Key List table
                    that lists all of the keys, if any, that match the search criteria. Each line in a Key List table
                    represents a different key (i.e., a different record in the Keys Table). The Key List table is
                    presented in the form of one or more ‘list pages’, with a maximum of 200 keys per list page. If a
                    Key List table contains {">"}200 records, the List Pages line displays a control that facilitates
                    navigation among the list pages. The total number of records in the active Key List table is
                    reported on the Displayed Records line, together with the sequential number of records displayed on
                    the active list page.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The Key List table provides synoptic data about each key that can be used to evaluate its
                    suitability for specific identification needs. The table also includes a link to each key’s Key
                    Record page (see below), and a link to a digital file of the publication that contains the key (if
                    available and permissible). Key List table records are alphabetized by (1) taxonomic scope
                    [ascending], (2) geographic scope [ascending], (3) year [descending], and (4) life stage
                    [ascending].
                </p>

                <p className="header-paragraph-2 anchor-tag" id="taxonListPage"><b><i>Terminal Taxon / Key List Page</i></b>
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The Terminal Taxon / Key List page displays the results of a Terminal Taxon Search. The general
                    format and functionality of the Terminal Taxon / Key List page is nearly identical to the Key List
                    page (see above), but with the addition of a Terminal Taxon column. Each line in a Terminal Taxon /
                    Key List table represents a different terminal taxon found in a key. Depending upon the search
                    criteria used, some keys may be represented in a Terminal Taxon / Key List table by more than one
                    line (unlike a Key List table, in which each key occurs on a maximum of one line). The total number
                    of records in the active Terminal Taxon / Key List table is reported on the Displayed Records line,
                    together with the sequential number of records displayed on the active list page.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    In the case of terminal taxon searches whose Search Name Set parameter is set to include searching
                    by Current Scientific Name, if the search string results in a match in the current scientific name
                    set, but not in the original scientific name set, the Terminal Taxon column displays both the
                    matched ‘current’ name and the unmatched ‘original’ name (i.e., the name actually used in the key),
                    with the latter displayed in an “as” annotation (e.g., <i>Chrysoperla carnea</i> [as: <i>Chrysopa
                    carnea</i>]). This feature allows users to associate matches on current names – which may not be
                    found in a particular key – with the corresponding terminal taxon names actually used in the key.
                    Terminal Taxon / Key List table records are alphabetized by (1) terminal taxon [ascending;
                    disregarding “as” annotations], (2) taxonomic scope [ascending], (3) geographic scope [ascending],
                    (4) year [descending], and (5) life stage [ascending].
                </p>

                <p className="header-paragraph-2 anchor-tag" id="keyRecordPage"><b><i>Key Record Page</i></b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The Key Record page is reached from links shown on Key List and Terminal Taxon / Key List pages and
                    displays synoptic data for individual keys, including a link to the publication containing the key
                    (if available and permissible) and a listing of the key’s terminal taxa—using both ‘original’ and
                    ‘current’ taxon names. Use the Key Record page to review metadata captured for individual keys, and
                    to compare the original and current taxon names associated with individual keys. Current taxon names
                    are based on the Lacewing Digital Library standard taxonomy in use when the active WNM dataset was
                    generated.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="discussion"><b>Discussion</b></p>

                <p className="header-paragraph-2 anchor-tag" id="extendedUses"><b><i>Extended Uses</i></b></p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The original impetus for the development of the WNM project was to create a resource: (1) that could
                    be used to find Neuropterida keys in the widely scattered scientific literature, (2) that would
                    provide sufficient metadata about those keys to allow users to determine their likelihood of
                    applicability for specific identification tasks, and (3) that could provide ready access to digital
                    copies of those keys (where available and permissible). However, the metakey datasets and interfaces
                    developed for the WNM have a variety of potential extended uses beyond that of key discovery and
                    access. Several possible extended uses of metakey functionality and/or data are briefly noted below.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Identification Resource Evaluation</i>. The existence in the WNM datasets of a substantial body
                    of data about the keys available for identifying neuropterid taxa worldwide raises the possibility
                    of answering questions concerning the adequacy of that body of identification resources to address
                    desirable neuropterid identification objectives on both global and regional scales, and on a
                    taxon-by-taxon basis. For instance, does the body of existing keys cover all valid species? If not,
                    which taxa are included and which are lacking? Are inadequacies in the body of keys evenly
                    distributed across higher taxa, or are some higher taxa better covered than others? Are some regions
                    of the world better covered by keys than others? Does the probability of a group being well covered
                    by keys correlate with the size of the group? Do the groups that are relatively poorly covered by
                    keys share any particular characteristics? What is the ‘half-life’ of a key (the average number of
                    years before the scientific names of half of its terminal taxa change)? For taxa and geographic
                    regions whose existing key resources are inadequate, the answers to these and related questions
                    could provide useful context and justification for the development of additional identification
                    resources.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Nomenclature Updates</i>. The nomenclature used in a fixed-couplet key becomes outdated as
                    taxonomic changes are made to the terminal taxa included in the key. The nomenclature of the
                    terminal taxa of any key included in the WNM can be updated to the current LDL standard taxonomy by
                    using the Terminal Taxa table on the key’s Key Record page, which contains parallel columns for the
                    ‘original’ and ‘current’ scientific names for each neuropterid terminal contained in the key.
                    Identifiers can use these Terminal Taxa table listings as information sources to annotate copies of
                    older keys with updated nomenclature.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Identification Confirmation</i>. To check or verify the existing identification associated with a
                    specimen, use the Terminal Taxon Search interface to locate keys that contain the identified taxon
                    as a terminal. One or more of those keys may be useful for verifying the existing identification.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>Comparative Taxon Diagnoses</i>. For many keys, the couplet option sequences that lead to
                    particular terminal taxa provide suites of characters that can function as brief diagnoses of those
                    taxa. Lacking other more explicit diagnoses, these implicit diagnoses provide a way to concisely and
                    efficiently reconstructed certain aspects of an author’s taxon concept (at least within the
                    geographic scope of the key). And, perhaps more usefully, provides one way to compare historical
                    changes in the circumscriptions of taxa over time and across different authors.
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <i>History of Key Formats and Styles</i>. While the ‘traditional text’ key format has long been the
                    numerically dominant format for Neuropterida keys, some keys also exist in other formats. Even
                    within the traditional text format, a substantial range of variation exists within certain style
                    elements, and some of these elements have evolved over time and/or appear subject to regional
                    preference. A case study of the formats and style elements found in Neuropterida keys might make an
                    interesting contribution to the historical evolution of identification keys as a technique and
                    mechanism for making animal identifications.
                </p>

                <p className="header-paragraph-2 anchor-tag" id="misidentifications"><b><i>Misidentifications</i></b>
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    No provisions are currently made in the WNM for handling certain cases of misidentified terminal
                    taxa. This includes cases of outright misidentification, and cases of effective misidentification
                    that result from the use of names (particularly in keys of widely different age) whose concepts have
                    changed over time through circumscriptional changes due to taxon splitting, or through changes in
                    taxon interpretation due to nomenclatural circumstances (e.g., fixations of primary types). While
                    most cases of misidentification will be subtle, and will not result in obvious anomalies in the
                    search results pages, some misidentifications may be more noticeable and result in apparent
                    anomalies. For example, references to the osmylid <i>Osmylus fulvicephalus</i> that occur in keys
                    under the incorrect terminal taxon name <i>Osmylus chrysops</i> will resolve in Terminal Taxon
                    Searches to the chrysopid <i>Chrysopa perla</i> (under which the name <i>chrysops</i> is currently a
                    synonym). However, as misidentifications are expected to be rare in keys, anomalies from this source
                    should affect only a very small percentage of the {">"}20,000 total terminal taxa present in the
                    current WNM dataset.
                </p>

            </div>
        </div>

    </>
};
export default Interaction_Introduction;