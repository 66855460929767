import React, {useEffect, useRef, useState} from "react";
import {Outlet, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../../Shared/_Layout";
import "../../../Content/SpeciesCatalog/SpeciesCatalog.css"
import {BottomFooterName, UpperLeftNav, UpperRightNav} from "../../../utils/CustomRecord";

const Species_CatelogLayout = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);

    return <>

        <tbody  id={"mainBody"}>
        <tr >
            <td   colSpan={2}><main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">
                <Outlet context={[[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],<></>,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]]}></Outlet>
            </main></td>
        </tr>
        </tbody>

        <tfoot ref={footerRef} id={"mainFooter"}>
        <tr>

            <td colSpan={2}>            <nav>
                <span className="module" style={{color: "#9B6702",}}><BottomFooterName currentPathData={currentPathData}/>  </span>
                <a href="/SpeciesCatalog/Main" target="_top">Main </a>
                | <a href={"/SpeciesCatalog/Introduction"} target="_top">Introduction </a>
                | <a href={"/SpeciesCatalog/Classification"} target="_top">Classification </a>
                | <a href={"/SpeciesCatalog/Families"} target="_top">Families </a>
                | <a href={"/SpeciesCatalog/SimpleSearch"} target="_top">Simple Search </a>
                | <a href={"/SpeciesCatalog/AdvancedSearch"} target="_top">Advanced Search </a>
                | <a href={"/SpeciesCatalog/Acknowledgments"} target="_top">Acknowledgments </a>
                | <a href={"/SpeciesCatalog/References"} target="_top">References </a>
                {/* | <a href= {require("~/SpeciesCatalog/Citation")} target="_top">Citation</a> */}
                | <a href={"/SpeciesCatalog/Versions"} target="_top">Versions </a>
                {/* | <a href={require("~/SpeciesCatalog/Help?id=19")} target="_top">Help</a> */}
                |

                {verifiedUser?<a href={"/Authenticate/LoggedIn"} target="_top"> Logged In </a>:<a href={"/Authenticate/Login"} target="_top"> Log In </a>}|
                {PDFAccess?<text style={{color:"green"}}> PDF+</text>:<text style={{color:"red"}}> PDF{(!isPublic&&verifiedUser)&&<>(<a href={"/Authenticate/Migration"} title={"upgrade using old module login to get new demo-site with PDF+ login"}>upgrade</a>)</>}</text>}
                           </nav>
        </td>

        </tr>
        </tfoot>
        {/*<div containerStyle={{position:"fixed",bottom:"0px",left:"20px",zIndex:999}} className={"printOnly"}>
            &nbsp;<p className="author-reserve-markup">
            © John D. Oswald,&nbsp;&nbsp;{new Date().getFullYear()},&nbsp;&nbsp;All rights reserved.
            </p>
        </div>*/}
    </>
};
export default Species_CatelogLayout;