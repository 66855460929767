import React, {useState} from "react";
import CustomSearch, {SearchProp} from "../../utils/CustomSearch";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasySearch, EasySearchCheckBox,
    EasySearchField,
    EasySearchInput,
    EasySearchSection,
    EasySearchSelect, EqFilter, OpFilter,
    Record
} from "../../utils/CustomRecord";
import {tblLdlFaunaVersionsPk} from "../../../../database/models/tblLdlFaunaVersions";
import {Model, WhereOptions} from "sequelize";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {attributes} from "js-cookie";
import callAPI from "../../utils/APIhelpers";
import BiblioCiteTypes from "../../utils/OnServerStartLoadedJsons/BiblioCiteTypes.json"



enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface BiblioSearchProps {
    Auth?: { filter: filterType, Val: string },
    NomYear?: { filter: filterType, Val: string },
    Title?: { filter: filterType, Val: string },
    Journal?: { filter: filterType, Val: string },
    VolPg?: { filter: filterType, Val: string },
    BibObjID?: { filter: filterType, Val: number },
    CiteType?: { Val: string },
    LangText?: { Val: string },
    EDocs?: { Val: boolean },
}

const conversionTable = {
    "Author": "Auth",
    "Year": "NomYear",
    "Title": "Title",
    "Journal": "Journal",
    "(Ser)Vol(No):Pg-Pg": "VolPg",
    "Reference #": "BibObjID",
    "Publication Type": "CiteType",
    "Primary Language": "LangText",
    "Digital Files": "EDocs"
}



const BibloSearch = (_props: { advanced?: boolean }) => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const savedSearch = location.state as any | undefined;


    /*const handleSearchResults = (feildData: SearchProp[]) => {
        let statesCopy: any = location.state ? location.state : {};
        statesCopy["searchProps"] = feildData;
        if (_props.advanced) {
            statesCopy["advanced"] = true;
            navigate("/Biblio/AdvancedSearch/SearchResults", {state: statesCopy});
        } else {
            statesCopy["advanced"] = false;
            navigate("/Biblio/SimpleSearch/SearchResults", {state: statesCopy});
        }
    }*/
    //console.log(savedSearch)
    return <>
        <Record title={_props.advanced?"Advanced Search":"Simple Search"} ><EasySearch<tblLdlBibNeur> defaultSearchStates={savedSearch?.searchProps?.searchStateData}  onSearch={(data)=>{
            console.log(data)
            let statesCopy: any = location.state ? location.state : {};
            statesCopy["searchProps"] = data;
            if (_props.advanced) {
                statesCopy["advanced"] = true;
                navigate("/Biblio/AdvancedSearch/SearchResults", {state: statesCopy});
            } else {
                statesCopy["advanced"] = false;
                navigate("/Biblio/SimpleSearch/SearchResults", {state: statesCopy});
            }
        }}>
            <EasySearchSection title={"Search By"}>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>(val,["Auth","AuthNoDiacr"])} title={"Author"} desc={"(e.g., Banks, Ban, anks, nks)"}>
                    <EasySearchSelect defaultValue={"contains"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>(val,"NomYear")} title={"Year"} desc={"(e.g., 1933, 193, 93, 933)"}>
                    <EasySearchSelect defaultValue={"is"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>(val,["Title", "TitleNoDiacr"])} title={"Title"} >
                    <EasySearchSelect defaultValue={"contains"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>(val,["Journal", "JournalNoDiacr"])} title={"Journal"} >
                    <EasySearchSelect defaultValue={"contains"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>(val,"VolPg")} title={"(Ser)Vol(No):Pg-Pg"} desc={"(e.g., 104, 45-56, 10:34-14)"}>
                    <EasySearchSelect defaultValue={"contains"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>(val,"BibObjID")} title={"Reference #"} desc={"(e.g., 9532, 95, 53, 32)"}>
                    <EasySearchSelect defaultValue={"is"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput isNumber={true}/>
                </EasySearchField>
                {!_props.advanced&&<EasySearchField collapseDesc={true} feildFunction={(val)=>EqFilter<tblLdlBibNeur>(val,"EDocs")} title={"Digital Files"} desc={"Check here to return only records with linked digital files."}>
                    <EasySearchCheckBox colSpan={3} convertFunction={(val)=>{
                        console.log(val)
                        return val?"Available, see below.":undefined}} defaultChecked={false}/>
                </EasySearchField>}
            </EasySearchSection>
            {_props.advanced&&<EasySearchSection title={"Restrictions"}>
                <EasySearchField feildFunction={(val)=>EqFilter<tblLdlBibNeur>(val,"CiteType")} title={"Publication Type"} desc={"(e.g., simple serial)"}>
                    <EasySearchSelect colSpan={2} defaultValue={""}  options={["", ...BiblioCiteTypes]}/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlBibNeur>([{DataType:"Select",data:"contains"},val[0]],"LangText")} title={"Primary Language"} desc={"(e.g., English, German)"}>
                    <EasySearchSelect colSpan={2} defaultValue={""}  options={["", "(not available)", "Arabic", "Azerbaijani", "Belarusian", "Bulgarian", "Catalan", "Catalan and Latin", "Chinese", "Chinese (traditional)", "Croatian", "Czech", "Danish", "Dutch", "Dutch and French", "Dutch and Latin", "English", "English and Chinese", "English and French", "English and German", "English and Japanese", "English and Latin", "English and Norwegian", "English and Polish", "English and Portuguese", "English and Russian", "English and Spanish", "English and Turkish", "English, German and Italian", "Estonian", "Finnish", "French", "French and Latin", "Frisian", "Georgian", "German", "German and Japanese", "German and Latin", "Greek and English", "Hebrew", "Hungarian [=Magyar]", "Hungarian [=Magyar] and German", "Hungarian [=Magyar] and Latin", "Italian", "Italian and Latin", "Japanese", "Japanese and English", "Korean", "Latin", "Lithuanian", "Norwegian", "Persian [=Farsi]", "Polish", "Polish and Latin", "Portuguese", "Romanian", "Russian", "Serbian", "Serbo-Croatian", "Slovak", "Slovenian", "Spanish", "Spanish and English", "Spanish and Latin", "Swedish", "Swedish and Latin", "Turkish", "Ukranian", "Vietnamese"]}/>
                </EasySearchField>
                <EasySearchField collapseDesc={true} feildFunction={(val)=>EqFilter<tblLdlBibNeur>(val,"EDocs")} title={"Digital Files"} desc={"Check here to return only records with linked digital files."}>
                    <EasySearchCheckBox colSpan={3} convertFunction={(val)=>{
                        console.log(val)
                        return val?"Available, see below.":undefined}} defaultChecked={false}/>
                </EasySearchField>
            </EasySearchSection>}
        </EasySearch></Record>
        {/*!_props.advanced &&
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Simple Search",
                    sections: [{
                        title: "Search By", feilds: [
                            {
                                sectionName: "Author",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: "(e.g., Banks, Ban, anks, nks)"
                            },
                            {
                                sectionName: "Year",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "is",
                                input: true,
                                description: "(e.g., 1933, 193, 93, 933)"
                            },
                            {
                                sectionName: "Title",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: ""
                            },
                            {
                                sectionName: "Reference #",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "is",
                                input: true,
                                description: "(e.g., 9532, 95, 53, 32)"
                            },
                        ]
                    },
                        {
                            feilds: [
                                {
                                    sectionName: "Digital Files",
                                    checkmark: true,
                                    description: "Check here to return only records with linked digital files."
                                }
                            ]
                        }]
                }}/>*/}
        {/*_props.advanced &&
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Advanced Search",
                    sections: [{
                        title: "Search By", feilds: [
                            {
                                sectionName: "Author",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: "(e.g., Banks, Ban, anks, nks)"
                            },
                            {
                                sectionName: "Year",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "is",
                                input: true,
                                description: "(e.g., 1933, 193, 93, 933)"
                            },
                            {
                                sectionName: "Title",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: ""
                            },
                            {
                                sectionName: "Journal",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: ""
                            },
                            {
                                sectionName: "(Ser)Vol(No):Pg-Pg",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: "(e.g., 104, 45-56, 10:34-14)"
                            },
                            {
                                sectionName: "Reference #",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "is",
                                input: true,
                                description: "(e.g., 9532, 95, 53, 32)"
                            },
                        ]
                    },
                        {
                            title: "Restrictions", feilds: [
                                {
                                    sectionName: "Publication Type",
                                    criteria: ["", "(not available)", "Abstract", "Auction catalogue", "Chapter in book", "Dissertation/Thesis", "Manuscript", "Miscellaneous digital matter", "Miscellaneous printed matter", "Multivolume book", "Newsletter", "Newsletter article", "Newspaper", "Pamphlet", "Print-on-demand book", "Report", "See reference", "Simple book", "Simple serial article", "Unknown", "Website"],
                                    criteriaVal: "",
                                    bigCriteria: true,
                                    description: "(e.g., simple serial)"
                                },
                                {
                                    sectionName: "Primary Language",
                                    criteria: ["", "(not available)", "Arabic", "Azerbaijani", "Belarusian", "Bulgarian", "Catalan", "Catalan and Latin", "Chinese", "Chinese (traditional)", "Croatian", "Czech", "Danish", "Dutch", "Dutch and French", "Dutch and Latin", "English", "English and Chinese", "English and French", "English and German", "English and Japanese", "English and Latin", "English and Norwegian", "English and Polish", "English and Portuguese", "English and Russian", "English and Spanish", "English and Turkish", "English, German and Italian", "Estonian", "Finnish", "French", "French and Latin", "Frisian", "Georgian", "German", "German and Japanese", "German and Latin", "Greek and English", "Hebrew", "Hungarian [=Magyar]", "Hungarian [=Magyar] and German", "Hungarian [=Magyar] and Latin", "Italian", "Italian and Latin", "Japanese", "Japanese and English", "Korean", "Latin", "Lithuanian", "Norwegian", "Persian [=Farsi]", "Polish", "Polish and Latin", "Portuguese", "Romanian", "Russian", "Serbian", "Serbo-Croatian", "Slovak", "Slovenian", "Spanish", "Spanish and English", "Spanish and Latin", "Swedish", "Swedish and Latin", "Turkish", "Ukranian", "Vietnamese"],
                                    criteriaVal: "",
                                    bigCriteria: true,
                                    description: "(e.g., English, German)"
                                },
                                {
                                    sectionName: "Digital Files",
                                    checkmark: true,
                                    description: "Check here to return only records with linked digital files."
                                }
                            ]
                        }]
                }}/>*/}
    </>
};


export default BibloSearch;