import React from "react";

const Citation = () => {
    return <>
        <div className="text-body">

            <h1>Citation</h1>

            <p className="header-paragraph-1">
                The individually numbered monographs in the <i>Lacewing Digital Library, Research Publication</i> series
                should be cited as recommended on their title pages. For a list of current publications in this
                series <a href="/HomePage/Publications">click here</a>. To cite other aspects of the <i>Lacewing Digital
                Library</i> see below.
            </p>

            <hr/>

            <p className="header-paragraph-2"><b>(1) Numbered publications in the <i>Lacewing Digital Library, Research
                Publication</i> series:</b></p>
            <p className="indented-paragraph">Cite each work as recommended on its title (Main) page.</p>


            <p className="header-paragraph-2"><b>(2) General information about the <i>Lacewing Digital
                Library</i> project (e.g., home page, vision statement, project team):</b></p>
            <p className="indented-paragraph">Oswald, J. D. (chief editor). Lacewing Digital Library.
                https://lacewing.tamu.edu. Accessed on 13 July 2020.</p>


            <p className="header-paragraph-2"><b>(3) Unattributed <i>Lacewing Digital Library</i> content:</b></p>
            <p className="indented-paragraph">Oswald, J. D. (chief editor). Lacewing Digital Library. [name of page].
                [URL]. Accessed on [fill in date of last site access].</p>
            <h4>Example</h4>
            <p className="indented-paragraph">Oswald, J. D. (chief editor). Lacewing Digital Library. Downloads page.
                https://lacewing.tamu.edu/Downloads/Main. Accessed on 14 September 2020.</p>

        </div>

       </>
};
export default Citation;