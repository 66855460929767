import React from "react";

const Introduction = () => {
    return <>


        <div className="content">
            <div className="text-body">

                <h1>Introduction</h1>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1">
                    The <i>Bibliography of the Neuropterida</i> project (BotN or Bibliography below) is an on-going
                    effort to comprehensively catalogue, annotate and provide shared access to the global literature on
                    the insect orders Neuroptera (lacewings, antlions, owlflies and their allies), Megaloptera
                    (fishflies and Dobsonflies), Raphidioptera (snakeflies) and Glosselytrodea—collectively the
                    superorder Neuropterida. As a bibliography, the BotN currently documents and synthesizes data on
                    15,100+ distinct works spanning more than 1400 years (see <i>Temporal Scope</i> below). As a digital
                    library, the BotN contains links to 16,000+ digital documents (links displayed where permissible),
                    representing 8700+ distinct works and 135,000+ unique pages of neuropterid-related text and
                    illustrations. As a literature annotation resource, the BotN contains 136,300+ annotations in
                    several categories (see <em>Annotations</em> below). For additional information on the scope and
                    development of the BotN see below and the <a href="Versions" target="_top">Versions</a> page.

                </p><p style={{textAlign: 'justify'}} className="header-paragraph-1">
                <b><i> Using the Bibliography</i></b>

            </p><p style={{marginTop: "5px", textAlign: "justify",}}>
                To access the principal pages of the BotN web site, make a selection from the Bibliography menu bar,
                which is displayed at the bottom of all BotN pages.
                The <a href="SimpleSearch" target="_top">Simple Search</a> and <a href="AdvancedSearch" target="_top">Advanced
                Search</a> pages provide gateways to all of the detailed content of the Bibliography<span
                className="header-paragraph-1">—</span>including basic citation data, citation notes, links to digital
                files and citation
                annotations. {/*For detailed information on the organization, content and  presentation of Bibliography pages and data, and for tips on how to most effectively use BotN features, select <a href="Help/279" target="_top">Help</a> from the Bibliography menu bar, then navigate  to the page or page element of interest.*/}

            </p><p style={{textAlign: 'justify'}} className="header-paragraph-1">
                <b><i>Taxonomic Scope</i></b>

            </p><p style={{marginTop: "5px", textAlign: "justify",}}>
                The BotN has been developed primarily as a research tool to provide bibliographic and related literature
                support for studies of neuropterid insects. Thus, its primary taxonomic emphasis is on the literature
                pertaining to extant and extinct insect taxa that are currently placed in the orders Neuroptera,
                Megaloptera, Raphidioptera and Glosselytrodea. To further aid historical and nomenclatural research on
                these groups, the Bibliography also contains citations to a substantial number of works that treat:
                (1) extant and extinct taxa that were formerly, but are not currently, placed in the orders Neuroptera,
                Megaloptera, Raphidioptera and Glosselytrodea (i.e., "Neuroptera sensu lato" references), (2) extant and
                extinct taxa that are nomenclatural homonyms of neuropterid taxa (original publications only);
                and (3) extant and extinct "taxa" based on unavailable names that belong to, or appear to belong to, the
                orders Neuroptera, Megaloptera, Raphidioptera and Glosselytrodea.

            </p><p style={{textAlign: 'justify'}} className="header-paragraph-1">
                <b><i>Subject Matter Scope</i></b>

            </p><p style={{marginTop: "5px", textAlign: "justify",}}>The BotN cites works containing information on all
                aspects of taxa in the superorder Neuropterida, including, but not restricted to: agriculture,
                biological control, biology, ecology, faunistics, history, morphology, paleontology, phylogeny,
                physiology, parasites, predators, systematics and taxonomy. The neuropterid-related content of a work
                may be minor and may not be reflected in its title. Citations to several classes of works that do not
                contain direct neuropterid content, but which are important or useful for neuropterid studies are also
                included, e.g., selected bibliographic and biographic reference works, works containing information
                useful for dating neuropterid publications, general nomenclatural works, and works documenting the
                chrono- and lithostratigraphic characteristics of fossil localities. </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Temporal Scope </i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>No temporal limitations have been imposed on works
                    included in the BotN. Most content development, however, has focused on the documentation of works
                    published on or after 1758, the year from which formal zoological nomenclature begins. The earliest
                    work cited in the Bibliography dates from 580 AD. The approximate distribution of cited works by
                    century is: 21st (2000's), 20%; 20th (1900's), 68%; 19th (1800's), 10%; 18th (1700's), 1%; pre-18th
                    (1600's and before), 0.1%. </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Language Considerations </i></b>
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The BotN is worldwide in scope and strives to
                    include works written in all languages. The approximate distribution of cited works by primary text
                    language is: English, 54%; German, 14%; French, 8%; Spanish, 7%; Russian, 4%; Italian, 3%; Chinese,
                    2%; Japanese, 2%; other languages (all less than 1.5% each), 6%. The current version of the
                    Bibliography cites works published in at least 41 languages. The titles of works written in
                    languages that utilize the Roman alphabet are preferentially cited in the language of the primary
                    title or main text language. Where they occur in the original works themselves, diacritical marks
                    used in the most common languages of western Europe (particularly German, French, Spanish and
                    Italian) have generally been preserved in Bibliography citations. Beginning with version 9.0,
                    efforts have been increased to retain a wider range of diacritical marks used in citations to works
                    in other languages. Citations to works published in languages that do not use the Roman alphabet are
                    generally included in the Bibliography when a Romanized translation or transliteration of at least
                    part of the citation becomes available. Transliterations and translations have been derived from a
                    variety of sources. Where both transliterated and translated bibliographic data are available, both
                    are commonly reported (in the same Bibliographic Record), particularly for works written in Russian,
                    Chinese and Japanese. Beginning with version 10.0, non-Roman character data have been included in
                    the citations of some works that were originally published in non-Roman scripts (primarily works
                    written in Cyrillic script). Roman-character-language translations of serial articles that were
                    originally published in non-Roman-character languages (e.g., English translations published in the
                    journal Entomological Review, which mirrors the Russian-language journal Entomologicheskoe
                    Obozrenie) are not given separate bibliographic records. Instead, the main Bibliographic Record
                    cites the original work, and information on any known translations are provided in the General Notes
                    field of the main record. Consequently, digital representations of translations are linked through
                    the Bibliographic Record pages of their original works. </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Included Works</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The citation content of the BotN is strongly
                    focused on works published in the primary scientific literature<span
                        className="header-paragraph-1">—</span>primarily scholarly journal articles, books and book
                    chapters. The current version of the Bibliography contains citations to works published in 2400+
                    serial publications. Manuscripts (particularly theses and dissertations) and informal, popular and
                    secondary works<span className="header-paragraph-1">—</span>in print or fixed-digital formats<span
                        className="header-paragraph-1">—</span>are documented (at the discretion of the author) where
                    considered noteworthy, as are selected web-native works (i.e., non-fixed digital works). </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Excluded Works</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Citations to neuropterid-related text and/or
                    illustrations appearing in several classes of secondary and ephemeral sources are included or
                    excluded at the discretion of the author, based on a subjective assessment of the value of the
                    information that they contain. This applies to information contained in newspaper articles, paper
                    ephemera (e.g., pamphlets), abstracts of papers presented at scientific meetings (oral or poster,
                    published or unpublished), dictionary and encyclopedia articles, treatments in general works on
                    entomology (e.g., textbooks), and other similar works. Although the BotN contains citations to many
                    abstracts of neuropterid-related presentations, no attempt is made to include all such works, and,
                    in recent years, most abstracts have been actively excluded as a low development priority for the
                    Bibliography citation set. The Bibliography does not cite abstracts of papers published in
                    abstracting journals (e.g., Biological Abstracts, Dissertation Abstracts); the original works are
                    cited instead.</p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Navás Works</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Because of the special historical importance and
                    bibliographic difficulties posed by the work of the prolific Spanish Jesuit author Longinos Navás
                    (1852-1938), who published more than 1000 papers in a wide range of journals around the world over
                    the course of 40 years, the Bibliography contains citations to all of the works known to have been
                    published by this author, including works that do not contain neuropterid content. The portion of
                    the Bibliography devoted to this author, who published on a wide range of religious and natural
                    history topics, is quite comprehensive. Particular attention has been devoted in the Bibliography to
                    documenting information concerning the serial and separate publications of this author. Many of the
                    Bibliographic Records treating Navás works contain detailed notes on their dates of publication and
                    the character of their separates. Navás published many of his works in series, using a single title
                    and differentiating successive papers numerically. Several of these series ran for many years and
                    spanned multiple journals. Complete collations of many of these series have been compiled for the
                    first time in the Bibliography and can be found in the General Notes field of the Bibliographic
                    Record of the first paper in each series. </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Strengths and Weaknesses</i></b>
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The BotN is particularly complete with respect to
                    literature treating the systematics, taxonomy, morphology and phylogeny of the Neuropterida. The
                    Bibliography may be regarded as essentially complete with regard to publications of taxonomic and
                    nomenclatural importance for the Neuropterida as of any version release date, apart from
                    undiscovered papers published near the release date. Although works in most other subject areas of
                    neuropterology are also well represented, the BotN is not as exhaustive as it might be in areas such
                    as general paleontology, agriculture-related topics, physiology, local and regional faunistics, and
                    works written in languages using non-Roman scripts. The assistance of individuals with special
                    expertise or knowledge in the neuropterid-related literature of these areas is invited to help
                    further the development of the Bibliography in these fields. </p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i> Annotations </i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The BotN provides access to a substantial volume of
                    original research data, produced or edited by the author, that are presented in the form of
                    annotations to individual Bibliographic Records. Several of the more important classes of annotation
                    information that are provided for many records are noted below.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Dates of Publication (1500+ works)</em>. A
                    considerable amount of original and secondary bibliographic research is incorporated into the BotN
                    in the form of information on the detailed dates of appearance of individual works, or parts of
                    works. This includes research into the true year (versus imprint year) and sub-year (month and/or
                    day) publication dates of 1500+ works, which are important for applications in scientific
                    nomenclature. Where the 'Imprint Year' and 'Publication Date' fields of a work's Bibliographic
                    Record differ, the source(s) used in determining the publication date are (in nearly all cases)
                    documented in the 'Dating Notes' field of the same record.</p>

                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Biographical Data (3000+ first authors)</em>.
                    Basic biographical information (i.e., some combination of full name, title, year of birth, year of
                    death, sex, nationality and occupation/interests) is provided for 3000+ first authors of works.
                    These data have been derived from a wide variety of sources. For living authors, most of the data
                    have been obtained through personal contacts with individual authors; for deceased authors, the
                    following references have been particularly useful and informative sources of relevant information:
                    Evenhuis (1997), Gaedike (1985), Gilbert (1977) and Groll (2006). E-mail contact information is
                    provided for 640+ living first authors.</p>

                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Taxon Annotations (50,500+ annotations for
                    2600+ works)</em>. This class of annotations documents the primary neuropterid taxa cited in each
                    work, and, for each cited taxon, indicates the general type(s) of information included in the work.
                    For papers that treat multiple taxa (revisions, catalogues, faunas, etc.), each taxon is annotated
                    separately. For many taxonomic works, synonymical listings are displayed as treated by the author of
                    the work. Taxon annotations can be used to restrict literature searches to display only papers
                    containing information on particular taxa (for papers where these data have been captured). Taxon
                    annotation lists can be printed to provide a list of the taxa and/or synonymy treated/used in each
                    work. </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><strong></strong><em>Figure Annotations (75,600+
                    annotations for 3100+ works)</em>. This class of annotations documents individual figures contained
                    in specific works, and provides basic information about each illustration (e.g., page, plate, and
                    figure numbers, life stage, sex, cited name, current name, figure subject and view). Figure
                    annotations can be used to restrict literature searches to display only papers containing
                    illustrations on particular taxa or particular subject matter. Figure annotation lists can be
                    printed to provide alternate figure captions, with standardized subject headings and updated taxon
                    nomenclature, for all of the neuropterid-related figures in each documented work.</p>

                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Separates and Reprints (3300+ works)</em>. The
                    Bibliography provides separately-recorded data on the pagination (and sometimes other data) of the
                    printed separates of 3300+ works. For works published in multiple states (e.g., as a journal/serial
                    article and as an independently printed 'separate' or 'reprint'), as many 19th and early 20th works
                    were, these data may be consulted to establish the correct original citation for taxonomic or other
                    data.</p>

                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Miscellaneous (2400+ works)</em>. Miscellaneous
                    notes are provided in the General Notes field of the Bibliographic Record page for 2400+ works.
                    These notes contain information on a wide variety of subjects, including correction of miscellaneous
                    errors, cross references among works with related subject matter, collations of publications issued
                    as series, detailed information on printed separates, and information about the neuropterid content
                    of larger works that were not devoted primarily to the Neuropterida. </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <strong></strong>
                    <b><i>Content Development </i></b>
                </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>Bibliographic content for the BotN has been derived
                    from a wide variety of sources, including: (1) standard abstracting journals (particularly the
                    Zoological Record [1864-present]), (2) the 'references cited' portions of published scientific
                    papers, (3) manuscript reference lists compiled and provided by colleagues, (4) original journal
                    runs, (5) collections of printed separates held by individuals and museums, (6) printed separates
                    and digital files sent by colleagues, (7) correspondence with colleagues, and (8) a wide variety of
                    original print and digital sources. Citations to works new to the Bibliography are added to the
                    working copy of the database underlying the BotN as they are discovered. The working copy of the
                    database is not linked directly to the on-line version of the Bibliography. Citations become
                    available on-line when they are episodically uploaded to the on-line Bibliography. New data uploads
                    may occur in conjunction with a documented version update of the Bibliography, or as part of an
                    undocumented data update to an existing Bibliography version. Citations may be incomplete when
                    initially uploaded to the BotN. Citation records are updated with additions and corrections in the
                    working copy of the BotN database as new information becomes available. Updated citation data are
                    made public with the next data update to the on-line Bibliography. The basic bibliographic data
                    (i.e., author, imprint year, title, name of publisher or serial, and pagination) of ca. 65% of the
                    works cited in the BotN have been personally verified by the author through the examination of one
                    or more forms of the work (i.e., print or digital originals, facsimile copies, or print or digital
                    separates). The Verified field of a work's Bibliographic Record indicates whether it's basic
                    bibliographic data have been verified. The WorldCat database (WorldCat 1971) has been used
                    extensively to check and update citation data for books and serial publications. </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>A major developmental focus of recent versions of
                    the Bibliography has been to enable direct access to the dispersed literature on the Neuropterida by
                    providing links to digital representations of cited works. The digital content linked through the
                    Bibliography can be divided into two general classes. First, links to content managed directly by
                    the Bibliography project (currently 99+% of BotN links); and second, links to web-based content that
                    is managed external to the Bibliography project (&lt;1% of links). While project-managed content
                    currently predominates, it is expected that the number and variety of external links will continue
                    to grow in the future as a percentage of total links. Project-managed content consists of discrete,
                    generally downloadable, files<span className="header-paragraph-1">—</span>primarily PDFs, but also
                    smaller numbers of files in other formats (e.g., manuscript Word documents, archived htm/html files)<span
                        className="header-paragraph-1">—</span>that have been derived from a variety of sources. The
                    vast majority of PDF files served through the BotN are original scans of the historical printed
                    literature that have been produced by an active scanning program maintained as part of the
                    Bibliography project. These files can be identified, when open, by the "Bibliography of the
                    Neuropterida" page that is appended to the end of each file. Most project-produced scans are
                    available in two PDF versions: a larger, non-searchable, master ("m") file, and a smaller searchable
                    ("s") file. Because of their larger size and higher quality, master files are recommended for
                    printing hard copies of particular works. Searchable files have been processed with an optical
                    character recognition algorithm and are searchable with free PDF reader software, though search
                    accuracy is highly dependent upon the character and quality of the underlying scan. Master and
                    searchable files can be differentiated in downloads by the lower-case letter "m" or "s" following
                    the reference number in the filename. Additional digital files have been provided by numerous
                    colleagues and organizations. Links to digital content that is not maintained directly by the
                    Bibliography project are primarily links to external web sites that contain substantive neuropterid
                    content, and links to neuropterid-related digital files that are hosted by web sites managed by
                    other organizations or individuals.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><b><i>Versioning</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Summary data on the various documented versions of
                    the Bibliography that have been produced to date, including information on data sets, release dates,
                    delivery formats and functionality changes, can be found on the <a href="Versions"
                                                                                       target="_top">Versions</a> page.
                    In versions of the Bibliography up to and including the initial release of version 9.0, essentially
                    all new content (e.g., new and updated citations, and links to new digital files) was first made
                    available in conjunction with a fully documented release of a new Bibliography version. However,
                    continuing efforts to integrate Bibliography-associated data with other current and future modules
                    of the <em>Lacewing Digital Library</em> have rendered that update model impracticable. In order to
                    ensure the cross-compatibility of datasets serving multiple LDL modules, versions 9.0 and later of
                    the Bibliography may be augmented with undocumented data updates. To continue tracking the growth
                    and development of the Bibliography, documented and incremented versions of the Bibliography will
                    continue to be produced episodically<span className="header-paragraph-1">—</span>particularly where
                    data updates are contemporaneous with interface improvements or the addition of new classes of data
                    to the Bibliography. However, from version 9.0 on, the numerical data associated with documented
                    versions of the Bibliography (e.g., on the Versions and other information pages) pertain only to the
                    initial release of that documented version, and do not include information about unannounced or
                    otherwise undocumented data updates made between documented versions. </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><b><i>User Assistance</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Users of the BotN are invited to report missing
                    works and incorrect or incomplete data on included works so that the Bibliography may be continually
                    improved.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>New reference materials that may contribute to
                    improving the Bibliography<span className="header-paragraph-1">—</span>such as specialized lists of
                    neuropterid-related references, publication lists of individual authors, printed and/or digital
                    separates of published papers, and scans of printed literature<span className="header-paragraph-1">—are requested from BotN users. These </span>will
                    be gratefully received and will be used to check and improve the Bibliography's completeness and
                    accuracy.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Users who possess digital files of works not
                    currently linked through the Bibliography are invited to forward copies of those files to the author
                    for incorporation, as may be appropriate, into future versions of the BotN.</p>

                <p style={{textAlign: 'justify'}} className="header-paragraph-1"><b><i>Citation Requested</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Where a researcher believes that the information
                    resources obtained from the BotN have been of substantial value in facilitating a research project,
                    the researcher is requested to cite the BotN in the "References Cited" section of any publications
                    resulting from that research project. Recommended formats for citing the Bibliography are given on
                    the <a href="Citation" target="_top">Citation</a> page.</p>

                <p style={{marginTop: "5px", textAlign: "justify",}}><b><i>Uses and Restrictions </i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The permitted uses of the <i>Bibliography of the Neuropterida</i> are identified below. All other
                    uses and rights are reserved by the author.
                </p>
                <ol style={{textAlign: "justify",}}>
                    <li>The BotN may be used by researchers as a tool to aid in the location of literature relevant to
                        their studies.
                    </li>
                    <li>The BotN may be used by researchers for on-line viewing or downloading of linked digital files
                        for personal use. The BotN owns no copyrights to any linked files. Files linked as PDFs are made
                        open access through the BotN: (1) after appropriate permissions have been obtained, (2) where
                        the original works are believed to no longer be covered by copyright restrictions, or (3) when
                        appropriate permission grantors are believed to no longer be extant (e.g., author(s) deceased,
                        journal-sponsoring organization defunct and without a successor). A BotN information page
                        containing a complete reference citation is added to the end of all pdf files produced
                        specifically for the BotN project. Because these pages sometimes contain information required by
                        the permission grants of publishers or authors, these pages, where present, must remain attached
                        to the digital files downloaded from the BotN.
                    </li>
                    <li>The BotN may be used by researchers as a reference source for bibliographic data to be included
                        in the "References Cited" portion of manuscripts being developed for publication (except as in
                        4. below). Authors may find the 'Copyable Citation' field provided in the Bibliographic Record
                        of cited works useful for copying and pasting citation data into manuscripts.
                    </li>
                    <li>The author reserves the right to publish the Bibliography, in full or in part, in all media.
                        Except as noted above, neither individuals nor organizations may not use BotN data as the basis
                        for the production or publication (in any medium) of other bibliographies, without the author's
                        permission. This includes the production of "sub-bibliographies" for individual neuropterid taxa
                        or specialized subject areas, and the inclusion of BotN data as a part of bibliographies of more
                        extensive scope. Anyone wishing to use data from the BotN to produce more restrictive or more
                        extensive bibliographies should contact the author.
                    </li>
                </ol>
            </div>
        </div>
       =</>
};
export default Introduction;