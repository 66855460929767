import React from "react";
import {Route, Routes} from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import _Layout from "./Views/Shared/_Layout"
import Login from "./Views/Authenticate/Login";
import MainContent from "./Views/HomePage/MainContent";
import Logout from "./Views/Authenticate/Logout";
import _HomePageLayout from "./Views/HomePage/Shared/_HomePageLayout";
import Vision from "./Views/HomePage/Vision";
import Team from "./Views/HomePage/Team";
import Citation from "./Views/HomePage/Citation";
import Contacts from "./Views/HomePage/Contacts";
import Publications from "./Views/HomePage/Publications";
import LoginFailure from "./Views/Authenticate/LoginFailure";
import LoginSuccess from "./Views/Authenticate/LoginSuccess";
import _BiblioLayout from "./Views/Biblio/Shared/_BiblioLayout";
import Main from "./Views/Biblio/Main";
import Introduction from "./Views/Biblio/Introduction";
import Serials_Main from "./Views/Serials/Main";
import _LoginLayout from "./Views/Authenticate/Shared/_LoginLayout";
import Species_Main from "./Views/SpeciesCatalog/Main";
import _GenusCatalogLayout from "./Views/GenusCatalog/Shared/_GenusCatalogLayout";
import Genus_Main from "./Views/GenusCatalog/Main";
import _FaunasLayout from "./Views/Faunas/Shared/_FaunasLayout";
import Faunas_Main from "./Views/Faunas/Main";
import _NeuroLayout from "./Views/NeuroDirectory/Shared/_NeuroLayout";
import DIR_Main from "./Views/NeuroDirectory/Main";
import _InteractionsLayout from "./Views/Interactions/Shared/_InteractionsLayout";
import INTER_Main from "./Views/Interactions/Main";
import MetaKey_Main from "./Views/Metakey/Main";
import _MetakeyLayout from "./Views/Metakey/Shared/_MetakeyLayout";
import _OldKeysLayout from "./Views/OldKeys/Shared/_OldKeysLayout";
import OldKeys_Main from "./Views/OldKeys/Main";
import Down_Main from "./Views/Downloads/Main";
import Class_Main from "./Views/Classification/Main";
import BibloSearch from "./Views/Biblio/BibloSearch";
import Acknowledgments from "./Views/Biblio/Acknowledgments";
import SpeciesSearch from "./Views/SpeciesCatalog/SpeciesSearch";
import DirectorySearch from "./Views/NeuroDirectory/DirectorySearch";
import MetakeySearch from "./Views/Metakey/MetakeySearch";
import GenusSearch from "./Views/GenusCatalog/GenusSearch";
import Species_Introduction from "./Views/SpeciesCatalog/Introduction";
import Species_Acknowledgments from "./Views/SpeciesCatalog/Acknowledgments";
import Genus_Introduction from "./Views/GenusCatalog/Introduction";
import Genus_Acknowledgments from "./Views/GenusCatalog/Acknowledgments";
import BibloResult from "./Views/Biblio/BibloResult";
import BibloRecord from "./Views/Biblio/BibloRecord";
import BibloTaxa from "./Views/Biblio/BibloTaxa";
import BibloFigure from "./Views/Biblio/BibloFigure";
import SpeciesResult from "./Views/SpeciesCatalog/SpeciesResult";
import SpeciesEpiRecord from "./Views/SpeciesCatalog/SpeciesEpiRecord";
import SpeciesMonoRecord from "./Views/SpeciesCatalog/SpeciesMonoRecord";
import Faunas_Acknowledgments from "./Views/Faunas/Acknowledgments";
import MetakeyResult from "./Views/Metakey/MetakeyResult";
import MetaKeyRecord from "./Views/Metakey/MetaKeyRecord";
import SpeciesKeyRecord from "./Views/SpeciesCatalog/SpeciesKeyRecord";
import SpeciesTaxonRecord from "./Views/SpeciesCatalog/SpeciesTaxonRecord";
import SpeciesFigureRecord from "./Views/SpeciesCatalog/SpeciesFigureRecord";
import SpeciesPhyloRecord from "./Views/SpeciesCatalog/SpeciesPhyloRecord";
import Faunas_MainTemplate from "./Views/Faunas/MainTemplate";
import FaunaSearch from "./Views/Faunas/FaunaSearch";
import FaunaResult from "./Views/Faunas/FaunaResult";
import {Reference} from "./Views/Shared/Reference";
import Faunas_References from "./Views/Faunas/FaunaReferences";
import Species_CatelogLayout from "./Views/SpeciesCatalog/Shared/_CatelogLayout";
import _CatelogLayout from "./Views/Classification/Shared/_CatelogLayout";
import LDLconfig from "./utils/OnServerStartLoadedJsons/LDLconfig.json"
import Biblio_Citation from "./Views/Biblio/Citation";
import Biblo_Versions from "./Views/Biblio/BibloVersions";
import Species_Classification from "./Views/SpeciesCatalog/SpeciesClassifiaction";
import Species_Versions from "./Views/SpeciesCatalog/SpeciesVersions";
import Faunas_Versions from "./Views/Faunas/FaunasVersions";
import MetaKey_Versions from "./Views/Metakey/MetaKeyVersions";
import Dir_Introduction from "./Views/NeuroDirectory/DirectoryIntroduction";
import Dir_Acknowledgments from "./Views/NeuroDirectory/DirectoryAcknowledgments";
import AddUpdate from "./Views/NeuroDirectory/AddUpdate";
import Dir_Contacts from "./Views/NeuroDirectory/Contacts";
import MetaKey_Introduction from "./Views/Metakey/MetaKeyIntroduction";
import MetaKey_Acknowledgments from "./Views/Metakey/MetaKeyAcknowledgments";
import Pages from "./Views/PageViewer/Pages";
import DirectoryResult from "./Views/NeuroDirectory/DirectoryResult";
import DirectoryRecord from "./Views/NeuroDirectory/DirectoryRecord";
import GenusResult from "./Views/GenusCatalog/GenusResult";
import Classification_Acknowledgments from "./Views/Classification/Acknowledgments";
import Interaction_Introduction from "./Views/Interactions/Introduction";
import LoggedIn from "./Views/Authenticate/Loggedin";
import VersionNotes from "./Views/Biblio/VersionNotes";
import Faunas_Introduction from "./Views/Faunas/Introduction";
import {LDLModule} from "./utils/OnServerStartLoadedOther/ModuleHelp";
import _KeysLayout from "./Views/Keys/Shared/_KeysLayout";
import Keys_Main from "./Views/Keys/Main";
import _TestingLayout from "./Views/Testing/Shared/_TestingLayout";
import GraphicsTesting from "./Views/Testing/GraphicsTesting";
import Testing_Main from "./Views/Testing/Main";
import Species_Families from "./Views/SpeciesCatalog/SpeciesFamilies";
import Migration from "./Views/Authenticate/Migration";


const isPublic:boolean=LDLconfig.isPublic;
function App() {
    console.log(isPublic)
    return (
        <div className="App">

            <Routes>
                <Route path="/" element={<_Layout isPublic={isPublic}/>}>
                    <Route path={"/Authenticate"} element={<_LoginLayout/>}>
                        <Route
                            path="/Authenticate/Login"
                            element={
                                <Login
                                    isPublic={isPublic}
                                    /* isDialog={false}
                                     setIsExpired={() => {}}
                                     setExpires={() => {}}
                                     user={undefined}*/
                                />
                            }
                        />
                        <Route
                            path="/Authenticate/LoggedIn"
                            element={
                                <LoggedIn
                                    isPublic={isPublic}
                                    /* isDialog={false}
                                     setIsExpired={() => {}}
                                     setExpires={() => {}}
                                     user={undefined}*/
                                />
                            }
                        />
                        <Route
                            path="/Authenticate/Logout"
                            element={
                                <Logout
                                    isPublic={isPublic}
                                    /*isDialog={false}
                                    setIsExpired={() => {}}
                                    setExpires={() => {}}
                                    user={undefined}*/
                                />
                            }
                        />
                        <Route
                            path="/Authenticate/LoginSuccess"
                            element={
                                <LoginSuccess
                                    isPublic={isPublic}
                                    /*isDialog={false}
                                    setIsExpired={() => {}}
                                    setExpires={() => {}}
                                    user={undefined}*/
                                />
                            }
                        />
                        <Route
                            path="/Authenticate/LoginFailure"
                            element={
                                <LoginFailure
                                    isPublic={isPublic}
                                    /*isDialog={false}
                                    setIsExpired={() => {}}
                                    setExpires={() => {}}
                                    user={undefined}*/
                                />
                            }
                        />
                        <Route
                            path="/Authenticate/Migration"
                            element={
                                <Migration
                                    isPublic={isPublic}
                                    /*isDialog={false}
                                    setIsExpired={() => {}}
                                    setExpires={() => {}}
                                    user={undefined}*/
                                />
                            }
                        />
                    </Route>

                    <Route path="/HomePage/MainContent" element={<MainContent/>}/>
                    <Route path="/HomePage" element={<_HomePageLayout/>}>
                        <Route path={"/HomePage/Vision"} element={<Vision/>}/>
                        <Route path={"/HomePage/Team"} element={<Team/>}/>
                        <Route path={"/HomePage/Citation"} element={<Citation/>}/>
                        <Route path={"/HomePage/Contacts"} element={<Contacts/>}/>
                        <Route path={"/HomePage/Publications"} element={<Publications/>}/>
                    </Route>

                    <Route path="/Biblio" element={<_BiblioLayout/>}>
                        <Route path="/Biblio/Main" element={<Main/>}/>
                        <Route path="/Biblio/Introduction" element={<Introduction/>}/>
                        <Route path="/Biblio/SimpleSearch" element={<BibloSearch/>}/>
                        <Route path="/Biblio/AdvancedSearch" element={<BibloSearch advanced={true} />}/>
                        <Route path="/Biblio/:Search/SearchResults" element={<BibloResult/>}/>
                        <Route path="/Biblio/:Search/SearchResults/Record" element={<BibloRecord/>}/>
                        <Route path="/Biblio/:Search/SearchResults/Record/Taxa" element={<BibloTaxa/>}/>
                        <Route path="/Biblio/:Search/SearchResults/Record/Figure" element={<BibloFigure/>}/>
                        <Route path="/Biblio/Figure" element={<BibloFigure/>}/>
                        <Route path="/Biblio/Taxa" element={<BibloTaxa/>}/>
                        <Route path="/Biblio/Record/Figure" element={<BibloFigure/>}/>
                        <Route path="/Biblio/Record/Taxa" element={<BibloTaxa/>}/>
                        <Route path="/Biblio/Record" element={<BibloRecord/>}/>
                        <Route path="/Biblio/Acknowledgments" element={<Acknowledgments/>}/>
                        <Route path="/Biblio/References" element={<Reference ModuleID={LDLModule.Biblio}/>}/>
                        <Route path="/Biblio/Citation" element={<Biblio_Citation/>}/>
                        <Route path="/Biblio/Versions" element={<Biblo_Versions/>}/>
                        <Route path="/Biblio/Versions/VersionNotes" element={<VersionNotes/>}/>
                    </Route>
                    <Route path={"/Serials/Main"} element={<Serials_Main/>}/>
                    <Route path={"/SpeciesCatalog"} element={<Species_CatelogLayout/>}>
                        <Route path={"/SpeciesCatalog/Main"} element={<Species_Main/>}/>
                        <Route path={"/SpeciesCatalog/Introduction"} element={<Species_Introduction/>}/>
                        <Route path={"/SpeciesCatalog/Classification"} element={<Species_Classification/>}/>
                        <Route path={"/SpeciesCatalog/Families"} element={<Species_Families/>}/>
                        <Route path={"/SpeciesCatalog/SimpleSearch"} element={<SpeciesSearch/>}/>
                        <Route path={"/SpeciesCatalog/AdvancedSearch"} element={<SpeciesSearch advanced={true} />} />
                        <Route path="/SpeciesCatalog/:Search/SearchResults" element={<SpeciesResult/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/Record" element={<SpeciesEpiRecord/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/MonographRecord" element={<SpeciesMonoRecord/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/MonographRecord/Record" element={<SpeciesEpiRecord/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/MonographRecord/Keys" element={<SpeciesKeyRecord/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/MonographRecord/PhylogeneticTree" element={<SpeciesPhyloRecord/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/MonographRecord/Figure" element={<SpeciesFigureRecord/>}/>
                        <Route path="/SpeciesCatalog/:Search/SearchResults/MonographRecord/Taxa" element={<SpeciesTaxonRecord/>}/>
                        <Route path="/SpeciesCatalog/MonographRecord/Keys" element={<SpeciesKeyRecord/>}/>
                        <Route path="/SpeciesCatalog/MonographRecord/Record" element={<SpeciesEpiRecord/>}/>
                        <Route path="/SpeciesCatalog/MonographRecord/PhylogeneticTree" element={<SpeciesPhyloRecord/>}/>
                        <Route path="/SpeciesCatalog/MonographRecord/Figure" element={<SpeciesFigureRecord/>}/>
                        <Route path="/SpeciesCatalog/MonographRecord/Taxa" element={<SpeciesTaxonRecord/>}/>
                        <Route path="/SpeciesCatalog/Keys" element={<SpeciesKeyRecord/>}/>
                        <Route path="/SpeciesCatalog/PhylogeneticTree" element={<SpeciesPhyloRecord/>}/>
                        <Route path="/SpeciesCatalog/Figure" element={<SpeciesFigureRecord/>}/>
                        <Route path="/SpeciesCatalog/Taxa" element={<SpeciesTaxonRecord/>}/>
                        <Route path="/SpeciesCatalog/Record" element={<SpeciesEpiRecord/>}/>
                        <Route path="/SpeciesCatalog/MonographRecord" element={<SpeciesMonoRecord/>}/>
                        <Route path={"/SpeciesCatalog/Acknowledgments"} element={<Species_Acknowledgments/>}/>
                        <Route path={"/SpeciesCatalog/References"} element={<Reference ModuleID={LDLModule.SpeciesCatalog}/>}/>
                        <Route path={"/SpeciesCatalog/Versions"} element={<Species_Versions/>}/>
                    </Route>
                    <Route path={"/GenusCatalog"} element={<_GenusCatalogLayout/>}>
                        <Route path={"/GenusCatalog/Main"} element={<Genus_Main/>}/>
                        <Route path={"/GenusCatalog/Introduction"} element={<Genus_Introduction/>}/>
                        <Route path={"/GenusCatalog/SimpleSearch"} element={<GenusSearch/>}/>
                        <Route path={"/GenusCatalog/AdvancedSearch"} element={<GenusSearch advanced={true}/>}/>
                        <Route path="/GenusCatalog/:Search/SearchResults" element={<GenusResult/>}/>
                        <Route path={"/GenusCatalog/Acknowledgments"} element={<Genus_Acknowledgments/>}/>
                        <Route path={"/GenusCatalog/References"} element={<Reference ModuleID={LDLModule.GenusCatalog}/>} />
                        <Route path={"/GenusCatalog/Citation"}/>
                        <Route path={"/GenusCatalog/Versions"}/>
                    </Route>
                    <Route path={"/Faunas"} element={<_FaunasLayout/>}>
                        <Route path={"/Faunas/Main"} element={<Faunas_Main/>}/>
                    </Route>
                    <Route path={"/NeuroDirectory"} element={<_NeuroLayout/>}>
                        <Route path={"/NeuroDirectory/Main"} element={<DIR_Main/>}/>
                        <Route path={"/NeuroDirectory/Introduction"} element={<Dir_Introduction/>}/>
                        <Route path={"/NeuroDirectory/Acknowledgments"} element={<Dir_Acknowledgments/>}/>
                        <Route path={"/NeuroDirectory/Search"} element={<DirectorySearch/>}/>
                        <Route path={"/NeuroDirectory/Search/SearchResults"} element={<DirectoryResult/>}/>
                        <Route path={"/NeuroDirectory/Search/SearchResults/Record"} element={<DirectoryRecord/>}/>
                        <Route path={"/NeuroDirectory/Record"} element={<DirectoryRecord/>}/>
                        <Route path={"/NeuroDirectory/AddUpdate"} element={<AddUpdate/>}/>
                        <Route path={"/NeuroDirectory/Contacts"} element={<Dir_Contacts/>}/>
                    </Route>
                    <Route path={"/Interactions"} element={<_InteractionsLayout/>}>
                        <Route path={"/Interactions/Main"} element={<INTER_Main/>} />
                        <Route path={"/Interactions/Introduction"} element={<Interaction_Introduction/>}/>
                        <Route path={"/Interactions/ScopeSearch"} />
                        <Route path={"/Interactions/TerminalTaxonSearch"}/>
                        <Route path={"/Interactions/Acknowledgments"} />
                        <Route path={"/Interactions/References"} element={<Reference ModuleID={LDLModule.Interactions}/>}/>
                        <Route path={"/Interactions/Versions"} />
                    </Route>
                    <Route path={"/Metakey"} element={<_MetakeyLayout/>}>
                        <Route path={"/Metakey/Main"} element={<MetaKey_Main/>}/>
                        <Route path={"/Metakey/Introduction"} element={<MetaKey_Introduction/>}/>
                        <Route path={"/Metakey/ScopeSearch"} element={<MetakeySearch/>}/>
                        <Route path="/Metakey/:Search/SearchResults" element={<MetakeyResult/>}/>
                        <Route path="/Metakey/:Search/SearchResults/Record" element={<MetaKeyRecord/>}/>
                        <Route path={"/Metakey/TerminalTaxonSearch"} element={<MetakeySearch advanced={true}/>}/>
                        <Route path="/Metakey/Record" element={<MetaKeyRecord/>}/>
                        <Route path={"/Metakey/Acknowledgments"} element={<MetaKey_Acknowledgments/>}/>
                        <Route path={"/Metakey/References"} element={<Reference ModuleID={LDLModule.Metakey} />}/>
                        <Route path={"/Metakey/Versions"} element={<MetaKey_Versions/>}/>
                    </Route>
                    <Route path={"/Keys"} element={<_OldKeysLayout/>}>
                        <Route path="Main" element={<OldKeys_Main/>}/>
                        <Route path="Introduction" />
                        <Route path="Acknowledgments"/>
                        <Route path="About" />
                        <Route path="Citation" />
                        <Route path="Contacts" />
                        <Route path="KeyInformation"/>
                        <Route path="Help"/>
                    </Route>
                    <Route path={"/KeysDichot"} element={<_KeysLayout/>}>
                        <Route path="Main" element={<Keys_Main/>}/>
                    </Route>
                    <Route path={"/Downloads/Main"} element={<Down_Main/>}/>
                    <Route path={"/Classification"} element={<_CatelogLayout/>}>
                        <Route path={"/Classification/Main"} element={<Class_Main/>}/>
                        <Route path={"/Classification/Introduction"}  element={<Species_Introduction/>} />
                        <Route path={"/Classification/Classification"} element={<Species_Classification/>}/>
                        <Route path={"/Classification/Acknowledgments"} element={<Classification_Acknowledgments/>}/>
                        <Route path={"/Classification/References"} element={<Reference ModuleID={LDLModule.Classification}/>}/>
                        <Route path={"/Classification/Versions"} element={<Species_Versions/>}/>
                    </Route>
                    <Route path={"/PageViewer/Pages"} element={<Pages/>}/>
                    <Route path={"/Testing"} element={<_TestingLayout/>}>
                        <Route path={"/Testing/Main"} element={<Testing_Main/>}/>
                        <Route path={"/Testing/Graphics"} element={<GraphicsTesting/>}/>
                    </Route>
                    <Route path={"/:Faunas"} element={<_FaunasLayout/>}>
                        <Route path={"/:Faunas/Main"} element={<Faunas_MainTemplate/>}/>
                        <Route path={"/:Faunas/DefineFauna"} element={<FaunaSearch/>}/>
                        <Route path={"/:Faunas/DefineFauna/Results"} element={<FaunaResult/>}/>
                        <Route path={"/:Faunas/Acknowledgments"} element={<Faunas_Acknowledgments/>}/>
                        <Route path={"/:Faunas/References"} element={<Faunas_References/>}/>
                        <Route path={"/:Faunas/Versions"} element={<Faunas_Versions/>}/>
                        <Route path={"/:Faunas/Introduction"} element={<Faunas_Introduction/>}/>
                    </Route>
                    <Route path={"/*"}/>
                </Route>
            </Routes>

        </div>
    );
}

export default App;
