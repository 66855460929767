import React, {useEffect, useState} from "react";
import {callGETAPI} from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyGraphic,
    EasyGraphicGraph,
    EasyGraphicHistogram,
    EasyGraphicLineGraph,
    Record
} from "../../utils/CustomRecord";


const GraphicsTesting = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat], isPublic, [onClickFunction, setOnClickFunction], layoutHeader, currentWindow, height, [afterTitle, setAfterTitle], [currentPathData, setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<{[p: string]: number | number[]}>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    useEffect(() => {
        //out = proccessSearch(_props?.searchProps, conversionTable, out as SearchOutputProps);

        callGETAPI("/api/general/graphic1", (error: boolean, outData: {[p: string]: number | number[]} | Response) => {
            if (error) {
                console.log("error")
            } else if (!(outData instanceof Response)) {
                setResultData(outData)
            }

        });

        /*callAPI("/api/general/doBiblioSearch", out, (error: boolean, outData: tblLdlBibNeur[] | Response) => {
            if (error) {
                console.log("error")
            } else {
                setResultData(outData)
            }

        });*/

    }, []);


    return <>{resultData && <>
        <Record title={"Graphics Test Histogram"}>
            <i>
                <>Results Records ({resultData.length}).</>
            </i>
            <EasyGraphic height={"400px"} width={"900px"}>
                <EasyGraphicHistogram
                    yLabel={"count"}
                    barWidth={"50%"}
                    barOffset={"10%"}
                    stacking={true}
                    xLabel={{title: "YEAR", fontColor: "#ff0000", textAlign: "center", containerStyle: {padding: "10px", margin: "10px", height: "100%", width: "100%"}}}
                    graphColor={"#c0c0c0"}
                    barColor={["#ff00ff","#ff0000","#00ff00","#0000ff","#ffff00"]}
                    strokeColor={"#000000"}
                    horizontalLines={{interval:"100px",subInterval:'50px',intervalType:"fixed",paddingRight:"0px",marginLeft:"15px",marginRight:"5px",paddingLeft:"5px",topIntervalValue:"2000px",snapTopClosestInterval:true}}
                    verticalLines={{interval:"20px",subInterval:"10px",marginRight:"5px",marginLeft:"0px",startInterval:"1740px",topIntervalValue:"2040px",textStyle:{rotation:0,font:"20px Ariel",textAlign:"center",containerStyle:{left:"0px",top:"0px"}},underGraph:true,intervalType:"fixed",paddingRight:"5px",paddingLeft:"0px",snapTopClosestInterval:true}}
                    containerStyle={{width: "100%", height: "100%", padding: "0px 30px 40px", margin: "10px 0% 80px 80px"}}
                    data={resultData}/>
            </EasyGraphic>
            <h1>Graphics Test Line Graph</h1>
            <EasyGraphic debug={false}  height={"400px"} width={"900px"}>
                <EasyGraphicLineGraph
                    yLabel={{title:"currently-valid species",font:"30px Times New Roman"}}
                    xLabel={{title: "Decade",font:"30px Times New Roman", fontColor: "#000000",textBaseLine:"top", textAlign: "center", containerStyle: {padding: "0px 10px", margin: "0px 10px", height: "100%", width: "100%"}}}
                    graphColor={"#c0c0c0"}
                    lineColor={["#ff00ff","#ff0000","#00ff00","#0000ff","#ffff00"]}
                    horizontalLines={{interval:"50px",subInterval:'10px',intervalType:"fixed",marginRight:"5px",marginLeft:"5px",paddingRight:"0px",paddingLeft:"5px",topIntervalValue:"1000px",snapTopClosestInterval:true}}
                    verticalLines={{interval:"20px",subInterval:"10px",marginRight:"5px",startInterval:"1740px",topIntervalValue:"2020px",textStyle:{rotation:0,font:"20px Ariel",textAlign:"center",containerStyle:{left:"0px",top:"0px"}},underGraph:true,intervalType:"fixed",paddingRight:"5px",paddingLeft:"0px",snapTopClosestInterval:true}}
                    containerStyle={{width: "100%", height: "100%", padding: "0px 30px 40px", margin: "10px 0% 80px 80px"}}
                                      data={resultData}/>
            </EasyGraphic>
            <h1>Graphics Graph</h1>
            <EasyGraphic debug={false} height={"400px"} width={"900px"}>
                <EasyGraphicGraph
                    yLabel={"count"}
                    xLabel={{title: "YEAR", fontColor: "#ff0000", textAlign: "center", containerStyle: {padding: "10px", margin: "10px", height: "100%", width: "100%"}}}
                    graphColor={"#c0c0c0"}
                    horizontalLines={{interval:"50px",intervalType:"fixed",paddingRight:"5px",paddingLeft:"5px",topIntervalValue:"210px",snapTopClosestInterval:true}}
                    verticalLines={{interval:"50px",startInterval:"50px",subInterval:"5px",marginRight:"5px",LabelStrings:["override1","","override2"],textStyle:{rotation:-45,font:"20px Ariel",textAlign:"right",containerStyle:{left:"0px",top:"0px"}},underGraph:true,intervalType:"fixed",paddingRight:"5px",paddingLeft:"0px",topIntervalValue:"210px",snapTopClosestInterval:true}}

                    containerStyle={{width: "100%", height: "100%", padding: "0px 30px 40px", margin: "10px 0% 80px 80px"}}/>
            </EasyGraphic>
        </Record>
    </>
    }
    </>
};


export default GraphicsTesting;