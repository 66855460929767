import React from "react";

const Genus_Main = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>Neuropterida Genera of the World</h1>
                <p className="subheader">
                    A Catalogue of the Genus-Group Names of the<br/>
                    Extant and Fossil<br/>
                    Neuroptera, Megaloptera, Raphidioptera, and Glosselytrodea<br/>
                    (Insecta: Neuropterida) of the World
                </p>
                <p className="version">Version 1.0<br/>(release date: Day Month Year)</p>
                <p style={{textAlign: "center"}}>
                    John D. Oswald<br/>
                    Department of Entomology, Texas A&amp;M University<br/>
                    College Station, Texas 77843-2475 USA
                </p>

                <p style={{textAlign: "center"}}>
                    <a style={{width: "120px", color: "white",}} href="SimpleSearch" className="btn btn-info">Simple
                        Search</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href="AdvancedSearch" className="btn btn-info">Advanced
                        Search</a>
                </p>

                <p style={{textAlign: 'justify'}}>
                    Abstract: The genus-group names of the insect superorder Neuropterida (orders Neuroptera,
                    Megaloptera, Raphidioptera, and Glosselytrodea) are catalogued. Catalogue coverage is worldwide in
                    scope and includes names applied to both extant and extinct taxa. Catalogue records document
                    fundamental nomenclatural, bibliographic and taxonomic data relevant to each name. Synoptic
                    information on species diversity and geographic distributions is provided for vaild genera and
                    subgenera. Chronostratigraphic and lithostratigraphic data are summarized for most fossil taxa. This
                    is a technical scientific catalogue produced primarily for use by professional and advanced
                    avocational systematic entomologists.<br/>
                    <br/>
                    Author Contact Information: (JDO, corresponding author) <a href="mailto:j-oswald@tamu.edu"
                                                                               style={{textDecoration: "none",}}>j-oswald@tamu.edu</a>.
                    <br/>
                    <br/>
                    Cite this work as: Oswald, J.D. 2016. Neuropterida Genera of the World. Version 1.0. URL:
                    http://lacewing.tamu.edu/GenusCatalog/Main. Last accessed [fill in date of last site access].
                </p>
            </div>

            <div className="text-body">
                <p className="author-reserve-markup">
                    &nbsp;© John D. Oswald, 2016<br/>
                    All rights reserved.
                </p>
            </div>

            {/*Press any size on the website, and size E*/}
            {/* <div className="text-body counter">
        <a href="http://www.amazingcounters.com">
            <img containerStyle={{border:"0"}} src="http://cc.amazingcounters.com/counter.php?i=3204799&c=9614710" alt="AmazingCounters.com"/>
        </a>
    </div> */}
        </div>
    </>
};
export default Genus_Main;