import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {layoutContextType} from "../Shared/_Layout";
import {
    CornerIcon,
    EasyGraphic,
    EasyTable,
    EasyTableElement,
    FileSizeKiloBytes,
    IconLink,
    ListElement,
    ListHeader, ListHolder,
    Record,
    
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlEDocs} from "../../../../database/models/tblLdlEDocs";


const BibloRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlBibNeur>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getBiblioByPk", {BibObjID: _props["BibObjID"]}, (error: boolean, outData: tblLdlBibNeur | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);

                setResultData(outData);
            }
        })
    }, []);


    return <>{resultData &&
        <Record title={<>Bibliographic Record <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
            href={`mailto:j-oswald@tamu.edu?subject=Bibliography of the Neuropterida: comment/correction (r#${resultData.BibObjID})`}
            IconID={LDLIcons.General_Email}/></>}>
            <ListHolder>
                {(resultData.LinkedTaxon?.length != 0||resultData.LinkedFigs?.length != 0)&&<ListElement title={"Bibliographic Record Links"} element={<>
                    {resultData.LinkedTaxon?.length != 0 &&
                        <><IconLink href={"./Taxa"} IconID={LDLIcons.BotN_TaxonCitations}
                                  HistoryLocStates={location.state}
                                  newStates={{"BibObjID": resultData.BibObjID}}/>&nbsp;</>}
                    {resultData.LinkedFigs?.length != 0 &&
                        <><IconLink href={"./Figure"} IconID={LDLIcons.BotN_FigureCitations}
                                  HistoryLocStates={location.state}
                                  newStates={{"BibObjID": resultData.BibObjID}}/>&nbsp;</>}
                    </>}/>}
            </ListHolder>
            <ListHeader title={"Basic Record"}>
                <ListElement title={"Reference#"} element={resultData.BibObjID}/>
                <ListElement title={"Author(s)"} element={resultData.Auth}/>
                <ListElement title={"Imprint Year"} element={resultData.NomYear}/>
                <ListElement title={"Title"} element={resultData.Title}/>
                <ListElement title={"Journal"} element={resultData.Journal}/>
                <ListElement title={"Volume:Page(s)"} element={resultData.VolPg}/>
            </ListHeader>
                <i>
                    <>Digital Files ({resultData.LinkedEDocs?.length}).</>
                </i>
                {resultData.LinkedEDocs ? <EasyTable maxHeight={"13em"} width={"80%"} tableRowData={resultData.LinkedEDocs}>
                    <EasyTableElement width={"5%"} title={"File"} elementFromValue={(val: tblLdlEDocs) => val.EDocID}
                                      desc={"the file's Bibliography of the Neuropterida edocID number"}/>
                    <EasyTableElement width={"61%"} title={"Content Type"}
                                      elementFromValue={(val: tblLdlEDocs) => val.Contents}
                                      desc={"a statement identifying the general content type of the associated file"}/>
                    <EasyTableElement width={"8%"} align={"left"} whiteSpace={"nowrap"} title={"Links"} elementFromValue={(val: tblLdlEDocs,index:number) => {
                        if (val.PublicEDoc == -1 || (val.PrivateEDoc == -1 && PDFAccess)) {
                            switch (val.KindID) {
                                case 61:
                                    return <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                     newStates={{"BibObjID": resultData?.BibObjID}}
                                                     IconID={LDLIcons.Viewer_OpenFile}/>
                                default:
                                    return <><IconLink newWindow={true} href={val.EDocLink} IconID={LDLIcons.General_OpenFile_Work}/>{(!(resultData?.LinkedEDocs?.filter((val)=>val.KindID==61).length)&&index==0)&&<> <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                                                                                                                                                                                               newStates={{"BibObjID": resultData?.BibObjID}}
                                                                                                                                                                                                                               IconID={LDLIcons.Viewer_OpenFile}/></>}</>
                            }
                        }
                        return <> &ndash; </>

                    }} desc={"one or more links to displays of the associated file"}/>
                    <EasyTableElement width={"8%"} title={"Type"}
                                      elementFromValue={(val: tblLdlEDocs) => val.Extension}
                                      desc={"the filetype extension of the associated digital file"}/>
                    <EasyTableElement align={"center"} width={"8%"} title={"Kb"}
                                      elementFromValue={(val: tblLdlEDocs) => <FileSizeKiloBytes href={val.EDocLink}
                                                                                                 guessSize={val.Size}/>}
                                      desc={"the approximate size of the associated file in kilobytes"}/>
                    <EasyTableElement width={"10%"} title={"Complete"}
                                      elementFromValue={(val: tblLdlEDocs) => val.Complete}
                                      desc={"yes = the associated file is believed to be complete, i.e., contains the complete text and/or illustrative content of the work cited in the bibliographic record; no = the associated file is known or suspected to be incomplete"}/>
                </EasyTable> : <></>}
            {(resultData.LinkedTaxon?.length != 0 ||resultData.LinkedTaxon?.length !=0)&&<ListHolder>
                <ListElement title={"Annotations"} element={<>{resultData.LinkedTaxon?.length != 0 && <><IconLink href={"./Taxa"} IconID={LDLIcons.BotN_TaxonCitations} HistoryLocStates={location.state} newStates={{"BibObjID": resultData.BibObjID}}/><i> Taxon Citations ({resultData.LinkedTaxon?.length}).</i></>} {resultData.LinkedFigs?.length != 0 && <><IconLink href={"./Figure"} IconID={LDLIcons.BotN_FigureCitations} HistoryLocStates={location.state} newStates={{"BibObjID": resultData.BibObjID}}/><i> Figure Citations ({resultData.LinkedFigs?.length}).</i>
            </>}</>}/>
            </ListHolder>}

            <ListHeader title={"Extended Record"}>
                <ListElement title={"Citation Type"} element={resultData.CiteType}/>
                <ListElement title={"Verified"} element={resultData.Ver}/>
                <ListElement title={"First Author"} element={resultData.FirstAuth}/>
                <ListElement title={"First Author E-mail"} element={resultData.FirstAuthEmail}/>
                <ListElement title={"Publication Date"} element={resultData.PubDate}/>
                <ListElement title={"Dating Notes"} element={resultData.DateNotes}/>
                <ListElement title={"Separate"} element={resultData.VolPgSep}/>
                <ListElement title={"Illustrations"} element={resultData.Figs}/>
                <ListElement title={"Language (Script)"} element={resultData.LangText}/>
                <ListElement title={"Summary/Abstract"} element={resultData.LangSumm}/>
                <ListElement title={"General Notes"} element={resultData.GenNotes}/>
                <ListElement title={"Standard Citation"} element={resultData.CopyCite}/>
                <ListElement title={"Extended Citation"} element={resultData.FullCite}/>
            </ListHeader>
            <ListHeader title={"Data"}>
                <ListElement title={"Source"} element={"Oswald, J. D. World Neuropterida Database."}/>
                <ListElement title={"Timestamp"} element={resultData.DatasetDateTime}/>
            </ListHeader>
        </Record>}</>
};


export default BibloRecord;