import React, {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";

const Logout = (_props: { isPublic: boolean }) => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    useEffect(() => {
        try {
            const response = fetch("/api/users/logout", {
                method: "GET",
            }).then(()=> {
                    setPDFAccess(false);
                    setVerifiedUser(false);
                }
            );

        } catch (e) {
        }
    }, []);
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Login</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    <i>Lacewing Digital Library{!_props.isPublic && <>, Development
                        Version</>}</i>{!_props.isPublic ? <> (LDL-Demo)</> : <> (LDL)</>}
                </p>
                <hr className="rule1" style={{marginBottom: "4px", marginTop: "4px",}}/>

                <p style={{color: "#FF0000"}}>You are now logged out of the <i>Lacewing Digital
                    Library{!_props.isPublic && <>, Development Version</>}</i>{!_props.isPublic && <> (LDL-Demo)</>}.
                    Click <a href="/Authenticate/Login" target="_self">here</a> to login again.</p>
            </div>
        </div>
    </>
};
export default Logout;