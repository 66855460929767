import React, {FormEvent, useEffect, useRef, useState} from "react";

export interface SearchProps {
    ResetSectionGoto?:{sectionIndex:number,fieldIndex:number};
    defaultSearchProps?:SearchProp[];
    layout:SearchPropLayout;
    onSearchClick?: (feildData: SearchProp[]) => void;
    extraElement?:JSX.Element;
}

export interface SearchPropLayout {
    title:string;
    sections: {title?:string,middle?:boolean,feilds:SearchProp[]}[]

}
export interface SearchProp {
                sectionName:string,criteria?:(string|{val:string,dis:string})[],inputData?:string,criteriaVal?:string,bigCriteria?:boolean,mediumCriteria?:boolean,bigInput?:boolean,mediumInput?:boolean,input?:boolean,checkmark?:boolean,description?:string
}

export type SearchOutputProps={ [key:string]:(({ filter?: any, Val: string }|string|undefined)) }
export const proccessSearch= (searchProps:SearchProp[]|undefined,conversionTable:{[SectionTitle:string]:string},out: SearchOutputProps)=>{
    searchProps?.map((dat:any) => {
        let realName = conversionTable[dat.sectionName]
        if(realName) {
            if (dat.criteriaVal && dat.criteriaVal != "") {
                if (dat.input &&dat.inputData&& dat.inputData !="") {
                    out[realName] = {filter: dat.criteriaVal, Val: dat.inputData}
                } else if (!dat.input) {
                    out[realName] = {Val: dat.criteriaVal}
                }
            } else if (dat.input &&dat.inputData&& dat.inputData != "") {
                out[realName] = {Val: dat.inputData}
            } else if (dat.checkmark) {
                if (dat.inputData == "true") {
                    out[realName] = {Val: "Available, see below."}
                }
            }
        }
    })
    return out;
}

const CustomSearch = (_props:SearchProps) => {
    const [dataHolder,setDataHolder]=useState<SearchProp[]>(_props.defaultSearchProps?_props.defaultSearchProps:_props.layout.sections.map((s)=>{
        return s.feilds.map((f)=>{
            return {...f};
        });
    }).flat())

    useEffect(() => {
        if(_props.defaultSearchProps) {
            setDataHolder(_props.defaultSearchProps);
        }else{
            reset();
        }
    }, [_props.defaultSearchProps,_props.layout]);

    const handleSimpleSearchResults = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(_props.onSearchClick) {
            _props.onSearchClick(dataHolder);
        }

    }
    const reset = (focus?:boolean)=>{
        if(focus) {
            if (_props.ResetSectionGoto) {
                document.getElementById(`input:${_props.ResetSectionGoto.sectionIndex}:${_props.ResetSectionGoto.fieldIndex}`)?.focus()
            } else {
                document.getElementById(`input:0:0`)?.focus()
            }
        }
      setDataHolder(_props.layout.sections.map((s)=>{
          return [...s.feilds.map((f)=>{
              return {...f};
          })]
      }).flat());

    }

    const searchBuilder = ()=> {
        const getIndexes = ()=>{
            let index = 0;
            return _props.layout.sections.map((val)=>val.feilds.map((val)=> {
                index++;
                return index-1;
            }))
        }
        const indexes = getIndexes()
        return  _props.layout.sections.map((section, sindex) => <>
                    <tr>
                        <td align="center">&nbsp;</td>
                        <td></td>
                    </tr>
                    {section.title && <tr>
                        {section.middle &&<td></td>}
                            <td colSpan={section.middle?2:1} className={section.middle?"DF1row align-left":"DF1row align-right"}><p
                            style={{color: "#0000FF", marginTop: '0px', marginBottom: '0px'}}>
                            <b>{section.title}&nbsp;</b>
                        </p></td>
                        <td></td>
                    </tr>}{
                    section.feilds.map((feild, findex) => <>
                                <tr>
                                    {(feild.input||feild.criteria||feild.checkmark)&&<td className="DF1row align-right" style={feild.checkmark?{paddingTop:"5px"}:{}}><b>{feild.sectionName}</b>:</td>}

                                    {feild.criteria&&<td colSpan={(feild.bigCriteria||feild.mediumCriteria)?2:1} style={feild.mediumCriteria?{width:"260px"}:{}}>
                                        <select style={(feild.bigCriteria||feild.mediumCriteria)?{width:"100%"}:{}} value={dataHolder[indexes[sindex][findex]].criteriaVal}
                                                onChange={(e) => setDataHolder((prev)=>{
                                                    if(prev){
                                                        let temp =[...prev]
                                                        temp[indexes[sindex][findex]].criteriaVal = e.target.value
                                                        return temp
                                                    }
                                                    return prev;
                                                })}>
                                            {feild.criteria.map((crit)=>{
                                                if(typeof crit == "string") {
                                                    return <option value={crit}>{crit}</option>
                                                }else{
                                                    return <option value={crit.val}>{crit.dis}</option>
                                                }
                                            })}
                                        </select>

                                    </td>}

                                    {feild.input&&<td colSpan={(feild.bigInput||feild.mediumInput)?2:1} style={feild.mediumInput?{width:"25%"}:{}}>
                                        <input id={`input:${sindex}:${findex}`} style={(feild.bigInput||feild.mediumInput)?{height: "29.5px",width:"100%"}:{height: "29.5px"}} name="author" type="text"
                                               onMouseOut={(e) => setDataHolder((prev)=>{
                                                   if(prev){
                                                       let temp = [...prev]
                                                       // @ts-ignore
                                                       temp[indexes[sindex][findex]].inputData=e.target.value.trim()
                                                       return temp
                                                   }
                                                   return prev;
                                               })}
                                               onBlur={(e) => setDataHolder((prev)=>{
                                                   if(prev){

                                                       let temp = [...prev]
                                                       temp[indexes[sindex][findex]].inputData=e.target.value.trim()
                                                       return temp
                                                   }
                                                   return prev;
                                               })}
                                               onChange={(e) => setDataHolder((prev)=>{
                                                   if(prev){

                                                       let temp = [...prev]
                                                       temp[indexes[sindex][findex]].inputData=e.target.value
                                                       return temp
                                                   }
                                                   return prev;
                                               })} value={dataHolder[indexes[sindex][findex]]?.inputData?dataHolder[indexes[sindex][findex]].inputData:""}/>
                                    </td>}
                                    {feild.checkmark&&<td colSpan={3} className="align-left">
                                        <input style={{marginBottom:"7px",marginLeft:"5px",}} name="author" type="checkbox"
                                               onChange={(e) => setDataHolder((prev)=>{
                                                   if(prev){
                                                       let temp = [...prev]
                                                       temp[indexes[sindex][findex]].inputData=String(e.target.checked)
                                                       return temp
                                                   }
                                                   return prev;
                                               })} checked={dataHolder[indexes[sindex][findex]].inputData=="true"}/>&nbsp;{feild.description}
                                    </td>}

                                    {(feild.description&&!feild.checkmark&&(feild.input||feild.criteria))&&<td className="DF1row align-left">&nbsp;&nbsp;{feild.description}</td>}
                                    {(feild.description&&!feild.checkmark&&!(feild.input||feild.criteria))&&<td colSpan={4} align={"center"} style={{fontSize:"small"}}>{feild.description}</td>}
                                </tr>
                            </>

                    )
                }</>
            )
    }

    return <>
        <div className="content">
            <div className="text-body">
                <h1>{_props.layout.title}</h1>
                <form  onSubmit={handleSimpleSearchResults}>
                    <table>
                        <tbody>
                        {searchBuilder()}
                        <tr>
                            <td></td>
                            <td>
                                <button style={{width:"120px",marginRight:"10px",marginTop:"10px",}} type="submit" className="btn btn-info">Search</button>
                            </td>
                            <td>
                                <a style={{color:"white",width:"100px",marginTop:"10px",}} className="btn btn-info" onClick={()=>reset(true)}>Reset</a>
                            </td>
                            <td></td>
                        </tr>

                        </tbody>
                    </table>
                </form>
                {_props.extraElement}
            </div>
        </div>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-66638549-4"></script>
        <script>
            window.onload = customFocusElement('author');
        </script>

        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-66638549-10"></script>
    </>};
export default CustomSearch;