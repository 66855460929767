import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {Link, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHolder,
    Record, TableLegendSection, UpdatePathData,

} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";
import {tblLdlSpCatPhyloTree} from "../../../../database/models/tblLdlSpCatPhyloTree";


const SpeciesPhyloRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlSpCatComb>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getSpecPhyloByPk", {CombObjID: _props["CombObjID"]}, (error: boolean, outData: tblLdlSpCatComb | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
                setCurrentPathData((prev)=>UpdatePathData({MonographRecord:{UpperLeftModuleName:<>Monograph Record &ndash; <i>{outData.ASCurrGenu} {outData.ASCurrSpec} {outData.ASCurrSubs != "$" && outData.ASCurrSubs}</i></>}},prev))

            }
        })
    }, []);


    return <>
        {resultData &&
            <Record title={<>Phylogenetic Trees <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                href={"mailto:j-oswald@tamu.edu?subject=Neuropterida Species of the World: comment/correction for Phylogenetic Trees page \"" + resultData.ASCurrGenu + " " + resultData.ASCurrSpec + (resultData.ASCurrSubs != "$" ? (" " + resultData.ASCurrSubs) : "") + " " + resultData.CurrCombAuthDate + "\""}
                IconID={LDLIcons.General_Email}
                HistoryLocStates={location.state}
            /></>}>
                <ListHolder>
                    <ListElement title={"Monograph Record Links"} element={<>
                        {resultData.TaxonCites?.length != 0 &&
                            <IconLink href={"../Taxa"} IconID={LDLIcons.NSW_TaxonCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.FigureCites?.length != 0 &&
                            <IconLink href={"../Figure"} IconID={LDLIcons.NSW_FigureCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.TerminalTaxon?.length != 0 &&
                            <IconLink href={"../Keys"} IconID={LDLIcons.NSW_IdentificationKeys} HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                    </>}/>
                </ListHolder>
                {resultData.PhyloTrees && <>
                    <i>
                        <>Phylogenetic Trees Records ({resultData.PhyloTrees.length}).</>
                    </i>
                    <EasyTable downloadable={true} downloadName={`${resultData.ASCurrGenu}_${resultData.ASCurrSpec}${resultData.ASCurrSubs != "$" ?("_"+resultData.ASCurrSubs):""}_Phylogenetic_Trees.csv`} extraLegendChildren={<><TableLegendSection title={"Notes"}>This table is designed to display information about phylogenetic tree diagrams that contain the active species or subspecies as a terminal taxon</TableLegendSection><TableLegendSection title={"Data Source"}>
                        {resultData.SpCat?.DSSource}
                    </TableLegendSection><TableLegendSection title={"Data Timestamp"}>
                        {resultData.SpCat?.DSDateTime}
                    </TableLegendSection></>} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData.PhyloTrees}>
                        <EasyTableElement width={"0%"} title={"Year"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeYear}
                                          desc={"the year of publication (or appearance) of the work containing the cited tree"}/>
                        <EasyTableElement width={"15%"} title={"Terminal Taxon"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeTerminalTaxon}
                                          desc={"a string that gives: (1) the LDL-Standard scientific name for a terminal taxon on the cited tree, (2) whether that taxon was a member of the primary 'focal' group of the cited tree [typically, an 'ingroup' taxon], or a member of a 'non-focal' group [typically, an 'outgroup' taxon], and (3) if different from (1), the scientific name used for the taxon on the cited tree; scientific names are cited in LDL-Standard form, i.e., corrected for strictly nomenclatural errors (e.g., unavailable misspellings, incorrect capitalization)"}/>
                        <EasyTableElement width={"15%"} title={"Tree Type"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeType}
                                          desc={"a standardized phrase that attempts to capture the primary use and/or content kind of the cited tree; trees cited as 'mapping trees' typically map a specific kind of data over a phylogenetic relationships tree"}/>
                        <EasyTableElement width={"15%"} title={"Tree Scope (focal/total)"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeScope}
                                          desc={"the 'focal' and 'total' taxonomic scopes of the cited tree; the 'focal' scope is cited as a single higher taxon name that attempts to best capture the set of taxa of primary analytical interest in the cited tree [typically, a taxon that encompasses the collective 'ingroup' taxa]; the 'total' scope is cited as a single higher taxon name that attempts to capture the total set of taxa included in the cited tree [typically, a taxon that encompasses the collective 'ingroup' + 'outgroup' taxa, with far-outgroup taxa sometimes excluded]"}/>
                        <EasyTableElement width={"15%"} title={"Analysis"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeAnalysis}
                                          desc={"the primary analytical method(s) used to produce the cited tree"}/>
                        <EasyTableElement width={"25%"} title={"Data"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeData}
                                          desc={"the kind(s) of data analyzed to produce the cited tree"}/>
                        <EasyTableElement width={"15%"} title={"Source"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreeSource}
                                          desc={"a short bibliographic citation to the work in which the cited tree appears"}/>
                        <EasyTableElement width={"10%"} title={"Page/Fig"}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PagePlateFigure}
                                          desc={"the page or plate on which the cited tree appears (or starts), and its figure designation; clarification and disambiguation information may be given in square brackets []"}/>
                        <EasyTableElement width={"0%"} title={<>1&#176;/2&#176;</>}
                                          elementFromValue={(val: tblLdlSpCatPhyloTree) => val.PhyloTreePrimarySecondary}
                                          desc={<>the cited tree occurs in a primary (1&#176;) or secondary (2&#176;)
                                              source; primary sources typically contain the first publication of a tree
                                              and usually contain original information on the tree's scope, data, and
                                              analytical methods; secondary sources contain complete or partial trees
                                              republished from primary sources; focus is commonly on comparing alternate
                                              tree topologies, and the amount and kinds of tree-associated data are
                                              highly variable</>}/>
                        <EasyTableElement width={"0px"} minWidth={"52px"} whiteSpace={"nowrap"} title={"Links"} elementFromValue={(val: tblLdlSpCatPhyloTree) => <>
                            <IconLink newWindow={true} href={"/Biblio/Record"} IconID={LDLIcons.BotN_BibliographicRecord}
                                      newStates={{"BibObjID": val.PhyloTreeBibObjID}}/>
                            {(val.EDoc && val.EDoc.EDocLink && (val.EDoc.PublicEDoc == -1 || (val.EDoc.PrivateEDoc == -1 && PDFAccess))) ? <>
                                <IconLink newWindow={true} href={val.EDoc.EDocLink}
                                          IconID={LDLIcons.General_OpenFile_Work}/>
                                <IconLink newWindow={true} href={"/PageViewer/Pages"} newStates={{
                                    "BibObjID": val.PhyloTreeBibObjID,
                                    "EDocPageID": val.EDocPage?.EDocPageID?val.EDocPage.EDocPageID:0
                                }} IconID={val.EDocPage?.EDocPageID?LDLIcons.Viewer_OpenPage:LDLIcons.Viewer_OpenFile}/>
                            </> : <>&nbsp;&ndash;&nbsp;&nbsp;&ndash;&nbsp;</>}
                            {(val.EDocPage && val.EDocPage.EDocPageLink && (val.EDocPage.PublicEDocPage == -1 || (val.EDocPage.PrivateEDocPage == -1 && PDFAccess))) ? <>
                                <IconLink newWindow={true} href={val.EDocPage.EDocPageLink}
                                          IconID={LDLIcons.General_Openfile_Page}/>
                            </> : <>&nbsp;&ndash;&nbsp;</>}
                        </>}
                                          desc={"one or more links to citation data and/or digital files containing the cited tree"}/>
                    </EasyTable>
                </>}
            </Record>}
    </>
};


export default SpeciesPhyloRecord;