import React, {useEffect, useState} from "react";
import {proccessSearch, SearchOutputProps} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {tblLdlGenCatComb} from "../../../../database/models/tblLdlGenCatComb";
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    IgnoreEmptySimple,
    Record,
    
    RemoveIfLastSame,
    SearchCriteria
} from "../../utils/CustomRecord";


enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface GenusSearchProps {
    ASOrde?: { filter: filterType, Val: string },
    ASFami?: { filter: filterType, Val: string },
    ASSubf?: { filter: filterType, Val: string },
    ASTrib?: { filter: filterType, Val: string },
    ASSubt?: { filter: filterType, Val: string },
    ASAuth?: { filter: filterType, Val: string },
    ASYear?: { filter: filterType, Val: string },
    GenuNum?: { Val: string },
    ASRefNum?: { Val: string },
    CombinationNum?: { Val: string },
    NameStatus?: { Val: string },
    ExtantExtinct?: { Val: string },
    GenOrSub?: { filter: filterType, Val: string },
    useOr?: string
}

const conversionTable = {
    "Order": "ASOrde",
    "Family": "ASFami",
    "Subfamily": "ASSubf",
    "Tribe": "ASTrib",
    "Subtribe": "ASSubt",
    "Author": "ASAuth",
    "Year": "ASYear",
    "Genus# is": "GenuNum",
    "Reference# is": "ASRefNum",
    "Combination# is": "CombinationNum",
    "Name Status": "NameStatus",
    "Extant / Fossil": "ExtantExtinct",
    "Genus or Subgenus": "GenOrSub",
    "Search Name Set": "KeyTermStatus"
}
const GenusResult = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlGenCatComb[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const _props = location.state as any | undefined;

    useEffect(() => {
        let out: GenusSearchProps = Object()

        out = proccessSearch(_props?.searchProps, conversionTable, out as SearchOutputProps);


        callAPI("/api/general/doGenusSearch", out, (error: boolean, outData: tblLdlGenCatComb[] | Response) => {
            if (error) {
                console.log("error")
            } else if (!(outData instanceof Response)) {

                setResultData(outData)
                console.log(outData)

            }

        });
    }, []);


    return <>
        {(resultData) && <>
            <Record title={"Search Results"}>
                <SearchCriteria searchCriteria={_props?.searchProps}>
                <i>
                    <>Results Records ({resultData.length}).</>
                </i>
                </SearchCriteria>
                <EasyTable autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData}>
                    <EasyTableSection align={"center"} title={"Catalogue Record Links"}
                                      desc={"a combination of genus- and species-group names, given in the format: Genus[ (Subgenus)] species[ subspecies][ infrasubspecific rank][ infrasubspecies] [(]Author, yyyy.mm.dd[)]"}>
                        <EasyTableElement width={"0%"} removeBottomLineIfEmpty={true} title={"Genus"}
                                          elementFromValue={RemoveIfLastSame((val: tblLdlGenCatComb) => val.Genu)}/>
                        <EasyTableElement width={"0%"} align={"right"} ignoreIfLastLine={true}
                                          removeBottomLineIfEmpty={true} title={"(Subgenus)"}
                                          elementFromValue={(val: tblLdlGenCatComb) => IgnoreEmptySimple(val.Subg)}/>
                        <EasyTableElement title={"Author, yyyy.mm.dd"}
                                          elementFromValue={(val: tblLdlGenCatComb) => <>{val.Author}, {val.Date}</>}/>
                    </EasyTableSection>
                    <EasyTableSection align={"center"} title={"Classification"}>
                        <EasyTableElement width={"0%"} title={"Order"}
                                          elementFromValue={(val: tblLdlGenCatComb) => val.ASOrde}
                                          desc={"the order of the Neuropterida to which the associated Taxon belongs"}/>
                        <EasyTableElement width={"0%"} title={"Family"}
                                          elementFromValue={(val: tblLdlGenCatComb) => val.ASFami}
                                          desc={"genus name of a combination that matches the active search criteria"}/>
                        <EasyTableElement width={"0%"} title={"Combination"}
                                          elementFromValue={(val: tblLdlGenCatComb) => val.CombinationNum}
                                          desc={"the “combination” number used in the Neuropterida Species of the World for the associated Taxon name."}/>
                    </EasyTableSection>
                </EasyTable>
            </Record>
        </>
        }
    </>
};


export default GenusResult;