import React from "react";
import CustomSearch, {SearchProp} from "../../utils/CustomSearch";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";


enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface GenusSearchProps {
    ASOrde?: { filter: filterType, Val: string },
    ASFami?: { filter: filterType, Val: string },
    ASSubf?: { filter: filterType, Val: string },
    ASTrib?: { filter: filterType, Val: string },
    ASSubt?: { filter: filterType, Val: string },
    ASAuth?: { filter: filterType, Val: string },
    ASYear?: { filter: filterType, Val: string },
    GenuNum?: { Val: string },
    ASRefNum?: { Val: string },
    CombinationNum?: { Val: string },
    NameStatus?: { Val: string },
    ExtantExtinct?: { Val: string },
    GenOrSub?: { filter: filterType, Val: string },
    useOr?: string
}

const conversionTable = {
    "Order": "ASOrde",
    "Family": "ASFami",
    "Subfamily": "ASSubf",
    "Tribe": "ASTrib",
    "Subtribe": "ASSubt",
    "Author": "ASAuth",
    "Year": "ASYear",
    "Genus# is": "GenuNum",
    "Reference# is": "ASRefNum",
    "Combination# is": "CombinationNum",
    "Name Status": "NameStatus",
    "Extant / Fossil": "ExtantExtinct",
    "Genus or Subgenus": "GenOrSub",
    "Search Name Set": "KeyTermStatus"
}
const GenusSearch = (_props: { advanced?: boolean }) => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const navigate = useNavigate();
    const location = useLocation();
    const savedSearch = location.state as any | undefined;
    const currentPath = location.pathname;

    const handleSearchResults = (feildData: SearchProp[]) => {
        let statesCopy: any = location.state ? location.state : {};
        statesCopy["searchProps"] = feildData;
        if (_props.advanced) {
            statesCopy["advanced"] = true;
            navigate('/GenusCatalog/AdvancedSearch/SearchResults', {state: statesCopy});
        } else {
            statesCopy["advanced"] = false;
            navigate('/GenusCatalog/SimpleSearch/SearchResults', {state: statesCopy});
        }
    }


    return <>
        {!_props.advanced &&
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Simple Search",
                    sections: [{
                        title: "Scientific Name", feilds: [
                            {
                                sectionName: "Genus or Subgenus",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g. sialis, sia, ia, alis)"
                            },
                        ]
                    },
                        {
                            title: "Restrictions", feilds: [
                                {
                                    sectionName: "Name Status",
                                    criteria: [{
                                        val: "",
                                        dis: "no restrictions (all available and unavailable names)"
                                    }, {val: "0", dis: "only valid names"}, {
                                        val: "1",
                                        dis: "only available names"
                                    }, {val: "2", dis: "only unavailable names"}, {
                                        val: "3",
                                        dis: "only invalid names"
                                    }],
                                    criteriaVal: "",
                                    bigCriteria: true
                                },
                                {
                                    sectionName: "Extant / Fossil",
                                    criteria: [{
                                        val: "",
                                        dis: "no restrictions (all extant and fossil names)"
                                    }, {val: "0", dis: "only names of extant taxa"}, {
                                        val: "1",
                                        dis: "only names of fossil taxa"
                                    }],
                                    criteriaVal: "",
                                    bigCriteria: true
                                }
                            ]
                        }]
                }}/>}
        {_props.advanced &&
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Advanced Search",
                    sections: [{
                        title: "Scientific Name", feilds: [
                            {
                                sectionName: "Order",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g., meg, megaloptera, gal, tera)"
                            },
                            {
                                sectionName: "Family",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g., dil, dilaridae, rid, dae)"
                            },
                            {
                                sectionName: "Subfamily",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: ""
                            },
                            {
                                sectionName: "Tribe",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: ""
                            },
                            {
                                sectionName: "Subtribe",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: ""
                            },
                        ]
                    },
                        {
                            title: "Miscellaneous", feilds: [
                                {
                                    sectionName: "Author",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: "(e.g., Ban, Banks, ank, nks)"
                                },
                                {
                                    sectionName: "Year",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "is",
                                    input: true,
                                    description: "(e.g., 1948, 194, 94, 948)"
                                },
                                {
                                    sectionName: "Journal",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "contains",
                                    input: true,
                                    description: "(e.g., ent, annals, jour, ology)"
                                }
                            ]
                        },
                        {
                            title: "Number", feilds: [
                                {
                                    sectionName: "Genus# is",
                                    input: true,
                                    mediumInput: true,
                                    description: " (CombGenu[or Subg]TaxObjID)"
                                },
                                {
                                    sectionName: "Reference# is",
                                    input: true,
                                    mediumInput: true,
                                    description: "(BibObjID, =BotN r#, =ref#)"
                                },
                                {
                                    sectionName: "Combination# is",
                                    input: true,
                                    mediumInput: true,
                                    description: "(CombObjID, =comb#)"
                                }
                            ]
                        },
                        {
                            title: "Restrictions", feilds: [
                                {
                                    sectionName: "Name Status",
                                    criteria: [{
                                        val: "",
                                        dis: "no restrictions (all available and unavailable names)"
                                    }, {val: "0", dis: "only valid names"}, {
                                        val: "1",
                                        dis: "only available names"
                                    }, {val: "2", dis: "only unavailable names"}, {
                                        val: "3",
                                        dis: "only invalid names"
                                    }],
                                    criteriaVal: "",
                                    bigCriteria: true
                                },
                                {
                                    sectionName: "Extant / Fossil",
                                    criteria: [{
                                        val: "",
                                        dis: "no restrictions (all extant and fossil names)"
                                    }, {val: "0", dis: "only names of extant taxa"}, {
                                        val: "1",
                                        dis: "only names of fossil taxa"
                                    }],
                                    criteriaVal: "",
                                    bigCriteria: true
                                }
                            ]
                        }]
                }}/>}
    </>
};


export default GenusSearch;