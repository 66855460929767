import React from "react";
import CustomSearch, {SearchProp} from "../../utils/CustomSearch";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";


enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface NeurDirSearchProps {
    Sur?: { filter: filterType, Val: string },
    Given?: { filter: filterType, Val: string },
    Mail?: { filter: filterType, Val: string },
    SerEmail?: { filter: filterType, Val: string },
    SerInterests?: { filter: filterType, Val: string },
    useOr?: string
}

const conversionTable = {
    "Last name (family name)": "Sur",
    "First name (given name)": "Given",
    "Address": "Mail",
    "E-mail": "SerEmail",
    "Interests": "SerInterests",
}
const DirectorySearch = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const savedSearch = location.state as any | undefined;


    const handleSearchResults = (feildData: SearchProp[]) => {
        let statesCopy: any = location.state ? location.state : {};
        statesCopy["searchProps"] = feildData;
        navigate('/NeuroDirectory/Search/SearchResults', {state: statesCopy});
    }
    return <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
        {
            title: "Search",
            sections: [{
                title: "Search By", feilds: [
                    {
                        sectionName: "Last name (family name)",
                        criteria: ["is", "begins with", "contains", "ends with"],
                        criteriaVal: "begins with",
                        input: true,
                        description: "(e.g., hagen, mcl)"
                    },
                    {
                        sectionName: "First name (given name)",
                        criteria: ["is", "begins with", "contains", "ends with"],
                        criteriaVal: "contains",
                        input: true,
                        description: "(e.g., mike, hen)"
                    },
                    {
                        sectionName: "Address",
                        criteria: ["is", "begins with", "contains", "ends with"],
                        criteriaVal: "contains",
                        input: true,
                        description: "(e.g., usa, spain)"
                    },
                    {
                        sectionName: "E-mail",
                        criteria: ["is", "begins with", "contains", "ends with"],
                        criteriaVal: "contains",
                        input: true,
                        description: "(e.g., rte, .edu, .ru)"
                    },
                    {
                        sectionName: "Interests",
                        criteria: ["is", "begins with", "contains", "ends with"],
                        criteriaVal: "contains",
                        input: true,
                        description: "(e.g., chrysopidae)"
                    },

                ]
            }]
        }} extraElement={<div style={{textAlign: 'right', fontSize: '9px', marginRight: '130px'}}>
        <a href="http://www.easycounter.com/">
            <img src="http://www.easycounter.com/counter.php?lacewingkrishna" style={{border: "0"}} alt="Hit Counter"/>
        </a>
        <br/><a href="http://www.easycounter.com/FreeCounter3.html">page counter</a>
    </div>}/>

};


export default DirectorySearch;