import React from "react";
import CustomSearch, {SearchProp} from "../../utils/CustomSearch";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasySearch, EasySearchCheckBox,
    EasySearchField,
    EasySearchInput,
    EasySearchSection,
    EasySearchSelect, EqFilter, Op, OpFilter, Record, SelectTrueFilter
} from "../../utils/CustomRecord";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";


const SpeciesSearch = (_props: { advanced?: boolean }) => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const navigate = useNavigate();
    const location = useLocation();
    const savedSearch = location.state as any | undefined;


   /* const handleSearchResults = (feildData: SearchProp[]) => {
        let statesCopy: any = location.state ? location.state : {};
        statesCopy["searchProps"] = feildData;
        if (_props.advanced) {
            statesCopy["advanced"] = true;
            navigate("/SpeciesCatalog/AdvancedSearch/SearchResults", {state: statesCopy});
        } else {
            statesCopy["advanced"] = false;
            navigate("/SpeciesCatalog/SimpleSearch/SearchResults", {state: statesCopy});
        }
    }*/


    return <>
        <Record title={_props.advanced?"Advanced Search":"Simple Search"} ><EasySearch<tblLdlSpCatComb> defaultSearchStates={savedSearch?.searchProps?.searchStateData}  onSearch={(data)=>{
            console.log(data)
            let statesCopy: any = location.state ? location.state : {};
            statesCopy["searchProps"] = data;
            if (_props.advanced) {
                statesCopy["advanced"] = true;
                navigate("/SpeciesCatalog/AdvancedSearch/SearchResults", {state: statesCopy});
            } else {
                statesCopy["advanced"] = false;
                navigate("/SpeciesCatalog/SimpleSearch/SearchResults", {state: statesCopy});
            }
        }}>
            <EasySearchSection comment={_props.advanced?undefined:"(For best results omit the nomenclatural gender ending of species-group names.)"}  title={"Scientific Name"}>
                {!_props.advanced&&<EasySearchField title={"Genus or Subgenus"} desc={"(e.g., chry, chrysopa, ryso, opa)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                {!_props.advanced&&<EasySearchField feildFunction={(val,si,fi,sa)=> {
                    const outLR = [OpFilter<tblLdlSpCatComb>(sa[si][fi - 1], ["CombGenuName", "CombSubgName"]),
                        OpFilter<tblLdlSpCatComb>(sa[si][fi + 1], ["CombSpgrName", "CombSpecName", "CombSubsName", "CombInfrName", "CombSubiName"])].filter((val) => !!val);
                    return outLR.length ? ({[val[0].data == "or" ? Op.or : Op.and]: outLR}) : undefined
                }} title={"and/or"}>
                    <EasySearchSelect convertFunction={(val)=>val=="or"?val:undefined} defaultValue={"and"}  options={["and","or"]}/>
                </EasySearchField>}
                {!_props.advanced&&<EasySearchField title={"Species, Subspecies or Variety"} desc={"(e.g., pall, pallidus, llid, dus)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput focus={true}/>
                </EasySearchField>}
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASOrde")}
                                                   title={"Order"} desc={"(e.g., meg, megaloptera, gal, tera)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASFami")}
                                                   title={"Family"} desc={"(e.g., dil, dilaridae, rid, dae)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASSubf")}
                                                   title={"Subfamily"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASTrib")}
                                                   title={"Tribe"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}
                {_props.advanced&&<EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASSubt")}
                                                   title={"Subtribe"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>}

            </EasySearchSection>
            {_props.advanced&&<EasySearchSection comment={"(For best results omit the nomenclatural gender ending of species-group names.)"}>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASOrigGenu")}
                                                   title={"Original Genus"} desc={"(e.g., chry, chrysopa, ryso, opa)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASCurrGenu")}
                                                   title={"Current Genus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, ["ASOrigGenu", "ASCurrGenu"])}
                                                   title={"Original or Current Genus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASOrigSubg")}
                                                   title={"Original Subgenus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "ASCurrSubg")}
                                                   title={"Current Subgenus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, ["ASOrigSubg", "ASCurrSubg"])}
                                                   title={"Original or Current Subgenus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, ["ASOrigGenu", "ASCurrGenu", "ASOrigSubg", "ASCurrSubg"])}
                                                   title={"Original or Current, Genus or Subgenus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombGenuName")}
                                                   title={"Combination Genus"} desc={"(e.g., chry, chrysopa, ryso, opa)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombSubgName")}
                                                   title={"Combination Subgenus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, ["CombGenuName", "CombSubgName"])}
                                                   title={"Combination Genus or Subgenus"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombSpgrName")}
                                                   title={"Combination (Species Group)"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombSpecName")}
                                                   title={"Combination Species"} desc={"(e.g., pall, pallidus, llid, dus)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombSubsName")}
                                                   title={"Combination Subspecies"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombInfrName")}
                                                   title={"Combination Infrasubspecies"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, "CombSubiName")}
                                                   title={"Combination Subinfrasubspecies"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>

                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val, ["CombSpgrName", "CombSpecName", "CombSubsName", "CombInfrName", "CombSubiName"])}
                                                   title={"Combination Any Species-Group Name"} desc={""}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
            </EasySearchSection>}
            {_props.advanced&&<EasySearchSection title={"Miscellaneous"}>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val,"ASAuth")} title={"Author"} desc={"(e.g., Banks, Ban, anks, nks)"}>
                    <EasySearchSelect defaultValue={"begins with"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val,"ASYear")} title={"Year"} desc={"(e.g., 1948, 194, 94, 948)"}>
                    <EasySearchSelect defaultValue={"is"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>OpFilter<tblLdlSpCatComb>(val,"ASJour")} title={"Journal"} desc={"(e.g., ent, annals, jour, ology)"}>
                    <EasySearchSelect defaultValue={"contains"}  options={["is", "begins with", "contains", "ends with"]}/>
                    <EasySearchInput/>
                </EasySearchField>
            </EasySearchSection>}
            {_props.advanced&&<EasySearchSection title={"Number"}>
                <EasySearchField feildFunction={(val)=>EqFilter<tblLdlSpCatComb>(val,["CombGenuTaxObjNum", "CombSubgTaxObjNum"])} title={"Genus# is"} desc={"(CombGenu[or Subg]TaxObjID)"}>
                    <EasySearchInput colSpan={2}/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>EqFilter<tblLdlSpCatComb>(val,["CombSpecTaxObjNum", "CombSubsTaxObjNum"])} title={"Species# is"} desc={"(CombSpec[or Subs, or Infr]TaxObjID)"}>
                    <EasySearchInput colSpan={2}/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>EqFilter<tblLdlSpCatComb>(val,"ASBibObjNum")} title={"Reference# is"} desc={"(BibObjID, =BotN r#, =ref#)"}>
                    <EasySearchInput colSpan={2}/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>EqFilter<tblLdlSpCatComb>(val,"CombObjID")} title={"Combination# is"} desc={"(CombObjID, =comb#)"}>
                    <EasySearchInput colSpan={2}/>
                </EasySearchField>
            </EasySearchSection>}
            {_props.advanced&&<EasySearchSection title={"Restrictions"}>
                <EasySearchField feildFunction={(val)=>SelectTrueFilter<tblLdlSpCatComb>(val,[[],["ASAvail"], ["ASUnavail"], ["ASValid"], ["ASAvail", "ASInvalid"]])} title={"Name Status"} desc={"(CombGenu[or Subg]TaxObjID)"}>
                    <EasySearchSelect colSpan={2} defaultValue={"available or unavailable"} useIndexAsValues={true}  options={["available or unavailable","available only","unavailable only","valid only","available/invalid only"]}/>
                </EasySearchField>
                <EasySearchField feildFunction={(val)=>SelectTrueFilter<tblLdlSpCatComb>(val,[[],["ASExtant"], ["ASExtinct"], ["ASExtant", "ASExtinct"]])} title={"Extant / Extinct"} desc={"(CombGenu[or Subg]TaxObjID)"}>
                    <EasySearchSelect colSpan={2} defaultValue={"extant or extinct"} useIndexAsValues={true}  options={["extant or extinct","extant only","extinct only","extant and extinct"]}/>
                </EasySearchField>
            </EasySearchSection>}
        </EasySearch></Record>
        {/*{!_props.advanced &&
            <CustomSearch ResetSectionGoto={{sectionIndex:0,fieldIndex:2}} defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Simple Search",
                    sections: [{
                        title: "Scientific Name", feilds: [
                            {
                                sectionName: "Genus or Subgenus",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g., chry, chrysopa, ryso, opa)"
                            },
                            {sectionName: "and/or", criteria: ["and", "or"], criteriaVal: "and"},
                            {
                                sectionName: "Species, Subspecies or Variety",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g., pall, pallidus, llid, dus)"
                            },
                            {
                                sectionName: "none",
                                description: "(For best results omit the nomenclatural gender ending of species-group names.)"
                            }
                        ]
                    }]
                }}/>}
        {_props.advanced &&
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Advanced Search",
                    sections: [{
                        title: "Scientific Name", feilds: [
                            {
                                sectionName: "Order",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g., meg, megaloptera, gal, tera)"


                            },
                            {
                                sectionName: "Family",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: "(e.g., dil, dilaridae, rid, dae)"


                            },
                            {
                                sectionName: "Subfamily",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: ""


                            },
                            {
                                sectionName: "Tribe",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: ""


                            },
                            {
                                sectionName: "Subtribe",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "begins with",
                                input: true,
                                description: ""


                            },
                        ]
                    },
                        {
                            feilds: [
                                {
                                    sectionName: "Original Genus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: "(e.g., chry, chrysopa, ryso, opa)"
                                },
                                {
                                    sectionName: "Current Genus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Original or Current Genus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Original Subgenus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Current Subgenus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Original or Current Subgenus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Original or Current, Genus or Subgenus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination Genus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: "(e.g., chry, chrysopa, ryso, opa)"
                                },
                                {
                                    sectionName: "Combination Subgenus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination Genus or Subgenus",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination (Species Group)",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination Species",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: "(e.g., pall, pallidus, llid, dus)"
                                },
                                {
                                    sectionName: "Combination Subspecies",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination Infrasubspecies",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination Subinfrasubspecies",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "Combination Any Species-Group Name",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: ""
                                },
                                {
                                    sectionName: "none",
                                    description: "(For best results omit the nomenclatural gender endings of any species-group names.)"
                                }
                            ]
                        },
                        {
                            title: "Miscellaneous", feilds: [
                                {
                                    sectionName: "Author",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "begins with",
                                    input: true,
                                    description: "(e.g., Ban, Banks, ank, nks)"
                                },
                                {
                                    sectionName: "Year",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "is",
                                    input: true,
                                    description: "(e.g., 1948, 194, 94, 948)"
                                },
                                {
                                    sectionName: "Journal",
                                    criteria: ["is", "begins with", "contains", "ends with"],
                                    criteriaVal: "contains",
                                    input: true,
                                    description: "(e.g., ent, annals, jour, ology)"
                                }
                            ]
                        },
                        {
                            title: "Number", feilds: [
                                {
                                    sectionName: "Genus# is",
                                    input: true,
                                    bigInput: true,
                                    description: " (CombGenu[or Subg]TaxObjID)"
                                },
                                {
                                    sectionName: "Species# is",
                                    input: true,
                                    bigInput: true,
                                    description: "(CombSpec[or Subs, or Infr]TaxObjID)"
                                },
                                {
                                    sectionName: "Reference# is",
                                    input: true,
                                    bigInput: true,
                                    description: "(BibObjID, =BotN r#, =ref#)"
                                },
                                {
                                    sectionName: "Combination# is",
                                    input: true,
                                    bigInput: true,
                                    description: "(CombObjID, =comb#)"
                                }
                            ]
                        },
                        {
                            title: "Restrictions", feilds: [
                                {
                                    sectionName: "Name Status",
                                    criteria: [{
                                        val: "",
                                        dis: "available or unavailable"
                                    }, {val: "0", dis: "available only"}, {
                                        val: "1",
                                        dis: "unavailable only"
                                    }, {val: "2", dis: "valid only"}, {
                                        val: "3",
                                        dis: "available/invalid only"
                                    }],
                                    criteriaVal: "",
                                    bigCriteria: true
                                },
                                {
                                    sectionName: "Extant / Extinct",
                                    criteria: [{val: "", dis: "extant or extinct"}, {
                                        val: "0",
                                        dis: "extant only"
                                    }, {val: "1", dis: "extinct only"}, {
                                        val: "2",
                                        dis: "extant and extinct"
                                    }],
                                    criteriaVal: "",
                                    bigCriteria: true
                                },
                            ]
                        }]
                }}/>}*/}

    </>
};


export default SpeciesSearch;