import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {Link, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHolder,
    Record,

    SelectFromArrays,
    TableLegendElement,
    TableLegendSection, UpdatePathData
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";
import {tblLdlSpCatAnnotCite} from "../../../../database/models/tblLdlSpCatAnnotCite";
import TaxonTopicTypes from "../../utils/OnServerStartLoadedJsons/TaxonTopicTypes.json";

const SpeciesTaxonRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlSpCatComb>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
   const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getSpecTaxCitByPk", {CombObjID: _props["CombObjID"]}, (error: boolean, outData: tblLdlSpCatComb | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
                setCurrentPathData((prev)=>UpdatePathData({MonographRecord:{UpperLeftModuleName:<>Monograph Record &ndash; <i>{outData.ASCurrGenu} {outData.ASCurrSpec} {outData.ASCurrSubs != "$" && outData.ASCurrSubs}</i></>}},prev))

            }
        })
    }, []);

   /* `Show All topics`,
        `Show All topics except ‘list(ed)’`,
        `Show ‘available’ topic records only`,
        `Show ‘behaviour’ topic records only`,
        `Show ‘biology’ topic records only`,
        `Show ‘description’ topic records only`,
        `Show ‘distribution’ topic records only`,
        `Show ‘economic entomology’ topic records only`,
        `Show ‘figured’ topic records only`,
        `Show ‘description’ topic records only`,
        `Show ‘immature stages’ topic records only`,
        `Show ‘list(ed)’ topic records only`,
        `Show ‘literature’ topic records only`,
        `Show ‘miscellaneous’ topic records only`,
        `Show ‘molecular’ topic records only`,
        `Show ‘morphology’ topic records only`,
        `Show ‘nomenclature’ topic records only`,
        `Show ‘note’ topic records only`,
        `Show ‘parasite’ topic records only`,
        `Show ‘phylogeny’ topic records only`,
        `Show ‘physiology’ topic records only`,
        `Show ‘redescription’ topic records only`,
        `Show ‘revision’ topic records only`,
        `Show ‘synonym(y)’ topic records only`,
        `Show ‘taxonomy’ topic records only`,
        `Show ‘type(s)’ topic records only`,
        `Show ‘unavailable’ topic records only`*/



    return <>
        {resultData &&
            <Record title={<>Taxon Citations <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                href={"mailto:j-oswald@tamu.edu?subject=Neuropterida Species of the World: comment/correction for Taxon Citations page \"" + resultData.ASCurrGenu + " " + resultData.ASCurrSpec + (resultData.ASCurrSubs != "$" ? (" " + resultData.ASCurrSubs) : "") + " " + resultData.CurrCombAuthDate + "\""}
                IconID={LDLIcons.General_Email}
                HistoryLocStates={location.state}
            /></>}>
                <ListHolder>
                    <ListElement title={"Monograph Record Links"} element={<>
                        {resultData.FigureCites?.length != 0 &&
                            <IconLink href={"../Figure"} IconID={LDLIcons.NSW_FigureCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.TerminalTaxon?.length != 0 &&
                            <IconLink href={"../Keys"} IconID={LDLIcons.NSW_IdentificationKeys} HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.PhyloTrees?.length != 0 &&
                            <IconLink href={"../PhylogeneticTree"} IconID={LDLIcons.NSW_PhylogeneticTrees}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}</>}/>
                </ListHolder>
                {resultData.TaxonCites && <>
                    <SelectFromArrays containerWidth={"100%"} selectOptions={[
                        {defaultSelectedIndex:0,arrayInput:["Show All Citations (sort by year)","Show All Citations (sort by taxon name)","Hide Citations List"]},
                        {defaultSelectedIndex:0,arrayInput:[`Show All topics`,`Show All topics except ‘list(ed)’`,...Array.from(TaxonTopicTypes).map((val)=>`Show '${val[0]}' topic records only`)]}
                    ]} selectRenderAbove={(valIndexPairs:{val:any,index:number}[])=>{

                        const arrayLength = (valIndexPairs[0].index!=2&&resultData&&resultData.TaxonCites)?(valIndexPairs[1].index!=0?(valIndexPairs[1].index==1?resultData.TaxonCites.filter((val)=>!(val.AnnotSetShort?.includes("Lst"))).length:resultData.TaxonCites.filter((val)=>val.AnnotSetShort?.includes(TaxonTopicTypes[valIndexPairs[1].index-2][1])).length):resultData?.TaxonCites?.length):0;



                        return <>{resultData.TaxonCites&&<i>
                        <>Taxon Citation Records ({resultData.TaxonCites.length} total; {arrayLength?arrayLength:0} displayed).  </>
                    </i>}</>}}  selectRender={(valIndexPairs: {val: any, index: number}[])=>{

                        let arrayout = resultData.TaxonCites;
                        if(arrayout){
                            switch (valIndexPairs[1].index){
                                case 0:;break;
                                case 1:
                                    arrayout=arrayout.filter((val)=>!(val.AnnotSetShort?.includes("Lst")))
                                    ;break;
                                default:
                                    const fVal=TaxonTopicTypes[valIndexPairs[1].index-2][1]
                                    arrayout=arrayout.filter((val)=>val.AnnotSetShort?.includes(fVal))
                                    ;
                            }

                        }
                        if(arrayout) {
                            switch (valIndexPairs[0].index) {
                                case 0:
                                    arrayout = arrayout.sort((val1, val2) => Number(val1.PageYear) - Number(val2.PageYear));break;
                                case 1:
                                    arrayout = arrayout.sort((val1, val2) => (val1.NameUsedInWorkCite&&val2.NameUsedInWorkCite)?val1.NameUsedInWorkCite.localeCompare(val2.NameUsedInWorkCite):0);break;
                                    ;break;
                                case 2:
                                    arrayout = undefined;
                            }
                        }

                        return <>{arrayout && <>
                            <EasyTable downloadable={true} downloadName={`${resultData.ASCurrGenu}_${resultData.ASCurrSpec}${resultData.ASCurrSubs != "$" ?("_"+resultData.ASCurrSubs):""}_Taxon_Citations.csv`} extraSymbolsChildren={
                                <><TableLegendElement title={"*"} element={"unavailable name"}/><TableLegendElement
                                    title={"?"} element={"unknown / uncertain"}/><TableLegendElement
                                    title={<>"&ndash;"</>} element={"not available / not applicable"}/></>
                           } extraLegendChildren={<><TableLegendSection title={"Abbreviations"}>
                                <TableLegendElement title={"p."} element={"page"}/>
                            </TableLegendSection>
                                <TableLegendSection title={"Notes"}>
                                    This table is designed to display topic-annotated taxon citations relating to the active species or subspecies. These data have been compiled from the relevant scientific literature, and frequently contain links to that literature. Each citation indicates the primary topics addressed in the citation text
                            </TableLegendSection><TableLegendSection title={"Data Source"}>
                                {resultData.SpCat?.DSSource}
                                </TableLegendSection><TableLegendSection title={"Data Timestamp"}>
                                    {resultData.SpCat?.DSDateTime}
                                </TableLegendSection>
                            </>} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow}   tableRowData={arrayout}>
                                    <EasyTableElement width={"6%"} title={"Year"} elementFromValue={(val: tblLdlSpCatAnnotCite) => val.PageYear}
                                                      desc={"the ‘page year’ of the page or plate on which the citation appears (or starts); the year that is associated with the cited page or plate in the Bibliography of the Neuropterida and other Lacewing Digital Library works"}/>
                                    <EasyTableElement width={"19%"} title={"Name Used In Work"}
                                                      elementFromValue={(val: tblLdlSpCatAnnotCite) => val.NameUsedInWorkCite}
                                                      desc={"the name used in the cited work; scientific names are corrected to the Neuropterida Species of the World standard for strictly nomenclatural errors (e.g., unavailable misspellings, incorrect capitalization, gender-matching of species-group names); erroneous, work-specific, scientific name orthographies, if recorded, are shown in parentheses () as “sic” citations; erroneous author and/or date citations are generally not recorded; documented misidentifications are shown enclosed in square brackets [], usually with brief documentation in the Notes column"}/>
                                    <EasyTableElement width={"16%"} title={"Topics"}
                                                      elementFromValue={(val: tblLdlSpCatAnnotCite) => val.AnnotSetLong}
                                                      desc={"one or more words or phrases that convey general information about classes of data that are documented to occur in the cited work, in association with the cited taxon"}/>
                                    <EasyTableElement width={"16%"} title={"Source"}
                                                      elementFromValue={(val: tblLdlSpCatAnnotCite) => val.AuthYear}
                                                      desc={"a short “Author Year” citation to the work in which the citation appears; for more detailed source citation data click the associated Bibliography of the Neuropterida icon in the Links column"}/>
                                    <EasyTableElement width={"9%"} title={"Page/Plate"}
                                                      elementFromValue={(val: tblLdlSpCatAnnotCite) => val.PagePlate}
                                                      desc={"the designation for the page or plate on which the citation appears (or starts); clarification and disambiguation information are given in square brackets []"}/>
                                    <EasyTableElement minWidth={"75px"} width={"9%"} title={"Links"} elementFromValue={(val: tblLdlSpCatAnnotCite) => <>
                                        <IconLink newWindow={true} href={"/Biblio/Record"} IconID={LDLIcons.BotN_BibliographicRecord}
                                                  newStates={{"BibObjID": val.LinkBotN}}/>
                                        {(val.EDoc && val.EDoc.EDocLink && (val.EDoc.PublicEDoc == -1 || (val.EDoc.PrivateEDoc == -1 && PDFAccess))) ? <>
                                            <IconLink newWindow={true} href={val.EDoc.EDocLink}
                                                      IconID={LDLIcons.General_OpenFile_Work}/>
                                            <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                      newStates={{"BibObjID": val.LinkBotN}}
                                                      IconID={LDLIcons.Viewer_OpenFile}/>
                                        </> : <>&nbsp;&ndash;&nbsp;&nbsp;&ndash;&nbsp;</>}
                                        {(val.EDocPage && val.EDocPage.EDocPageLink && (val.EDocPage.PublicEDocPage == -1 || (val.EDocPage.PrivateEDocPage == -1 && PDFAccess))) ? <>
                                            <IconLink newWindow={true} href={val.EDocPage.EDocPageLink}
                                                      IconID={LDLIcons.General_Openfile_Page}/>
                                            <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                      newStates={{"BibObjID": val.LinkBotN, "EDocPageID": val.EDocPage?.EDocPageID?val.EDocPage.EDocPageID:0}}
                                                      IconID={LDLIcons.Viewer_OpenPage}/>
                                        </> : <>&nbsp;&ndash;&nbsp;&nbsp;&ndash;&nbsp;</>}
                                    </>}
                                                      desc={"one or more icon-based links to bibliographic citation data and/or digital files; mouse-over any icon to display a related tooltip"}/>
                                    <EasyTableElement width={"25%"} title={"Notes"} elementFromValue={(val: tblLdlSpCatAnnotCite) => val.Notes}
                                                      desc={"notes relevant to the citation record, if any"}/>
                                </EasyTable>
                            </>}</>
                    }} optionRender={(val: any, index: number, selectIndex: number)=>val}></SelectFromArrays>
                </>}

            </Record>}
    </>
};


export default SpeciesTaxonRecord;