import React from "react";
import {Link, useLocation, useOutletContext} from "react-router-dom";
import {MainTEXT} from "./Shared/MainTEXT";
import nameTranslator from "../../utils/nameTranslator";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json"
import FaunaCountry from "../../utils/OnServerStartLoadedJsons/FaunaCountry.json"
import {tblLdlFaunaPoliticalUnits} from "../../../../database/models/tblLdlFaunaPoliticalUnits";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";
import {layoutContextType} from "../Shared/_Layout";


const Faunas_MainTemplate = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const location = useLocation();
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);
    let myMainPath = MainTEXT[paths[0]] ? MainTEXT[paths[0]] : MainTEXT.default;

    // @ts-ignore
    const myModule:tblLdlModule=LDLallowedModules[paths[0]]
    // @ts-ignore
    const myPoliticalUnit:tblLdlFaunaPoliticalUnits|undefined = FaunaCountry[myModule.GeogID];
const MAINPAGEHEADER=`Neuropterida of ${
        // @ts-ignore
        myPoliticalUnit?(`${myPoliticalUnit?.DefiniteArticle} ${myPoliticalUnit.PoliticalUnitName}`):(nameTranslator["/" + paths[0]]?.TabName ? `${currentPathData[paths[0]].UpperLeftModuleName}` :`${paths[0]}`)}`
    if(!MainTEXT[paths[0]]){
        if(myMainPath.Cite.props.children){
            myMainPath.Cite=<>{myMainPath.Cite.props.children.map((val: string|JSX.Element)=>typeof val=='string'?val.replaceAll("%MAINPAGEHEADER%",MAINPAGEHEADER).replaceAll("%MODULENAME%",paths[0]):val)}</>
        }
    }
    console.log(myPoliticalUnit)
    return <div className="content">
        <div className="text-body">
            <h1>Neuropterida of {
                // @ts-ignore
                myPoliticalUnit?(<>{myPoliticalUnit?.DefiniteArticle} {myPoliticalUnit.PoliticalUnitName}</>):(nameTranslator["/" + paths[0]]?.TabName ? (currentPathData[paths[0]].UpperLeftModuleName) :<>{paths[0]}</>)}</h1>
            <p className="version" style={{textAlign: "center"}}>
                <br/>
            </p>
            <table align="center" className="nametable">
                <tbody>
                {myMainPath.names.reverse().map((name, index) => {
                    const subsection = myMainPath.names.slice(index, index + 2);
                    if (index % 2 == 0) {
                        return <tr>{subsection.reverse().map((name, index) => {
                            return <td colSpan={subsection.length == 2 ? 1 : 2} align={"center"}
                                       style={{paddingTop: "12px", paddingRight: "12px", paddingLeft: "12px"}}>{name.map((val) => <>{val}<br/></>)}</td>
                        })}</tr>
                    }
                }).reverse()}
                </tbody>
            </table>

            <p style={{textAlign: "center"}}>
                <Link style={{width: "100px", color: "white",}} to="../Introduction" className="btn btn-info">
                    Introduction
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link style={{width: "100px", color: "white",}} to="../DefineFauna" className="btn btn-info">
                    Define Fauna
                </Link>
            </p>

            <p style={{textAlign: "left"}}>
                <i><u>Abstract</u></i>: {myMainPath.abstract}
                <br/>
                <br/>
                <i><u>Cite this Publication as</u></i>: {myMainPath.Cite}
                <br/>
                <br/>
                <small>(Note: The different modules of the Lacewing Digital Library constitute distinct publications and
                    should be cited separately. For information on the current version and release of this publication
                    see the <a href="./Versions">Versions</a> page.)</small>
                <br/>
                <br/>
                <i><u>Author Contact Information</u></i>: {myMainPath.Author}
            </p>

            <p className="author-reserve-markup">
                © {myMainPath.author},&nbsp;&nbsp;{new Date().getFullYear()}<br/>
                All rights reserved.
            </p>
        </div>
    </div>
};
export default Faunas_MainTemplate;