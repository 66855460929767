import React from "react";
import Icons from "../../utils/OnServerStartLoadedJsons/Icons.json"
import {IconLink} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";

const Publications = () => {
    return (<>

        <div className="text-body">
            <h1>Publications</h1>
            <p className="header-paragraph-1">
                The original works published to date in the <i>Lacewing Digital Library, Research
                Publication</i> series&mdash;the monographic series of the <i>Lacewing Digital Library</i>&mdash;are
                listed below in the order in which they were first published.
            </p>
            <hr/>
            <br/>
            <ol>
                <li>
                    <a href="/SpeciesCatalog/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[4].IconLink} className="standard-icon"
                                   title={Icons[4].DefaultTooltip}/>&nbsp;
                    </a>&nbsp;Oswald, J. D. Neuropterida Species of the World. <i>Lacewing Digital Library, Research
                    Publication</i> No. 1. [first LDL version: 2007.10.31]

                </li>

                <li>
                    <a href="/Biblio/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[2].IconLink} className="standard-icon" title={Icons[2].DefaultTooltip}/>
                    </a> &nbsp;Oswald, J. D. Bibliography of the Neuropterida. <i>Lacewing Digital Library, Research
                    Publication</i> No. 2. [first LDL version: 2009.04.07]

                </li>

                <li>
                    <a href="/Italy/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[17].IconLink} className="standard-icon" title={Icons[17].IconLink}/>
                    </a>  &nbsp;Letardi, A.; Badano, D. Neuropterida of Italy. <i>Lacewing Digital Library, Research
                    Publication</i> No. 3. [first LDL version: 2017.01.31]

                </li>

                <li>
                    <a href="/Slovenia/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[19].IconLink} className="standard-icon" title={Icons[19].IconLink}/>
                    </a>    &nbsp;Devetak, D. Neuropterida of Slovenia. <i>Lacewing Digital Library, Research
                    Publication</i> No. 4. [first LDL version: 2017.01.31]

                </li>

                <li>
                    <a href="/SpeciesCatalog/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[8].IconLink} className="standard-icon" title={Icons[8].IconLink}/>
                    </a>   &nbsp;Oswald, J. D. World Neuropterida Metakey. <i>Lacewing Digital Library, Research
                    Publication</i> No. 5. [first LDL version: 2017.02.21]

                </li>

                <li>
                    <a href="/Ireland/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[15].IconLink} className="standard-icon" title={Icons[15].IconLink}/>
                    </a>  &nbsp;Jepson, J. E. Neuropterida of Ireland. <i>Lacewing Digital Library, Research
                    Publication</i> No. 6. [first LDL version: 2018.09.07]

                </li>

                <li>
                    <a href="/SouthAfrica/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[21].IconLink} className="standard-icon" title={Icons[21].IconLink}/>
                    </a>  &nbsp;Mansell, M. W.; Oswald, J. D. Neuropterida of South Africa. <i>Lacewing Digital Library,
                    Research Publication</i> No. 7. [first LDL version: 2018.09.20]

                </li>

                <li>
                    <a href="/Albania/Main" target="_top" style={{textDecoration: "none"}}>
                        &nbsp;<img src={Icons[13].IconLink} className="standard-icon" title={Icons[13].IconLink}/>
                    </a>  &nbsp;Devetak, D. Neuropterida of Albania. <i>Lacewing Digital Library, Research
                    Publication</i> No. 8. [first LDL version: 2019.07.11]

                </li>

                <li>
                    &nbsp;<IconLink href={"/Switzerland/Main"} IconID={LDLIcons.WNF_Switzerland_Main}/>  &nbsp;Duelli, P.; Monnerat, C.; Koch, B. Neuropterida of Switzerland. <i>Lacewing Digital
                    Library, Research Publication</i> No. 9. [first LDL version: 2024.08.26]

                </li>

            </ol>

        </div>

       </>)
};
export default Publications;