import React from "react";
import {useLocation} from "react-router-dom";
import {Reference} from "../Shared/Reference";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";

const Faunas_References = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);

    // @ts-ignore
    const module:tblLdlModule =LDLallowedModules[paths[0]]


    return <Reference ModuleID={module.ID}/>
};
export default Faunas_References;