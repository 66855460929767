import {LDLIcons} from "./OnServerStartLoadedOther/IconsHelp";

const nameTranslator:{[key:string]:{"DisplayName":string,"TabName":string,"TabDisplay":string,"ShortName":string,"IconContentRef":number,"MainTab":string,"UnSeenParent"?:string,"SubLinks"?:{[key:string]:{"TabName":string,"TabDisplay":string,"IconContentRef"?:number,"ShortName"?:string}|string}}} = {
  "/HomePage":{
    "DisplayName":"Lacewing Digital Library ($/HomePage$)",
    "TabName":"Lacewing Digital Library",
    "TabDisplay":"%/HomePage% $/HomePage$",
    "ShortName":"LDL",
    "IconContentRef": LDLIcons.LDL_Main,
    "MainTab": "/MainContent",
    "SubLinks": {
      "/MainContent": "#INHERIT#",
      "/Team": {
        "TabName": "Project Team",
        "TabDisplay": "&/Team&"
      }
    }
    },
  "/Biblio":{
    "DisplayName":"Bibliography of the Neuropterida ($/Biblio$)",
    "TabName":"Bibliography",
    "TabDisplay":"%/Biblio% &/Biblio&",
    "ShortName":"BotN – %/Biblio%",
    "IconContentRef": LDLIcons.BotN_Main,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/SimpleSearch": {
        "TabName":"Simple Search",
        "TabDisplay":"&/SimpleSearch&"
      },
      "/AdvancedSearch": {
        "TabName":"Advanced Search",
        "TabDisplay":"&/AdvancedSearch&"
      },
      "/SearchResults": {
        "TabName": "Search Results",
        "TabDisplay":"&/SearchResults&"
      },
      "/Record": {
        "TabName":"Bibliographic Record",
        "TabDisplay":"%/Record% &/Record&",
        "IconContentRef": LDLIcons.BotN_BibliographicRecord
      },
      "/Taxa": {
        "TabName":"Taxon Citations",
        "TabDisplay":"%/Taxa% &/Taxa&",
        "IconContentRef": LDLIcons.BotN_TaxonCitations
      },
      "/Figure": {
        "TabName":"Figure Citations",
        "TabDisplay":"%/Figure% &/Figure&",
        "IconContentRef": LDLIcons.BotN_FigureCitations
      },
      "/VersionNotes": {
        "TabName":"Version Notes",
        "TabDisplay":"&/VersionNotes&",
      }
    }
  },
  "/Serials": {
    "DisplayName": "Serial",
    "TabName": "Serials",
    "TabDisplay": "&/Serials&",
    "ShortName": "",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main"
  },
  "/SpeciesCatalog":{
    "DisplayName":"Neuropterida Species of the World ($/SpeciesCatalog$)",
    "TabName":"Species",
    "TabDisplay":"%/SpeciesCatalog% &/SpeciesCatalog&",
    "ShortName":"NSW – %/SpeciesCatalog%",
    "IconContentRef": LDLIcons.NSW_Main,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/SimpleSearch": {
        "TabName":"Simple Search",
        "TabDisplay":"&/SimpleSearch&"
      },
      "/AdvancedSearch": {
        "TabName":"Advanced Search",
        "TabDisplay":"&/AdvancedSearch&"
      },
      "/SearchResults": {
        "TabName": "Search Results",
        "TabDisplay":"&/SearchResults&"
      },
      "/Record": {
        "TabName":"Epithet Record",
        "TabDisplay":"%/Record% &/Record&",
        "IconContentRef": LDLIcons.NSW_EpithetRecord
      },
      "/MonographRecord":{
        "TabName":"Monograph Record",
        "TabDisplay":"%/MonographRecord% &/MonographRecord&",
        "IconContentRef": LDLIcons.NSW_MonographicRecord
      },
      "/Keys": {
        "TabName": "Identification Keys",
        "TabDisplay":"%/Keys% &/Keys&",
        "IconContentRef": LDLIcons.NSW_IdentificationKeys
      },
      "/Taxa": {
        "TabName":"Taxon Citations",
        "TabDisplay":"%/Taxa% &/Taxa&",
        "IconContentRef": LDLIcons.NSW_TaxonCitations
      },
      "/Figure": {
        "TabName":"Figure Citations",
        "TabDisplay":"%/Figure% &/Figure&",
        "IconContentRef": LDLIcons.NSW_FigureCitations
      },
      "/PhylogeneticTree": {
        "TabName":"Phylogenetic Trees",
        "TabDisplay": "%/PhylogeneticTree% &/PhylogeneticTree&",
        "IconContentRef": LDLIcons.NSW_PhylogeneticTrees
      }
    }
  },
  "/GenusCatalog":{
    "DisplayName":"Neuropterida Genera of the World ($/GenusCatalog$)",
    "TabName":"Genera",
    "TabDisplay":"&/GenusCatalog&",
    "ShortName":"NGW",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/SimpleSearch": {
        "TabName":"Simple Search",
        "TabDisplay":"&/SimpleSearch&"
      },
      "/AdvancedSearch": {
        "TabName":"Advanced Search",
        "TabDisplay":"&/AdvancedSearch&"
      },
      "/SearchResults": {
        "TabName": "Search Results",
        "TabDisplay":"&/SearchResults&"
      },
      "/Record": {
        "TabName":"Record",
        "TabDisplay":"&/Record&"
      }
    }
  },
  "/Faunas":{
    "DisplayName":"World Neuropterida Fanuas ($/Faunas$)",
    "TabName":"Faunas",
    "TabDisplay":"&/Faunas&",
    "ShortName":"WNF",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main":{
        "TabName":"About",
        "TabDisplay":"&/Main&"
      }
    }
    },
  "/World": {
    "DisplayName":"World Neuropterida by Country ($/World$)",
    "TabName":"World",
    "TabDisplay":"%/World% &/World&",
    "ShortName":"NidaWorld – %/World%",
    "IconContentRef": LDLIcons.WNF_World_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_World_FaunalList
      }
    }
  },
  "/Albania": {
    "DisplayName":"Neuropterida of Albania ($/Albania$)",
    "TabName":"Albania",
    "TabDisplay":"%/Albania% &/Albania&",
    "ShortName":"NidaAL – %/Albania%",
    "IconContentRef": LDLIcons.WNF_Albania_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Albania_FaunalList
      }
    }
  },
  "/Brazil": {
    "DisplayName":"Neuropterida of Brazil ($/Brazil$)",
    "TabName":"Brazil",
    "TabDisplay":"%/Brazil% &/Brazil&",
    "ShortName":"NidaBR – %/Brazil%",
    "IconContentRef": LDLIcons.WNF_Brazil_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Brazil_FaunalList
      }
    }
  },
  "/China": {
    "DisplayName":"Neuropterida of China ($/China$)",
    "TabName":"China",
    "TabDisplay":"%/China% &/China&",
    "ShortName":"NidaCN – %/China%",
    "IconContentRef": LDLIcons.WNF_China_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_China_FaunalList
      }
    }
  },
  "/France": {
    "DisplayName":"Neuropterida of France ($/France$)",
    "TabName":"France",
    "TabDisplay":"%/France% &/France&",
    "ShortName":"NidaFR – %/France%",
    "IconContentRef": LDLIcons.WNF_France_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_France_FaunalList
      }
    }
  },
  "/Honduras": {
    "DisplayName":"Neuropterida of Honduras ($/Honduras$)",
    "TabName":"Honduras",
    "TabDisplay":"%/Honduras% &/Honduras&",
    "ShortName":"NidaHN – %/Honduras%",
    "IconContentRef": LDLIcons.WNF_Honduras_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Honduras_FaunalList
      }
    }
  },
  "/Ireland": {
    "DisplayName":"Neuropterida of Ireland ($/Ireland$)",
    "TabName":"Ireland",
    "TabDisplay":"%/Ireland% &/Ireland&",
    "ShortName":"NidaIE – %/Ireland%",
    "IconContentRef": LDLIcons.WNF_Ireland_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Ireland_FaunalList
      }
    }
  },
  "/Italy": {
    "DisplayName":"Neuropterida of Italy ($/Italy$)",
    "TabName":"Italy",
    "TabDisplay":"%/Italy% &/Italy&",
    "ShortName":"NidaIT – %/Italy%",
    "IconContentRef": LDLIcons.WNF_Italy_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Italy_FaunalList
      }
    }
  },
  "/NorthMacedonia": {
    "DisplayName":"Neuropterida of North Macedonia ($/NorthMacedonia$)",
    "TabName":"North Macedonia",
    "TabDisplay":"%/NorthMacedonia% &/NorthMacedonia&",
    "ShortName":"NidaMK – %/NorthMacedonia%",
    "IconContentRef": LDLIcons.WNF_NorthMacedonia_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_NorthMacedonia_FaunalList
      }
    }
  },
  "/Mexico": {
    "DisplayName":"Neuropterida of Mexico ($/Mexico$)",
    "TabName":"Mexico",
    "TabDisplay":"%/Mexico% &/Mexico&",
    "ShortName":"NidaMX – %/Mexico%",
    "IconContentRef": LDLIcons.WNF_Mexico_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Mexico_FaunalList
      }
    }
  },
  "/Serbia": {
    "DisplayName":"Neuropterida of Serbia ($/Serbia$)",
    "TabName":"Serbia",
    "TabDisplay":"%/Serbia% &/Serbia&",
    "ShortName":"NidaRS – %/Serbia%",
    "IconContentRef": LDLIcons.WNF_Serbia_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Serbia_FaunalList
      }
    }
  },
  "/Slovenia": {
    "DisplayName":"Neuropterida of Slovenia ($/Slovenia$)",
    "TabName":"Slovenia",
    "TabDisplay":"%/Slovenia% &/Slovenia&",
    "ShortName":"NidaSI – %/Slovenia%",
    "IconContentRef": LDLIcons.WNF_Slovenia_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Slovenia_FaunalList
      }
    }
  },
  "/SouthAfrica": {
    "DisplayName":"Neuropterida of South Africa ($/SouthAfrica$)",
    "TabName":"South Africa",
    "TabDisplay":"%/SouthAfrica% &/SouthAfrica&",
    "ShortName":"NidaZA – %/SouthAfrica%",
    "IconContentRef": LDLIcons.WNF_SouthAfrica_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_SouthAfrica_FaunalList
      }
    }
  },
  "/Switzerland": {
    "DisplayName":"Neuropterida of Switzerland ($/Switzerland$)",
    "TabName":"Switzerland",
    "TabDisplay":"%/Switzerland% &/Switzerland&",
    "ShortName":"NidaCH – %/Switzerland%",
    "IconContentRef": LDLIcons.WNF_Switzerland_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Switzerland_FaunalList
      }
    }
  },
  "/UnitedStates": {
    "DisplayName":"Neuropterida of the United States ($/UnitedStates$)",
    "TabName":"United States",
    "TabDisplay":"%/UnitedStates% &/UnitedStates&",
    "ShortName":"NidaUS – %/UnitedStates%",
    "IconContentRef": LDLIcons.WNF_UnitedStates_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_UnitedStates_FaunalList
      }
    }
  },
  "/Colombia": {
    "DisplayName":"Neuropterida of Colombia ($/Colombia$)",
    "TabName":"Colombia",
    "TabDisplay":"%/Colombia% &/Colombia&",
    "ShortName":"NidaCO – %/Colombia%",
    "IconContentRef": LDLIcons.WNF_Colombia_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Colombia_FaunalList
      }
    }
  },
  "/Germany": {
    "DisplayName":"Neuropterida of Germany ($/Germany$)",
    "TabName":"Germany",
    "TabDisplay":"%/Germany% &/Germany&",
    "ShortName":"NidaDE – %/Germany%",
    "IconContentRef": LDLIcons.WNF_Germany_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Germany_FaunalList
      }
    }
  },
  "/Namibia": {
    "DisplayName":"Neuropterida of Namibia ($/Namibia$)",
    "TabName":"Namibia",
    "TabDisplay":"%/Namibia% &/Namibia&",
    "ShortName":"NidaNA – %/Namibia%",
    "IconContentRef": LDLIcons.WNF_Namibia_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Namibia_FaunalList
      }
    }
  },
  "/Poland": {
    "DisplayName":"Neuropterida of Poland ($/Poland$)",
    "TabName":"Poland",
    "TabDisplay":"%/Poland% &/Poland&",
    "ShortName":"NidaPL – %/Poland%",
    "IconContentRef": LDLIcons.WNF_Poland_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_Poland_FaunalList
      }
    }
  },
  "/UnitedKingdom": {
    "DisplayName":"Neuropterida of the United Kingdom ($/UnitedKingdom$)",
    "TabName":"the United Kingdom",
    "TabDisplay":"%/UnitedKingdom% &/UnitedKingdom&",
    "ShortName":"NidaUK – %/UnitedKingdom%",
    "IconContentRef": LDLIcons.WNF_UnitedKingdom_Main,
    "UnSeenParent": "/Faunas",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/DefineFauna": {
        "TabName":"Define Fauna",
        "TabDisplay":"&/DefineFauna&"
      },
      "/Results": {
        "TabName": "Faunal List",
        "TabDisplay":"%/Results% &/Results&",
        "IconContentRef": LDLIcons.WNF_UnitedKingdom_FaunalList
      }
    }
  },
  "/NeuroDirectory": {
    "DisplayName":"Neuropterists Directory ($/NeuroDirectory$)",
    "TabName":"Directory",
    "TabDisplay":"&/NeuroDirectory&",
    "ShortName":"NeurDir",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/Search": {
        "TabName":"Search",
        "TabDisplay":"&/Search&"
      },
      "/AddUpdate": {
        "TabName":"Add/Update",
        "TabDisplay":"&/AddUpdate&"
      },
      "/SearchResults": {
        "TabName": "Search Results",
        "TabDisplay":"&/SearchResults&"
      },
      "/Record": {
        "TabName":"Person",
        "TabDisplay":"&/Record&"
      }
    }
  },
  "/Interactions": {
    "DisplayName":"Neuropterida Interactions ($/Interactions$)",
    "TabName":"Interactions",
    "TabDisplay":"%/Interactions% &/Interactions&",
    "ShortName":"NI – %/Interactions%",
    "IconContentRef": LDLIcons.NI_Main,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#"
    }
  },
  "/Metakey": {
    "DisplayName":"World Neuropterida Metakey ($/Metakey$)",
    "TabName":"Metakey",
    "TabDisplay":"%/Metakey% &/Metakey&",
    "ShortName":"WNM – %/Metakey%",
    "IconContentRef": LDLIcons.WNM_Main,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/ScopeSearch": {
        "TabName":"Scope Search",
        "TabDisplay":"&/ScopeSearch&"
      },
      "/TerminalTaxonSearch": {
        "TabName":"Terminal Taxon Search",
        "TabDisplay":"&/TerminalTaxonSearch&"
      },
      "/SearchResults": {
        "TabName": "Key List",
        "TabDisplay":"%/SearchResults% &/SearchResults&",
        "IconContentRef": LDLIcons.WNM_KeyList
      },
      "/Record": {
        "TabName":"Key Record",
        "TabDisplay":"%/Record% &/Record&",
        "IconContentRef": LDLIcons.WNM_KeyRecord
      }
    }
  },
  "/KeysDichot":{
    "DisplayName":"World Neuropterida Fanuas ($/KeysDichot$)",
    "TabName":"Faunas",
    "TabDisplay":"&/KeysDichot&",
    "ShortName":"WNF",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main":{
        "TabName":"About",
        "TabDisplay":"&/Main&"
      }
    }
  },
  "/Keys": {
    "DisplayName":"Lacewing Digital Library",
    "TabName":"Digital Keys",
    "TabDisplay":"&/Keys&",
    "ShortName":"",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/Psychopsidae": {
        "TabName":"Psychopsidae Genera",
        "TabDisplay":"&/Psychopsidae&"
      },
      "/Psychopsidaekey": {
        "TabName":"Key Lacewing Digital Library",
        "TabDisplay":"&/Psychopsidaekey&"
      },
      "/Sialidae": {
        "TabName":"Sialidae Genera",
        "TabDisplay":"&/Sialidae&"
      },
      "/Sialidaekey": {
        "TabName":"Key Lacewing Digital Library",
        "TabDisplay":"&/Sialidaekey&"
      }
    }
  },
  "/Downloads": {
    "DisplayName": "",
    "TabName": "Downloads",
    "TabDisplay": "&/Downloads&",
    "ShortName": "",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#"
    }
  },
  "/Classification":{
    "DisplayName":"Classification of World Neuropterida ($/Classification$)",
    "TabName":"Classification",
    "TabDisplay":"%/Classification% &/Classification&",
    "ShortName":"NSW – %/Classification%",
    "IconContentRef": LDLIcons.NSW_Main,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
      "/SimpleSearch": {
        "TabName":"Simple Search",
        "TabDisplay":"&/SimpleSearch&"
      },
      "/AdvancedSearch": {
        "TabName":"Advanced Search",
        "TabDisplay":"&/AdvancedSearch&"
      },
      "/SearchResults": {
        "TabName": "Search Results",
        "TabDisplay":"&/SearchResults&"
      },
      "/Record": {
        "TabName":"Record",
        "TabDisplay":"&/Record&"
      }
    }
  },
  "/PageViewer":{
    "DisplayName":"Viewer (%/PageViewer%)",
    "TabName":"Viewer",
    "TabDisplay":"%/PageViewer% &/PageViewer&",
    "ShortName":"NSW – %/PageViewer%",
    "IconContentRef": LDLIcons.Viewer_OpenFile,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Pages",
  },"/Testing": {
    "DisplayName": "Testing",
    "TabName": "Testing",
    "TabDisplay": "Testing",
    "ShortName": "",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/Main",
    "SubLinks": {
      "/Main": "#INHERIT#",
    }
  },"/Authenticate": {
    "DisplayName": "Lacewing Digital Library (Login)",
    "TabName": "LDL - Authenticate",
    "TabDisplay": "Authenticate",
    "ShortName": "",
    "IconContentRef": LDLIcons.General_DefaultIcon,
    "UnSeenParent": "/HomePage",
    "MainTab": "/LoggedIn",
    "SubLinks": {
      "/LoggedIn": "#INHERIT#",
      "/LoginSuccess": "#INHERIT#",
    }
  }
}
export default nameTranslator;