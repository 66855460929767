import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHeader,
    ListHolder,
    Record
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlMetakey} from "../../../../database/models/tblLdlMetakey";
import {tblLdlMetakeyTerminals} from "../../../../database/models/tblLdlMetakeyTerminals";


const MetaKeyRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlMetakey>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getMetaKeyByPk", {KeyID: _props["KeyID"]}, (error: boolean, outData: tblLdlMetakey | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
            }
        })
    }, []);


    function getTerminalTaxaProccess(MetaKeyTerminals: tblLdlMetakeyTerminals[]) {
        let out: { Current: tblLdlMetakeyTerminals, Scientific: tblLdlMetakeyTerminals }[] = [];
        let i = 0;
        while (i + 1 < MetaKeyTerminals.length) {
            out.push({Current: MetaKeyTerminals[i + 1], Scientific: MetaKeyTerminals[i]});
            i += 2;
        }
        out = out.sort((val1, val2) => (val1.Scientific.Comb0 && val2.Scientific.Comb0) ? (val1.Scientific.Comb0.localeCompare(val2.Scientific.Comb0)) : ((val1.Scientific.Comb0) ? (1) : ((val2.Scientific.Comb0) ? (-1) : (0))));
        return out;
    }

    return <>
        {resultData &&
            <Record title={"Key Record"}>
                <ListHeader title={"Basic Key Data"}>
                    <ListElement title={"Key #"} element={resultData.KeyID}/>
                    <ListElement title={"Key Record Links"}
                                 element={<>
                                     <IconLink newWindow={true} href={"/Biblio/Record"} IconID={LDLIcons.BotN_BibliographicRecord}
                                               newStates={{"BibObjID": resultData.BibObjID}}/>
                                     {(resultData.EDoc && resultData.EDoc.EDocLink && (resultData.EDoc.PublicEDoc || (resultData.EDoc.PrivateEDoc && PDFAccess))) &&
                                         <><IconLink newWindow={true} href={resultData.EDoc.EDocLink}
                                                     IconID={LDLIcons.General_OpenFile_Work}
                                                     /> <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                                                IconID={LDLIcons.Viewer_OpenKey}
                                                                                newStates={{
                                                                                    "BibObjID": resultData.BibObjID,
                                                                                    "EDocPageID": resultData.KeyEDocPageID
                                                                                }}/></>}
                                 </>}/>
                    <ListElement title={"Short Citation"} element={resultData.AuthYear}/>
                    <ListElement title={"Long Citation"} element={resultData.Reference}/>
                </ListHeader>
                <ListHeader title={"Key Features"}>
                    <ListElement title={"Taxonomic Scope"} element={<>
                        <i>{resultData.TaxScope1 != "$" && resultData.TaxScope1}</i> {resultData.TaxScope3}</>}/>
                    <ListElement title={"Geographic Scope"} element={resultData.GeographicScope}/>
                    <ListElement title={"Terminals"} element={resultData.TerminalsLong}/>
                    <ListElement title={"Life Stage(s)"} element={resultData.LifeStageLong}/>
                    <ListElement title={"Sex(es)"} element={resultData.Sex}/>
                    <ListElement title={"Language"} element={resultData.Language}/>
                    <ListElement title={"Format"} element={resultData.Format}/>
                    <ListElement title={"Couplet(s)"} element={resultData.CoupletNum}/>
                    {(resultData.KeyNotes != "$") && <ListElement title={"Notes"} element={resultData.KeyNotes}/>}
                </ListHeader>

                <ListHolder>
                    <i>Terminal Taxa
                        ({(resultData.MetaKeyTerminals?.length ? resultData.MetaKeyTerminals.length : 0) / 2}).</i>
                </ListHolder>
                {(resultData.MetaKeyTerminals) &&
                    <EasyTable tableRowData={getTerminalTaxaProccess(resultData.MetaKeyTerminals)}>
                        <EasyTableElement width={"48%"} align={"right"} title={"Cited Name"}
                                          elementFromValue={(val:{Current: tblLdlMetakeyTerminals, Scientific: tblLdlMetakeyTerminals}) => <>
                                              <i>{val.Scientific.Comb1 != "$" && val.Scientific.Comb1}</i> {val.Scientific.Comb2}</>}
                                          desc={"the scientific name used in the key, corrected for strictly nomenclatural errors, but not adjusted for current taxonomy or current hierarchical taxon rank"}/>
                        <EasyTableElement align={"center"} width={"2%"}
                                          elementFromValue={(val:{Current: tblLdlMetakeyTerminals, Scientific: tblLdlMetakeyTerminals}) => <>{val.Scientific.NowText}</>}/>
                        <EasyTableElement width={"48%"} title={"Current Name (LDL standard taxonomy)"}
                                          elementFromValue={(val:{Current: tblLdlMetakeyTerminals, Scientific: tblLdlMetakeyTerminals}, index) => {
                                              return <>{(!!val.Current.LdlPresCombObjID && !!(val.Current.SpCatComb)) &&
                                                  <IconLink newWindow={true} href={"/SpeciesCatalog/MonographRecord"}
                                                            newStates={{"CombObjID": val.Current.LdlPresCombObjID}}
                                                            IconID={LDLIcons.NSW_MonographicRecord}/>}
                                                  <i> {val.Current.Comb1 != "$" && val.Current.Comb1}</i> {val.Current.Comb2}</>
                                          }}
                                          desc={"the current name of the terminal taxon (using the Lacewing Digital Library standard taxonomy)"}/>
                    </EasyTable>}


            </Record>}
    </>
};


export default MetaKeyRecord;