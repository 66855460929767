export enum LDLIcons{
	LDL_Main=1, 
	BotN_Main=2, 
	BotN_BibliographicRecord=3, 
	NSW_Main=4, 
	NSW_MonographicRecord=5, 
	NI_Main=6, 
	NI_InteractionRecord=7, 
	WNM_Main=8, 
	WNM_KeyList=9, 
	WNM_KeyRecord=10, 
	Viewer_OpenKey=11, 
	Viewer_OpenFile=12, 
	WNF_Albania_Main=13, 
	WNF_Albania_FaunalList=14, 
	WNF_Ireland_Main=15, 
	WNF_Ireland_FaunalList=16, 
	WNF_Italy_Main=17, 
	WNF_Italy_FaunalList=18, 
	WNF_Slovenia_Main=19, 
	WNF_Slovenia_FaunalList=20, 
	WNF_SouthAfrica_Main=21, 
	WNF_SouthAfrica_FaunalList=22, 
	WNF_China_Main=23, 
	WNF_China_FaunalList=24, 
	WNF_France_Main=25, 
	WNF_France_FaunalList=26, 
	WNF_Namibia_Main=27, 
	WNF_Namibia_FaunalList=28, 
	WNF_Serbia_Main=29, 
	WNF_Serbia_FaunalList=30, 
	WNF_NorthMacedonia_Main=31, 
	WNF_NorthMacedonia_FaunalList=32, 
	General_GoToPage=33, 
	Viewer_FirstPage=34, 
	Viewer_PreviousPage=35, 
	Viewer_NextPage=36, 
	Viewer_LastPage=37, 
	General_OpenFile_Work=38, 
	General_Openfile_Page=39, 
	General_ShowLegend=40, 
	General_HideLegend=41, 
	General_Information=42, 
	WNF_Switzerland_Main=43, 
	WNF_Switzerland_FaunalList=44, 
	WNF_UnitedKingdom_Main=45, 
	WNF_UnitedKingdom_FaunalList=46, 
	WNF_UnitedStates_Main=47, 
	WNF_UnitedStates_FaunalList=48, 
	WNF_Colombia_Main=49, 
	WNF_Colombia_FaunalList=50, 
	WNF_Brazil_Main=51, 
	WNF_Brazil_FaunalList=52, 
	WNF_Germany_Main=53, 
	WNF_Germany_FaunalList=54, 
	WNF_Mexico_Main=55, 
	WNF_Mexico_FaunalList=56, 
	WNF_Poland_Main=57, 
	WNF_Poland_FaunalList=58, 
	General_Email=59, 
	General_Download_Excelfile=60, 
	LDL_Search=61, 
	General_Image=62, 
	WNF_Honduras_Main=64, 
	WNF_Honduras_FaunalList=65, 
	General_PrintOrSave=67, 
	NSW_EpithetRecord=68, 
	LDL_Home_NSF=69, 
	LDL_Home_TAMU=70, 
	LDL_Home_COALS=71, 
	LDL_Home_ENTO=72, 
	BotN_FigureCitations=73, 
	BotN_TaxonCitations=74, 
	NSW_SearchResults=75, 
	BotN_SearchResults=76, 
	NSW_FigureCitations=77, 
	NSW_TaxonCitations=78, 
	LDL_PopUpWindow=79, 
	NSW_PhylogeneticTrees=80, 
	General_DefaultIcon=81, 
	WNF_Andorra_Main=82, 
	WNF_Andorra_FaunalList=83, 
	WNF_UnitedArabEmirates_Main=84, 
	WNF_UnitedArabEmirates_FaunalList=85, 
	WNF_Afghanistan_Main=86, 
	WNF_Afghanistan_FaunalList=87, 
	WNF_AntiguaandBarbuda_Main=88, 
	WNF_AntiguaandBarbuda_FaunalList=89, 
	WNF_Armenia_Main=92, 
	WNF_Armenia_FaunalList=93, 
	WNF_Angola_Main=94, 
	WNF_Angola_FaunalList=95, 
	WNF_Argentina_Main=96, 
	WNF_Argentina_FaunalList=97, 
	WNF_Austria_Main=98, 
	WNF_Austria_FaunalList=99, 
	WNF_Australia_Main=100, 
	WNF_Australia_FaunalList=101, 
	WNF_Aruba_Main=102, 
	WNF_Aruba_FaunalList=103, 
	WNF_Azerbaijan_Main=104, 
	WNF_Azerbaijan_FaunalList=105, 
	WNF_BosniaandHerzegovina_Main=106, 
	WNF_BosniaandHerzegovina_FaunalList=107, 
	WNF_Barbados_Main=108, 
	WNF_Barbados_FaunalList=109, 
	WNF_Bangladesh_Main=110, 
	WNF_Bangladesh_FaunalList=111, 
	WNF_Belgium_Main=112, 
	WNF_Belgium_FaunalList=113, 
	WNF_BurkinaFaso_Main=114, 
	WNF_BurkinaFaso_FaunalList=115, 
	WNF_Bulgaria_Main=116, 
	WNF_Bulgaria_FaunalList=117, 
	WNF_Bahrain_Main=118, 
	WNF_Bahrain_FaunalList=119, 
	WNF_Burundi_Main=120, 
	WNF_Burundi_FaunalList=121, 
	WNF_Benin_Main=122, 
	WNF_Benin_FaunalList=123, 
	WNF_Bolivia_Main=124, 
	WNF_Bolivia_FaunalList=125, 
	WNF_Bahamas_Main=128, 
	WNF_Bahamas_FaunalList=129, 
	WNF_Bhutan_Main=130, 
	WNF_Bhutan_FaunalList=131, 
	WNF_Botswana_Main=132, 
	WNF_Botswana_FaunalList=133, 
	WNF_Belarus_Main=134, 
	WNF_Belarus_FaunalList=135, 
	WNF_Belize_Main=136, 
	WNF_Belize_FaunalList=137, 
	WNF_Canada_Main=138, 
	WNF_Canada_FaunalList=139, 
	WNF_DemocraticRepublicoftheCongo_Main=140, 
	WNF_DemocraticRepublicoftheCongo_FaunalList=141, 
	WNF_CentralAfricanRepublic_Main=142, 
	WNF_CentralAfricanRepublic_FaunalList=143, 
	WNF_RepublicoftheCongo_Main=144, 
	WNF_RepublicoftheCongo_FaunalList=145, 
	WNF_CôtedIvoire_Main=148, 
	WNF_CôtedIvoire_FaunalList=149, 
	WNF_Chile_Main=150, 
	WNF_Chile_FaunalList=151, 
	WNF_Cameroon_Main=152, 
	WNF_Cameroon_FaunalList=153, 
	WNF_CostaRica_Main=158, 
	WNF_CostaRica_FaunalList=159, 
	WNF_Cuba_Main=160, 
	WNF_Cuba_FaunalList=161, 
	WNF_CaboVerde_Main=162, 
	WNF_CaboVerde_FaunalList=163, 
	WNF_Curaçao_Main=164, 
	WNF_Curaçao_FaunalList=165, 
	WNF_Cyprus_Main=166, 
	WNF_Cyprus_FaunalList=167, 
	WNF_Czechia_Main=168, 
	WNF_Czechia_FaunalList=169, 
	WNF_Djibouti_Main=172, 
	WNF_Djibouti_FaunalList=173, 
	WNF_Denmark_Main=174, 
	WNF_Denmark_FaunalList=175, 
	WNF_Dominica_Main=176, 
	WNF_Dominica_FaunalList=177, 
	WNF_DominicanRepublic_Main=178, 
	WNF_DominicanRepublic_FaunalList=179, 
	WNF_Algeria_Main=180, 
	WNF_Algeria_FaunalList=181, 
	WNF_Ecuador_Main=182, 
	WNF_Ecuador_FaunalList=183, 
	WNF_Estonia_Main=184, 
	WNF_Estonia_FaunalList=185, 
	WNF_Egypt_Main=186, 
	WNF_Egypt_FaunalList=187, 
	WNF_WesternSahara_Main=188, 
	WNF_WesternSahara_FaunalList=189, 
	WNF_Eritrea_Main=190, 
	WNF_Eritrea_FaunalList=191, 
	WNF_Spain_Main=192, 
	WNF_Spain_FaunalList=193, 
	WNF_Ethiopia_Main=194, 
	WNF_Ethiopia_FaunalList=195, 
	WNF_Finland_Main=196, 
	WNF_Finland_FaunalList=197, 
	WNF_Fiji_Main=198, 
	WNF_Fiji_FaunalList=199, 
	WNF_FederatedStatesofMicronesia_Main=200, 
	WNF_FederatedStatesofMicronesia_FaunalList=201, 
	WNF_FaroeIslands_Main=202, 
	WNF_FaroeIslands_FaunalList=203, 
	WNF_Gabon_Main=206, 
	WNF_Gabon_FaunalList=207, 
	WNF_Grenada_Main=208, 
	WNF_Grenada_FaunalList=209, 
	WNF_Georgia_Main=210, 
	WNF_Georgia_FaunalList=211, 
	WNF_Ghana_Main=212, 
	WNF_Ghana_FaunalList=213, 
	WNF_Greenland_Main=214, 
	WNF_Greenland_FaunalList=215, 
	WNF_Gambia_Main=216, 
	WNF_Gambia_FaunalList=217, 
	WNF_Guinea_Main=218, 
	WNF_Guinea_FaunalList=219, 
	WNF_EquatorialGuinea_Main=220, 
	WNF_EquatorialGuinea_FaunalList=221, 
	WNF_Greece_Main=222, 
	WNF_Greece_FaunalList=223, 
	WNF_Guatemala_Main=224, 
	WNF_Guatemala_FaunalList=225, 
	WNF_GuineaBissau_Main=226, 
	WNF_GuineaBissau_FaunalList=227, 
	WNF_Guyana_Main=228, 
	WNF_Guyana_FaunalList=229, 
	WNF_Croatia_Main=232, 
	WNF_Croatia_FaunalList=233, 
	WNF_Haiti_Main=234, 
	WNF_Haiti_FaunalList=235, 
	WNF_Hungary_Main=236, 
	WNF_Hungary_FaunalList=237, 
	WNF_Indonesia_Main=238, 
	WNF_Indonesia_FaunalList=239, 
	WNF_Israel_Main=242, 
	WNF_Israel_FaunalList=243, 
	WNF_India_Main=244, 
	WNF_India_FaunalList=245, 
	WNF_Iraq_Main=246, 
	WNF_Iraq_FaunalList=247, 
	WNF_Iran_Main=248, 
	WNF_Iran_FaunalList=249, 
	WNF_Iceland_Main=250, 
	WNF_Iceland_FaunalList=251, 
	WNF_Jamaica_Main=254, 
	WNF_Jamaica_FaunalList=255, 
	WNF_Jordan_Main=256, 
	WNF_Jordan_FaunalList=257, 
	WNF_Japan_Main=258, 
	WNF_Japan_FaunalList=259, 
	WNF_Kenya_Main=260, 
	WNF_Kenya_FaunalList=261, 
	WNF_Kyrgyzstan_Main=262, 
	WNF_Kyrgyzstan_FaunalList=263, 
	WNF_Cambodia_Main=264, 
	WNF_Cambodia_FaunalList=265, 
	WNF_Kiribati_Main=266, 
	WNF_Kiribati_FaunalList=267, 
	WNF_Comoros_Main=268, 
	WNF_Comoros_FaunalList=269, 
	WNF_SaintKittsandNevis_Main=270, 
	WNF_SaintKittsandNevis_FaunalList=271, 
	WNF_NorthKorea_Main=272, 
	WNF_NorthKorea_FaunalList=273, 
	WNF_SouthKorea_Main=274, 
	WNF_SouthKorea_FaunalList=275, 
	WNF_Kuwait_Main=276, 
	WNF_Kuwait_FaunalList=277, 
	WNF_Kazakhstan_Main=278, 
	WNF_Kazakhstan_FaunalList=279, 
	WNF_Laos_Main=280, 
	WNF_Laos_FaunalList=281, 
	WNF_Lebanon_Main=282, 
	WNF_Lebanon_FaunalList=283, 
	WNF_SaintLucia_Main=284, 
	WNF_SaintLucia_FaunalList=285, 
	WNF_Liechtenstein_Main=286, 
	WNF_Liechtenstein_FaunalList=287, 
	WNF_SriLanka_Main=288, 
	WNF_SriLanka_FaunalList=289, 
	WNF_Liberia_Main=290, 
	WNF_Liberia_FaunalList=291, 
	WNF_Lesotho_Main=292, 
	WNF_Lesotho_FaunalList=293, 
	WNF_Lithuania_Main=294, 
	WNF_Lithuania_FaunalList=295, 
	WNF_Luxembourg_Main=296, 
	WNF_Luxembourg_FaunalList=297, 
	WNF_Latvia_Main=298, 
	WNF_Latvia_FaunalList=299, 
	WNF_Libya_Main=300, 
	WNF_Libya_FaunalList=301, 
	WNF_Morocco_Main=302, 
	WNF_Morocco_FaunalList=303, 
	WNF_Monaco_Main=304, 
	WNF_Monaco_FaunalList=305, 
	WNF_Moldova_Main=306, 
	WNF_Moldova_FaunalList=307, 
	WNF_Montenegro_Main=308, 
	WNF_Montenegro_FaunalList=309, 
	WNF_Madagascar_Main=310, 
	WNF_Madagascar_FaunalList=311, 
	WNF_MarshallIslands_Main=312, 
	WNF_MarshallIslands_FaunalList=313, 
	WNF_Mali_Main=316, 
	WNF_Mali_FaunalList=317, 
	WNF_Burma_Main=318, 
	WNF_Burma_FaunalList=319, 
	WNF_Mongolia_Main=320, 
	WNF_Mongolia_FaunalList=321, 
	WNF_Mauritania_Main=322, 
	WNF_Mauritania_FaunalList=323, 
	WNF_Malta_Main=324, 
	WNF_Malta_FaunalList=325, 
	WNF_Mauritius_Main=326, 
	WNF_Mauritius_FaunalList=327, 
	WNF_Maldives_Main=328, 
	WNF_Maldives_FaunalList=329, 
	WNF_Malawi_Main=330, 
	WNF_Malawi_FaunalList=331, 
	WNF_Malaysia_Main=334, 
	WNF_Malaysia_FaunalList=335, 
	WNF_Mozambique_Main=336, 
	WNF_Mozambique_FaunalList=337, 
	WNF_Niger_Main=340, 
	WNF_Niger_FaunalList=341, 
	WNF_Nigeria_Main=342, 
	WNF_Nigeria_FaunalList=343, 
	WNF_Nicaragua_Main=344, 
	WNF_Nicaragua_FaunalList=345, 
	WNF_Netherlands_Main=346, 
	WNF_Netherlands_FaunalList=347, 
	WNF_Norway_Main=348, 
	WNF_Norway_FaunalList=349, 
	WNF_Nepal_Main=350, 
	WNF_Nepal_FaunalList=351, 
	WNF_Nauru_Main=352, 
	WNF_Nauru_FaunalList=353, 
	WNF_Niue_Main=354, 
	WNF_Niue_FaunalList=355, 
	WNF_NewZealand_Main=356, 
	WNF_NewZealand_FaunalList=357, 
	WNF_Oman_Main=358, 
	WNF_Oman_FaunalList=359, 
	WNF_Panama_Main=360, 
	WNF_Panama_FaunalList=361, 
	WNF_Peru_Main=362, 
	WNF_Peru_FaunalList=363, 
	WNF_PapuaNewGuinea_Main=364, 
	WNF_PapuaNewGuinea_FaunalList=365, 
	WNF_Philippines_Main=366, 
	WNF_Philippines_FaunalList=367, 
	WNF_Pakistan_Main=368, 
	WNF_Pakistan_FaunalList=369, 
	WNF_Palestine_Main=372, 
	WNF_Palestine_FaunalList=373, 
	WNF_Portugal_Main=374, 
	WNF_Portugal_FaunalList=375, 
	WNF_Palau_Main=376, 
	WNF_Palau_FaunalList=377, 
	WNF_Paraguay_Main=378, 
	WNF_Paraguay_FaunalList=379, 
	WNF_Qatar_Main=380, 
	WNF_Qatar_FaunalList=381, 
	WNF_Romania_Main=382, 
	WNF_Romania_FaunalList=383, 
	WNF_Russia_Main=386, 
	WNF_Russia_FaunalList=387, 
	WNF_Rwanda_Main=388, 
	WNF_Rwanda_FaunalList=389, 
	WNF_SaudiArabia_Main=390, 
	WNF_SaudiArabia_FaunalList=391, 
	WNF_SolomonIslands_Main=392, 
	WNF_SolomonIslands_FaunalList=393, 
	WNF_Seychelles_Main=394, 
	WNF_Seychelles_FaunalList=395, 
	WNF_Sudan_Main=396, 
	WNF_Sudan_FaunalList=397, 
	WNF_Sweden_Main=398, 
	WNF_Sweden_FaunalList=399, 
	WNF_Singapore_Main=400, 
	WNF_Singapore_FaunalList=401, 
	WNF_Slovakia_Main=404, 
	WNF_Slovakia_FaunalList=405, 
	WNF_SierraLeone_Main=406, 
	WNF_SierraLeone_FaunalList=407, 
	WNF_SanMarino_Main=408, 
	WNF_SanMarino_FaunalList=409, 
	WNF_Senegal_Main=410, 
	WNF_Senegal_FaunalList=411, 
	WNF_Somalia_Main=412, 
	WNF_Somalia_FaunalList=413, 
	WNF_Suriname_Main=414, 
	WNF_Suriname_FaunalList=415, 
	WNF_SouthSudan_Main=416, 
	WNF_SouthSudan_FaunalList=417, 
	WNF_SãoTomeandPrincipe_Main=418, 
	WNF_SãoTomeandPrincipe_FaunalList=419, 
	WNF_ElSalvador_Main=420, 
	WNF_ElSalvador_FaunalList=421, 
	WNF_SintMaartin_Main=422, 
	WNF_SintMaartin_FaunalList=423, 
	WNF_Syria_Main=424, 
	WNF_Syria_FaunalList=425, 
	WNF_Swaziland_Main=426, 
	WNF_Swaziland_FaunalList=427, 
	WNF_Chad_Main=428, 
	WNF_Chad_FaunalList=429, 
	WNF_Togo_Main=430, 
	WNF_Togo_FaunalList=431, 
	WNF_Thailand_Main=432, 
	WNF_Thailand_FaunalList=433, 
	WNF_Tajikistan_Main=434, 
	WNF_Tajikistan_FaunalList=435, 
	WNF_TimorLeste_Main=436, 
	WNF_TimorLeste_FaunalList=437, 
	WNF_Turkmenistan_Main=438, 
	WNF_Turkmenistan_FaunalList=439, 
	WNF_Tunisia_Main=440, 
	WNF_Tunisia_FaunalList=441, 
	WNF_Tonga_Main=442, 
	WNF_Tonga_FaunalList=443, 
	WNF_Turkey_Main=444, 
	WNF_Turkey_FaunalList=445, 
	WNF_TrinidadandTobago_Main=446, 
	WNF_TrinidadandTobago_FaunalList=447, 
	WNF_Tuvalu_Main=448, 
	WNF_Tuvalu_FaunalList=449, 
	WNF_Tanzania_Main=450, 
	WNF_Tanzania_FaunalList=451, 
	WNF_Ukraine_Main=452, 
	WNF_Ukraine_FaunalList=453, 
	WNF_Uganda_Main=454, 
	WNF_Uganda_FaunalList=455, 
	WNF_Uruguay_Main=458, 
	WNF_Uruguay_FaunalList=459, 
	WNF_Uzbekistan_Main=460, 
	WNF_Uzbekistan_FaunalList=461, 
	WNF_HolySee_Main=462, 
	WNF_HolySee_FaunalList=463, 
	WNF_SaintVincentandtheGrenadines_Main=464, 
	WNF_SaintVincentandtheGrenadines_FaunalList=465, 
	WNF_Venezuela_Main=466, 
	WNF_Venezuela_FaunalList=467, 
	WNF_Vietnam_Main=468, 
	WNF_Vietnam_FaunalList=469, 
	WNF_Vanuatu_Main=470, 
	WNF_Vanuatu_FaunalList=471, 
	WNF_Yemen_Main=472, 
	WNF_Yemen_FaunalList=473, 
	WNF_Zambia_Main=476, 
	WNF_Zambia_FaunalList=477, 
	WNF_Zimbabwe_Main=478, 
	WNF_Zimbabwe_FaunalList=479, 
	WNF_World_Main=480, 
	WNF_World_FaunalList=481, 
	General_ShowList=482, 
	General_HideList=483, 
	General_Download_PDFfile=484, 
	NSW_IdentificationKeys=485, 
	WNF_SubcountryList=486, 
	Viewer_OpenPage=488, 
	Viewer_CurrentPage=489, 
	General_GotoKey=490
}