import React from "react";

const Genus_Introduction = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>Introduction </h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    The <i>Neuropterida Genera of the World</i> (NGW or Catalogue below) is a systematic catalogue of
                    the world genus-group taxa of the four insect orders Neuroptera (lacewings, antlions, owlflies, and
                    their allies), Megaloptera (fishflies and Dobsonflies), Raphidioptera (snakeflies) and
                    Glosselytrodea, which are treated here collectively as the superorder Neuropterida. NGW Version 1.0,
                    released here, represents the first publication of this catalogue in a web-based format. The
                    catalogue documents and integrates taxonomic and nomenclatural information on neuropterid
                    genus-group names from more than 0000 different papers and books, published between 1758 and 0000 in
                    more than 0000 different journals and in at least 000 languages. The catalogue owes much to the
                    variety of regional synopses and family-level checklists, catalogues and monographs (see <a
                    href="#baselinetaxreferences">Baseline Taxonomic References</a>) that have become available over the
                    past several decades. The <i>Neuropterida Genera of the World</i> catalogue is the only modern work
                    that contains comprehensive genus-group coverage of all four neuropterid orders on a global scale,
                    and the only catalogue that includes both extant and fossil taxa across this complete taxonomic
                    domain.
                </p><p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                With respect to the general cataloguing of basic taxonomic and nomenclatural data for the Neuropterida,
                the Catalogue may be viewed as the successor to Oswald and Penny's 1991 "Genus-group names of the
                Neuroptera, Megaloptera and Raphidioptera of the World". The scope of the new work, however, has been
                substantially broadened in several areas.
                First, the NGW contains information on extinct neuropterid taxa, including the extinct order
                Glosselytrodea. Although not widely recognized or appreciated, the "genus-level" diversity of extinct
                taxa in the orders Neuroptera, Megaloptera and Raphidioptera is quite extensive—accounting for more than
                35% of the total number of valid genera in these orders—and these taxa have not previously been
                comprehensively catalogued (though Carpenter 1992 provids a good starting point). While the phylogenetic
                affinities of the Glosselytrodea are not universally agreed upon, their inclusion within the
                Neuropterida by some recent authors has made it advisable to include them here. Second, the NGW has been
                updated to include extant genus-group taxa proposed since 1991—representing an increase of approximately
                10% in the number of names of extant taxa. Third, the NGW contains several classes of new data.
                Etymologies and nomenclatural genders are presented for most names, as are the type localities of their
                type species. Synoptic information on species diversity and geographic distribution is provided for all
                vaild genera and subgenera, and available chronostratigraphic and lithostratigraphic data are summarized
                for fossil taxa.
            </p><p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                The Catalogue is implemented here in an open-access format as a new module within the <a
                href={"/HomePage/MainContent"} target="_top"><i>Lacewing Digital Library</i></a> web portal, which will
                help to enable its future integration with other developing digital resources for the Neuropterida.
                Additional information on several general aspects of catalogue content and development is given below.
                Further information on the organization, content and detailed presentation of catalogue pages and data
                can be accessed by selecting <a href="schelp.html" target="_top">Help</a> from the navigation bar found
                at the bottom of all catalogue pages.
            </p><p className="header-paragraph-2"><b><i>Scope</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The <i>Neuropterida Genera of the World</i> catalogue is comprehensive in geographic scope and
                    includes information on both extant and fossil taxa. The catalogue provides detailed information on
                    the taxonomy and nomenclature of neuropterid genus-group names. For similar and related
                    information <span className="header-paragraph-1">on neuropterid species-group names, see the most recent version of the <a
                    href={"/SpeciesCatalog/Main"}
                    target="_top"><i>Neuropterida Species of the World</i></a> catalogue</span>. Individual Catalogue
                    Record pages are provided for all available neuropterid genus-group names databased as of the
                    version posting date. The following classes of unavailable genus-group names are also accorded
                    separate Catalogue Record pages: (1) "specimen label names" that are known to have been explicitly
                    mentioned in the published scientific literature; (2) names that were inadvertently published in an
                    unavailable manner prior to their subsequent publication as available names (for each such name only
                    the earliest known unavailable use is given a separate Catalogue Record; citations to other
                    unavailable uses published between the earliest unavailable use and the earliest available use are
                    generally included in the General Notes data field of the record for the unavailable name); (3)
                    other genus-group names mentioned in the scientific literature that do not meet the criteria of
                    availability defined in the Code, except for the following classes of names (which are explicitly
                    excluded): (a) known, but unavailable “thesis/dissertation names”; (2) known, but unpublished,
                    “specimen label names”, and (3) “misspellings” that do not qualify as emendations (these include,
                    among others, “lapsus calami” names, printers’ errors, and names deemed to be "incorrect subsequent
                    spellings").
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The long history and diverse taxonomies of the
                    name/taxon "Neuroptera" have been the source of several special problems for this catalogue. Used in
                    Linnaeus' 10th edition of the Systema Natura in 1758 as a polyphyletic receptacle of convenience for
                    species now included in numerous orders scattered across the Holometabola and non-Holometabola, the
                    taxonomic history of the Neuroptera has been characterized by successive waves of reductionism, and
                    its concept has been refined over the years primarily through the sequential removal of more
                    internally cohesive segregates. During most of its history, multiple, substantially different,
                    concepts of the Neuroptera have co-existed in parallel in the scientific literature. One historical
                    consequence of this pluralism has been that for much of its history (particularly prior to ca. 1910)
                    at least one very broad concept of the Neuroptera has existed in which diverse (now extraordinal)
                    insects could be included. Throughout the late 19th and early 20th centuries, many taxa that are now
                    considered extraordinal were placed within broad concepts of the Neuroptera. As the major concepts
                    of the Neuroptera continued to narrow during the late 19th and early 20th centuries most of the
                    extant taxa falling outside our present concept of the superorder Neuropterida were removed from the
                    order. However, because many fossil taxa remained poorly known and weakly documented, some fossil
                    "neuropteroid" taxa of dubious ordinal position have remained in the group up until the present day,
                    or have only very recently been removed. To better document the taxonomic position of these taxa, a
                    small number of names belonging to genera that were formerly included in one or more of the three
                    orders comprising the current Neuropterida, but which have since been excluded from the superorder,
                    are also included in the catalogue as separate Catalogue Records. These records are included as a
                    convenience to users, and may be particularly helpful to users interested in the relatively poorly
                    documented literature on the fossil Neuropterida.</p>
                <p className="header-paragraph-2"><b><i>Nomenclature</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The fourth edition of the International Code of Zoological Nomenclature (the "Code") has been
                    applied to matters of nomenclature throughout the Catalogue.
                </p>
                <p className="header-paragraph-2"><b><i>Etymologies and Nomenclatural Genders</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The NGW catalogue contains a substantial body of
                    previously-unpublished research into the etymologies and nomenclatural genders of neuropterid
                    genus-group names. Etymology statements are presented in a consistent format that: (1) indicates
                    whether or not the original author of the name explained its etymology, (2) details the known,
                    inferred, or presumed origin of the various parts of the name, (3) states the context within which
                    the name was proposed, where known or inferable, and (4) cites the primary sources of information,
                    if any, that were used in deriving the etymology as presented. An attempt has been made to determine
                    the proper nomenclatural gender of each available genus-group name by a close application of Art. 30
                    of the Code. The proper nomenclatural gender of most names is clear, and the subarticle of the Code
                    used to determine the gender of these is explicitly stated. A number of ambiguous cases, however,
                    remain. For each of these, a 'working' nomenclatural gender has been assigned to the name, together
                    with a statement indicating the reasoning behind the application of that gender. These gender
                    assignments should be considered provisional and may require future modification.</p>
                <p className="header-paragraph-2"><b><i>Data Completeness</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    During the lengthy period of data collection for this catalogue the priorities of data capture have
                    shifted several times to address intermediate objectives. Consequently, not all Catalogue Records
                    are equally complete, and certain classes of information are more thoroughly represented across the
                    full range of genera. Some general notes are presented below regarding the relative completeness of
                    several important classes of catalogue data.
                </p>
                <p className="header-paragraph-2"><b>Original Publication Data</b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The original publication data of 99+% of catalogue names have been verified by the author from
                    personal examination of originals or facsimile copies (e.g., photocopies, digital scans, published
                    facsimile reprints [not “separates”]) of the relevant literature. The Verified data field in each
                    Catalogue Record indicates whether original publication data have been verified for individual
                    names. The working Catalogue Records for unverified names may contain particularly incomplete and
                    non-standard data entries.
                </p>
                <p className="header-paragraph-2"><b>Distribution Data</b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    <em>Geographic</em>. Geographic distribution data are provided for almost all of the valid
                    neuropterid genera and subgenera contained in the catalogue. The distribution statements given in
                    the catalogue have been structured to provide information conveniently at three distinct
                    heirarchical levels<span className="header-paragraph-1">: </span>New World vs. Old World,
                    biogeographic region, and country or group of countries. Within a particular biogeographic region,
                    where the documented distribution of a genus-group taxon consists of five or fewer countries, a
                    complete list of those countries is generally reported. Where a taxon is documented from six or more
                    countries, its distribution is generally described in a summary statement. For some larger countries
                    (especially Australia, Canada, Mexico and the United States, and with somewhat less consistency
                    Brazil, China and Russia) first-order political subdivisions within the country (e.g., states,
                    provinces) are often included in the distribution statements, generally in abbreviated format. Most
                    of the baseline taxonomic reference works shown in Tables 1 and 2 below have provided significant
                    amounts of distributional information for the catalogue. Other distributional data have been derived
                    from hundreds of other publications, including taxonomic revisions and country and regional faunas.
                    With very few exceptions, the reported distribution data have been derived from published scientific
                    literature sources. The few exceptional records are based on reliably identified specimens examined
                    by the author or other neuropterists. Individuals wishing to learn the specific sources of the
                    distribution data cited for particular taxa may contact the author for additional information.
                    Distributions reported for some unavailable names give the country(ies) of origin of the specimens
                    attributed to such names in the publications in which the names originally or subsequently appeared.
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The geographic distributions of both extant and
                    extinct taxa are aggregated and reported here using the commonly-recognized zoological biogeographic
                    regions. While the utility of aggregating data in this way may be questioned on a number of
                    grounds<span className="header-paragraph-1">—</span>particularly for the many extinct taxa that
                    certainly existed in biogeographic and ecological settings very different from those existing
                    today<span className="header-paragraph-1">—</span>the biogeographic regions are used here solely as
                    convenient, large-scale, geographic regions that are helpful in simplifying and rapidly conveying
                    information on distributions. No ecological generalizations should be inferred from this usage.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Chronostratigraphic</em>. Temporal distribution
                    data are provided for most genera and subgenera that contain species described from fossils. These
                    data typically cite the period/system (e.g., Cretaceous), epoch/series (e.g., Early), and stage
                    (e.g., Aptian) names of the chronostratigraphic unit (or older and younger bounding units) from
                    which the extinct member(s) of the genus/subgenus have been formally documented to exist in the
                    published scientific literature (unpublished data have generally been excluded). The ages attributed
                    to extinct taxa have been derived from sources believed to be accurate and reliable. However, the
                    dating of many fossils is controversial, and conflicting information exists in the literature for
                    many taxa and deposits. In such cases, in particular, attempts have been made to determine fossil
                    age(s) from recent sources based on well-documented data and methods. Undoubtedly though, the
                    precise age some taxa will be subject to change as our knowledge of the geological contexts of the
                    strata in which they were deposited become better known. The terminology and dates published in <em>A
                        Geologic Time Scale 2004</em> (Gradstein et al. 2004) have been adopted as standards for
                    chronostratigraphic usage in this catalogue, though a minority of the data presented here do not yet
                    fully conform to this standard.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><em>Lithostratigraphic</em>. An attempt has been
                    made to identify the rock unit(s) from which the fossil(s) included in each genus and subgenus
                    originated. However, lithostratigraphic information is reported considerable less often than
                    chronostratigraphic information in original descriptions and subsequent treatments of taxa, and
                    these data are reported only where it has been possible to locate relevent information. Typically,
                    lithostratigraphic data are reported as formal formation names (together with a country name for
                    reference), sometimes with associated supergroup, group and/or member names. Fossils known from
                    amber are generally reported separately, citing the name commonly used for the amber source
                    (together with a country name for reference).</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><em>General</em>. While substantial effort has gone
                    into constructing the distribution statements recorded in this catalogue, our cursory knowledge of
                    the true temporal and spatial distributions of many taxa; the shifting taxonomic boundaries of some
                    genera and subgenera; and the difficulty of identifying, obtaining and synthesizing information
                    relevant to the various facets of the distributions of more than 1000 genus-group taxa world wide;
                    prevents complete accuracy in recording and reporting distribution data. The distributions presented
                    here are believed to be substantially correct, but updates are on-going and will be provided in
                    future versions of the catalogue.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><b> Type Species</b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Data on type species and method of fixation are
                    provided for nearly all available names. Where no valid type species fixation appears to exist, the
                    originally included species are generally cited, together with a discussion of the current type
                    status of the name. Known invalid type species designations are also noted. Type species are cited
                    in their original combinations. The type locality, chronostratigraphic age, and lithostratigraphic
                    origin of the type species of each genus/subgenus are also cited (where known), as additional points
                    of reference.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><b>Name Status</b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The nomenclatural status (available vs.
                    unavailable) and taxonomic status (valid vs. invalid) of each name is indicated. Invalid (and where
                    possible, unavailable) names are associated with their currently valid genus or subgenus name. Known
                    junior homonyms are identified, and references to their associated senior homonyms and replacement
                    names (if any) are provided.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}><b>Genus-Group Name Combinations</b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    Access to individual NGW Catalogue Records is provided through interactive search functionality.
                    User-entered search criteria are used to generate Search Results pages that display lists of
                    genus-group name combinations that match the user’s criteria. More than 0000 different combinations
                    are available for matching in the current catalogue data set. Some reported combinations may
                    involving juxtapositions of generic and subgeneric names that are not technically correct
                    nomenclaturally, but are included as representations of combinations found in the literature.
                </p>
                <p className="header-paragraph-2"><b><a><i>Baseline Taxonomic References</i></a></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The major taxonomic monographs, catalogues and checklists of global or broad regional scope that
                    have been used as “baseline taxonomic references” for this work are shown in Tables 1 and 2 below.
                    The taxonomic information contained in these works (e.g., synonyms and higher classifications) has
                    generally been followed in the catalogue, unless altered by subsequent work. Where recent works
                    present different opinions about the proper taxonomic treatment of particular taxa, the author has
                    used his personal judgment in determining the usage presented in the catalogue. The General Notes
                    field of the appropriate Catalogue Record may contain information on alternative views. It should
                    not be assumed that the genera and subgenera treated as valid in this catalogue are monophyletic
                    (=holophyletic) unless specific phylogenetic data are available in the literature to support such
                    conclusions.
                </p>
                <div id="table" style={{textAlign: "center"}}>
                    <table id="data">
                        <tbody>
                        <tr>
                            <td colSpan={7} className="caption1" style={{textAlign: 'justify'}}>
                                <div style={{textAlign: 'justify'}}>
                                    Table 1. Baseline Taxonomic References: works primarily restricted by taxon. The
                                    works included in this table are relatively recent works that cover broad geographic
                                    regions and wide taxonomic domains (world wide and family wide unless otherwise
                                    indicated). Revisions/reviews of individual genera have been excluded. Works
                                    included in Table 2 are not repeated here.
                                </div>
                            </td>
                        </tr>
                        <tr className="headerrow1">
                            <td className="col"><b>Order/Family</b></td>
                            <td className="col1" align="left"><b>Reference(s) [BotN ref#] (comments)</b></td>
                        </tr>
                        <tr style={{backgroundColor: "#000000"}}></tr>
                        <tr>
                            <td className="cellwithborders1"><b>Neuroptera</b></td>
                            <td className="cellwithborders1" align="left">New 1989 [r#6615]; Oswald &amp; Penny 1991
                                [r#7138]
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Ascalaphidae</td>
                            <td className="cellwithborders3" align="left">Ghosh 1988 [r#9656] (E. India); New 1984
                                [r#4490] (Australia); Penny [1982] [r#5105] (New World); Sziráki 1998 [r#9362] (Asia and
                                Pacific islands); Tjeder 1992 [r#7246] (Afrotropical Haplogleniinae);
                                Tjeder &amp; Hansson 1992 [r#7247] (Afrotropical Ascalaphini)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Berothidae</td>
                            <td className="cellwithborders3" align="left">U. Aspöck 1986 [r#1425]; U. Aspöck 1990
                                [r#1430] (Africa); U. Aspöck &amp; Mansell 1994 [r#7532] (Rhachiberothinae); Nel et al.
                                2005 [r#11744] (fossil Rhachiberothinae)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Chrysopidae</td>
                            <td className="cellwithborders3" align="left">Brooks 1997 [r#9322]; Brooks &amp; Barnard
                                1990 [r#6991]; Ghosh 1990 [r#9484] (India); Nel et al. 2005 [r#11761] (fossils); New
                                1980 [r#4464] (Australia); Tsukaguchi 1995 [r#8409] (Japan); Winterton &amp; Brooks 2002
                                [r#10215] (Apochrysinae); X.-K. Yang 1997 [r#8956] (China)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Coniopterygidae</td>
                            <td className="cellwithborders3" align="left">Johnson 1980 [r#3144] (North America);
                                Meinander 1972 [r#4121]; Meinander 1990 [r#4147]; Engel 2004 [r#11170] (fossils);
                                Sziráki 2004 [r#12365] (Palearctic &amp; Oriental regions)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Dilaridae</td>
                            <td className="cellwithborders3" align="left">Monserrat 1988 [r#4308] (Iberian peninsula);
                                Oswald 1998 [r#9236]
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Hemerobiidae</td>
                            <td className="cellwithborders3" align="left">Monserrat 1990 [r#4311]; New 1988 [r#4514]
                                (Australia); Oswald 1993 [r#7349]
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Ithonidae</td>
                            <td className="cellwithborders3" align="left">Riek 1974 [r#5415] (Australia)</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Mantispidae</td>
                            <td className="cellwithborders3" align="left">Hoffman 1992 [r#8849] (New World Mantispinae);
                                Lambkin 1986a,b [r#3615, 3616] (Australia); Ohl 2004 [r#11556]; Penny 1982 [r#5106] (New
                                World); Penny &amp; da Costa [1985] [r#5113] (Brazil)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Myrmeleontidae</td>
                            <td className="cellwithborders3" align="left">Ghosh 1984 [r#2587] (India); Hölzel 1972
                                [r#2968] (near Asia), 1987 [r#3001] (Distoleonini); New 1985a, b, c [r#4494, 4495, 4496]
                                (Australia); Stange 1989 [r#6545] (Dimarini), 1994 [r#7533] (Brachynemurini), 2004
                                [r#11168]; Stange &amp; Miller 1985 [r#5823] (Acanthaclisini), 2003 [r#10553] (Taiwan)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Nemopteridae</td>
                            <td className="cellwithborders3" align="left">Hölzel 1975 [r#2980] (Crocinae); Mansell 1983
                                [r#4008] (Crocinae); Tjeder 1967 [r#6050] (S. Africa)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Nevrorthidae</td>
                            <td className="cellwithborders3" align="left">Monserrat 1977 [r#4246]</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Nymphidae</td>
                            <td className="cellwithborders3" align="left">New 1996 [r#4465] (Australia)</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Osmylidae</td>
                            <td className="cellwithborders3" align="left">New 1983 [r#4476] (Australian Kempyninae); New
                                1988 [r#6510] (Irian Jaya); New 1991 [r#7212] (Oriental region)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Polystoechotidae</td>
                            <td className="cellwithborders3" align="left">Archibald &amp; Makarkin 2006 [r#11800]
                                (fossils)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Psychopsidae</td>
                            <td className="cellwithborders3" align="left">Andersen 2001 [r#10152] (fossils); New 1988
                                [r#6511] (Australia and Oriental region); Oswald 1993 [#7341]
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Rapismatidae</td>
                            <td className="cellwithborders3" align="left">Barnard 1981 [r#1510]</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Sisyridae</td>
                            <td className="cellwithborders3" align="left">Flint 2006 [r#11913] (New World); Monserrat
                                1977 [r#4246], 1981 [r#4272] (Oriental region)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3"><b>Megaloptera</b></td>
                            <td className="cellwithborders3" align="left">Oswald &amp; Penny 1991 [r#7138];
                                New &amp; Theischinger 1993 [#7701]; Theischinger 1983 [r#6004] (Australia);
                                Yang &amp; Liu 2010 [r#13492] (China)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Corydalidae</td>
                            <td className="cellwithborders3" align="left">Glorioso 1981 [#2625] (Corydalinae)</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Sialidae</td>
                            <td className="cellwithborders3" align="left">Hayashi &amp; Suda 1995 [r#7774] (Japan);
                                X.-y. Liu &amp; D. Yang 2006 [r#11697] (Oriental China); Vshivkova 1985 [r#6189]
                                (Europe &amp; Caucasus); Whiting 1994 [r#6988] (North America); Wichard &amp; Engel 2006
                                [r#11833]
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3"><b>Raphidioptera</b></td>
                            <td className="cellwithborders3" align="left">H. Aspöck et al. 1991 [r#6999]; <span
                                className="cellwithborders1">Oswald &amp; Penny 1991 [r#7138]</span>; Engel 2002
                                [r#10270] (fossils)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Inocelliidae</td>
                            <td className="cellwithborders3" align="left">H. Aspöck et al. 1991 [r#6999]</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders2">Raphidiidae</td>
                            <td className="cellwithborders2" align="left">H. Aspöck et al. 1991 [r#6999]</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div id="table" style={{textAlign: "center"}}>
                    <table id="data">
                        <tbody>
                        <tr>
                            <td colSpan={7} className="caption1" style={{textAlign: 'justify'}}>
                                <div style={{textAlign: 'justify'}}>
                                    Table 2. Baseline Taxonomic References: works primarily restricted by geographic
                                    region. Works included in this table are recent, large-area, regional faunas that
                                    treat the entire Neuropterida (unless otherwise indicated). No attempt has been made
                                    to include all recent national faunal lists.
                                </div>
                            </td>
                        </tr>

                        <tr className="headerrow">
                            <td className="col"><b>Region</b></td>
                            <td className="col1" align="left"><b>Reference(s) [BotN ref#] (comments)</b></td>
                        </tr>
                        <tr style={{backgroundColor: "#000000"}}></tr>
                        <tr>
                            <td className="cellwithborders1">Nearctic</td>
                            <td className="cellwithborders1" align="left">Oswald et al. 2002 [r#10033] (Mexico); Penny
                                et al. 1997 [r#8867] (America north of Mexico)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Neotropical</td>
                            <td className="cellwithborders3" align="left">Oswald et al. 2002 [r#10033] (Mexico) ; Penny
                                1977 [r#5098] (South America); Penny [ed.] 2002 [r#10000] (Costa Rica)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Palearctic</td>
                            <td className="cellwithborders3" align="left">H. Aspöck et al. 1980 [r#6747] (Europe); H.
                                Aspöck et al. [r#9847] (western Palearctic); Kuwayama 1962 [r#10914] (Japan); Makarkin
                                1995a, b [r#8360, 8361], Vshivkova 1995 [r#8504] (eastern Russia)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Afrotropical</td>
                            <td className="cellwithborders3" align="left">---</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Oriental</td>
                            <td className="cellwithborders3" align="left">New 2003 [r#10897] (Malesia)</td>
                        </tr>
                        <tr>
                            <td className="cellwithborders3">Australian</td>
                            <td className="cellwithborders3" align="left">New 1996 [r#8585] (Australia); Wise 1991
                                [r#7137] (New Zealand)
                            </td>
                        </tr>
                        <tr>
                            <td className="cellwithborders2">Oceania</td>
                            <td className="cellwithborders2" align="left">Zimmerman 1957 [r#6440] (Hawaiian Islands)
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <p className="header-paragraph-2"><b><i>Higher Classification</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The suprageneric classification of the Neuropterida used in the catalogue is given below (current as
                    of the nominal posting date of the version). Family-group taxa that contain both extant and extinct
                    species are asterisked (*); taxa that contain only extinct species are double-asterisked (**).
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>The higher classification adopted here recognizes 3
                    orders, 46 families (including 1 Neuropterida incertae sedis family), 58 subfamilies, 37 tribes and
                    11 subtribes. Of these, 25 families, 9 subfamilies and 2 tribes are known only from fossils, while
                    21 families, 49 subfamilies, 35 tribes and 11 subtribes are known from only extant species, or a
                    combination of extant and fossil species. In the classification given below, all family-group names
                    are listed alphabetically within the next higher ranked taxon. No phylogenetic sequencing should be
                    inferred in this classification; and the monophyly of individual taxa should not be assumed without
                    support from the technical phylogenetic literature. The working classification adopted for this
                    catalogue is intentionally silent on the issue of whether suborders, superfamilies or other ranks
                    above the family-group should be recognized in the Neuropterida. This should not be interpreted as a
                    rejection of the actual or potential utility of such names and the groups that they represent.</p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>---update classification</p>
                <p className="header-paragraph-2"><b>Suprageneric Classification of the Neuropterida of the World</b>
                </p>
                <p style={{marginTop: "5px",}}>
                </p>

                {/*TODO: convert Paragraph to Ul and Li items*/}

                <p className="Level1">Neuroptera</p>
                <p className="Level2">Archeosmylidae**</p>
                <p className="Level2">Ascalaphidae</p>
                <p className="Level3">Albardiinae</p>
                <p className="Level3">Ascalaphinae</p>
                <p className="Level4">Acmonotini</p>
                <p className="Level4">Ascalaphini</p>
                <p className="Level4">Encyoposini</p>
                <p className="Level4">Hybrisini</p>
                <p className="Level4">Proctarrelabrini</p>
                <p className="Level4">Suhpalacsini</p>
                <p className="Level4">Ululodini</p>
                <p className="Level4">Ululomyiini</p>
                <p className="Level3">Haplogleniinae</p>
                <p className="Level4">Allocormodini</p>
                <p className="Level4">Campylophlebiini</p>
                <p className="Level4">Melambrotini</p>
                <p className="Level4">Proctolyrini</p>
                <p className="Level4">Tmesibasini</p>
                <p className="Level2">Babinskaiidae**</p>
                <p className="Level2">Berothidae</p>
                <p className="Level3">Berothimerobiinae</p>
                <p className="Level3">Berothinae</p>
                <p className="Level3">Cyrenoberothinae</p>
                <p className="Level3">Nosybinae</p>
                <p className="Level3">Nyrminae</p>
                <p className="Level3">Rhachiberothinae</p>
                <p className="Level4">Paraberothini**</p>
                <p className="Level4">Rhachiberothini</p>
                <p className="Level2">Brongniartiellidae**</p>
                <p className="Level2">Chrysopidae</p>
                <p className="Level3">Apochrysinae</p>
                <p className="Level3">Chrysopinae</p>
                <p className="Level4">Ankylopterygini</p>
                <p className="Level4">Belonopterygini</p>
                <p className="Level4">Chrysopini</p>
                <p className="Level4">Leucochrysini</p>
                <p className="Level3">Limaiinae**</p>
                <p className="Level3">Nothochrysinae</p>
                <p className="Level2">Coniopterygidae</p>
                <p className="Level3">Aleuropteryginae</p>
                <p className="Level4">Aleuropterygini</p>
                <p className="Level4">Coniocompsini</p>
                <p className="Level4">Fontenelleini</p>
                <p className="Level3">Brucheiserinae</p>
                <p className="Level3">Coniopteryginae</p>
                <p className="Level4">Coniopterygini</p>
                <p className="Level4">Conwentziini</p>
                <p className="Level4">Phthanoconini**</p>
                <p className="Level2">Dilaridae</p>
                <p className="Level3">Dilarinae</p>
                <p className="Level3">Nallachiinae</p>
                <p className="Level2">Grammolingiidae**</p>
                <p className="Level2">Hemerobiidae</p>
                <p className="Level3">Adelphohemerobiinae</p>
                <p className="Level3">Carobiinae</p>
                <p className="Level3">Drepanacrinae</p>
                <p className="Level3">Drepanepteryginae</p>
                <p className="Level3">Hemerobiinae</p>
                <p className="Level3">Megalominae</p>
                <p className="Level3">Microminae</p>
                <p className="Level3">Notiobiellinae</p>
                <p className="Level3">Psychobiellinae</p>
                <p className="Level3">Sympherobiinae</p>
                <p className="Level2">Ithonidae</p>
                <p className="Level2">Kalligrammatidae**</p>
                <p className="Level2">Mantispidae</p>
                <p className="Level3">Calomantispinae</p>
                <p className="Level3">Drepanicinae</p>
                <p className="Level3">Mantispinae</p>
                <p className="Level3">Mesomantispinae**</p>
                <p className="Level3">Symphrasinae</p>
                <p className="Level2">Mesithonidae**</p>
                <p className="Level2">Mesoberothidae**</p>
                <p className="Level2">Mesochrysopidae**</p>
                <p className="Level2">Myrmeleontidae</p>
                <p className="Level3">Araripeneurinae**</p>
                <p className="Level3">Myrmeleontinae</p>
                <p className="Level4">Acanthaclisini</p>
                <p className="Level4">Brachynemurini</p>
                <p className="Level4">Dendroleontini</p>
                <p className="Level5">Acanthoplectrina</p>
                <p className="Level5">Dendroleontina</p>
                <p className="Level5">Nuglerina</p>
                <p className="Level5">Periclystina</p>
                <p className="Level5">Voltorina</p>
                <p className="Level4">Gnopholeontini</p>
                <p className="Level4">Lemolemini</p>
                <p className="Level4">Maulini</p>
                <p className="Level4">Myrmecaelurini</p>
                <p className="Level4">Myrmeleontini</p>
                <p className="Level5">Myrmeleontina</p>
                <p className="Level5">Porrerina</p>
                <p className="Level4">Nemoleontini</p>
                <p className="Level5">Dimarellina</p>
                <p className="Level5">Nemoleontina</p>
                <p className="Level5">Neuroleontina</p>
                <p className="Level5">Obina</p>
                <p className="Level4">Nesoleontini</p>
                <p className="Level3">Palparinae</p>
                <p className="Level4">Dimarini</p>
                <p className="Level4">Palparidiini</p>
                <p className="Level4">Palparini</p>
                <p className="Level4">Pseudimarini</p>
                <p className="Level3">Pseudonymphinae**</p>
                <p className="Level3">Stilbopteryginae</p>
                <p className="Level2">Nemopteridae</p>
                <p className="Level3">Crocinae</p>
                <p className="Level3">Nemopterinae</p>
                <p className="Level2">Nevrorthidae</p>
                <p className="Level2">Nymphidae</p>
                <p className="Level2">Nymphitidae**</p>
                <p className="Level2">Osmylidae</p>
                <p className="Level3">Eidoporisminae</p>
                <p className="Level3">Epiosmylinae**</p>
                <p className="Level3">Kempyninae</p>
                <p className="Level3">Osmylinae</p>
                <p className="Level3">Porisminae</p>
                <p className="Level3">Protosmylinae</p>
                <p className="Level3">Saucrosmylinae**</p>
                <p className="Level3">Spilosmylinae</p>
                <p className="Level3">Stenosmylinae</p>
                <p className="Level2">Osmylitidae**</p>
                <p className="Level2">Osmylopsychopidae**</p>
                <p className="Level2">Palaeoleontidae**</p>
                <p className="Level2">Panfiloviidae**</p>
                <p className="Level2">Permithonidae**</p>
                <p className="Level2">Polystoechotidae</p>
                <p className="Level2">Prohemerobiidae**</p>
                <p className="Level2">Psychopsidae</p>
                <p className="Level3">Psychopsinae</p>
                <p className="Level3">Triassopsychopsinae**</p>
                <p className="Level3">Zygophlebiinae</p>
                <p className="Level2">Rapismatidae</p>
                <p className="Level2">Roeslerianidae**</p>
                <p className="Level2">Sisyridae</p>
                <p className="Level2">Solenoptilidae**</p>
                <p className="Level1">Megaloptera*</p>
                <p className="Level2">Corydalidae*</p>
                <p className="Level3">Chauliodinae*</p>
                <p className="Level3">Corydalinae*</p>
                <p className="Level2">Corydasialidae**</p>
                <p className="Level2">Euchauliodidae**</p>
                <p className="Level2">Parasialidae**</p>
                <p className="Level2">Sialidae*</p>
                <p className="Level1">Raphidioptera*</p>
                <p className="Level2">Priscaenigmatidae**</p>
                <p className="Level2">Alloraphidiidae**</p>
                <p className="Level2">Baissopteridae**</p>
                <p className="Level2">Inocelliidae*</p>
                <p className="Level3">Electrinocelliinae**</p>
                <p className="Level3">Inocelliinae*</p>
                <p className="Level2">Mesoraphidiidae**</p>
                <p className="Level2">Raphidiidae*</p>
                <p className="Level3">Raphidiinae*</p>
                <p className="Level4">Agullini*</p>
                <p className="Level4">Alenini</p>
                <p className="Level3">Succinoraphidiinae**</p>
                <p className="Level1">Glosselytrodea** </p>
                <p className="Level2">Archoglossopteridae**</p>
                <p className="Level2">Glosselytridae**</p>
                <p className="Level2">Glossopteridae**</p>
                <p className="Level2">Jurinidae**</p>
                <p className="Level2">Permoberothidae**</p>
                <p className="Level2">Polycytellidae**</p>
                <p className="Level2">Uskatelytridae**</p>
                <p className="Level1">Neuropterida (Incertae Sedis)** </p>
                <p className="Level2">Rafaelidae**</p>
                <p className="header-paragraph-2"><b><i>Catalogue Updates and Corrections</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>
                    The author bears full responsibility for all errors of omission and commission in this catalogue and
                    is committed to a regular program of catalogue improvement. Users who discover errors are requested
                    to report them to the author so that they may be corrected in future versions. For the convenience
                    of users, an “Email Comments/Corrections” button is provided on each Catalogue Record page. Click
                    this button to produce an e-mail form that can be used to send comments and corrections directly to
                    the author.
                </p><br/><br/>
            </div>
        </div>
    </>
};
export default Genus_Introduction;