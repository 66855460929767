import React from "react";


const Team = () => {
    return (<>

        <div className="text-body">
            <h1>Project Team</h1>
            <p className="header-paragraph-1">
                The <i>Lacewing Digital Library</i> (LDL) is a collaborative project that has received important input
                and support from a variety of people and organizations.
                The primary individuals and organizations associated with the overall project are identified below,
                together with brief synopses of their main areas of contribution.
                Individuals whose work has primarily been associated with specific LDL component projects are recognized
                on the Acknowledgments pages associated with those components.
            </p>
            <hr/>
            <p className="header-paragraph-2">
                <b>People</b>
            </p>
            <h4>
                Team Leader
            </h4>
            <p className="indented-paragraph">
                John Oswald, Professor &amp; Curator, Department of Entomology (2004-present): project concept, vision
                and development;
                overall project direction, coordination and management; chief project editor; general website
                organization and design;
                author and scientific content provider; feedback and review of design and content.
            </p>
            <h4>
                Current Team Members (alphabetical by surname):
            </h4>
            <p className="indented-paragraph">Bryce Gredig, bachelor's degree student, Department of Computer Science and
                Engineering (2024-present): computer programmer, website development, code design standardization, backend rebuild, frontend rebuild, code language port (Razor & C#) to (React & Node.js), IIS management, MSSQL management.</p>

            <h4>Former Team Members (alphabetical by surname): </h4>

            <p className="indented-paragraph">Snehith Bikumandla, master's degree student, Department of Computer
                Science and Engineering (2023): computer programmer, website development.</p>
            <p className="indented-paragraph">Luis Calixto, computer programmer, Department of Entomology (2012-2013):
                web server and security issues</p>
            <p className="indented-paragraph">Therese Catanach, master’s degree student, Department of Entomology
                (2007-2009): feedback and review of design and content</p>
            <p className="indented-paragraph">Sankalp Chapalgaonker, master's degree student, Department of Computer
                Science and Engineering (2022-2024): computer programmer, website development.</p>
            <p className="indented-paragraph">Ting "Candice" Chen, master's degree student, Department of Computer
                Science (2014): computer programmer, web site rebuild.</p>
            <p className="indented-paragraph">
                Joshua Jones, doctoral degree student, Department of Entomology (2006-2012): general website
                organization, design and project support;
                initial webpage layout and design element details; early website implementation; coordination of early
                technical team activities; photography and graphics;
                feedback and review of design and content
            </p>
            <p className="indented-paragraph">
                Jia-cheng "Jason" Liu, master's degree student, Department of Computer Science & Engineering
                (2019-2020): computer programmer, website development.
            </p>
            <p className="indented-paragraph">Shubham Parasher, master's degree student, Department of Computer Science
                and Engineering (2022-2023): computer programmer, website development.</p>
            <p className="indented-paragraph">
                Rakesh Parvataneni, master’s degree student, Department of Electrical and Computer Engineering
                (2010-2011):
                webpage implementation; website to database content integration; file maintenance and server issues;
                graphics
            </p>
            <p className="indented-paragraph">Yukon Peng, master's degree student, Department of Computer Science and
                Engineering (2021-2022): computer programmer, website development.</p>
            <p className="indented-paragraph">Srishti Rath, master's degree student, Department of Computer Science and
                Engineering (2023): computer programmer, website development.</p>
            <p className="indented-paragraph">
                Krishna Tadi, master’s degree student, Department of Electrical and Computer Engineering (2007-2009):
                webpage design and implementation;
                website to database content integration; file maintenance and server issues; graphics; feedback and
                review of design and content
            </p>
            <p className="indented-paragraph">Tian-jian Wang, master's degree student, Department of Electrical &
                Computer Engineering (2016-2018): computer programmer, website development.</p>
            <p className="indented-paragraph">Yue "Belle" Wu, master's degree student, Department of Computer Science
                and Engineering (2021-2022): computer programmer, website development.</p>
            <p className="indented-paragraph">Dongqing "Serena" Yang, master's degree student, Department of Computer
                Science and Engineering (2021-2022): computer programmer, website development.</p>
            <p className="indented-paragraph">Li-ming Ye, master's degree student, Department of Electrical & Computer
                Engineering (2014): computer programmer, website development.</p>
            <p className="indented-paragraph">Yuan-xing Yin, master's degree student, Department of Electrical &
                Computer Engineering (2015): computer programmer, website development.</p>
            <p className="indented-paragraph">Jia-hao Zhang, master's degree student, Department of Electrical &
                Computer Engineering (2015-2016): computer programmer, website development.</p>
            <p className="header-paragraph-2">
                <b>Institutions and Organizations</b>
            </p>
            <p className="indented-paragraph">
                Department of Entomology, College of Agriculture and Life Sciences, Texas A&M University (2004-present):
                website hosting and general support (to John Oswald and lab)<br/><br/>
                Texas AgriLife Research [formerly Texas Agricultural Experiment Station] (2004-present): general
                research support (to John Oswald and lab) <br/>
                <br/>
                U.S. National Science Foundation: grant funding to John Oswald directly or indirectly supporting project
                content and/or website development
            </p>
        </div>

        </>)
};
export default Team;