import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    EasyTableSection,
    IconLink,
    Record,
    
    SafeHTML
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlFaunaVersions} from "../../../../database/models/tblLdlFaunaVersions";
import nameTranslator from "../../utils/nameTranslator";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json";
import {tblLdlFaunaPoliticalUnits} from "../../../../database/models/tblLdlFaunaPoliticalUnits";
import FaunaCountry from "../../utils/OnServerStartLoadedJsons/FaunaCountry.json";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";


const Faunas_Versions = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlFaunaVersions[]>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);
    // @ts-ignore
    const myGeo = LDLallowedModules[paths[0]]
    // @ts-ignore
    const myModule:tblLdlModule=LDLallowedModules[paths[0]]
    // @ts-ignore
    const myPoliticalUnit:tblLdlFaunaPoliticalUnits|undefined = FaunaCountry[myModule.GeogID];
    const PageName=`${
        // @ts-ignore
        myPoliticalUnit?(`${myPoliticalUnit?.DefiniteArticle} ${myPoliticalUnit.PoliticalUnitName}`):(nameTranslator["/" + paths[0]]?.TabName ? `${currentPathData[paths[0]].UpperLeftModuleName}` :`${paths[0]}`)}`

    useEffect(() => {
        callAPI("/api/general/getFaunaVersionsByProgID", {ProjID: myGeo.BibObjectID}, (error: boolean, outData: tblLdlFaunaVersions[] | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData);
                setResultData(outData);
            }
        })
    }, []);
//https://lacewing-demo.tamu.edu/JasonViewer/test.pdf
//https://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/henry2002ref10252-1.pdf

    return <>{resultData &&
        <Record title={"Versions"}>
            <i>
                <>Neuropterida of {PageName} version statistics.</>
            </i>
            <EasyTable tableRowData={resultData}>
                <EasyTableElement width={"7%"} align={"center"} title={"Version"}
                                  elementFromValue={(val: tblLdlFaunaVersions) => <SafeHTML html={val.Version}/>}
                                  desc={"the number associated with each documented version of the Bibliography; the “active datasets” line contains up-to-date data record counts for several columns (calculated programmatically from the current Bibliography datasets), these counts may correspond to the most recent documented version, or to subsequent undocumented dataset updates."}/>
                <EasyTableElement width={"11%"} align={"center"} title={"Release Date"}
                                  elementFromValue={(val: tblLdlFaunaVersions) => <SafeHTML html={val.DateDocumented}/>}
                                  desc={"the number associated with each documented version of the Bibliography; the “active datasets” line contains up-to-date data record counts for several columns (calculated programmatically from the current Bibliography datasets), these counts may correspond to the most recent documented version, or to subsequent undocumented dataset updates."}/>
                <EasyTableSection align={"center"} title={"Page Archive"} paddingRight={"10px"}>
                    <EasyTableElement align={"center"} width={"6.5%"} title={"Main"}
                                      elementFromValue={(val: tblLdlFaunaVersions) => <>{val.PageArchiveMainURL == "&ndash;" || !val.PageArchiveMainURL ?
                                          <SafeHTML html={val.PageArchiveMainURL}/> :
                                          <IconLink href={val.PageArchiveMainURL}
                                                    IconID={LDLIcons.General_OpenFile_Work} />}</>}
                                      desc={"the number associated with each documented version of the Bibliography; the “active datasets” line contains up-to-date data record counts for several columns (calculated programmatically from the current Bibliography datasets), these counts may correspond to the most recent documented version, or to subsequent undocumented dataset updates."}/>
                    <EasyTableElement align={"center"} width={"7.5%"} title={"Intro"}
                                      elementFromValue={(val: tblLdlFaunaVersions) => <>{val.PageArchiveIntroURL == "&ndash;" || !val.PageArchiveIntroURL ?
                                          <SafeHTML html={val.PageArchiveIntroURL}/> :
                                          <IconLink href={val.PageArchiveIntroURL}
                                                    IconID={LDLIcons.General_OpenFile_Work} />}</>}
                                      desc={"the number associated with each documented version of the Bibliography; the “active datasets” line contains up-to-date data record counts for several columns (calculated programmatically from the current Bibliography datasets), these counts may correspond to the most recent documented version, or to subsequent undocumented dataset updates."}/>
                </EasyTableSection>
                <EasyTableSection align={"center"} title={"Data"}>
                    <EasyTableElement align={"center"} width={"8%"} title={"Taxa"}
                                      elementFromValue={(val: tblLdlFaunaVersions) => <SafeHTML
                                          html={val.DataTaxaCount}/>}
                                      desc={"the number associated with each documented version of the Bibliography; the “active datasets” line contains up-to-date data record counts for several columns (calculated programmatically from the current Bibliography datasets), these counts may correspond to the most recent documented version, or to subsequent undocumented dataset updates."}/>
                    <EasyTableElement align={"center"} width={"9%"} title={"Records"}
                                      elementFromValue={(val: tblLdlFaunaVersions) => <SafeHTML
                                          html={val.DataRecordCount}/>}
                                      desc={"the number associated with each documented version of the Bibliography; the “active datasets” line contains up-to-date data record counts for several columns (calculated programmatically from the current Bibliography datasets), these counts may correspond to the most recent documented version, or to subsequent undocumented dataset updates."}/>
                </EasyTableSection>
                <EasyTableElement width={"56%"} align={"center"} title={"Benchmarks"}
                                  elementFromValue={(val: tblLdlFaunaVersions) => <SafeHTML html={val.Benchmarks}/>}
                                  desc={"notable version changes, features or milestones"}/>
            </EasyTable>

        </Record>}</>
};


export default Faunas_Versions;