import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {Link, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    ListElement,
    ListHolder,
    Record,

    TableLegendElement,
    TableLegendSection, UpdatePathData
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";
import {tblLdlSpCatFigs} from "../../../../database/models/tblLdlSpCatFigs";


const SpeciesFigureRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlSpCatComb>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getSpecFigCitByPk", {CombObjID: _props["CombObjID"]}, (error: boolean, outData: tblLdlSpCatComb | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
                setCurrentPathData((prev)=>UpdatePathData({MonographRecord:{UpperLeftModuleName:<>Monograph Record &ndash; <i>{outData.ASCurrGenu} {outData.ASCurrSpec} {outData.ASCurrSubs != "$" && outData.ASCurrSubs}</i></>}},prev))

            }
        })
    }, []);

    return <>
        {resultData &&
            <Record
                    title={<>Figure Citations <IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                        href={"mailto:j-oswald@tamu.edu?subject=Neuropterida Species of the World: comment/correction for Figure Citations page \"" + resultData.ASCurrGenu + " " + resultData.ASCurrSpec + (resultData.ASCurrSubs != "$" ? (" " + resultData.ASCurrSubs) : "") + " " + resultData.CurrCombAuthDate + "\""}
                        IconID={LDLIcons.General_Email}
                        HistoryLocStates={location.state}
                    /></>}>
                <ListHolder>
                    <ListElement title={"Monograph Record Links"} element={<>
                        {resultData.TaxonCites?.length != 0 &&
                            <IconLink href={"../Taxa"} IconID={LDLIcons.NSW_TaxonCitations}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.TerminalTaxon?.length != 0 &&
                            <IconLink href={"../Keys"} IconID={LDLIcons.NSW_IdentificationKeys} HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                        {resultData.PhyloTrees?.length != 0 &&
                            <IconLink href={"../PhylogeneticTree"} IconID={LDLIcons.NSW_PhylogeneticTrees}
                                      HistoryLocStates={location.state}
                                      newStates={{"CombObjID": resultData.CombObjID}}/>}</>}/>
                        </ListHolder>
                {resultData.FigureCites && <>
                    <i>
                        <>Figure Citation Records ({resultData.FigureCites.length}).</>
                    </i>
                    <EasyTable downloadable={true} downloadName={`${resultData.ASCurrGenu}_${resultData.ASCurrSpec}${resultData.ASCurrSubs != "$" ?("_"+resultData.ASCurrSubs):""}_Figure_Citations.csv`} extraLegendChildren={
                        <>
                            <TableLegendSection title={"Abbreviations"}>
                                <TableLegendElement title={"a"} element={"adult(s)"}/>
                                <TableLegendElement title={"b&w"} element={"black & white"}/>
                                <TableLegendElement title={"e"} element={"egg(s)"}/>
                                <TableLegendElement title={"fig"} element={"figure"}/>
                                <TableLegendElement title={"l"} element={"larva(e), number indicates instar"}/>
                                <TableLegendElement title={"n/a"} element={"not applicable / not available"}/>
                                <TableLegendElement title={"p"} element={"page / pupa(e)"}/>
                                <TableLegendElement title={"pl"} element={"plate"}/>
                                <TableLegendElement title={"pp"} element={"prepupa(e)"}/>
                                <TableLegendElement title={"r#"}
                                                    element={"Bibliography of the Neuropterida (BotN) reference number."}/>
                            </TableLegendSection>
                            <TableLegendSection title={"Notes"}>This table is designed to display information about illustrations relating to the active species or subspecies. These data have been compiled from illustrations contained in the scientific literature, and frequently contain links to that literature</TableLegendSection><TableLegendSection title={"Data Source"}>
                            {resultData.SpCat?.DSSource}
                        </TableLegendSection><TableLegendSection title={"Data Timestamp"}>
                            {resultData.SpCat?.DSDateTime}
                        </TableLegendSection>
                        </>} autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData.FigureCites}>
                        <EasyTableElement width={"5%"} title={"Stage"} elementFromValue={(val: tblLdlSpCatFigs) => val.FigLifeStage}
                                          desc={"the life stage(s) illustrated in or associated with the figure"}/>
                        <EasyTableElement width={"3%"} title={"Sex"} elementFromValue={(val: tblLdlSpCatFigs) => val.FigSexSymbol}
                                          desc={"the sex(es) illustrated in or associated with the figure"}/>
                        <EasyTableElement width={"18%"} title={"Subject"} elementFromValue={(val: tblLdlSpCatFigs) => val.FigSubject}
                                          desc={"the subject matter or scope of the figure, as it pertains to the associated taxon"}/>
                        <EasyTableElement width={"7%"} title={"View"} elementFromValue={(val: tblLdlSpCatFigs) => val.FigView}
                                          desc={"the aspect from which the primary subject matter of the figure is depicted"}/>
                        <EasyTableElement width={"12%"} title={"Style"} elementFromValue={(val: tblLdlSpCatFigs) => val.FigArtAbbrev}
                                          desc={"the kind of artwork that the figure represents in the cited publication"}/>
                        <EasyTableElement width={"14%"} title={"Source"} elementFromValue={(val: tblLdlSpCatFigs) => val.FigPubCite}
                                          desc={"a short bibliographic citation to the work in which the figure appears"}/>
                        <EasyTableElement width={"12%"} title={"Page/Plate, Figure"}
                                          elementFromValue={(val: tblLdlSpCatFigs) => val.FigPubLabel}
                                          desc={"the designation for the page or plate on which the figure appears, and its associated figure designation; clarification and disambiguation information are given in square brackets []"}/>
                        <EasyTableElement textIndent={"0px"} width={"7%"} title={"Links"} elementFromValue={(val: tblLdlSpCatFigs) => <>
                            <IconLink newWindow={true} href={"/Biblio/Record"} IconID={LDLIcons.BotN_BibliographicRecord}
                                      newStates={{"BibObjID": val.FigBibObjID}}/>
                            {(val.EDoc && val.EDoc.EDocLink && (val.EDoc.PublicEDoc == -1 || (val.EDoc.PrivateEDoc == -1 && PDFAccess))) ? <>
                                <IconLink newWindow={true} href={val.EDoc.EDocLink}
                                          IconID={LDLIcons.General_OpenFile_Work}/>
                                <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                          newStates={{"BibObjID": val.FigBibObjID}}
                                          IconID={LDLIcons.Viewer_OpenFile}/>
                            </> : <>&nbsp;&ndash;&nbsp;&nbsp;&ndash;&nbsp;</>}
                            {(val.EDocPage && val.EDocPage.EDocPageLink && (val.EDocPage.PublicEDocPage == -1 || (val.EDocPage.PrivateEDocPage == -1 && PDFAccess))) ? <>
                                <IconLink newWindow={true} href={val.EDocPage.EDocPageLink}
                                          IconID={LDLIcons.General_Openfile_Page}/>
                                <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                          newStates={{"BibObjID": val.FigBibObjID, "EDocPageID": val.EDocPage?.EDocPageID}}
                                          IconID={val.EDocPage?.EDocPageID?LDLIcons.Viewer_OpenPage:LDLIcons.Viewer_OpenFile}/>
                            </> : <>&nbsp;&ndash;&nbsp;&ndash;&nbsp;</>}
                        </>} desc={"one or more links to citation data and/or digital files relevant the figure"}/>
                        <EasyTableElement width={"25%"}  title={"Name Used In Work"}
                                          elementFromValue={(val: tblLdlSpCatFigs) => val.FigOgCombCitation}
                                          desc={"the scientific name associated with the figure in the cited publication, usually derived from an associated figure or plate caption or legend; scientific names are corrected to the Neuropterida Species of the World standard for strictly nomenclatural errors (e.g., unavailable misspellings, incorrect capitalization); erroneous, work-specific, scientific name orthographies, if recorded, are shown in parentheses () as “sic” citations; erroneous author and/or date citations are generally not recorded; misidentifications are shown enclosed in square brackets [], usually with brief documentation"}/>
                    </EasyTable>

                </>}
            </Record>}
    </>
};


export default SpeciesFigureRecord;