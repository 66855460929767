import React from "react";

const Contacts = () => {
    return <>

        <div className="text-body">
            <h1>Contacts</h1>
            <p className="header-paragraph-1">
                Inquiries and comments about the <i>Lacewing Digital Library</i> (LDL) web portal (including all
                unattributed components displayed under the LDL banner) may be directed to the Chief Editor. For contact
                information relevant to separately authored/edited LDL publications see the contact information provided
                on the Main pages of those publications. For contact information related to sites linked through the LDL
                web portal, but not displayed under the LDL banner, see the contacts recommendations associated with the
                specific linked site.
            </p>
            <hr/>
            <p className="header-paragraph-2">
                <b>For general inquiries and comments contact:</b>
            </p>
            <h4>
                Chief Editor
            </h4>


            <p className="indented-paragraph">
                <a href="http://entomology.tamu.edu/people/oswald-john/" target="_top">John D. Oswald</a><br/>
                Department of Entomology<br/>
                Texas A&amp;M University<br/>
                College Station, TX 77843-2475<br/>
                E-mail: <a href="mailto:j-oswald@tamu.edu" style={{textDecoration: "none",}}>j-oswald@tamu.edu</a><br/>
                Phone: 979-321-5862
            </p>

        </div>

       </>
};
export default Contacts;