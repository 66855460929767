import React from "react";

const Genus_Acknowledgments = () => {
    return <>

        <div className="content">
            <div className="text-body">
                <h1>Acknowledgments</h1>
                <p style={{textAlign: 'justify'}}>
                    Over the long course of the development of the data for this catalogue it has been my great pleasure
                    to communicate and interact with most of the world's neuropteran systematists who have been active
                    since the early 1980’s. I am indebted to most of them for their freely-given contributions of
                    literature, facts and advice, which have all contributed in some way to the development of this
                    work. The following colleagues have been particularly helpful and supportive over the years as
                    reliable sources of information and advice on topics great and small related to neuropterid
                    taxonomy, nomenclature and cataloging (listed alphabetically by surname): Horst Aspöck, Ulrike
                    Aspöck, Stephen Brooks, Oliver Flint, Herbert Hölzel, Vladimir Makarkin, Mervyn Mansell, Victor
                    Monserrat, Timothy New, Michael Ohl, Norman Penny, Lionel Stange and György Sziráki.
                    Vladimir Makarkin has provided especially invaluable assistance in matters concerning fossil
                    neuropterids and Russian literature. As an evolutionary extension of the "Genus-group names of the
                    Neuroptera, Megaloptera and Raphidioptera of the World" (Oswald &amp; Penny 1991), I would like to
                    acknowledge the contributions of Norm Penny to the data underlying this derivative work.
                </p>
                <p style={{textAlign: 'justify'}}>---acknowledge Spanish guy who provided input on etymologies of some
                    Navas names?</p>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}><b><i>Graduate Students</i></b></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Several graduate students have provided valuable
                    assistance in developing the web interface of the <i>Neuropterida Genera of the World</i> site. Most
                    of the graphical and design elements of the interface were initially deveolped by Joshua Jones
                    (entomology). Non-public beta implementations of the site were subsequently produced by Joshua and
                    Krishna Tadi (computer engineering). Pratheek Akireddy (computer science) later provided additional
                    development effort and was primarily responsible for release of the current public version of the
                    site. Joshua played an important role in helping to conceptualize the organization and presentation
                    of page and data elements for the interface. Krishna and Pratheek were responsibile for the more
                    technical aspects of site networking, ColdFusion scripting associated with the site's searching and
                    reporting functionality, and back-end database development and maintenance. </p>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}><strong><em>Funding</em></strong></p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Final development and web delivery of version 1.0
                    of the <i>Neuropterida Genera of the World</i> site was supported in part by the U.S. <a
                        href="http://www.nsf.gov/" target="_top">National Science Foundation (NSF)</a> through the <a
                        href="http://www.nsf.gov/div/index.jsp?div=DEB" target="_top">Division of Environmental Biology
                        (DEB)</a> program <a
                        href="http://www.nsf.gov/funding/pgm_summ.jsp?pims_id=5451&amp;org=DEB&amp;from=home"
                        target="_top">Partnerships for Enhancing Expertise in Taxonomy (PEET)</a>, grant #0933588. The
                    National Science Foundation funds research and education in most fields of science and engineering.
                    Grantees are wholly responsible for conducting their project activities and preparing the results
                    for publication.&nbsp;&nbsp;Thus, the Foundation does not assume responsibility for such findings or
                    their interpretation.</p>
                <p style={{textAlign: 'justify'}}>General in-kind support for the <i>Neuropterida Genera of the
                    World</i> project has been provided over the years by the <a href="http://entomology.tamu.edu/"
                                                                                 target="_top">Department of
                    Entomology</a> of <a href="http://www.tamu.edu/" target="_top">Texas A&amp;M University</a>, <a
                    href="http://taes.tamu.edu/" target="_top">Texas AgriLife Research</a> (formerly the Texas
                    Agricultural Experiment Station), and the <a href="http://library.tamu.edu/" target="_top">Texas
                        A&amp;M University Libraries</a>.</p><br/><br/>
            </div>
        </div>
    </>
};
export default Genus_Acknowledgments;