import React, {useEffect, useState} from "react";
import {SearchProp} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {
    CornerIcon,
    DetailsTable,
    EasyTable,
    EasyTableElement,
    Icon,
    IconLink,
    ListElement,
    ListHeader,
    ListHolder,
    Record,
    ResultRecordTableHolder,
    SelectFromArray,
    SplitPageHolder,
    SubTitle,
    TableBody,
    TableCell,
    TableRow, UpdatePathData
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {tblLdlSpCatComb} from "../../../../database/models/tblLdlSpCatComb";
import {tblLdlSpCatCongen} from "../../../../database/models/tblLdlSpCatCongen";
import nameTranslator from "../../utils/nameTranslator";
import LDLAllowedModulesByCountryName from "../../utils/OnServerStartLoadedJsons/LDLAllowedModulesByCountryName.json"
import {tblLdlSpCatDistGeog} from "../../../../database/models/tblLdlSpCatDistGeog";
import _ from "lodash";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";


const WebPageTranslator: {
    [Key: string]: {
        "DisplayName": string,
        "TabName": string,
        "TabDisplay": string,
        "ShortName": string,
        "IconContentRef": number,
        "MainTab": string,
        "SubLinks"?: {
            [Key: string]: {
                "DisplayName"?: string,
                "TabName": string,
                "TabDisplay": string,
                "ShortName"?: string,
                "IconContentRef"?: number,
                "MainTab"?: string
            } | string
        }
    }
} = nameTranslator;

// @ts-ignore
const SpeciesMonoRecord = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();
    const [showingResult, setShowingResult] = useState(false);
    const [resultData, setResultData] = useState<tblLdlSpCatComb>()
    const [searchProps, setSearchProps] = useState<SearchProp[]>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    const currentPath = location.pathname;

    useEffect(() => {
        callAPI("/api/general/getSpecCombPk", {CombObjID: _props["CombObjID"]}, (error: boolean, outData: tblLdlSpCatComb | Response) => {
            if (error) {
                console.log(error);
            } else if (!(outData instanceof Response)) {
                console.log(outData)
                setResultData(outData);
                console.log(_props,outData)
                if(outData) {
                    setCurrentPathData((prev)=>UpdatePathData({MonographRecord:{UpperLeftModuleName:<>Monograph Record &ndash; <i>{outData.ASCurrGenu} {outData.ASCurrSpec} {outData.ASCurrSubs != "$" && outData.ASCurrSubs}</i></>}},prev))
                }
            }
        })
    }, [location]);
    return <>
        {resultData &&
            <Record>
                <div style={{marginLeft:"2.5vw",marginTop:"0.5em",display:"table",float:"right"}}>
                    {resultData.SpCat?.HCIsNida ==-1&&<EasyTable LegendleftItems={<>{(()=>{
                        const counts = {spec:0,fspec:0,subs:0,fsubs:0};
                        if(resultData.GenCombList){
                            resultData.GenCombList.forEach((val)=>{
                                const len = val.SpecSubs?val.SpecSubs.length-1:0;
                                if(val.SpecSubs?.endsWith("sp.)")){
                                    if(val.SpecSubs[len-4]=="b"){
                                        if(val.SpecSubs[len-7]=="†"){
                                            counts.fsubs++;
                                            counts.subs++;
                                        }else{
                                            counts.subs++;
                                        }
                                    }else if(val.SpecSubs[len-4]=="†"){
                                        counts.fspec++;
                                        counts.spec++;
                                    }else{
                                        counts.spec++;
                                    }
                                }
                            })
                        }
                        return [(!!counts.spec?`${counts.spec} sp.${!!counts.fspec?` [${counts.fspec}†]`:''}`:''),(!!counts.subs?` ${counts.subs} ssp.${!!counts.fsubs?` [{counts.fsubs}†]`:''}`:'')].join(';')
                    })()}</>} defaultSelectedIndex={resultData.GenCombList?.findIndex((val)=>val.PresCombObjID==resultData.CombObjID)} maxHeight={"143px"} width={"225px"} tableRowData={resultData.GenCombList ? resultData.GenCombList : []}>
                        <EasyTableElement paddingTop={"0px"} paddingBottom={"0px"} noBorderTop={true} title={<i>{resultData.GenCombList?.at(0)?.GenuName}</i>} elementFromValue={(val: tblLdlSpCatCongen)=><><IconLink newWindow={true} href={"/SpeciesCatalog/MonographRecord"}
                                                                                                                                                                                                                       IconID={LDLIcons.NSW_MonographicRecord}
                                                                                                                                                                                                                       newStates={{"CombObjID": val.PresCombObjID}}/> <i>{val.SpecSubsName}</i>{val.SpecSubs}</>}/>
                    </EasyTable>}

                    <div style={{width:"fit-content",position:"relative"}}>

                        <img style={{border:"1px solid black",marginTop:"20px"}} src={"http://lacewing.tamu.edu/neuropterida/neur_dir/photos/NeuropteristsDirectoryImageNotAvailable.jpg"}/>
                        <p style={{textAlign:"left",marginTop:"5px"}}>[photographer]</p>

                    </div>
                </div>
                        <h1 style={{textAlign:"left"}}><>{(resultData.ASExtinct == "-1") &&
                            <b>&dagger;</b>}<i><b>{resultData.ASCurrGenu} {resultData.ASCurrSpec} {resultData.ASCurrSubs != "$" && resultData.ASCurrSubs}</b></i> {resultData.CurrCombAuthDate}</> <><IconLink IconID={LDLIcons.General_PrintOrSave}  href={"javascript:window.print()"}/> <IconLink
                            href={"mailto:j-oswald@tamu.edu?subject=Neuropterida Species of the World: comment/correction for Monograph Record page \"" + resultData.ASCurrGenu + " " + resultData.ASCurrSpec + (resultData.ASCurrSubs != "$" ? (" " + resultData.ASCurrSubs) : "") + " " + resultData.CurrCombAuthDate + "\""}
                            IconID={LDLIcons.General_Email}
                            HistoryLocStates={location.state}
                        /></></h1>
                        <SubTitle align={"left"}><>({resultData.SpCat?.SRClas})</>
                        </SubTitle>
                <br/>
                {(resultData.TaxonCites?.length != 0 ||resultData.FigureCites?.length != 0||resultData.TerminalTaxon?.length != 0||resultData.PhyloTrees?.length != 0)&&<ListHolder>
                            <ListElement title={"Monograph Record Links"} element={<>
                                {resultData.TaxonCites?.length != 0 &&
                                    <IconLink href={"./Taxa"} IconID={LDLIcons.NSW_TaxonCitations}
                                              HistoryLocStates={location.state}
                                              newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                                {resultData.FigureCites?.length != 0 &&
                                    <IconLink href={"./Figure"} IconID={LDLIcons.NSW_FigureCitations}
                                              HistoryLocStates={location.state}
                                              newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                                {resultData.TerminalTaxon?.length != 0 &&
                                    <IconLink href={"./Keys"} IconID={LDLIcons.NSW_IdentificationKeys} HistoryLocStates={location.state}
                                              newStates={{"CombObjID": resultData.CombObjID}}/>}&nbsp;
                                {resultData.PhyloTrees?.length != 0 &&
                                    <IconLink href={"./PhylogeneticTree"} IconID={LDLIcons.NSW_PhylogeneticTrees}
                                              HistoryLocStates={location.state}
                                              newStates={{"CombObjID": resultData.CombObjID}}/>}</>}/>
                        </ListHolder>}
                        <ListHeader title={"Taxonomy"}>
                            <ListElement title={"Classification"} element={"LDL Standard Taxonomy"}/>
                            <ListElement title={"Taxonomic Status"} element={resultData.SpCat?.SRStat}/>
                            <ListElement title={"Taxonomic Notes"} element={resultData.SpCat?.SynObjNotesTaxPublic}/>
                        </ListHeader>
                    <ListHeader title={"Scientific Names"}>
                        <ListElement title={"Short Form"} element={<>
                            <i>{resultData.ASCurrGenu} {resultData.ASCurrSpec} {resultData.ASCurrSubs != "$" && resultData.ASCurrSubs}</i> {resultData.CurrCombAuthDate} {resultData.CombLParen == "(" && <>[<i>{resultData.ASOrigGenu}</i>]</>}</>}/>
                        <ListElement title={"Long Form"} element={<>
                            <i>{resultData.CurrCombTaxonName}</i> {resultData.CurrCombAuthDate} {resultData.CombLParen == "(" && <>[<i>{resultData.ASOrigGenu}</i>]</>}</>}/>
                        <ListElement title={<>Original Combination (species – {resultData.ASCurrSpec})</>}
                                     element={<>
                                         <i>{resultData.OrigSpCat?.OrigCombName}</i> {resultData.OrigSpCat?.OrigCombAuthYear} {resultData.OrigSpCat?.OrigCombCite}
                                         &nbsp;<IconLink newWindow={true} href={"/Biblio/Record"}
                                                   newStates={{"BibObjID": resultData.OrigSpCat?.OrigCombBibObjID}}
                                                   IconID={LDLIcons.BotN_BibliographicRecord}/>
                                         {resultData.OrigSpCat?.EDoc && (resultData.OrigSpCat?.EDoc.PublicEDoc || verifiedUser && resultData.OrigSpCat?.EDoc.PrivateEDoc == -1) &&
                                             <><IconLink newWindow={true} href={resultData.OrigSpCat?.EDoc.EDocLink}

                                                         IconID={LDLIcons.General_OpenFile_Work}/>
                                                 <IconLink newWindow={true} href={"/PageViewer/Pages"}
                                                           newStates={{"BibObjID": resultData.OrigSpCat?.OrigCombBibObjID}}
                                                           IconID={LDLIcons.Viewer_OpenFile}/>
                                             </>} [{resultData.OrigSpCat?.OrigCombVer}]</>}/>
                        <ListElement title={"Nomenclatural Gender Forms (species)"} element={<>
                            <i>{resultData.OrigSpCat?.GendFormMasc}</i> (masculine), <i>{resultData.OrigSpCat?.GendFormFem}</i> (feminine), <i>{resultData.OrigSpCat?.GendFormNeut}</i> (neuter) {resultData.OrigSpCat?.GendFormChecked == -1 ? "[verified]" : "[working, not verified]"}</>}/>
                    </ListHeader>
                        <SelectFromArray width={"330px"} defaultSelectedIndex={resultData.CombAllSynonyms?.length ? 0 : 1}
                                         arrayInput={["Show All Synonyms", "Hide Synonyms", "Show Original Combination Synonyms Only", "Show Synonyms with Available Names Only", "Show Synonyms with Unavailable Names Only"]}
                                         selectRenderAbove={(val, index) => {
                                             let numDisplay = resultData.CombAllSynonyms?.length;
                                             switch (index) {
                                                 case 1:
                                                     numDisplay = 0;
                                                     break;
                                                 case 2:
                                                     numDisplay = resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1).length;
                                                     break;
                                                 case 3:
                                                     numDisplay = resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.ASAvail == "-1").length;
                                                     break;
                                                 case 4:
                                                     numDisplay = resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.ASUnavail == "-1").length;
                                             }

                                             return <i>Synonyms
                                                 ({resultData.CombAllSynonyms?.length} total; {numDisplay} displayed).  </i>
                                         }}
                                         selectRender={(val, index) => {
                                             if (val != "Hide Synonyms") {
                                                 let inData = resultData.CombAllSynonyms;
                                                 switch (index) {
                                                     case 2:
                                                         inData = inData?.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1);
                                                         break;
                                                     case 3:
                                                         inData = inData?.filter((val: tblLdlSpCatComb) => val.ASAvail == "-1");
                                                         break;
                                                     case 4:
                                                         inData = inData?.filter((val: tblLdlSpCatComb) => val.ASUnavail == "-1");

                                                 }

                                                 return <ListHolder><ResultRecordTableHolder width={"max-content"} maxWidth={"90vw"}  maxHeight={"187px"} noBorder={true}><DetailsTable>
                                                     <TableBody tableRowData={inData ? inData : []}
                                                                rowRender={(val: tblLdlSpCatComb, index,a,f,selected,c,setC) => {
                                                                    return <TableRow selected={selected} onClick={()=>{
                                                                        if (setC) {
                                                                            setC(index)
                                                                        }}}><TableCell paddingTop={"0px"}
                                                                                       paddingBottom={"0px"}
                                                                                       noBorderTop={true}

                                                                                       element={<>
                                                                                           <i>{val.CombGenuName}{val.CombSubgName != "$" && <> ({val.CombSubgName})</>}{val.CombSpgrName != "$" && <> ({val.CombSpgrName})</>} {val.CombSpecName}</i>{val.CombSubsRankAbbrev != "$" && <> {val.CombSubsRankAbbrev}</>}
                                                                                           <i>{val.CombSubsName != "$" && <> {val.CombSubsName}</>}</i>{val.CombInfrRankAbbrev != "" && <> {val.CombInfrRankAbbrev}</>}
                                                                                           <i>{val.CombInfrRankAbbrev != "" && <> {val.CombInfrName}</>}</i>{val.CombSubiRankAbbrev != "" && <> {val.CombSubiRankAbbrev}</>}
                                                                                           <i>{val.CombSubiRankAbbrev != "" && <> {val.CombSubiName}</>}</i> {val.LParenToRParen}{val.IsOrigComb == -1 && <> r#{val.LeastNomObjBibObjPage}
                                                                                           &nbsp;<IconLink newWindow={true}
                                                                                               href={"/Biblio/Record"}
                                                                                               newStates={{"BibObjID": val.ASBibObjNum}}
                                                                                               IconID={LDLIcons.BotN_BibliographicRecord}/>
                                                                                           {(val.EDoc && val.EDoc.EDocLink && (val.EDoc.PublicEDoc || verifiedUser && val.EDoc.PrivateEDoc == -1)) &&
                                                                                               <><IconLink newWindow={true}
                                                                                                   href={val.EDoc.EDocLink}
                                                                                                   IconID={LDLIcons.General_OpenFile_Work}/><IconLink newWindow={true}
                                                                                                   href={"/PageViewer/Pages"}
                                                                                                   newStates={{"BibObjID": val.ASBibObjNum}}
                                                                                                   IconID={LDLIcons.Viewer_OpenFile}/></>} [{val.CombFullDate}] </>}</>}/></TableRow>;
                                                                }}/>
                                                 </DetailsTable></ResultRecordTableHolder></ListHolder>
                                             } else {
                                                 return <></>
                                             }
                                         }
                                         } optionRender={(val) => val}/>

                        {(resultData.TaxonCites?.length != 0 ||resultData.FigureCites?.length != 0||resultData.TerminalTaxon?.length != 0||resultData.PhyloTrees?.length != 0)&&<ListHolder>
                            <ListElement title={"Literature"} element={<><>{resultData.TaxonCites?.length != 0 && <>
                                <IconLink href={"./Taxa"} IconID={LDLIcons.NSW_TaxonCitations} HistoryLocStates={location.state}
                                          newStates={{"CombObjID": resultData.CombObjID}}/><i> Taxon Citations ({resultData.TaxonCites?.length}).</i>
                            </>}
                                {resultData.FigureCites?.length != 0 && <> <IconLink href={"./Figure"} IconID={LDLIcons.NSW_FigureCitations}
                                                                                     HistoryLocStates={location.state}
                                                                                     newStates={{"CombObjID": resultData.CombObjID}}/><i> Figure Citations ({resultData.FigureCites?.length}).</i>
                                </>}
                                {resultData.TerminalTaxon?.length != 0 && <> <IconLink href={"./Keys"} IconID={LDLIcons.NSW_IdentificationKeys} HistoryLocStates={location.state}
                                                                                       newStates={{
                                                                                           "CombObjID": resultData.CombObjID
                                                                                       }}/><i> Identification Keys ({resultData.TerminalTaxon?.length}).</i>
                                </>}
                                {resultData.PhyloTrees?.length != 0 && <> <IconLink href={"./PhylogeneticTree"} IconID={LDLIcons.NSW_PhylogeneticTrees}
                                                                                    HistoryLocStates={location.state}
                                                                                    newStates={{"CombObjID": resultData.CombObjID}}/><i> Phylogenetic Trees ({resultData.PhyloTrees?.length}).</i>

                                </>}</></>}/>
                        </ListHolder>}

                        <ListHeader title={"Geographic Distribution"}>
                            <ListElement title={"Summary Statement"} element={resultData.SpCat?.SRDist}/>
                            <ListElement title={"Distribution Notes"} element={resultData.SpCat?.SynObjNotesDistGeogPublic}/>
                            <SelectFromArray displaySelect={!!(resultData.DistGeos?.length)} selectRenderAbove={(val, index) => {
                                let totalLen=0
                                let arrayLen: number | undefined = totalLen
                                if(index==1) {
                                    arrayLen = resultData.DistGeos?resultData.DistGeos?.filter((value: tblLdlSpCatDistGeog, index: number, array: tblLdlSpCatDistGeog[]) => {
                                        return array.findIndex((val: tblLdlSpCatDistGeog) => _.isEqual({...val, ...{DistGeogID: 0}}, {...value, ...{DistGeogID: 0}})) == index;
                                    }).length:0;
                                    totalLen=arrayLen
                                } else if(index==0){

                                        arrayLen = resultData.DistGeos?resultData.DistGeos?.filter((value: tblLdlSpCatDistGeog, index: number, array: tblLdlSpCatDistGeog[]) => {
                                            return array.findIndex((val: tblLdlSpCatDistGeog) => (val.DistGeogCountry == value.DistGeogCountry)) == index;
                                        }).length:0;
                                    totalLen=arrayLen
                                }else if(index==2){
                                    arrayLen=0
                                    totalLen=resultData.DistGeos?resultData.DistGeos?.filter((value: tblLdlSpCatDistGeog, index: number, array: tblLdlSpCatDistGeog[]) => {
                                        return array.findIndex((val: tblLdlSpCatDistGeog) => (val.DistGeogCountry == value.DistGeogCountry)) == index;
                                    }).length:0;
                                }
                                return <i>Country Records ({totalLen} total; {arrayLen} displayed).  </i>;
                            }} defaultSelectedIndex={resultData.DistGeos?.length ? 0 : 2}
                                             arrayInput={["Show Countries (no duplicates or sources)", "Show Country Records (with duplicates and sources)", "Hide List"]}
                                             selectRender={(val, index) => {
                                                 let array: any[] | undefined = [];
                                                 switch (index) {
                                                     case 0:
                                                         array = resultData.DistGeos?.filter((value: tblLdlSpCatDistGeog, index: number, array: tblLdlSpCatDistGeog[]) => {
                                                             return array.findIndex((val: tblLdlSpCatDistGeog) => (val.DistGeogCountry == value.DistGeogCountry)) == index;
                                                         });
                                                         break;
                                                     case 1:
                                                         array = resultData.DistGeos?.filter((value: tblLdlSpCatDistGeog, index: number, array: tblLdlSpCatDistGeog[]) => {
                                                             return array.findIndex((val: tblLdlSpCatDistGeog) => _.isEqual({...val, ...{DistGeogID: 0}}, {...value, ...{DistGeogID: 0}})) == index;
                                                         });
                                                 }

                                                 return <>{(index == 0) &&
                                                     <EasyTable maxHeight={"160px"} width={"400px"} tableRowData={array ? array : []}>
                                                         <EasyTableElement noBorderTop={true} paddingTop={"0px"} paddingBottom={"0px"} align={"center"} width={"10%"} title={"Fauna"}
                                                                           elementFromValue={(val: tblLdlSpCatDistGeog) => {

                                                                               //@ts-ignore
                                                                               const Module:tblLdlModule=LDLAllowedModulesByCountryName[val.DistGeogCountry]

                                                                               if (Module&&Module.MainIconID&&(Module.ShowLdlReactPublic==-1||(Module.ShowLdlReactDemo==-1&&!isPublic))) {
                                                                                   return <IconLink newWindow={true}
                                                                                                    href={"/" + Module.LdlModuleReference+ "/Main"}
                                                                                                    IconID={Module.MainIconID}
                                                                                   />
                                                                               }
                                                                               return <></>;
                                                                           }}/>
                                                         <EasyTableElement noBorderTop={true} paddingTop={"0px"} paddingBottom={"0px"} width={"90%"} title={"Country"}
                                                                           elementFromValue={(val) => val.DistGeogCountry}/>

                                                     </EasyTable>}
                                                     {(index == 1) &&
                                                         <EasyTable maxHeight={"290px"} maxWidth={"max-content"} tableRowData={array ? array : []}>
                                                             <EasyTableElement align={"center"} width={"0%"} title={"Fauna"}
                                                                               elementFromValue={(val) => {

                                                                                   //@ts-ignore
                                                                                   const Module:tblLdlModule=LDLAllowedModulesByCountryName[val.DistGeogCountry]

                                                                                   if (Module&&Module.MainIconID&&(Module.ShowLdlReactPublic==-1||(Module.ShowLdlReactDemo==-1&&!isPublic))) {
                                                                                       return <IconLink newWindow={true}
                                                                                                        href={"/" + Module.LdlModuleReference+ "/Main"}
                                                                                                        IconID={Module.MainIconID}
                                                                                       />
                                                                                   }
                                                                                   return <></>;
                                                                               }}/>
                                                             <EasyTableElement width={"20%"} title={"Country"}
                                                                               elementFromValue={(val) => val.DistGeogCountry}
                                                                               desc={"the name of a country from which the taxon has been reported"}/>
                                                             <EasyTableElement width={"30%"} title={"Source"}
                                                                               elementFromValue={(val) => val.DistGeogPubCite}
                                                                               desc={"a bibliographic citation to the source of the country record"}/>
                                                             <EasyTableElement width={"10%"} title={"Page/Plate"}
                                                                               elementFromValue={(val) => val.DistGeogPubPgPl}
                                                                               desc={"the designation for the page or plate on which the distribution record appears; clarification and disambiguation information may be given in square brackets []"}/>
                                                             <EasyTableElement width={"10%"} title={"Links"}
                                                                               elementFromValue={(val: tblLdlSpCatDistGeog) => {
                                                                                   return <><IconLink href={"/Biblio/Record"}
                                                                                                      newStates={{"BibObjID": val.DistGeogBibObjID}}
                                                                                                      IconID={LDLIcons.BotN_BibliographicRecord}/>&nbsp;
                                                                                       {(val.EDoc && val.EDoc.EDocLink && (val.EDoc.PublicEDoc || verifiedUser && val.EDoc.PrivateEDoc == -1)) ?
                                                                                           <IconLink href={val.EDoc.EDocLink}

                                                                                                     IconID={LDLIcons.General_OpenFile_Work}/> : <> – </>}&nbsp;{(val.EDocPage && val.EDocPage.EDocPageLink && (val.EDocPage.PublicEDocPage || verifiedUser && val.EDocPage.PrivateEDocPage == -1)) ?
                                                                                           <IconLink
                                                                                               href={val.EDocPage.EDocPageLink}

                                                                                               IconID={LDLIcons.General_Openfile_Page}/> : <> – </>}</>
                                                                               }}
                                                                               desc={"a link to a (usually complete) digital copy of the publication containing the country record, where available and permissible."}/>
                                                             <EasyTableElement width={"20%"} title={"Name Used in Work"}
                                                                               elementFromValue={(val) => val.DistGeogWorkCombCitation}
                                                                               desc={"the scientific name associated with the distribution record in the cited publication; scientific names are corrected to the Neuropterida Species of the World standard for strictly nomenclatural errors (e.g., unavailable misspellings, incorrect capitalization); erroneous, work-specific, scientific name orthographies, if recorded, are shown in parentheses () as “sic” citations; erroneous author and/or date citations are generally not recorded; misidentifications are shown enclosed in square brackets []"}/>
                                                         </EasyTable>}</>
                                             }} optionRender={(val) => val}/>
                        </ListHeader>





                <ListHeader title={"Lithostratigraphic Distribution"}>
                    <ListElement title={"Summary Statement"} element={resultData.SpCat?.SRStrat}/>
                </ListHeader>
                <ListHeader title={"Temporal Distribution"}>
                    <ListElement title={"Extant / Extinct"} element={resultData.SpCat?.CNExtant}/>
                    <ListElement title={"Temporal Range"} element={resultData.SpCat?.SRTempRang}/>
                </ListHeader>
                <SelectFromArray displaySelect={!!(resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1||(val.CombObjID==resultData.CombObjID)).length)} selectRenderAbove={(val, index) => {
                    let len = resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1).length;
                    if(len==0){ len = resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1||(val.CombObjID==resultData.CombObjID)).length};
                    return <i> Types ({len} total; {index ? 0 : len} displayed).  </i>
                }} defaultSelectedIndex={(resultData.CombAllSynonyms?.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1||(val.CombObjID==resultData.CombObjID)).length)?0:1} arrayInput={["Show All Types", "Hide Types"]}
                                 selectRender={(val, index) => {
                                     if (index == 0) {
                                         let dataFilter=resultData.CombAllSynonyms?resultData.CombAllSynonyms.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1):[]
                                         return <EasyTable maxHeight={"132px"} width={"480px"} maxWidth={"94vw"} tableRowData={resultData.CombAllSynonyms ?(dataFilter.length!=0?dataFilter:resultData.CombAllSynonyms.filter((val: tblLdlSpCatComb) => val.IsOrigComb == -1||(val.CombObjID==resultData.CombObjID))).sort((val1, val2)=>{
                                             const sortStringA=`${(val1.CombSubiName&&val1.CombSubiName != "$"?val1.CombSubiName:(val1.CombInfrName&& val1.CombInfrName != "$"?val1.CombInfrName:(val1.CombSubsName&&val1.CombSubsName!='$'?val1.CombSubsName:(val1.CombSpecName&&val1.CombSpecName!='$'?val1.CombSpecName:'none')))).replace("*","")} ${val.LParenToRParen} `;
                                             const sortStringB=`${(val2.CombSubiName&&val2.CombSubiName != "$"?val2.CombSubiName:(val2.CombInfrName&& val2.CombInfrName != "$"?val2.CombInfrName:(val2.CombSubsName&&val2.CombSubsName!='$'?val2.CombSubsName:(val2.CombSpecName&&val2.CombSpecName!='$'?val2.CombSpecName:'none')))).replace("*","")} ${val.LParenToRParen} `;
                                             console.log(sortStringA,sortStringB)
                                             return sortStringA.localeCompare(sortStringB)
                                         }) : []}>
                                             <EasyTableElement   noBorderTop={true} paddingTop={"0px"} paddingBottom={"0px"} title={"Epithet Record"} elementFromValue={(val:tblLdlSpCatComb)=>{
                                                 let SelectedAtts = {att: "none", SpecNum: "0"}
                                                 //console.log(val)
                                                 if (val.CombSubiTaxObjNum && val.CombSubiName && val.CombSubiName != "$") {
                                                     SelectedAtts = {
                                                         att: val.CombSubiName,
                                                         SpecNum: val.CombSubiTaxObjNum
                                                     }
                                                 } else if (val.CombInfrName && val.CombInfrTaxObjNum && val.CombInfrName != "$") {
                                                     SelectedAtts = {
                                                         att: val.CombInfrName,
                                                         SpecNum: val.CombInfrTaxObjNum
                                                     }
                                                 } else if (val.CombSubsName && val.CombSubsTaxObjNum && val.CombSubsName != "$") {
                                                     SelectedAtts = {
                                                         att: val.CombSubsName,
                                                         SpecNum: val.CombSubsTaxObjNum
                                                     }
                                                 } else if (val.CombSpecName && val.CombSpecTaxObjNum) {
                                                     SelectedAtts = {
                                                         att: val.CombSpecName,
                                                         SpecNum: val.CombSpecTaxObjNum
                                                     }
                                                 }
                                                 return <>
                                                     <IconLink href={"./Record"}
                                                               IconID={LDLIcons.NSW_EpithetRecord}
                                                               HistoryLocStates={location.state}
                                                               newStates={{"SpecNum": SelectedAtts.SpecNum}}/>
                                                     <i> {SelectedAtts.att}</i>
                                                     <> {val.LParenToRParen} </>
                                                     <> [<i>{val.CombGenuName} {val.CombSpecName}{val.CombSubsName != "$" && <> {val.CombSubsName}</>}</i>{val.CombInfrName != "$" && <> {val.CombInfrRankAbbrev}</>}<i>{val.CombInfrName != "$" && <> {val.CombInfrName}</>}</i>{val.CombSubiName != "$" && <> {val.CombSubiRankAbbrev}</>}<i>{val.CombSubiName != "$" && <> {val.CombSubiName}</>}</i>]
                                                     </>
                                                 </>
                                             }}/>
                                         </EasyTable>
                                     }
                                     return <></>
                                 }} optionRender={(val) => val}/>
                <ListHeader title={"Data"}>
                    <ListElement title={"Source"} element={resultData.SpCat?.DSSource}/>
                    <ListElement title={"Timestamp"} element={resultData.SpCat?.DSDateTime}/>
                </ListHeader>
            </Record>}
    </>
};


export default SpeciesMonoRecord;