import React, {useEffect, useRef, useState} from "react";
import {Outlet, useLocation, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../../Shared/_Layout";
import {BottomFooterName} from "../../../utils/CustomRecord";
//import "../../../Content/Keys/Keys.css"

const _OldKeysLayout = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();
    const location = useLocation();
    const currentPath = location.pathname;
    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);
    return <>

        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}><main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties} role="main">

                <Outlet context={[[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],<></>,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]]}></Outlet>
            </main></td>
        </tr>
        </tbody>


        <tfoot ref={footerRef} id={"mainFooter"}>
        <tr>
            <td colSpan={2}>
            <nav>
                <span><BottomFooterName currentPathData={currentPathData}/>  </span>
                <a href="Main" target="_top">Main </a>
                | <a href="Introduction" target="_top">Introduction </a>
                | <a href="Acknowledgments" target="_top">Acknowledgments </a>
                | <a href="About" target="_top">About </a>
                | <a href="Citation" target="_top">Citation </a>
                | <a href="Contacts" target="_top">Contacts </a>
                | <a href="KeyInformation" target="_top">KI </a>
                | <a href="Help" target="_top">Help</a>
            </nav>
            </td>
        </tr>
        </tfoot>
    </>
};
// @ts-ignore
export default _OldKeysLayout;