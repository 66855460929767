import React from "react";

const Vision = () => {
    return (<>

        <div className="text-body">
            <h1>Vision</h1>
            <p>
                The past several decades have witnessed a revolution in digital technologies that have transformed the
                world, and with it the science of systematics. The new digital technologies developed during this time
                have forever altered the ways in which systematists capture, store, manage, analyze and disseminate
                information about the earth's biota. The vision of the Lacewing Digital Library (LDL) project is to help
                empower the global neuropterological community to harness the promise of the digital revolution and use
                it to advance the science of neuropterology.
                <br/><br/>
                The current generation of systematists, together with partners in computer and other sciences, will
                formulate and develop the digital foundation of systematics and biodiversity for the current century
                using new tools and methods that integrate text, images, maps, video, sounds and other data in ways that
                were not previously possible. To help enable neuropterists to develop a new foundation for systematic
                neuropterology, a key element of the LDL vision is the creation and regular improvement of a persistent,
                digital, multimedia platform that contains a suite of tools and products relevant to advancing the
                science of neuropterology. We envision a web-based platform that is open access—to ensure use without
                regard to time, place or funding; global in scope—to encourage a broad view of the science of
                neuropterology; and community-oriented—designed to serve the needs of, and foster collaboration among,
                the world community of neuropterists. Furthermore, we envision a platform that can support, and provide
                a showcase for, the products of both individual and collaborative research and scholarship on
                neuropterid insects—to encourage the use and growth of the platform as a focal-point for
                neuropterological studies by all interested neuropterists.
                <br/><br/>
                The objectives of the LDL project team are (1) to develop and maintain a web platform for the Lacewing
                Digital Library, the LDL web portal, that can serve as a base for (2) the implementation of a set of
                digital resources (modules) of broad interest and utility to the global neuropterological community
                (bibliography/digital library, taxon catalogues, faunas, neuropterists' directory, digital keys, etc.).
                We invite inquiries from other neuropterists who would like to collaborate in this venture to develop a
                wide-ranging community-resource for our discipline.
                <br/>
            </p>
        </div>

      </>)
};
export default Vision;