import React, {FormEvent, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import callAPI, {callGETAPI} from "../../utils/APIhelpers";

const Migration = (_props: { isPublic: boolean }) => {
    const [user_id, setuser_id] = useState("");
    const [user_pass, setuser_pass] = useState("");
    const navigate = useNavigate();
    const [alert,setAlert]=useState<JSX.Element>();
    useEffect(() => {
        callGETAPI("/api/users/isLoggedIn", (error: boolean, outData: { expires: any, user: any } | Response) => {
            if (error) {
                navigate("/Authenticate/Login");
            } else {
                console.log(outData)
            }
        })
    }, []);

    const handleAuthorize = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        callAPI("/api/users/migrate", {id: user_id, password: user_pass}, (error: boolean, outData: any | Response) => {
            if (error) {
                console.log("error")
                console.log(outData);
                navigate("/Authenticate/LoginFailure")// Set password error to true
            } else {
                /*Cookies.set("username", outData.UserName, {
                    expires: 1 / 24 * 24, // expire in 24 hours.
                });
                Cookies.set("userid", outData.ID, {
                    expires: 1 / 24 * 24, // expire in 24 hours.
                });*/
                if(outData.UserName&&outData.PdfAccess == -1){
                    setAlert(<dialog open={true}>you are now logged into the migration account to login to this account in the future copy new login info from here and save it then click next<br/>new username: <b>{outData.UserName}</b><br/>new password: <b>{outData.Password}</b><br/><Link className={"btn btn-info"} to={"/Authenticate/LoginSuccess"}>next</Link></dialog>)
                }
            }
        })


    }
    return <>


        <div className="content">
            <div className="text-body">
                <h1>Migrate</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    To gain access to PDF+ on the new LDL-Demo site you must put in your old modules login information here while logged into your account that has access to LDL-Demo.
                </p>
                <hr className="rule1" style={{marginBottom: "2em", marginTop: "4px",}}/>
                {alert}
                <form onSubmit={handleAuthorize}>
                    <table style={{border: "0"}} align="center">
                        <tbody>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td align="right" style={{whiteSpace: "nowrap"}} className="DF1row"><b>Username</b>:</td>
                            <td><input style={{height: "29.5px",}} placeholder="Username" type="text" name="user_id"
                                       value={user_id} onChange={(e) => setuser_id(e.target.value)}/></td>
                        </tr>

                        <tr>
                            <td align="right" style={{whiteSpace: "nowrap"}} className="DF1row"><b>Password</b>:</td>
                            <td>
                                <input style={{height: "29.5px",}} placeholder="Password" type="password"
                                       name="user_pass" value={user_pass}
                                       onChange={(e) => setuser_pass(e.target.value)}/>
                            </td>
                        </tr>

                        <tr>

                            <td colSpan={2}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button style={{width: "100px"}} type="submit" className="btn btn-info">Login</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style={{width: "80px", color: "white",}}
                                                                 href="/Authenticate/Login"
                                                                 className="btn btn-info"> Reset</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>

    </>
};
export default Migration;