import React, {useEffect, useState} from "react";
import {proccessSearch, SearchOutputProps} from "../../utils/CustomSearch";
import callAPI from "../../utils/APIhelpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {tblLdlBibNeur} from "../../../../database/models/tblLdlBibNeur";
import {layoutContextType} from "../Shared/_Layout";
import {
    EasyTable,
    EasyTableElement,
    IconLink,
    Record,
    
    SearchCriteria
} from "../../utils/CustomRecord";
import {tblLdlNeurDir} from "../../../../database/models/tblLdlNeurDir";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";


enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface NeurDirSearchProps {
    Sur?: { filter: filterType, Val: string },
    Given?: { filter: filterType, Val: string },
    Mail?: { filter: filterType, Val: string },
    SerEmail?: { filter: filterType, Val: string },
    SerInterests?: { filter: filterType, Val: string },
    useOr?: string
}

const conversionTable = {
    "Last name (family name)": "Sur",
    "First name (given name)": "Given",
    "Address": "Mail",
    "E-mail": "SerEmail",
    "Interests": "SerInterests",
}
const DirectoryResult = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<tblLdlBibNeur[] | any>()
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const _props = location.state as any | undefined;

    useEffect(() => {
        let out: NeurDirSearchProps = Object()

        out = proccessSearch(_props?.searchProps, conversionTable, out as SearchOutputProps);


        callAPI("/api/general/doNeurDirSearch", out, (error: boolean, outData: tblLdlBibNeur[] | Response) => {
            if (error) {
                console.log("error")
            } else {
                setResultData(outData)
                console.log(outData)


            }

        });
    }, []);


    return <>
        {(resultData) && <Record title={"Search Results"}>
            <SearchCriteria searchCriteria={_props?.searchProps}>
            <i>
                <>Results Records ({resultData.length}).</>
            </i>
            </SearchCriteria>
            <EasyTable autoBottomMaxHeight={true} currentBodyHeight={height} currentWindow={currentWindow} tableRowData={resultData}>
                <EasyTableElement minWidth={"121px"} width={"0%"} title={"Profile Image"}
                                  elementFromValue={(val: tblLdlNeurDir) => <>{val.PhotoPath != "http://lacewing.tamu.edu/neuropterida/neur_dir/photos/NeuropteristsDirectoryImageNotAvailable.jpg" ?
                                      <img src={val.PhotoPath} style={{minWidth: "100px"}}/> : <>image not
                                          available</>}</>}/>
                <EasyTableElement valign={"middle"} align={"center"} width={"0%"} title={"Link"}
                                  elementFromValue={(val: tblLdlNeurDir) => <><IconLink href={"./Record"}
                                                                                        newStates={{"AgentID": val.AgentID}}
                                                                                        HistoryLocStates={location.state}
                                                                                        IconID={LDLIcons.General_GoToPage}/></>}/>
                <EasyTableElement valign={"middle"} width={"50%"} title={"Name"}
                                  elementFromValue={(val: tblLdlNeurDir) => <>{val.SerResults1}</>}/>

            </EasyTable>
        </Record>
        }
    </>
};


export default DirectoryResult;