const Dir_Introduction = () => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Introduction</h1>
                <p style={{textAlign: 'justify'}}>
                    The <i>Neuropterists Directory</i> is a queryable, open-access compilation of contact information
                    for individuals
                    world wide who have an interest in the insects of the superorder Neuropterida (orders Neuroptera,
                    Megaloptera, Raphidioptera, and Glosselytrodea).
                    Photos, speciality interests, and basic biographic information are also provided for some
                    individuals.
                    The directory is maintained by an editor and all data are vetted (to the extent possible) prior to
                    incorporation into the directory.
                    Directory data are updated as new information is provided to the editor.
                    For information on how to update an existing directory entry, or how to request to be added to the
                    directory, see the <a href="AddUpdate" target="_top">Add/Update</a> page.
                    It is the policy of the directory to display only information that individuals wish to share
                    publically.
                    Please direct requests to remove particular information, or complete directory entries, to the <a
                    href="AddUpdate" target="_top">editor</a>.
                </p>
            </div>
        </div>

       </>
};
export default Dir_Introduction;