const Dir_Acknowledgments = () => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Acknowledgments</h1>
                <p style={{textAlign: 'justify'}}>
                    The editor would like to thank all who have responded positively to requests for information about
                    themselves
                    —without this cooperation and good will the directory would not be possible.
                    Special thanks are due to the following individuals who have made special contributions to the
                    directory.

                    Cathy Banks helped to enter and format data for early versions of the directory.
                    Norm Penny and Colin Plant provided digital copies of mailing lists that were useful in developing
                    the initial version of the directory back in the mid 1990's.
                    Krishna Tadi and Joshua Jones made important contributions to the design and technical development
                    of the <i>Lacewing Digital Library</i> implementation of the directory.
                </p>
            </div>
        </div>

      </>
};
export default Dir_Acknowledgments;