import React from "react";

const MetaKey_Acknowledgments = () => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Acknowledgments</h1>
                <p style={{textAlign: 'justify'}}>
                    The author thanks Tian-jian Wang, Jiacheng 'Jason' Liu, and Hayoung Jeon for their work on the
                    technical implementation of the WNM project.
                </p>
                <p style={{marginTop: "5px", textAlign: "justify",}}>Initial development of the <i>World Neuropterida
                    Metakey</i> was supported in part by National Science Foundation (NSF) Partnerships for Enhancing
                    Expertise in Taxonomy (PEET) grant #0933588 to the author. The National Science Foundation funds
                    research and education in most fields of science and engineering. Grantees are wholly responsible
                    for conducting their project activities and preparing the results for publication. Thus, the
                    Foundation does not assume responsibility for such findings or their interpretation. </p>

                <p style={{marginTop: "5px", textAlign: "justify",}}>General in-kind support for Lacewing Digital
                    Library projects has been provided over the years by the Department of Entomology of Texas A&M
                    University, Texas A&M AgriLife Research, and the Texas A&M Libraries. </p>
            </div>
        </div>
         </>
};
export default MetaKey_Acknowledgments;