import React from "react";
import CustomSearch, {SearchProp} from "../../utils/CustomSearch";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";

import MetaKeySearchTerms from "../../utils/OnServerStartLoadedJsons/MetaKeySearchTerms.json"


const searchTerms: any = {...MetaKeySearchTerms};

searchTerms.lan.unshift({val: "", dis: "any language"});
searchTerms.taxScope.unshift({val: "", dis: "any Neuropterida taxa"});
searchTerms.geoScope.unshift({val: "", dis: "any geography"});
searchTerms.form.unshift({val: "", dis: "any format"});

enum filterType {
    "contains",
    "is",
    "begins with",
    "ends with"
}

interface MetaKeySearchProps {
    TaxScope0?: { Val: string },
    GeographicScope?: { Val: string },
    sex?: { Val: string },
    stage?: { Val: string },
    Language?: { Val: string },
    Format?: { Val: string },
    PubYear?: { filter: filterType, Val: string },
    Comb0?: { filter: filterType, Val: string },
    KeyTermStatus?: { Val: string },

}

const conversionTable = {
    "Taxonomic Scope": "TaxScope0",
    "Geographic Scope": "GeographicScope",
    "Sex": "sex",
    "Life Stage": "stage",
    "Language": "Language",
    "Format": "Format",
    "Publication Year": "PubYear",
    "Terminal Taxon Name": "Comb0",
    "Search Name Set": "KeyTermStatus"
}
const MetakeySearch = (_props: { advanced?: boolean }) => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const navigate = useNavigate();
    const location = useLocation();
    const savedSearch = location.state as any | undefined;

    const handleSearchResults = (feildData: SearchProp[]) => {
        let statesCopy: any = location.state ? location.state : {};
        statesCopy["searchProps"] = feildData;
        if (_props.advanced) {
            statesCopy["advanced"] = true;
            console.log(statesCopy);
            navigate("/Metakey/TerminalTaxonSearch/SearchResults", {state: statesCopy});
        } else {
            statesCopy["advanced"] = false;
            navigate("/Metakey/ScopeSearch/SearchResults", {state: statesCopy});
        }
    }


    return <>
        {!_props.advanced ?
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Scope Search",
                    sections: [{
                        title: "Scope Parameters", middle: true, feilds: [
                            {
                                sectionName: "Taxonomic Scope",
                                criteria: searchTerms?.taxScope,
                                criteriaVal: "",
                                mediumCriteria: true,
                                description: "(select)"
                            },
                            {
                                sectionName: "Geographic Scope",
                                criteria: searchTerms?.geoScope,
                                criteriaVal: "",
                                mediumCriteria: true,
                                description: "(select)"
                            },
                        ]
                    },
                        {
                            title: "Restriction Parameters", middle: true, feilds: [
                                {
                                    sectionName: "Sex",
                                    criteria: [{val: "", dis: "any sex"}, {
                                        val: "0",
                                        dis: "male (♂ only or ♂ & ♀)"
                                    }, {val: "1", dis: "female (♀ only or ♂ & ♀)"}, {
                                        val: "2",
                                        dis: "male and female (♂ & ♀)"
                                    }],
                                    criteriaVal: "",
                                    mediumCriteria: true,
                                    description: "(select)"
                                },
                                {
                                    sectionName: "Life Stage",
                                    criteria: [{val: "", dis: "any life stage"}, {val: "0", dis: "adult"}, {
                                        val: "1",
                                        dis: "pupa"
                                    }, {val: "2", dis: "larva"}, {val: "3", dis: "egg"}],
                                    criteriaVal: "",
                                    mediumCriteria: true,
                                    description: "(select)"
                                },
                                {
                                    sectionName: "Language",
                                    criteria: searchTerms?.lan,
                                    criteriaVal: "",
                                    mediumCriteria: true,
                                    description: "(select)"
                                },
                                {
                                    sectionName: "Format",
                                    criteria: searchTerms?.form,
                                    criteriaVal: "",
                                    mediumCriteria: true,
                                    description: "(select)"
                                },
                                {
                                    sectionName: "Publication Year",
                                    criteria: ["is before", "is", "is after"],
                                    criteriaVal: "is after",
                                    input: true,
                                    description: "(1500 to current year)"
                                },

                            ]
                        }]
                }}/> :
            <CustomSearch defaultSearchProps={savedSearch?.searchProps} onSearchClick={handleSearchResults} layout={
                {
                    title: "Terminal Taxon Search",
                    sections: [{
                        title: "Scientific Name or Fragment", middle: true, feilds: [
                            {
                                sectionName: "Terminal Taxon Name",
                                criteria: ["is", "begins with", "contains", "ends with"],
                                criteriaVal: "contains",
                                input: true,
                                description: "(e.g., chry, neur, opt, ini)"
                            },
                            {
                                sectionName: "none",
                                description: "(For best results omit the nomenclatural gender ending of the species-group names.)"
                            },
                        ]
                    },
                        {
                            title: "Options", middle: true, feilds: [
                                {
                                    sectionName: "Search Name Set",
                                    criteria: [{val: "2", dis: "Current scientific name"}, {
                                        val: "1",
                                        dis: "Scientific name used in key"
                                    }, {val: "", dis: "Both name sets"}],
                                    criteriaVal: "2",
                                    mediumCriteria: true,
                                    description: "(select)"
                                },
                            ]
                        }
                    ]
                }}/>}

    </>
};


export default MetakeySearch;