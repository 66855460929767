import React from "react";

const DIR_Main = () => {
    return <>

        <div className="content" style={{marginBottom: "80px",}}>
            <div className="text-body" id="wrapper-main">
                <h1>Neuropterists Directory</h1>
                <p style={{textAlign: "center"}}>
                    Editor<br/>
                    <br/>
                    John D. Oswald <br/>
                    Department of Entomology<br/>
                    Texas A&amp;M University<br/>
                    <br/>
                </p>

                <p style={{textAlign: "center"}}>
                    <a style={{color: "white", width: "120px",}} href="Search" className="btn btn-info"
                       target="_top"> Search</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{color: "white", width: "120px",}} href="AddUpdate" className="btn btn-info"
                       target="_top">Add/Update</a>
                </p>


                <p className="style1"
                   style={{textAlign: "center", fontSize: "9px", marginLeft: "695px", marginTop: "445px",}}>
                    © John D. Oswald, 2020. <br/>
                    All rights reserved.
                </p>
            </div>
        </div>
    </>
};
export default DIR_Main;