import React, {useEffect, useRef, useState} from "react";
import "../../Content/HomePage/MainPage.css"
import {useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {BottomFooterName} from "../../utils/CustomRecord";

const MainContent = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);


    return (<>
        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}>
        <main style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties}>
            <div id="greeting">
                <span className="welcome">Welcome</span>
                <span className="welcome-text"> to the Lacewing Digital Library</span>
            </div>

            <div id="intro">
                <p>
                    The <i>Lacewing Digital Library</i> (LDL)
                    is a web portal designed to provide convenient access to a wealth of digital information pertaining
                    to the lacewings,
                    antlions, fishflies, snakeflies and related insects of the superorder Neuropterida (orders
                    Neuroptera, Megaloptera, Raphidioptera and Glosselytrodea). The LDL portal provides access to
                    multiple on-line sources of information about neuropterid insects, world neuropterists,
                    and related topics of interest. To access the resources available through the portal select an item
                    from the menu bar above, or click on one of the buttons below.
                </p>
                <p style={{textAlign: "center", marginTop: "0px"}}>
                    <a style={{width: "100px", color: "white",}} href={"../Biblio/Main"}
                       className="btn btn-info">Bibliography</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "60px", color: "white",}} href={"../SpeciesCatalog/Main"}
                       className="btn btn-info">Species</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "60px", color: "white",}} href={"../Faunas/Main"}
                       className="btn btn-info">Faunas</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px", color: "white",}} href={"../Metakey/Main"}
                       className="btn btn-info">Identification Keys</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "150px", color: "white",}} href={"../HomePage/Publications"}
                       className="btn btn-info">LDL Publication List</a>
                </p>
                <p style={{color: "red", marginTop: "10px",}}>NOTE to users of the <i>Lacewing Digital Library</i></p>
                <p style={{marginTop: "10px",}}>Most of the original content available in the LDL is contained in the
                    publications of the <a href="/HomePage/Publications" target="_top"/><i>Lacewing Digital Library,
                        Research Publication</i> series. Each of these publications is a separate work of scholarship
                    and has distinct authorship. They should be cited individually by author, title, and number to
                    ensure proper attribution and crediting of their scholarly content. In Literature Cited listings,
                    citation of the Lacewing Digital Library as a general web portal should be avoided in cases where
                    citation of individual publications in the <i>Lacewing Digital Library, Research
                        Publication</i> series is more appropriate. In general, publications in the <i>Lacewing Digital
                        Library, Research Publication</i> series should be cited in a manner similar to that used for
                    works traditionally published in monographic-series serials. For additional information on citing
                    LDL publications <a href="/HomePage/Citation" target="_top">click here</a>.</p>

            </div>

        </main>
            </td>
        </tr>
        </tbody>
        <tfoot ref={footerRef} id={"mainFooter"}>
        <tr>

            <td colSpan={2}>
            <nav>
                <span className="module"><BottomFooterName currentPathData={currentPathData}/> </span>
                <a href={"./MainContent"} target="_top">Home </a>
                | <a href={"./Vision"} target="_top">Vision </a>
                | <a href={"./Team"} target="_top">Project Team </a>
                | <a href={"./Citation"} target="_top">Citation </a>
                | <a href={"./Contacts"} target="_top">Contacts </a>
                | <a href={"./Publications"} target="_top">Publications </a>
                |{verifiedUser?<a href={"/Authenticate/LoggedIn"} target="_top"> Logged In </a>:<a href={"/Authenticate/Login"} target="_top"> Log In </a>}|
                {PDFAccess?<text style={{color:"green"}}> PDF+</text>:<text style={{color:"red"}}> PDF{(!isPublic&&verifiedUser)&&<>(<a href={"/Authenticate/Migration"} title={"upgrade using old module login to get new demo-site with PDF+ login"}>upgrade</a>)</>}</text>}
            </nav>
            {/*  2020.10.13 JDO commented out the text line with TAMU links; these duplicate the logo icon links below
                <br />

                <nav className="hosts">
                    <a href="http://entomology.tamu.edu/" target="_top">Hosted by the Department of Entomology</a>
                    | <a href="http://aglifesciences.tamu.edu/" target="_top">College of Agriculture and Life Sciences</a>
                    | <a href="http://www.tamu.edu/" target="_top">Texas A&amp;M University</a>
                </nav>
        */}
            <div id="logo-links">
                <table style={{border: "0"}}>{/* cellspacing="0" cellpadding="3" */}
                    <tr>

                        <td>
                            <div className="logo" id="NSFlogo"><a href="http://www.nsf.gov/" target="_top"><img
                                src={require("../../Content/HomePage/Graphics/BottomBanner/NSF_logo.gif")} width="65"
                                height="65"/></a></div>
                        </td>
                        <td>
                            <div className="logo" id="TAMUlogo"><a href="http://www.tamu.edu/" target="_top"><img
                                src={require("../../Content/HomePage/Graphics/BottomBanner/TAMU-logo.png")} width="150"
                                height="65"/></a></div>
                        </td>
                        <td>
                            <div className="logo" id="coalslogo"><a href="http://aglifesciences.tamu.edu/"
                                                                    target="_top"><img
                                src={require("../../Content/HomePage/Graphics/BottomBanner/COALS-logo.jpg")} width="150"
                                height="45"/></a></div>
                        </td>
                        <td>
                            <div className="logo" id="entlogo"><a href="http://entomology.tamu.edu/" target="_top"><img
                                src={require("../../Content/HomePage/Graphics/BottomBanner/Entomology-logo.jpg")}
                                width="150" height="31"/></a></div>
                        </td>

                    </tr>
                </table>
            </div>
        </td>
        </tr>
        </tfoot>



        </>)
};
export default MainContent;