import React, {useEffect, useState} from "react";
import {
    EasyGraphic,
    EasyGraphicLineGraph,
    EasyTable,
    EasyTableElement,
    Icon,
    ParagraphDesc,
    ParagraphHeader,
    Record
} from "../../utils/CustomRecord";
import {LDLIcons} from "../../utils/OnServerStartLoadedOther/IconsHelp";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import {callGETAPI} from "../../utils/APIhelpers";

const Species_Introduction = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat], isPublic, [onClickFunction, setOnClickFunction], layoutHeader, currentWindow, height, [afterTitle, setAfterTitle], [currentPathData, setCurrentPathData]] = useOutletContext<layoutContextType>();

    const [resultData, setResultData] = useState<{[p: string]: number | number[]}>()
    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any;
    useEffect(() => {
        //out = proccessSearch(_props?.searchProps, conversionTable, out as SearchOutputProps);

        callGETAPI("/api/general/graphic1", (error: boolean, outData: {[p: string]: number | number[]} | Response) => {
            if (error) {
                console.log("error")
            } else if (!(outData instanceof Response)) {
                setResultData(outData)
            }

        });

        /*callAPI("/api/general/doBiblioSearch", out, (error: boolean, outData: tblLdlBibNeur[] | Response) => {
            if (error) {
                console.log("error")
            } else {
                setResultData(outData)
            }

        });*/

    }, []);

    return <Record title={"Introduction"}>



                <ParagraphDesc>The&nbsp;<i>Neuropterida</i> <i>
                    Species of the World</i>&nbsp;(NSW) is a systematic catalogue and developing
                    monograph of the world species and subspecies of the insect orders Neuroptera (lacewings, antlions, owlflies,
                    and their allies), Megaloptera (fishflies and Dobsonflies), Raphidioptera
                    (snakeflies), and Glosselytrodea (glosselytrodeans),
                    which are treated collectively as the superorder Neuropterida.
                </ParagraphDesc>

                <ParagraphDesc>The NSW documents, integrates, and synthesizes a wide
                    variety of systematics-related information from thousands of primary research
                    articles, books, and book chapters published primarily from 1758 to present. In
                    addition to the primary scientific literature, the NSW builds on the corpus of regional
                    synopses, checklists, catalogues, and monographs (see&nbsp;<a href="#Classification">Classification</a>&nbsp;below)
                    that have been published over the past several decades. With respect to the
                    cataloguing of basic taxonomic and nomenclatural data, the NSW may be viewed as
                    the successor to Herman Hagen’s 1866 “Hemerobidarum
                    Synopsis synonymica” (for the Neuroptera),
                    to Califre Martins et al.’s 2022 “A world checklist
                    of extant and extinct species of Megaloptera” (for the Megaloptera), and to Horst
                    Aspöck et al.’s 1991 “Die Raphidiopteren
                    der Erde” (for the Raphidioptera),
                    which represent the last fixed-format works in which the world species of these
                    orders were comprehensively catalogued. The <i>Neuropterida</i><i>
                        Species of the World</i> is the only modern work that contains comprehensive
                    species-level coverage of all three orders on a global scale, and the only work
                    that includes both extant and extinct taxa at the species level across all
                    three ordinal domains. A global species accumulation curve for the world Neruopterida is shown in Figure 00.</ParagraphDesc>

                <ParagraphDesc>With respect to species-level monography of the Neuropterida, the Monograph Record pages added to the NSW
                    in version 6.0 continue to provide a platform for expanding data delivery to
                    new datasets, and support ongoing efforts to develop a comprehensive,
                    interactive, and interconnected synthesis of current knowledge of insects in the
                    superorder Neuropterida.</ParagraphDesc>

                <ParagraphDesc>Since 2008, data derived from the NSW has been used as the
                    primary global species database for the Neuropterida
                    segment of the&nbsp;<a href="http://www.catalogueoflife.org/" target="_blank">Catalogue
                        of Life</a>, the most comprehensive and authoritative global index to the
                    world’s species, and an international resource that is playing an increasingly
                    central role in the efforts of many national and international programs and
                    organizations to integrate and disseminate planetary biodiversity information.</ParagraphDesc>

                <ParagraphDesc>Additional information on several aspects of NSW content and
                    development is given below. For comparative notes on the current and past
                    versions of the NSW, including summary information on the numbers of taxa
                    treated, and the dates that particular functionality improvements were
                    introduced, see the&nbsp;<a href="./Versions">Versions</a>&nbsp;page.</ParagraphDesc>

                <ParagraphHeader title={"Catalogue Organization"}/>

                <ParagraphHeader title={"Primary Page Sets"}/>

                <ParagraphDesc>Data delivery in the NSW is organized around two fundamental
                    page sets: Epithet Records (<Icon IconID={LDLIcons.NSW_EpithetRecord}  />) and Monograph Records (<Icon IconID={LDLIcons.NSW_MonographicRecord} />). Epithet Record pages aggregate
                    data on the basis of individual species-group epithets.
                    These pages are designed to deliver data primarily in the domain of 'strict
                    nomenclature', particularly data on epithet availability status and
                    nomenclatural types. Monograph Record pages aggregate data by valid taxon names.
                    These pages are designed to deliver data organized taxonomically based on the
                    LDL Standard Taxonomy. The two sets of pages are closely interlinked using
                    their respective icons. Both sets of pages are organized internally using
                    line-group headers, under which occur one or more data lines or tables.</ParagraphDesc>

                <ParagraphHeader title={"Epithet Record Pages"}/>

                <ParagraphDesc>Epithet Record pages organize data based on individual
                    species-group epithets. The line-groups present on Epithet Record pages are:</ParagraphDesc>
                <ul>
                <li>Taxonomy – a minimal amount current taxonomic
                    data relating to the header taxon epithet, including a link to the epithet’s
                    associated Monograph Record page</li>

                <li>Nomenclature – basic data on the nomenclatural
                    status (i.e., availability or unavailability) of the header taxon epithet,
                    together with original citation data, and nomenclatural gender forms</li>

                <li>Nomenclatural Type – summary and tabular information
                    on nomenclatural type specimen(s), including quotes from a large dataset of
                    type-related literature extracts</li>

                <li>Data – information on the proximal source and
                    age of the data displayed on the page</li>
                </ul>
                <ParagraphHeader title={"Monograph Record Pages"}/>

                <ParagraphDesc>Monograph Record pages organize data based on valid, or
                    ‘treated as valid’, species-group taxa (species and subspecies). The
                    line-groups present on Monograph Record pages are:</ParagraphDesc>
                <ul>
                <li>Taxon Name – the header taxon name is usually
                    the ‘short form’ name of a valid species or subspecies; occasionally it is an invalid
                    'treated as valid' name (in some cases involving taxa with unresolved
                    nomenclatural issues), or an invalid, usually unavailable, name (in some cases involving
                    names of uncertain taxonomy)</li>

                <li>Taxon Classification – a synoptic statement of
                    the classification (order to subtribe) of the header taxon in the LDL Standard
                    Taxonomy</li>

                <li>Monograph Record Links – a set of icon-based
                    links to (sometimes extensive) information about the header taxon contained in
                    the scientific literature</li>

                <li>Taxonomy – the taxonomic status of the header
                    taxon; sometimes with relevant taxonomic notes</li>

                <li>Scientific Names – various data relating to the
                    scientific names of the header taxon, including short- and long-form name,
                    original combination and publication data, and nomenclatural gender form names</li>

                <li>Synonyms – a listing of the nomenclatural synonyms
                    of the header taxon</li>

                <li>Literature – a set of icon-based links to
                    information about the header taxon contained in the scientific literature; similar to line Monograph Record Links, but with
                    count data for each class of information</li>

                <li>Geographic Distribution – a summary statement of
                    the geographic distribution of the header taxon, together with a tabular list
                    of the countries from which the taxon has been documented</li>

                <li>Lithostratigraphic Distribution – for fossil
                    taxa, summary data on the lithostratigraphy of the header taxon</li>

                <li>Temporal Distribution – statements regarding the
                    known temporal distribution of the header taxon</li>

                <li>Types – a table of links to the Epithet Record
                    pages of the synonyms of the header taxon <b></b></li>

                <li>Data – information on the proximal source and
                    age of the data displayed on the page</li>
                </ul>
                <ParagraphHeader title={"Combinations"}/>

                <ParagraphDesc>Access to Epithet and Monograph Record pages is provided
                    through the interactive search functionality of the Simple and Advanced Search
                    pages. User-entered search criteria are used to generate Search Results pages
                    that display lists of combinations (i.e., different arrangements of genus- and
                    species-group names) that match the user’s criteria. In the latest NSW data
                    set, nearly 23,000 distinct combinations are available for matching. Some
                    searchable combinations involve juxtapositions of generic and subgeneric names
                    that may not be technically correct nomenclaturally;
                    these are included only as representations of combinations that are present in
                    the literature. However, all combinations cited as either correct Original or Current
                    combinations on Monograph Record pages are nomenclaturally
                    correct to the best of the knowledge of the author.</ParagraphDesc>

                <ParagraphHeader title={"Data Tables"}/>

                <ParagraphDesc>The NSW makes extensive use of
                    tabular data presentations as a means of efficiently displaying large amounts
                    of data from the scientific literature. In addition to
                    a table-top caption, many tables also contain an associated table legend, which
                    can be access by clicking the table’s Show Table Legend icon (<Icon/>). Table legends typically provide detailed information about a
                    table’s data columns, and sometimes additional notes on table design, function,
                    or intended use.</ParagraphDesc>

        {resultData&&<><EasyTable float={"right"} width={"150px"} tableRowData={[["#ff00ff","Total"],["#ff0000","Neuroptera"],["#00ff00","Megaloptera"],["#0000ff","Raphidioptera"],["#ffff00","Glosselytrodea"]]}>
            <EasyTableElement width={"0%"} title={"Color"} elementFromValue={(val)=><div style={{width:"16px",height:"16px",backgroundColor:val[0]}}></div>}/>
            <EasyTableElement title={"Order"} elementFromValue={(val)=>val[1]}/>
        </EasyTable><EasyGraphic debug={false}  height={"400px"} width={`${currentWindow.width*.95 - 17-150}px`}>
            <EasyGraphicLineGraph
                yLabel={{title:"currently-valid species",font:"30px Times New Roman"}}
                xLabel={{title: "Decade",font:"30px Times New Roman", fontColor: "#000000",textBaseLine:"top", textAlign: "center", containerStyle: {padding: "0px 10px", margin: "0px 10px", height: "100%", width: "100%"}}}
                graphColor={"#c0c0c0"}
                lineColor={["#ff00ff","#ff0000","#00ff00","#0000ff","#ffff00"]}
                horizontalLines={{interval:"50px",subInterval:'10px',intervalType:"fixed",marginRight:"5px",marginLeft:"5px",paddingRight:"0px",paddingLeft:"5px",topIntervalValue:"1000px",snapTopClosestInterval:true}}
                verticalLines={{interval:"20px",subInterval:"10px",marginRight:"5px",startInterval:"1740px",topIntervalValue:"2020px",textStyle:{rotation:0,font:"20px Ariel",textAlign:"center",containerStyle:{left:"0px",top:"0px"}},underGraph:true,intervalType:"fixed",paddingRight:"5px",paddingLeft:"0px",snapTopClosestInterval:true}}
                containerStyle={{width: "100%", height: "100%", padding: "0px 30px 40px", margin: "10px 0% 30px 80px"}}
                data={resultData}/>
        </EasyGraphic></>}

                <ParagraphHeader title={"Updates"}/>

                <ParagraphDesc>The NSW is committed to a program of regular enhancement.
                    Improvements include the regular updating of taxonomic and nomenclatural data
                    (e.g., incorporating new taxa and updating classifications in accordance with
                    modern revisions and phylogenetic research), and the addition of new classes of
                    systematics-related data, as such datasets become mature enough for
                    incorporation. Most new nomenclatural and taxonomic changes that fall within
                    the domain of the NSW are incorporated into the project within 12 months of
                    their original publication. Users who are aware of the existence of new neuropterid taxa or taxonomic actions that are not yet
                    reflected in the NSW, or who discover errors in current NSW information, are
                    invited to report them so that they may be added to or corrected in future
                    versions. Authors who publish new information that augment
                    or extend knowledge of the systematics or biology of the Neuropterida
                    are requested to send copies of such works to the author in
order to help ensure the timely addition of new information. All
                    communications – additions, comments, corrections, publications, etc. – should
                    be sent to the author at&nbsp;<a href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.</ParagraphDesc>

                <ParagraphHeader title={"Scope"}/>

                <ParagraphDesc>The&nbsp;<i>Neuropterida</i><i>
                    Species of the World</i>&nbsp;is comprehensive in geographic scope and includes
                    information on both extant and fossil taxa. It provides detailed information on
                    the taxonomy and nomenclature of individual neuropterid
                    species-group epithets (through its Epithet Record pages), and synthetic
                    presentations of these and other data for individual valid species and
                    subspecies (through its Monograph Records pages). Detailed information on
                    genus-group names is not provided in this work. Epithet Record pages are
                    provided for all available and unavailable neuropterid
                    species-group epithets databased as of the posted version or release date;
                    Monograph Record pages are provided for all species- and subspecies-ranked taxa
                    that were flagged as valid in the LDL Standard Taxonomy on the same date.</ParagraphDesc>

                <ParagraphDesc>The following classes of unavailable species-group names are
                    also reported on separate Epithet Record pages: (1) unavailable "specimen
                    label names" that are known to be explicitly mentioned in the published
                    scientific literature; (2) taxon names that were inadvertently published in an
                    unavailable manner prior to their subsequent publication as available names;
                    and (3) other neuropterid species-group names
                    mentioned in the scientific literature that do not meet the criteria of
                    availability defined in the International Code of Zoological Nomenclature,
                    except for the following classes of names, which are generally excluded: (a)
                    known, but unavailable “thesis/dissertation names” [sometimes included]; (b)
                    known, but unpublished, “specimen label names” [not included], and (c)
                    “misspellings” that do not qualify as emendations (these include, among others,
                    “lapsus calami” names, printers’ errors, and names with incorrect nomenclatural
                    gender endings) [not included]. For the classes of unavailable names that it
                    treats, NSW practice is to include on a separate Epithet Record page the
                    earliest known use of each such name, and to attribute its authorship to the
                    author(s) of the work in which it appeared (in a manner similar
to the customary citation style of available names). Throughout the NSW,
                    unavailable names are consistently flagged with an asterisk (*) placed at the
                    end of every use of such names. </ParagraphDesc>

                <ParagraphDesc>The long history and diverse taxonomies of the name/taxon
                    "Neuroptera" have been the source of
                    several special problems for development of the NSW.
                    Used by Linnaeus (1758) as a polyphyletic receptacle of convenience for species
                    now included in approximately ten orders of holometabolous and
                    non-holometabolous insects, the taxonomic history of the Neuroptera
                    has been characterized by successive waves of reduction, and its concept has
                    been refined over the years primarily through the sequential removal of internally
                    cohesive subgroups. During most of its history, multiple different taxonomic
                    concepts of the Neuroptera
                    have co-existed in the scientific literature. One historical consequence of
                    this pluralism has been that for much of its history (particularly prior to ca.
                    1910) at least one very broad concept of the Neuroptera has existed in which diverse (now
                    extraordinal) insects could be included. Throughout the late 19th and early
                    20th centuries, many taxa that are now considered extraordinal continued to be placed
                    by some authors within broad concepts of the Neuroptera. However, as the dominant concept of the Neuroptera continued to narrow during this period, most of
                    the extant taxa that now fall outside of our present concept of the superorder Neuropterida were removed from the Neuroptera.
                    But, many extinct taxa known only from fossils
                    remained poorly known and weakly documented, and some fossil "neuropteroid" taxa of dubious ordinal position have
                    remained in the group up until the present day, or have only very recently been
                    removed. To better document the taxonomic position of these taxa, a small
                    number of names belonging to species that were formerly included in one or more
                    of the neuropterid orders, but which have since been
                    excluded from the superorder, are also included in the catalogue as separate Epithet
                    and Monograph Records. In addition, an attempt has been made to include as
                    separate Epithet Records all species-group names that have ever been combined
                    with a genus-group name that is currently considered to be valid (or a synonym
                    of such a valid genus-group name) within the current concept of the Neuropterida, even if the valid species to which such names
                    are currently assigned lie outside the Neuropterida.
                    These records are included as a convenience to users who have cause to research
                    the early (generally pre mid-19th century) literature
                    of the group.</ParagraphDesc>

                <ParagraphHeader title={"Classification"}/>

                <ParagraphDesc>The classification used in the NSW is generally referred to
                    throughout the <i>Lacewing Digital Library</i> as the LDL Standard Taxonomy and
                    is used (at least in part) in all of the modules of
                    the LDL. This classification generally follows the taxonomies presented in the most
                    recent taxonomic monographs and catalogues that are available for each higher
                    taxon, which are then updated on an ongoing basis from more recent taxonomic work.
                    The principle taxonomic works upon which the early versions of the LDL Standard
                    Taxonomy was originally based are shown in Tables 1 and 2. Where recent works
                    present divergent opinions about the proper taxonomic treatment of individual
                    taxa, the author has used his judgment to determine which opinion to use in the
                    NSW, guided where possible by the principals of maximizing the recognition of
                    monophyletic groups and minimizing the recognition on non-monophyletic groups. A
                    synoptic view of the suprageneric part (order to
                    subtribe) of the current LDL Standard Taxonomy can be viewed on the&nbsp;<a href="/SpeciesCatalog/Classification">Classification</a>&nbsp;page,
                    and classifications of individual family-group taxa can be viewed on the <a href="/SpeciesCatalog/Families">Families</a> page.<br/>
                        <br/>
                            Subgeneric and uninominal species-group names that have been cited in the
                            recent taxonomic literature are generally included as epithets in the
                            combination that is cited for a (sub)species’ Long Form scientific name on the
                            NSW Monograph Record page; but, to help standardize presentation, Monograph
                            Record header names are displayed as typical bi- or trinominal
                            names. Subgeneric names are generally not recognized where focused phylogenetic
                            work suggests that their recognition leads to the formal naming of paraphyletic
                            groups. However, because rigorous phylogenetic work remains lacking for many
                            taxa, it should not be assumed that the genera, subgenera, or ‘species groups’
                            used in the LDL Standard Taxonomy are monophyletic unless specific phylogenetic
                            data are available to substantiate such conclusions.</ParagraphDesc>

                <EasyTable caption={<>Table 1. Baseline Taxonomic References: works primarily restricted by taxon. The works included
                    in this table are relatively recent works that cover broad geographic regions and wide taxonomic
                    domains (worldwide and family wide unless otherwise indicated), or represent phylogenetic works
                    of particular relevance to the taxonomy/classification of the Neuropterida. Revisions/reviews of
                    individual genera have been excluded. Works included in Table 2 are not repeated here.</>} tableRowData={[[<><b>Neuroptera</b></>,<>New 1989 [r#6615]; Oswald &amp; Penny 1991 [r#7138]</>],
                    [<>Ascalaphidae</>,<>Ghosh 1988 [r#9656] (E. India); New 1984 [r#4490] (Australia); Penny [1982]
                        [r#5105] (New World); Sziráki 1998 [r#9362] (Asia and Pacific islands); Tjeder 1992 [r#7246]
                        (Afrotropical Haplogleniinae); Tjeder &amp; Hansson 1992 [r#7247] (Afrotropical Ascalaphini)
                    </>],
                    [<>Berothidae</>,<>U. Aspöck 1986 [r#1425]; U. Aspöck 1990 [r#1430] (Africa); U.
                        Aspöck &amp; Mansell 1994 [r#7532] (Rhachiberothinae); Nel et al. 2005 [r#11744] (fossil
                        Rhachiberothinae); U. Aspöck & Randolf 2014 [r#15696] (phylogeny)
                    </>],
                    [<>Chrysopidae</>,<>Brooks 1997 [r#9322]; Brooks &amp; Barnard 1990 [r#6991]; Ghosh 1990 [r#9484]
                        (India); Nel et al. 2005 [r#11761] (fossils); New 1980 [r#4464] (Australia); Tsukaguchi 1995
                        [r#8409] (Japan); Winterton &amp; Brooks 2002 [r#10215] (Apochrysinae); X.-K. Yang 1997
                        [r#8956] (China)
                    </>],
                    [<>Coniopterygidae</>,<>Johnson 1980 [r#3144] (North America); Meinander 1972 [r#4121]; Meinander 1990
                        [r#4147]; Engel 2004 [r#11170] (fossils); Sziráki 2011 [r#14094]
                    </>],
                    [<>Dilaridae</>,<>Monserrat 1988 [r#4308] (Iberian peninsula); Oswald 1998 [r#9236]; X.-y. Liu et
                        al. 2017 [r#16192] (phylogeny)
                    </>],
                    [<>Hemerobiidae</>,<>Monserrat 1990 [r#4311]; New 1988 [r#4514] (Australia); Oswald 1993 [r#7349];
                        Garzón-Orduña et al. 2016 [r#15951] (phylogeny)
                    </>],
                    [<>Ithonidae</>,<>Riek 1974 [r#5415] (Australia), Barnard 1981 [r#1510] (Rapismatidae),
                        Archibald &amp; Makarkin 2006 [r#11800] (fossils [Polystoechotidae]); Winterton & Makarkin
                        2010 [r#13510] (phylogeny)
                    </>],
                    [<>Mantispidae</>,<>Hoffman 1992 [r#8849] (New World Mantispinae); Lambkin 1986a,b [r#3615, 3616]
                        (Australia); Ohl 2004 [r#11556]; Penny 1982 [r#5106] (New World); Penny &amp; da Costa
                        [1985] [r#5113] (Brazil); X.-y. Liu et al. 2014 [r#15683] (phylogeny)
                    </>],
                    [<>Myrmeleontidae</>,<>Ghosh 1984 [r#2587] (India); Hölzel 1972 [r#2968] (near Asia), 1987 [r#3001]
                        (Distoleonini); New 1985a, b, c [r#4494, 4495, 4496] (Australia); Stange 1989 [r#6545]
                        (Dimarini), 1994 [r#7533] (Brachynemurini), 2004 [r#11168]; Stange &amp; Miller 1985
                        [r#5823] (Acanthaclisini), 2003 [r#10553] (Taiwan); Michel et al. 2017 [r#15981] (phylogeny)
                    </>],
                    [<>Nemopteridae</>,<>Hölzel 1975 [r#2980] (Crocinae); Mansell 1983 [r#4008] (Crocinae); Tjeder 1967
                        [r#6050] (S. Africa); Sole et al. 2013 [r#15013] (phylogeny)
                    </>],
                    [<>Nevrorthidae</>,<>Monserrat 1977 [r#4246]</>],
                    [<>Nymphidae</>,<>New 1996 [r#4465] (Australia)</>],
                    [<>Osmylidae</>,<>New 1983 [r#4476] (Australian Kempyninae); New 1988 [r#6510] (Irian Jaya); New
                        1991 [r#7212] (Oriental region)
                    </>],

                    [<>Psychopsidae</>,<>Andersen 2001 [r#10152] (fossils); New 1988 [r#6511] (Australia and Oriental
                        region); Oswald 1993 [#7341]; Bakkes et al. 2017 [r#16256] (phylogeny)
                    </>],

                    [<>Sisyridae</>,<>Flint 2006 [r#11913] (New World); Monserrat 1977 [r#4246], 1981 [r#4272]
                        (Oriental region)
                    </>],
                    [<><b>Megaloptera</b></>,<>Theischinger 1983 [r#6004] (Australia); Oswald &amp; Penny 1991 [r#7138];
                        New &amp; Theischinger 1993 [#7701]; Yang & Liu 2010 [r#13492] (China)
                    </>],
                    [<>Corydalidae</>,<>Glorioso 1981 [#2625] (Corydalinae); X.-y. Liu & D. Yang 2006 [r#11832]
                        (Chauliodinae, phylogeny)
                    </>],
                    [<>Sialidae</>,<>Hayashi &amp; Suda 1995 [r#7774] (Japan); X.-y. Liu &amp; D. Yang 2006
                        [r#11697] (Oriental China); Vshivkova 1985 [r#6189] (Europe &amp; Caucasus); Whiting 1994
                        [r#6988] (North America); Wichard &amp; Engel 2006 [r#11833]; X.-y. Liu et al. 2015
                        [r#15506] (phylogeny)
                    </>],
                    [<><b>Raphidioptera</b></>,<>H. Aspöck et al. 1991 [r#6999]; Oswald &amp; Penny 1991 [r#7138]; Engel 2002
                        [r#10270] (fossils)
                    </>],
                    [<>Inocelliidae</>,<>H. Aspöck et al. 1991 [r#6999]</>],
                    [<>Raphidiidae</>,<>H. Aspöck et al. 1991 [r#6999]</>]]}>
                    <EasyTableElement width={"0%"} title={"Taxon"} align={"left"} elementFromValue={(val)=>val[0]} desc={"Neuropterida order or family"}/>
                    <EasyTableElement title={"References"} align={"left"} elementFromValue={(val)=>val[1]} desc={"format: Author year [Bibliography of the Neuropterida reference #] (taxonomic or geographic domain of reference)"}/>
                </EasyTable>
                <br/>

                <EasyTable caption={<> Table 2. Baseline Taxonomic References: works primarily restricted by geographic region. Works
                    included in this table are prominent national or regional faunas that treat the entire
                    Neuropterida (unless otherwise indicated). No attempt has been made to include all recent
                    national faunal lists.</>} tableRowData={[[<>Nearctic</>,<>Oswald et al. 2002 [r#10033] (Mexico); Penny et al. 1997 [r#8867] (America
                    north of Mexico)
                </>],
                    [<>Neotropical</>,<>Oswald et al. 2002 [r#10033] (Mexico) ; Penny 1977 [r#5098] (South America);
                        Penny [ed.] 2002 [r#10000] (Costa Rica)
                    </>],
                    [<>Palearctic</>,<>H. Aspöck et al. 1980 [r#6747] (Europe); H. Aspöck et al. [r#9847] (western
                        Palearctic); Kuwayama 1962 [r#10914] (Japan); Makarkin 1995a, b [r#8360, 8361], Vshivkova
                        1995 [r#8504] (eastern Russia); Nielsen 2015 [r#15913] (Denmark); Rintala et al. 2014
                        [r#15679] (Finland)
                    </>],
                    [<>Ethiopian</>,<>---</>],
                    [<>Oriental</>,<>New 2003 [r#10897] (Malesia)</>],
                    [<>Australian</>,<>New 1996 [r#8585] (Australia); Wise 1991 [r#7137] (New Zealand)</>],
                    [<>Oceania</>,<>Zimmerman 1957 [r#6440] (Hawaiian Islands)</>]]}>
                    <EasyTableElement align={"left"} width={"0%"} title={"Region"} elementFromValue={(val)=>val[0]} desc={"world biogeographic region"}/>
                    <EasyTableElement align={"left"} title={"References"} elementFromValue={(val)=>val[1]} desc={"format: Author year [Bibliography of the Neuropterida reference #] (geographic domain of reference)"}/>
                </EasyTable>
                <br/>

                <ParagraphHeader title={"Nomenclature"}/>

                <ParagraphDesc>The fourth edition of the International Code of Zoological
                    Nomenclature has been applied to matters of nomenclature throughout the NSW. Considerable effort has been expended to represent NSW
                    information concerning names and nomenclatural acts in Code-compliant forms, as
                    understood on the Data Timestamp dates of the individual datasets. In cases
                    where interpretation of the Code has seemed problematic, external opinions from
                    authorities on zoological nomenclature have frequently been sought.</ParagraphDesc>

                <ParagraphDesc>In accordance with Art. 15.2 of the Code, names originally
                    published as varieties or forms after 1960 are treated as infrasubspecific,
                    and thus unavailable. Variety and form names published before 1961 have generally
                    been interpreted as subspecific, and thus available, except in cases where the
                    intent of the original author is very clear that such names were intended to be
                    infrasubspecific (Art. 45.6). Under this
                    interpretation, the vast majority of variety and form names proposed by authors such as Navás and Lacroix are available names. Although some such
                    names have been cited by later authors as either valid names or synonyms, the
                    NSW contains a number of variety
                    and form names that appear to have been overlooked or ignored by previous
                    catalogers and revisers. In the NSW, previously overlooked variety and form
                    names are generally treated taxonomically as invalid junior synonyms of the
                    species names with which they were originally combined (rather than as valid
                    subspecies of the originally combined species), and
                    are included here in the synonymy of the appropriate valid species (either the
                    originally combined species or its senior valid synonym). While this procedure
                    is consistent with the species placement of these variety/form names by their
                    original authors, and has the added benefit of relegating most of these
                    generally poorly documented names to synonymy, it should be noted that until
                    the primary type materials of such names have been located and examined (or
                    newly designated) this procedure has the potential to include as synonyms some
                    names that may turn out eventually to be valid species or subspecies names.</ParagraphDesc>

                <br/>

                <ParagraphHeader title={"Synonymical Listings"}/>

                <ParagraphDesc>The synonymical listings presented
                    on Monograph Record pages are designed to display concise lists of all known
                    nomenclatural synonyms that pertain to the page’s header taxon. Synonyms are
                    displayed as combinations of one or more genus-group names and one or more
                    species-group names, and are followed by an author string, and a year of
                    publication. All uninominal scientific names are given in their correct
                    forms/spellings according to the relevant articles of the International Code of
                    Zoological Nomenclatural (Code), as understood on the Data Timestamp date. Unavailable
                    genus- and species-group names are always asterisked (*). Synonymical
                    listings intentionally exclude synonym strings that are known to include
                    incorrectly formed uninominal names (e.g., misspellings, incorrect original
                    spellings, mismatched gender endings, incorrect capitalization, improper
                    hyphenation). Longer lists of synonyms are displayed in a scrollable list box. Synonymical listings may be restricted to specialized
                    sub-lists by selecting an option from the associated combo box.</ParagraphDesc>

                <ParagraphDesc>Author and year strings are scripted following the relevant
                    articles of the Code. Author strings involving three or more authors are cited
                    in their shortened 'et al.' forms (Recommendation 51C). Recommendation 51E,
                    involving 'A in B' authorship citations, has also been followed. To distinguish
                    among authors who share the same surname, one or two disambiguating given-name
                    initials are generally given preceding the surnames of such authors. Synonym
                    lines that include the original publication (i.e., first occurrence) of a
                    species-group epithet have the following additional information appended after
                    the year of publication: (1) the Bibliography of the Neuropterida
                    reference number (r#) and the page/ParagraphDesclate number {'\{'}format: r#00000:[pl. ]000{'\}'} of
                    the first occurrence; (2) an icon-based link to the Bibliographic Record page
                    in the Bibliography of the Neuropterida that contains
                    information on the original publication; (3) where available and permissible
                    based on login status, an icon-based link to a digital copy of the original
                    publication; and (4) the 'full date' of the publication, enclosed in square
                    brackets [], that is currently assigned in the Bibliography of the Neuropterida to the page or plate within the original
                    publication on which the new species-group name was established. </ParagraphDesc>

                <ParagraphDesc>Synonyms are listed in ascending chronological order based
                    on their dates of first occurrence as documented in NSW Taxon Citation records.
                    These dates approximate the chronological order in which the different synonyms
                    appeared in the scientific literature, but future indexing of additional
                    literature may change the relative order of some listed synonyms. </ParagraphDesc>

                <ParagraphDesc>Several classes of 'combinations' are deemed to have been
                    implicitly (even if not explicitly) created. Example #1: an original explicit
                    'combination' of the form 'Genus (Subgenus) species' or 'Genus (species group)
                    species', is deemed to have also implicitly created the combination 'Genus species'. Example #2: an author who exclusively uses
                    combinations of the form ‘Genus species’ in a work, but elsewhere in the work
                    clearly assigns some or all of the species to informal, taxon-name-based,
                    species groups, is deemed to have also implicitly created a set of combinations
                    of the form ‘Genus (species group) species’ for the
                    species so assigned. Combinations that currently have no documented occurrences
                    as NSW Taxon Citation records are given at the end of a synonymical
                    listing. Such combinations may have originated in incompletely indexed
                    publications or in manuscript materials (e.g., identification labels). These
                    combinations will be incorporated into the chronological listing of synonyms as
                    they are more fully documented. Users who identify possible errors in a synonymical listings (or any other NSW data) are invited to
                    report such issues so that they can be confirmed and corrected. Comments can be
                    conveniently submitted to the author by clicking the email icon found to the
                    right of the header line on most programmatically-generated
                    pages that contain significant data displays.</ParagraphDesc>

                <ParagraphHeader title={"Data Completeness"}/>

                <ParagraphDesc>During the lengthy period of data collection for the NSW the
                    priorities of data capture have shifted several times to address intermediate
                    objectives. Consequently, not all records are equally complete, and certain
                    classes of information are more thoroughly represented across the full range of
                    species. Some general information is presented below regarding the relative
                    completeness of several important classes of NSW data.</ParagraphDesc>

                <ParagraphHeader title={"Original Publication Data"}/>

                <ParagraphDesc>The original publication data associated with 99.9+% of NSW names
                    (epithets and taxa) have been verified by the author from personal examination
                    of originals or facsimile copies (e.g., photocopies, digital scans, published
                    facsimile reprints [not “separates”], digital PDF ‘originals’) of the relevant
                    literature. The ‘Verified’ data line on each Epithet Record page indicates
                    whether the original publication data have been verified for individual epithets.
                    The working Epithet and Monograph Records pages for unverified names may
                    contain particularly incomplete and non-standard data entries.</ParagraphDesc>

                <ParagraphHeader title={"Distribution Data"}/>

                <ParagraphDesc>Summary data on the geographical distributions of
                    essentially all valid neuropterid species and
                    subspecies are included in the NSW in the ‘Geographic Distribution’ line group
                    on Monograph Record pages. On the ‘Summary Statement’ line, if the documented
                    distribution of a taxon consists of five or fewer
                    countries, a complete list of those countries is given. Where a taxon is
                    documented from six or more countries, the ‘Summary Statement’ of its
                    distribution is generally reduced to a brief geographical summary that describes
                    the range in terms of its documented limits. For some larger countries
                    (especially Australia, Canada, Mexico, and the United States, and with somewhat
                    less consistency Brazil, China, and Russia) first-order political subdivisions
                    within the country (e.g., states, provinces) are included in the distribution summary
                    statements, generally in abbreviated format. The “Country Records” table option
                    “Show Countries (no duplicates and sources)” provides a more complete listing
                    of the countries from which a taxon has been recorded. Another “Country
                    Records” table option – “Show Country Records (with duplicates and sources)” –
                    may be selected from the Country Records combination box, to display more detailed
                    information about the sources of the country records.</ParagraphDesc>

                <ParagraphDesc>While the geographical distributions reported in the NSW are
                    believed to be substantially correct, as a practical matter it is difficult to
                    keep up with all newly-published distribution records reported worldwide in a
                    group of ca. 7800 valid species – even for records at country level. Catalogue
                    distribution statements are reviewed and updated as new records are entered
                    into the NSW database or otherwise become known to the author. The
                    collaborations of authors on the published modules of the LDL <i>World Neuropterida Faunas</i> project are also gratefully
                    acknowledged for their clarifications of species currently recorded from the
                    countries covered by their faunas.</ParagraphDesc>

                <ParagraphDesc>With very few exceptions, the reported distribution data
                    have been derived from records contained in the published scientific literature
                    (with data currently entered from &gt;3100 individual publications). The few
                    exceptional records are based on reliably identified specimens examined by the
                    author or other neuropterists. Distributions reported
                    for unavailable names generally cite the country(ies)
                    of origin of the specimen(s) attributed to those names in the publications in
                    which the names originally appeared in unavailable form.</ParagraphDesc>

                <ParagraphHeader title={"Primary Type Data"}/>

                <ParagraphDesc>Data on primary types of individual nomenclatural epithets
                    are provided on Epithet Record pages. Complete or partial primary type data
                    (principally type kind, sex, depository and type locality) are currently
                    reported for ca. 90% of all available species-group names included in the NSW.
                    Many of these data are preliminary, being based on an assessment of some, but
                    not necessarily all, of the relevant literature. Primary type data are actively
                    being updated as additional literature is examined for type-relevant
                    information. To provide users with a basis for assessing the status and quality
                    of the primary type data presented for particular epithets,
                    direct quotes (i.e., ‘literature extracts’) from the source(s) from which the
                    standardized data statements given have been derived are provided in the ‘Type-Related
                    Literature Extracts’ table on each Epithet Record page. With few exceptions,
                    only the sources quoted in this table have been used to derive data for the
                    other type-related NSW data fields. Thus, if a type-relevant statement is found
                    by a user in a publication that is not cited in the ‘Type-Related Literature Extracts’
                    field, it can generally be assumed that that statement has not been considered
                    when entering data for other type-relevant NSW fields. Hence, users should
                    consider such statements, together with the data presented in the NSW, to
                    determine the correct type information of interest. </ParagraphDesc>

                <ParagraphHeader title={"Fossil Taxa"}/>

                <ParagraphDesc>The current version of the NSW contains records for ca. 1200
                    valid species-group taxa that are known only from fossils. The NSW uses a
                    temporal, rather than lithological, criterion to define fossils; any materials
                    interpreted to be pre-Holocene or older in age are treated as fossils, while
                    all materials interpreted to be of Holocene age are treated as non-fossils.</ParagraphDesc>

                <ParagraphDesc>As of 2024, the author is not aware of any species-level neuropterid taxa that have been reported from both Holocene
                    and pre-Holocene material. Nor is the author aware of any species-group neuropterid taxa known only from Holocene material that is
                    currently judged to be globally extinct, i.e., to have gone globally extinct
                    during the Holocene. Cases might be made for such extinctions for some island
                    endemics (e.g., some endemic Hawaiian Neuroptera
                    species), but the extensive and repeated sampling needed to build cases for
                    such possible extinctions does not currently exist.</ParagraphDesc>

                <br/>

                <br/>


    </Record>
};
export default Species_Introduction;