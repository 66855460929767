import React from "react";

const Biblio_Citation = () => {
    return <>
        <div className="content">
            <div className="text-body">
                <h1>Citation</h1>
                <p style={{textAlign: "left"}} className="header-paragraph-1">
                    The <i>Bibliography of the Neuropterida</i> (BotN) may be cited as shown below.
                </p>
                <div style={{borderTop: "1px solid #000000", textAlign: "left", paddingBottom: "2px",}}>
                </div>
                <p style={{textAlign: "left"}} className="header-paragraph-2">
                    <b>For the complete <i>Bibliography of the Neuropterida</i> publication use:</b>
                </p>

                <div style={{marginLeft: ".3in", textIndent: ".3in", textAlign: "justify"}}>
                    <p style={{textAlign: "left"}} className="indented-paragraph">
                        Oswald, J. D. Bibliography of the Neuropterida. <i>Lacewing Digital Library, Research
                        Publication</i> No. 2. https://lacewing.tamu.edu/Biblio/Main. Last accessed [fill in date of
                        last site access].
                    </p>
                </div>

                <h4 style={{textAlign: "left"}}>Example</h4>

                <div style={{marginLeft: ".3in", textIndent: ".3in", textAlign: "justify",}}>
                    <p style={{textAlign: "left"}} className="indented-paragraph">
                        Oswald, J. D. Bibliography of the Neuropterida. <i>Lacewing Digital Library, Research
                        Publication</i> No. 2. https://lacewing.tamu.edu/Biblio/Main. Last accessed 10 June 2019.
                    </p>
                </div>

                <p className="header-paragraph-2">
                    <b>For a set of records, such as a particular taxon set or author, use:</b>
                </p>

                <div style={{marginLeft: ".3in", textIndent: ".3in", textAlign: "justify",}}>
                    <p style={{textAlign: "left"}} className="indented-paragraph">
                        Oswald, J. D. [Familyname, Genusname, Authorname, etc.], [number of records examined or
                        accessed] records, in: Bibliography of the Neuropterida. <i>Lacewing Digital Library, Research
                        Publication</i> No. 2. https://lacewing.tamu.edu/Biblio/Main. Accessed on [fill in date of last
                        site access].
                    </p>
                </div>

                <h4 style={{textAlign: "left"}}>Example</h4>

                <div style={{marginLeft: ".3in", textIndent: ".3in", textAlign: "justify",}}>
                    <p style={{textAlign: "left"}} className="indented-paragraph">
                        Oswald, J. D. Longinos Navás citations, 1100 records, in: Bibliography of the Neuropterida. <i>Lacewing
                        Digital Library, Research Publication</i> No. 2. https://lacewing.tamu.edu/Biblio/Main. Last
                        accessed 10 June 2019.
                    </p>
                </div>

                <p style={{textAlign: "left"}} className="header-paragraph-2">
                    <b>For an individual record use:</b>
                </p>

                <div style={{marginLeft: ".3in", textIndent: ".3in", textAlign: "justify",}}>
                    <p style={{textAlign: "left"}} className="indented-paragraph">
                        Oswald, J. D. Record: [description of record], in: Bibliography of the Neuropterida. <i>Lacewing
                        Digital Library, Research Publication</i> No. 2. https://lacewing.tamu.edu/Biblio/Main. Last
                        accessed [fill in date of last site access].
                    </p>
                </div>
                <h4 style={{textAlign: "left"}}>Example</h4>

                <div style={{marginLeft: ".3in", textIndent: ".3in", textAlign: "justify",}}>
                    <p style={{textAlign: "left"}} className="indented-paragraph">
                        Oswald, J. D. Record: Hagen, H. A., reference #456, in: Bibliography of the Neuropterida. <i>Lacewing
                        Digital Library, Research Publication</i> No. 2. https://lacewing.tamu.edu/Biblio/Main. Last
                        accessed 10 June 2019.
                    </p>
                </div>
            </div>
        </div>
    </>
};
export default Biblio_Citation;