import React, {useEffect, useRef, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import "../../Content/Faunas/Faunas.css"

const Serials_Main = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],header,currentWindow,HeaderHeight,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const footerRef = useRef(null)
    const [height,setHeight]=useState(0);
    useEffect(() => {
        if (!footerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const fdist=footerRef.current?.clientHeight;
            // @ts-ignore
            //console.log(hdist,fdist,currentWindow.height,[headerRef.current?.clientHeight,footerRef.current?.clientHeight])
            setHeight(currentWindow.height-HeaderHeight-(fdist?fdist:0))
        });
        resizeObserver.observe(footerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [currentWindow,HeaderHeight]);

    return<>


        <tbody id={"mainBody"}>
        <tr>
            <td colSpan={2}>

        <main className={"Faunas"} style={{height:height,maxHeight:height,overflow:"auto","--mh":`${height}px`} as React.CSSProperties}  role="main">

        <div className="content">
            <div className="text-body">
                <h1>Neuropterida Serials</h1>
                <p className="subheader">
                    Neuropterida Specialty Journals and Newsletters
                    <br/>
                </p>
                <p style={{textAlign: "left"}}>
                    <i><u>Abstract</u></i>: [add text]
                </p>

                <h3>Journals</h3>

                <ul itemType='disc'>
                    <li className='MsoNormal'>
                        <a href="http://www.ucm.es/info/zoo/JofN.htm">Journal of Neuropterology</a> [1998-2001]
                        (formerly published by the International Association of Neuropterology; all articles published
                        in this journal are indexed in the <a href="http://lacewing.tamu.edu/Biblio/Main"
                                                              target="_blank">Bibliography of the Neuropterida</a>)
                    </li>
                    <li className='MsoNormal'>
                <span className="MsoNormal">
                    Neuroptera International [1980-1992] (formerly published by the Association Mondiale des N&eacute;vropt&eacute;ristes; all articles published in this journal are indexed in the <a
                    href="http://lacewing.tamu.edu/Biblio/Main" target="_blank">Bibliography of the Neuropterida</a>)
                </span>
                    </li>
                    <li className='MsoNormal'>
                 <span className="MsoNormal">
                    Neuroptera International, Supplemental Series [1986] (formerly published by the Association Mondiale des N&eacute;vropt&eacute;ristes; only one number issued, see the <a
                     href="http://lacewing.tamu.edu/Biblio/Main" target="_blank">Bibliography of the Neuropterida</a>)
                </span><br/>
                        <br/>
                    </li>
                </ul>

                <div className='MsoNormal' style={{textAlign: "center",}}>

                    <hr/>

                </div>

                <h3>Newsletters</h3>

                <ul itemType='disc'>
                    <li className='MsoNormal'>
                        Lacewing News : Newsletter of
                        the International Association of Neuropterology [2006-present] (<span className="MsoNormal">
                continued from: Neuropterists' Newsletter</span>)<br/>
                        Editor: Agostino Letardi (<a href="mailto:agostino.letardi@enea.it">agostino.letardi@enea.it</a>;
                        #13+); Former Editor: Atilano Contreras-Ramos (#10-11)<br/>
                        <br/>
                        <a href="LacewingNews10.pdf">No. 10</a> (Spring
                        2006) [numbering continued from the Neuropterists' Newsletter]<br/>
                        <a href="LacewingNews11.pdf">No. 11</a> (Winter 2006)<br/>
                        No. 12 (not issued)<br/>
                        <a href="LacewingNews13.pdf">No. 13</a> (Autumn 2011)<br/>
                        {/*<a href="http://lacewing.tamu.edu/neuropterida/neur_bibliography/edoc12/oswald2017ref13078ReferencesVer5-16609.pdf">No. 13</a> (Autumn 2011)<br/>*/}
                        <a href="LacewingNews14.pdf">No. 14</a> (Spring 2012)<br/>
                        <a href="LacewingNews15.pdf">No. 15</a> (Autumn 2012)<br/>
                        <a href="LacewingNews16.pdf">No. 16</a> (Spring 2013)
                    </li>
                    <br/>

                    <li className='MsoNormal'>
                        Neuropterists' Newsletter :
                        Voice of the International Association of Neuropterology [1981-1999] (continued by: Lacewing
                        News)
                        <br/>
                        Editor: Norman D. Penny (
                        <a href="mailto:npenny@calacademy.org"> npenny@calacademy.org</a>)
                        <br/>
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet1.htm">
                            Number
                            1
                        </a> (July 1981)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet2.htm">
                            Number
                            2
                        </a> (March 1994)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet3.htm">
                            Number
                            3
                        </a> (September 1994)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet4.htm">
                            Number
                            4
                        </a> (December 1994)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet5.htm">
                            Number
                            5
                        </a> (April 1995)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet6.htm">
                            Number
                            6
                        </a> (April [sic; for December] 1995)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet7.htm">
                            Number
                            7
                        </a> (September 1996)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet8.htm">
                            Number
                            8
                        </a> (September 1998)
                        <br/>
                        <a href="http://www.calacademy.org/research/entomology/Entomology_Resources/mecoptera/Neuroptera/newsletters/newslet9.htm">
                            Number
                            9
                        </a> (June 1999) [ceased with this number; numbering continued with Lacewing News]
                    </li>
                    <br/>
                    <li className='MsoNormal'>
                        NeuroNews : Newsletter of
                        the British Isles Neuroptera Recording Scheme [1988-2001]<br/>
                        Editor: Colin W. Plant (<a href="mailto:cpauk1@ntlworld.com">cpauk1@ntlworld.com</a>)<br/>
                        <br/>
                        Numbers 1-16 (April 1988 to Summer 1995; not available on-line)<br/>
                        <a href="nnews17.html">Number 17</a> (Winter 1995/1996)<br/>
                        <a href="nnews18.html">Number 18</a> (Summer 1996)<br/>
                        <a href="nnews19.html">Number 19</a> (Winter 1996)<br/>
                        <a href="nnews20.html">Number 20</a> (Summer 1997)<br/>
                        <a href="nnews21.html">Number 21</a> (Winter 1997 / 1998)<br/>
                        <a href="nnews22.html">Number 22</a> (Summer 1998)<br/>
                        <a href="nnews23.html">Number 23</a> (Winter 1998/1999)<br/>
                        <a href="nnews24.html">Number 24</a> (Summer 1999)<br/>
                        <a href="nnews25.html">Number 25</a> (Winter 1999/2000)<br/>
                        <a href="nnews26.html">Number 26</a> (Winter 2000/2001) [ceased with this number]
                    </li>
                </ul>
            </div>
        </div>
        </main></td></tr></tbody>
        <tfoot ref={footerRef} id={"mainFooter"}>

        </tfoot></>
};
export default Serials_Main;