import React, {useState} from "react";

const OldKeys_Main = () => {
    const [selectCntry, setselectCntry] = useState("-1");


    return <>
        <style>
            @import url({String(require("../../Content/Keys/MainPage.css"))}
        </style>
        <div id="wrapperMain">
            <div id="background">


                <center>
                    <p className="style3">Interactive Digital</p>
                    <p className="style4">Keys to the Neuropterida Genera of the World</p>
                    <p className="style6">(Insecta: Neuroptera, Megaloptera and Raphidioptera)</p>


                    {/* Start Public Version Text */}
                    {/* Text contained between the comments "Start Public Version Text" and "End Public Version Text" MUST be retained after deleting the Developer Version Text after porting the DigitalKeysContent page to the Public Version of the LDL. */}
                    <div>
                        <table id="familypics2" className="style7"
                               style={{border: "2"}}>{/*cellspacing="2" cellpadding="2"*/}
                            <tbody>
                            <tr>
                                <td className="innerborder"><a href="./Psychopsidae" target="_top"><img
                                    src={require("../../Content/Keys/graphics/Cabralis_gloriosus_LDL150x100.jpg")}
                                    alt="Psychopsidae" width="150" height="100" style={{border: "0"}}/></a></td>
                                <td className="innerborder"><a href="./Sialidae" target="_top"><img
                                    src={require("../../Content/Keys/graphics/Sialis_lutaria_LDL150x100.jpg")}
                                    alt="Sialidae" width="150" height="100" style={{border: "0"}}/></a></td>
                            </tr>
                            <tr>
                                <td className="innerborder" align="center"
                                    style={{textDecoration: "none", backgroundColor: "#FFCC99"}}><a
                                    href="./Psychopsidae" target="_top">Psychopsidae</a></td>
                                <td className="innerborder" align="center"
                                    style={{textDecoration: "none", backgroundColor: "#FFFF99"}}><a
                                    href="./Sialidae" target="_top">Sialidae</a></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    <table className="style7">{/*cellspacing="5" cellpadding="3"*/}
                        <tbody>
                        <tr>
                            <td className="innerborder" align="center" style={{backgroundColor: "#FFCC99"}}
                                width="100">Neuroptera
                            </td>
                            <td className="innerborder" align="center" style={{backgroundColor: "#FFFF99"}}
                                width="100">Megaloptera
                            </td>
                            <td className="innerborder" align="center" style={{backgroundColor: "#CCFFFF"}}
                                width="100">Raphidioptera
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <br/>

                    <table width="" style={{border: "0"}}>{/*cellspacing="1" cellpadding="2"*/}
                        <tbody>
                        <tr>
                            <td width="">
                                <div style={{textAlign: "right"}}><span className="style5">Available Keys:</span></div>
                            </td>
                            <td width="">
                                <select name="selectCntry" id="selectCntry" value={selectCntry}
                                        onChange={(e) => setselectCntry(e.target.value)}>
                                    <option value="-1">Select a key here, or click on a family name or image in the
                                        table above. . .
                                    </option>
                                    <option value="./Psychopsidae">Key to the Psychopsidae Genera of the World
                                    </option>
                                    <option value="./Sialidae">Key to the Sialidae Genera of the World</option>
                                </select>
                            </td>
                            <td width="24"><a href={selectCntry != "-1" ? selectCntry : undefined}><img
                                src={require("../../Content/Keys/graphics/go.jpg")} alt="entry box" height="24"
                                style={{border: "0", alignItems: "abs-bottom"}}/></a></td>
                        </tr>
                        </tbody>
                    </table>

                    <div style={{marginLeft: "100px", marginRight: "100px",}}>

                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <div id="Collaboratornotes" style={{textAlign: "center"}}>
                                        <div style={{textAlign: 'justify'}}>
                                            <p>
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                    {/* End Public Version Text */}


                </center>
            </div>

        </div>
    </>
};
export default OldKeys_Main;