import React from "react";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {layoutContextType} from "../Shared/_Layout";
import LDLallowedModules from "../../utils/OnServerStartLoadedJsons/LDLallowedModules.json";
import {IntroductionTEXT} from "./Shared/IntroductionTEXT";
import nameTranslator from "../../utils/nameTranslator";
import {tblLdlModule} from "../../../../database/models/tblLdlModule";
import FaunaCountry from "../../utils/OnServerStartLoadedJsons/FaunaCountry.json";
import LDLUsedBibObjID from "../../utils/OnServerStartLoadedJsons/LDLUsedBibObjID.json";
import {tblLdlFaunaPoliticalUnits} from "../../../../database/models/tblLdlFaunaPoliticalUnits";

const Faunas_Introduction = () => {
    const [[PDFAccess, setPDFAccess], [verifiedUser, setVerifiedUser], [navDat, setNavDat], [divDat, setDivDat],isPublic,[onClickFunction,setOnClickFunction],layoutHeader,currentWindow,height,[afterTitle,setAfterTitle],[currentPathData,setCurrentPathData]] = useOutletContext<layoutContextType>();

    const navigate = useNavigate();
    const location = useLocation();
    const _props = location.state as any | undefined;
    const currentPath = location.pathname;
    const paths = currentPath.split('/').slice(1);
    // @ts-ignore
    const myGeo:tblLdlModule = LDLallowedModules[paths[0]];
    // @ts-ignore
    const myModule:tblLdlModule=LDLallowedModules[paths[0]]
    // @ts-ignore
    const myPoliticalUnit:tblLdlFaunaPoliticalUnits|undefined = FaunaCountry[myModule.GeogID];
    const DisplayName=`${
        // @ts-ignore
        myPoliticalUnit?(`${myPoliticalUnit?.DefiniteArticle} ${myPoliticalUnit.PoliticalUnitName}`):(nameTranslator["/" + paths[0]]?.TabName ? `${currentPathData[paths[0]].UpperLeftModuleName}` :`${paths[0]}`)}`

    // @ts-ignore
    return <IntroductionTEXT GeogID={myGeo.GeogID} ProjectID={(myGeo.BibObjectID&&LDLUsedBibObjID[myGeo.BibObjectID])?myGeo.BibObjectID:0} name={paths[0]} displayName={DisplayName}/>
};
export default Faunas_Introduction;