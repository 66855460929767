import React, {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import callAPI, {callGETAPI} from "../../utils/APIhelpers";

const Login = (_props: { isPublic: boolean }) => {
    const [user_id, setuser_id] = useState("");
    const [user_pass, setuser_pass] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        callGETAPI("/api/users/isLoggedIn", (error: boolean, outData: { expires: any, user: any } | Response) => {
            if (error) {
                console.log(error, outData)
            } else {
                navigate("/Authenticate/LoggedIn");
            }
        })
    }, []);

    const handleAuthorize = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        callAPI("/api/users/login", {id: user_id, password: user_pass}, (error: boolean, outData: any | Response) => {
            if (error) {
                console.log("error")
                console.log(outData);
                navigate("/Authenticate/LoginFailure")// Set password error to true
            } else {
                /*Cookies.set("username", outData.UserName, {
                    expires: 1 / 24 * 24, // expire in 24 hours.
                });
                Cookies.set("userid", outData.ID, {
                    expires: 1 / 24 * 24, // expire in 24 hours.
                });*/
                if (outData.UserName) {
                    navigate("/Authenticate/LoginSuccess");
                }
            }
        })


    }
    return <>


        <div className="content">
            <div className="text-body">
                <h1>Login{!_props.isPublic && <> (LDL-Demo)</>}</h1>
                <p className="header-paragraph-1" style={{textAlign: 'justify'}}>
                    To log in to the <i>Lacewing Digital Library{!_props.isPublic && <>, Development
                    Version</>}</i>{!_props.isPublic && <> (LDL-Demo; lacewing-demo.tamu.edu)</>} enter a valid username
                    and password below.{!_props.isPublic && <> This version of the LDL is designed to allow authors and
                    collaborators access to their projects while they are being developed or upgraded. To switch to
                    the <i>Lacewing Digital Library, Public Version</i> (LDL-Public; lacewing.tamu.edu) click <a
                        href="https://lacewing.tamu.edu/HomePage/MainContent" target="_top"><i>here</i></a>.</>}
                </p>
                <hr className="rule1" style={{marginBottom: "2em", marginTop: "4px",}}/>
                <form onSubmit={handleAuthorize}>
                    <table style={{border: "0"}} align="center">
                        <tbody>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td align="right" style={{whiteSpace: "nowrap"}} className="DF1row"><b>Username</b>:</td>
                            <td><input style={{height: "29.5px",}} placeholder="Username" type="text" name="user_id"
                                       value={user_id} onChange={(e) => setuser_id(e.target.value)}/></td>
                        </tr>

                        <tr>
                            <td align="right" style={{whiteSpace: "nowrap"}} className="DF1row"><b>Password</b>:</td>
                            <td>
                                <input style={{height: "29.5px",}} placeholder="Password" type="password"
                                       name="user_pass" value={user_pass}
                                       onChange={(e) => setuser_pass(e.target.value)}/>
                            </td>
                        </tr>

                        <tr>

                            <td colSpan={2}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button style={{width: "100px"}} type="submit" className="btn btn-info">Login</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style={{width: "80px", color: "white",}}
                                                                 href="/Authenticate/Login"
                                                                 className="btn btn-info"> Reset</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>

    </>
};
export default Login;