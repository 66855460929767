import React from "react";

const Main = () => {

    return <>

        <div className="content">
            <div className="text-body">

                <h1>Bibliography of the Neuropterida</h1>
                <p className="subheader text-center">
                    An Annotated Bibliography and Digital Library of the<br/>
                    Literature of the Extant and Fossil<br/>
                    Neuroptera, Megaloptera, Raphidioptera and Glosselytrodea<br/>
                    (Insecta: Neuropterida) of the World
                </p>

                <br/>

                {/* >>>>>>> master */}
                <p className="text-center">
                    John D. Oswald<br/>
                    Department of Entomology, Texas A&amp;M University<br/>
                    College Station, Texas 77843-2475 USA
                </p>

                <p className="text-center">
                    <a style={{width: "100px",}} href="Introduction" className="btn btn-info">
                        Introduction
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px",}} href="SimpleSearch" className="btn btn-info">
                        Simple Search
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{width: "120px",}} href="AdvancedSearch" className="btn btn-info">
                        Advanced Search
                    </a>
                </p>
                <span/>

                <div className="text-body">
                    <p>

                        <i><u>Abstract</u></i>: The primary and selected secondary literature pertaining to the insect
                        superorder Neuropterida (orders Neuroptera, Megaloptera, Raphidioptera and Glosselytrodea) are
                        catalogued and annotated. Strong emphasis is placed on documenting the peer-reviewed scientific
                        literature relevant to the group; informal, popular, secondary, and manuscript print sources are
                        documented where considered noteworthy, as are selected digital- and web-native works.
                        Bibliography coverage is worldwide and includes all aspects of neuropterology. Coverage is
                        particularly complete in the subject areas of systematics, taxonomy, phylogeny, biogeography,
                        morphology, paleontology, biology and faunistics. Extensive coverage is also provided for other
                        subject areas, such as physiology, parasites, ecology, and economic neuropterology. Each of the
                        approximately 17,200 works currently included in the Bibliography is represented by a separate
                        bibliographic record. Bibliographic records provide standardized data for documenting, citing,
                        and locating works and, where available and permissible, provide links to one or more digital
                        representations of those works. Many bibliographic records also contain detailed annotation data
                        that are relevant to specific works. Bibliographic record annotations address a wide variety of
                        topics, including: the neuropterid content of individual works, publication errata, dates of
                        publication, author data, and variant publication states. In addition to Bibliographic record
                        annotations, more than 200,000 index annotations provide page-level access to several classes of
                        information (e.g., figures, identification keys, and new genus- and species-group taxa) that are
                        contained in publications viewable via the Lacewing Digital Library File Viewer.

                        <br/>
                        <br/>
                        <i><u>Cite this Publication as</u></i>: Oswald, J. D. Bibliography of the Neuropterida. <a
                        href="/HomePage/Publications" target="_black"><i>Lacewing Digital Library, Research
                        Publication</i></a> No. 2. http://lacewing.tamu.edu/Biblio/Main. Last accessed [fill in date of
                        last site access].
                        <br/>
                        <br/>
                        <small>(Notes: The different modules of the Lacewing Digital Library constitute distinct
                            publications and should be cited separately. For information on the current version and
                            release of this publication see the <a href="/Biblio/Versions">Versions</a> page.)</small>
                        {/* >>>>>>> master */}
                        <br/>
                        <br/>
                        <i><u>Author Contact Information</u></i>: (JDO, corresponding author) <a
                        href="mailto:j-oswald@tamu.edu">j-oswald@tamu.edu</a>.
                    </p>
                </div>

                <div className="text-body">
                    <p className="author-reserve-markup">© John D. Oswald, {new Date().getFullYear()}<br/>All rights
                        reserved. </p>
                </div>
            </div>
        </div>
    </>
};

export default Main;